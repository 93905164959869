import React, { Component } from 'react'
import Attachment from '../../../images/iconsSVG/Attachment';
import socketClient from "socket.io-client";
import Cookies from 'js-cookie';
import ScrollToBottom from 'react-scroll-to-bottom';
import { Backdrop, Fade, Modal } from '@material-ui/core';

class Mentor_chat_class_comp extends Component {   
  
    state = { 
        chatList:[],
        channel: null,  
        routeUrl:null,
        message:'',
        imageFile:null,
        imagePath:null,
        file:null,
        viewFile:null,
        selectedUser: null,
        room_id: null
     }
      predefined_messages=[
      {
      createdAt: "",
      message: `Hi ${localStorage.getItem('user_name')} I am your mentor ${localStorage.getItem('mentor_name')} for your Digital Marketing Executive Program.`,
      users: {_id: '123', name: 'admin'},
      _id: "62960aed1ea6152d56a3dc59",
      },
      {
       createdAt: "",
       message: "Hope you're finding the course interesting & useful.",
       users: {_id: '123', name: 'admin'},
       _id: "62960aed1ea6152d56a3dc59",
        },
        {
      createdAt: "",
      message: "Any assistance do let me know, I am happy to assist you!",
      users: {_id: '123', name: 'admin'},
      _id: "62960aed1ea6152d56a3dc59",
          }
    ]
     componentDidMount() {
       console.log('triggered',this.predefined_messages)
       this.setState({ chatList:this.predefined_messages })

        this.configureSocket();
       
        if (this.socket.connected) {
          this.sendMessage(null);
        }

        this.setState({ selectedUser: localStorage.getItem('mentor_id') })
        setInterval(() => {
          this.getMessages();
        }, 1000)
      }


      configureSocket = () => {
        var socket = socketClient("https://api.ezioskillacademy.com", {
          transports: ['websocket', 'polling', 'flashsocket'],        
          query: { token: `Bearer ${Cookies.get('token')}`, room_id: localStorage.getItem('user_id') || null }        
        });
        this.setState({ room_id: localStorage.getItem('user_id') || null})
        this.socket = socket;
        
        socket.on('connection', () => {
          
          if (socket.connected) {

            
            this.getMessages();
          }
        });
        
        // Join chatroom
        socket.emit('joinRoom', {});
        
        socket.on('message', message => {
          
          if (message.room_id === this.state.room_id) {
            this.setState({ chatList:[...this.predefined_messages,...message.messages] })
          }
        });
        // socket.on('notification', message => {
        
        //   this.setState({notiCount:message.notifocation_count})
        //     //this.setState({ chatList:message })
        // });
        // socket.emit('get-notification-count',null,(n)=>{
        
        // })
        // socket.emit('get-message-count',null,(n)=>{
          
        
        // })
    }
    getMessages = () => {
        
        this.socket.emit('get-message',{ "batch_id": localStorage.getItem('user_id') || null });
      }
      sendMessage = (text) => {
        
        let channel_id=1
       
        if(text?.trim() || this.state.imageFile || this.state.file){
        let requiredFile=this.state.imageFile||this.state.file
        let requiredFilename=this.state.imageFile?.name||this.state.file?.name
        
        this.socket.emit(
          'send-message',
          { 
            channel_id,
            text:text,
            file:requiredFile,
            file_name:requiredFilename,
            senderName: localStorage.getItem('user_name'),
            id: Date.now(),
            to: this.state?.selectedUser?._id||null,
            batch_id: localStorage.getItem('user_id') || null }
        ); 
      
        this.setState({message:''})
        this.setState({imageFile:null})
        this.setState({file:null})
        this.setState({selectedUser:null})
        this.setState({mentionUserListExecutedCount:0})
        this.setState({initAtSymbol:false})
        
        }
      }

      attachFile_mentor=(e)=>{
        //const previewImg=document.getElementById('preview_img')
          if(e.target.files[0].type.includes('image')){
              this.setState({imageFile:e.target.files[0]})
              this.setState({imagePath:URL.createObjectURL(e.target.files[0])})
            
          }
          else{
              this.setState({file:e.target.files[0]})
          }
      }
    render() { 
      
        return (   
       <>
       {console.log('hello',this.state.chatList)}
        <div className="title">Mentor Chat
        <i class="fa fa-times" aria-hidden="true" onClick={this.props.mentorChatHandler}></i>
        </div>
        <div className="mc_box">
        <ScrollToBottom className="message-container_mentor">
        {
                   this.state.chatList.length>0?(
                     <>
                     {
                       this.state.chatList.map((chat,index)=>{
                         let date=new Date(chat.createdAt)
                         let dateCondition=`${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`.includes('NaN')?'':`${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
                        const isImg=chat?.file_url? [ "jpeg", "jpg", "gif", "png" ].includes(chat.file_url.split('.')[chat.file_url.split('.').length-1]):null

                        const fileName=chat?.file_url?.split('/')[chat?.file_url?.split('/').length-1]


                         return (
                            <>
                           <div key={index} 
                           className={localStorage.getItem('user_id')==chat.users._id?'item right':'item'}
                           >
                                 {
                               localStorage.getItem('user_id')!==chat.users._id&&(
                                <h1 className='mentor_name'>{localStorage.getItem('mentor_name')}</h1>
                               )
                             }
                             
                             {
                               localStorage.getItem('user_id')!==chat.users._id&&(
                                <div class="icon">
                                <i class="fa fa-user"></i>
                            </div>
                               )
                             }
                           
                           <div className='msg'>
                           {
                                   chat?.file_url &&(
                                     <a className="download_btn" href={chat.file_url} download>
                                     <i class="fa fa-download" aria-hidden="true"></i>
                                     </a>
                                   )}
                                
                                {
                                  chat.message &&(
                                   <p >{chat.message}</p>
                                  )
                                }
                                {
                                  chat?.file_url &&
                                  <>
                                  {
                                    isImg?(
                                    
                                     <img onClick={()=>this.setState({viewFile:{type:'image',url:chat.file_url}})} src={chat.file_url} style={{cursor:'pointer'}}/>
                                   
                                    ):(
                             
                                      <div className='input_file_box'  
                                      //onClick={()=>this.setState({viewFile:{type:'file',url:chat.file_url}})}
                                      >

                                        <img src={require('../../../images/dashboard/files.svg')}/>
                                        <h5>{fileName?.length>10?fileName.slice(0,10)+'...':fileName||"File"}</h5>                                        
                                      </div>
                                     
                                    )
                                  }                           
                                 
                                  </>
                                    
                                  
                                }
                               
                           </div> 
                           <div className='date_chat_mentor'>{dateCondition}</div>
                         
                           </div>
                             <br clear="both"/>
                           </>
                         )
                       }
                        
                       )
                     }
                     
                     </>                
                   ):(
                     <h1>No Message Found</h1>
                   )
                 }
            </ScrollToBottom>
        
        </div>
        
        <div className="typing-area">
        {
                       this.state.imageFile?(
                         <div className='chat_img_mentor chat_img_mentor_img'>
                         <div>
                           <img id="preview_img" src={this.state.imagePath} alt="" style={{maxHeight:'100px'}}/>
                         </div>
                         </div>
                       ):this.state.file?(
                         <div className='chat_img_mentor'>
                           {this.state.file.name}
                         </div>
                       ):null
                     }
            <div className="input-field">
         
                <input type="text" placeholder="Type your message"  autoFocus onChange={(e=> this.setState({message:e.target.value}))} onKeyPress={(e=>{e.key==='Enter'&&this.sendMessage(this.state.message)})} value={this.state.message}/>
                <input id='attachFile_mentor' type="file" onChange={this.attachFile_mentor} style={{display:'none'}}/>
                <div onClick={()=>{document.getElementById('attachFile_mentor').click()}}>
                   <Attachment />
                   </div>
                <button onClick={()=>this.sendMessage(this.state.message)}>Send</button>
            </div>
        </div>
        <Modal
      aria-labelledby="spring-modal-title"
      aria-describedby="spring-modal-description"
      className={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      open={this.state.viewFile}
      onClose={()=>this.setState({viewFile:null})}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={this.state.viewFile}>
        <div className="view_file_popup">
        
          {
            this.state.viewFile?.type=='image'&&(
              <img src={this.state.viewFile?.url} alt="" />
            )
          }
          {
            this.state.viewFile?.type=='file'&&(
              <iframe 
              //src="http://www.africau.edu/images/default/sample.pdf" 
              src={this.state.viewFile?.url}
              frameBorder="0" height="500px" width="100%"></iframe>
            )
          }
        </div>
        </Fade></Modal>
        </>);
    }
}
 

export default Mentor_chat_class_comp