import React from 'react'

export default function CardRightArrow() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"  width="143.163" height="143.163" viewBox="0 0 143.163 143.163">
            <defs>
                <filter id="Path_13858" x="0" y="0" width="143.163" height="143.163" filterUnits="userSpaceOnUse">
                <feOffset input="SourceAlpha"/>
                <feGaussianBlur stdDeviation="14" result="blur"/>
                <feFlood floodOpacity="0.161"/>
                <feComposite operator="in" in2="blur"/>
                <feComposite in="SourceGraphic"/>
                </filter>
            </defs>
            <g id="Group_4016" data-name="Group 4016" transform="translate(-634 -379)">
                <g transform="matrix(1, 0, 0, 1, 634, 379)" filter="url(#Path_13858)">
                <path id="Path_13858-2" data-name="Path 13858" d="M29.581,0A29.581,29.581,0,1,1,0,29.581,29.581,29.581,0,0,1,29.581,0Z" transform="translate(42 42)" fill="#fba919"/>
                </g>
                <g id="right-arrow" transform="translate(691.53 443.926)">
                <g id="Group_3879" data-name="Group 3879">
                    <path id="Path_13857" data-name="Path 13857" d="M28.354,138.154h0L22.5,132.326a1.12,1.12,0,0,0-1.581,1.588l3.931,3.912H1.12a1.12,1.12,0,1,0,0,2.241H24.848l-3.931,3.912a1.12,1.12,0,0,0,1.581,1.588l5.854-5.826h0A1.121,1.121,0,0,0,28.354,138.154Z" transform="translate(0 -132)"/>
                </g>
                </g>
            </g>
        </svg>
    )
}
