import React from 'react'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';
export default function Faq() {

    const faqLeftList = [
        {
            qus: 'Is there eligibility to enroll in the digital marketing program? ',
            ans: 'The best part of the digital marketing course is that any student who is from either a technical or non-technical background can apply for the digital marketing course. Yes, any student or professional who is willing to learn can join the course.  '
        },
        {
            qus: 'Does Digital Marketing is a demanding career? ',
            ans: `Digital Marketing has been on a constant increase in recent years. This growth is likely to fly up in future years as well. The opportunities will always be high in this domain. So, there is no doubt it's worth learning digital marketing. `
        },
        {
            qus: 'Is there any duration to complete the courses? ',
            ans: 'This is based on the package you select. You can learn from 2 hours to 21 hours and access the LMS portal for 90 days. '
        },
        {
            qus: 'How long can I access the study materials?  ',
            ans: `You'll have access to your own LMS, which has videos and study material for up to 90 days and can extend based on request.  `
        },
        {
            qus: 'What kind of payment option will you offer? ',
            ans: `Based on your convenience, you can pay your fees through UPI, credit, debit cards, cash, cheque, or bank transfers. `
        },
    ]

    const faqRightList = [
        {
            qus: 'Why should I enroll in Ezio Skill Academy? ',
            ans:  `Ezio Skill Academy offers a competitive learning space and framework for professionals, business owners, and students. Apart from cutting-edge study material we are dedicated to our work and offer real-time projects to experience live exposure to the latest industry trends which provide more excelling insights.  

            Any digital marketing aspirants can learn and groom their digital skills and shape their personal and business growth. `
        },
        {
            qus: 'Are there any benefits of getting an online certificate? ',
            ans: 'Earning an online certificate can upgrade your skills and enhance your professional credentials. By the way, you can save time and study based on your convenience. '
        },
     
    ]

    return (
        <>
     
        
        <section style={{height:'100vh',padding:'0'}}>
         
       
        <div className='dash_page_bg d-questionnaire user_faq'>
            <div className='dash_section_ptb dmax-questionnaire-w'>
                <div className='row'>
                    <div className='col-12 col-md-6'>
                        <Accordion allowZeroExpanded>
                            {
                                faqLeftList.map((faql, idx) => (
                                    <AccordionItem key={idx}>
            <AccordionItemHeading>
                <AccordionItemButton>
                    {faql.qus}
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
                <p>{faql.ans}</p>
            </AccordionItemPanel>
        </AccordionItem>
                                ))
                            }
                        </Accordion>
                    </div>
                    <div className='col-12 col-md-6'>
                        <Accordion allowZeroExpanded>
                            {
                                faqRightList.map((faql, idx) => (
                                    <AccordionItem key={idx}>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            {faql.qus}
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>{faql.ans}</p>
                                    </AccordionItemPanel>
                                </AccordionItem>
                                ))
                            }
                        </Accordion>
                    </div>
                </div>                    
            </div>
        </div>
        </section>
        </>
    )
}
