import React,{useEffect} from 'react';
import logo from './logo.svg';
import 'bootstrap/dist/css/bootstrap.css'
import 'font-awesome/css/font-awesome.min.css';
import './App.css';

import { BrowserRouter as Router , Route } from 'react-router-dom';
import { withRouter } from 'react-router'
import Entrollment from './components/entrollment/entrollment.js'
import Landing from './components/landing/landing.js'
import Home from './components/website/pages/home.js'
import Lessons from './components/lessons/lessons.js'
//import Payment from './components/payment/payment.js'
import Footer   from './components/Footer/footer.js'
import Header  from  './components/Header/Header.js'
import Index from  './components/Dashboard/index.js'
import AdminIndex from './components/Dashboard/admin';
import Login from './components/Dashboard/login';
import SuperAdminIndex from './components/Dashboard/superadmin/index';
import { Switch } from 'react-router-dom';
import Notification from './components/Dashboard/user/notification';
import { Redirect } from 'react-router-dom';
import IndexWebsite from './components/website';


function App(props) {
	// // console.log(props)
	useEffect(()=>{
		// console.log('props',props.location.pathname)
		 document.addEventListener('contextmenu', (e) => {
      e.preventDefault();
    });

    document.onkeydown = function(e) {
      if(e.keyCode == 123) {
         return false;
      }
      if(e.ctrlKey && e.shiftKey && e.keyCode == 'I'.charCodeAt(0)) {
         return false;
      }
      if(e.ctrlKey && e.shiftKey && e.keyCode == 'C'.charCodeAt(0)) {
         return false;
      }
      if(e.ctrlKey && e.shiftKey && e.keyCode == 'J'.charCodeAt(0)) {
         return false;
      }
      if(e.ctrlKey && e.keyCode == 'U'.charCodeAt(0)) {
         return false;
      }
    }
	},[])
	const loadScript = (src) => {
		return new Promise((resolve) => {
		  const script = document.createElement("script");
		  script.src = src;
		  script.onload = () => {
			resolve(true);
		  };
		  script.onerror = () => {
			resolve(false);
		  };
		  document.body.appendChild(script);
		});
	  };
	
	  useEffect(() => {
		loadScript("https://checkout.razorpay.com/v1/checkout.js");
	  });
	
  return (
    <div className="App">

	 <Switch>
	 {/* <Router> */}
		
		{/* <Route exact path='/home' component={Home} /> */}
		<Route exact path='/login' component={Login} />
		{/* <Route exact path='/entroll' component={Entrollment} />
		<Route exact path='/payment' component={Payment} /> */}
		<Route  path='/superadmin'>
			{
				localStorage.getItem('user_id') && localStorage.getItem('isSuperadmin') ?<SuperAdminIndex/>:<Redirect to={'/login'}/>
			}
		</Route>
		<Route  path='/admin'>
			{
				localStorage.getItem('user_id') && localStorage.getItem('isAdmin') ?<AdminIndex/>:<Redirect to={'/login'}/>
			}
		</Route>
		<Route  path='/course'>
			{
				localStorage.getItem('user_id') && localStorage.getItem('isUser') ?<Index/>:<Redirect to={'/login'}/>
			}
		</Route>
		
		{/* {
			localStorage.getItem('user_id') && localStorage.getItem('isAdmin') ?<Route  path='/superadmin' component={SuperAdminIndex} />	:
			localStorage.getItem('user_id')?<Route  path='/course' component={Index} />	:<Route><Redirect to={'/login'}/></Route>
		} */}
		
		<Route  path='/' component={IndexWebsite} />
	
		{/* <Route exact path="/user/notifications" component={ Notification } /> */}
		{/* <Route exact path='**' component={PageNotFound} /> */}
			
			
	 {/* </Router> */}
	 </Switch>
	 
    </div>
  );
}

export default withRouter(App);


function PageNotFound(){
	return(
<div style={{width:'100vw',height:'100vh',display:'flex',alignItems:'center',justifyContent:'center'}}>
<h1>Page Not Found</h1>
				</div>
	)
}