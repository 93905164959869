import React from 'react'

export default function TestimonialsIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="23.23" height="22.307" viewBox="0 0 23.23 22.307">
      <g id="Testimonials_" data-name="Testimonials " transform="translate(-14658 20675)">
        <g id="XMLID_2534_" transform="translate(14658 -20684.973)">
          <path id="XMLID_2538_" d="M23.23,299.521l-2.743-.4-1.227-2.486h0l-1.227,2.486-2.593.377.956-.932-3.3-.48-1.478-2.994-1.478,2.994-3.3.48.956.932L5.2,299.123,3.97,296.637h0l-1.226,2.485-2.743.4,1.985,1.935-.469,2.732L3.97,302.9h0l2.454,1.29-.469-2.732,1.921-1.872L9.224,300.9l-.564,3.291,2.955-1.554,2.955,1.554-.564-3.291,1.348-1.314,1.921,1.873-.469,2.732,2.454-1.29h0l2.454,1.29-.469-2.732,1.985-1.935Z" transform="translate(0 -271.909)" fill="#143059"/>
          <path id="XMLID_2638_" d="M15.774,103.148v-.7a5.987,5.987,0,0,1,.89-3.148,3.851,3.851,0,0,0-.752-.4,2.085,2.085,0,1,0-2.825,0,3.828,3.828,0,0,0-2.41,3.551v.7h5.1Z" transform="translate(-10.182 -81.36)" fill="#143059"/>
          <path id="XMLID_2639_" d="M362.362,102.452v.7h5.1v-.7a3.828,3.828,0,0,0-2.41-3.551,2.085,2.085,0,1,0-2.825,0,3.855,3.855,0,0,0-.752.4A5.987,5.987,0,0,1,362.362,102.452Z" transform="translate(-344.723 -81.359)" fill="#143059"/>
          <path id="XMLID_2731_" d="M159.944,21.093h0a4.635,4.635,0,0,0-3-4.335,3.591,3.591,0,1,0-3.276,0,4.635,4.635,0,0,0-3,4.335v.7h9.267Z" transform="translate(-143.696)" fill="#143059"/>
        </g>
      </g>
    </svg>
  )
}
