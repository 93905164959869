import React from 'react'

function DbPayment() {
  return (    
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
          <g id="wallet" transform="translate(-0.003 0)">
            <g id="Group_4792" data-name="Group 4792" transform="translate(0.003 0)">
              <g id="Group_4791" data-name="Group 4791" transform="translate(0 0)">
                <path id="Path_14860" data-name="Path 14860" d="M18.676,16.364H15.488a3.636,3.636,0,1,1,0-7.273h3.188a.455.455,0,0,0,.455-.455V7.273a1.816,1.816,0,0,0-1.677-1.8L14.839.91A1.8,1.8,0,0,0,12.366.245L3.4,5.455H1.825A1.822,1.822,0,0,0,0,7.273V18.182A1.822,1.822,0,0,0,1.825,20H17.31a1.822,1.822,0,0,0,1.822-1.818V16.819A.455.455,0,0,0,18.676,16.364ZM15.4,3.725l.992,1.73h-3.97ZM5.21,5.455l7.615-4.424A.882.882,0,0,1,13.5.94a.893.893,0,0,1,.547.422v0L7.008,5.455Z" transform="translate(-0.003 0)" fill="#fff"/>
                <path id="Path_14861" data-name="Path 14861" d="M304.335,234.674h-3.051a2.615,2.615,0,0,0,0,5.229h3.051a1.309,1.309,0,0,0,1.307-1.307v-2.615A1.309,1.309,0,0,0,304.335,234.674Zm-3.051,3.486a.872.872,0,1,1,.872-.872A.873.873,0,0,1,301.285,238.16Z" transform="translate(-285.643 -224.519)" fill="#fff"/>
              </g>
            </g>
          </g>
        </svg>
  )
}

export default DbPayment