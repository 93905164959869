import React, { useEffect } from 'react'
import Arrow from '../../../../images/iconsSVG/Arrow'
import RedArrow from '../../../../images/iconsSVG/RedArrow'
import $ from 'jquery'
import Slider from "react-slick";
import { ourCourses } from '../data/our_courses';
import MoreCourse from './common/more_courses';


function Marketing_Fundamentals() {
  useEffect(()=>{

    const sectionAll = document.querySelectorAll("section");
            const observer = new IntersectionObserver(
              (entries) => {
                entries.forEach((entry) => {
                   
                  if (entry.isIntersecting) {
                    
                    entry.target.classList.add("scroll_ani");
                    
                  }
                });
              },
              {
                rootMargin: "-100px",
              }
            );
            sectionAll.forEach((section) => {
              observer.observe(section);
            });

    courseAccordian('1')
  },[])
  const courseSyllabus=[
    {
      id:'1',
      title:' What is Marketing Mix?',
      desc:'A marketing mix is a set of principles or tactics that must focus on a complete marketing plan.  It generally refers to the 4Ps of marketing: product, price, promotion, and place. Paying attention to marketing will assist the organization in making strategic decisions while introducing new products or reworking existing products. '
    },
    {
      id:'2',
      title:'What is Digital Marketing? ',
      desc:'Digital Marketing is the advertising method of promoting your brand or services on the internet or through any other digital medium, which is also called online marketing. '
    },
    {
      id:'3',
      title:'Difference between Traditional Marketing and Digital Marketing? ',
      desc:'Traditional marketing is any type of advertising which happens offline. It means advertising takes place on radio, broadcast, print, newspaper, and direct mail. In contrast, digital marketing is a type of advertising for a product or service which happens online. Such as social media, Pay-per-click, content marketing, email marketing, and more. '
    },
    {
      id:'4',
      title:'Enhancing Website Performance',
      desc:'You know what? The performance of your website is an important factor that must be concentrated on when it comes to website optimization. Suppose your site loading time is too long; users will not be going to wait anymore. '
    },
    {
      id:'5',
      title: `Creating Social Media Strategy`,
      desc:`A social media strategy is the outline of everything you perform on this media and get confidence to hit on social media. It'll direct your every step of action. Hence, the more precise approach, the more winning it will be. Depending on your audience, brand, objective, and media, your strategy will vary.  `
    },
    {
      id:'6',
      title:'What is Paid-Search-Advertising?',
      desc:`PPC is also referred to as pay-per-click, it's a type of online advertising. Here marketers will bid on keywords that have high traffic. These ads are displayed to a target audience, channel, or keyword search. `
    },
    {
      id:'7',
      title:'How to Implement Advanced Email Marketing?  ',
      desc:`In this course, you'll come to know the strategies and methods to convert your email marketing from zero to an amazing one.  By applying segmentation and personalization to build ever-changing customized, learn how to come up with highly effective email.   `
    },
  ]
  function courseAccordian(id){
    $('.course_syllabus_inner_left ul li').removeClass('course_syllabus_acc_active')
    $('#course_syllabus_acc'+id).addClass('course_syllabus_acc_active')

  }
  const recognized_certification=[
    {
        id:'1',
        name:'Ezio Skill Academy Certificate',
        desc:'Master the Talents You Required for becoming A Marketing Expert in Any verticals across the globe. Experiential learning through advance syllabus, case studies & live project centric. ',  
        img:'Ezio-Skill-Academy.jpg',
    },
    {
        id:'2',
        name:'Ezio Audit Tool Certificate  ',
        desc:'Expertise in search engine audit tools, our hands-on training let you easily analyze the website and know what is working and what is not. Experience the site audit tool usage and turn the website weaker side into a stronger one.  ',  
        img:'Ezio-Skill-Academy.jpg',
    },
    {
        id:'3',
        name:'LinkedIn Certificate',
        desc:'Become exceptionally skilled and gain the knowledge of DM strategy to transform you into a “Qualified Digital Marketer”.',  
        img:'linkedi-certificate-1.jpg',
    },
    {
        id:'4',
        name:'HubSpot Academy Certificate   ',
        desc:'The candidates will gain top-notch skills in implementing digital marketing strategies with high-standard materials and training exercises. A digital marketing certificate from HubSpot Academy is recognized by the universities.   ',  
        img:'hubspot-academy.jpg',
    },
    {
        id:'5',
        name:'Facebook Blueprint Certificate  ',
        desc:'Earn Facebook blueprint certified "digital marketing associate” becoming an expert level proficiency in Facebook advertising. This is excluded in the Course cost & separate cost will be applicable for this certification ',  
        img:'Facebook-Blueprint.jpg',
    },
    {
        id:'6',
        name:'Google Analytics   ',
        desc: `Qualified in fundamental and advanced Google analytics concepts and Google's measurement tools. Google Analytics Certificates make provision for implementing SEO strategy through learning high-in-demand skills.  `,  
        img:'Google-Analytics.jpg',
    },
    {
        id:'7',
        name:'Google Ads  ',
        desc:'It is an industry-recognized marketing certificate from Google. Professional accreditation is provided by Google. Recognized you as a master in Google Ads.     ',  
        img:'adwords.jpg',
    },
    {
        id:'8',
        name:'Bing Ads   ',
        desc:'Knowing the hack to push your business forward from the effective Microsoft advertising campaigns and enhance your skillset on ads through this program.  With the Bind ads certification, upskill your Microsoft advertising proficiency. ',  
        img:'Bing-Ads-certification.jpg',
    },
]

const certificate_settings = {
  dots: true,
  infinite: true,
  speed: 500,
  autoplay: false,
  slidesToShow: 1,
  slidesToScroll: 1
};


  return (
    <div id='courses'>
    <section className="banner_sec">     
<div className="container">
 <div className="row">
           <div className="col-lg-6 col-md-6 centered_content website_left_inner_banner">
                {/* <p>Academic &gt; &nbsp;<span>Digital Marketing </span></p> */}
                <h1>
                Marketing Fundamentals
                </h1> 
                <p style={{margin:'20px 0'}}> 
                Acquire knowledge of core principles of marketing, which you master's in marketing fundamentals. Along with the introduction to digital marketing transformation. Get the outline of Digital Marketing and how to build & implement a strategy.  
                </p>
                <div style={{display: "flex"}}>
                <a href='#syllabus'>
                    <button className="btn btn-outline-primary my-2 my-sm-0 ml-3 active_btn apply_now_btn" type="submit"> Explore Course </button> 
               </a>
                </div> 
            </div>

            <div className="col-lg-6 col-md-6" >
                <img className='website_banner_standing_girl' src={require('../../../../images/website/Courses_page/women-png.png')} alt='' style={{display:'block',margin:'auto'}} />
                <img className='banner_round_icon' src={require('../../../../images/website/Courses_page/1.png')} alt='' style={{left:'8%',top:'10%',filter: 'drop-shadow(0px 9px 14px rgba(50, 50, 50, 0.15))'}}/>
                <img className='banner_round_icon' src={require('../../../../images/website/Courses_page/archer.png')} alt='' style={{right:'10%',top:'16%'}}/>
                <img className='banner_round_icon' src={require('../../../../images/website/Courses_page/peoples.png')} alt='' style={{right:'5%',bottom:'10%',height:'65px'}}/>
                <img className='banner_round_icon' src={require('../../../../images/website/Courses_page/analytics.png')} alt='' style={{left:'15%',bottom:'5%',height:'65px',filter: 'drop-shadow(0px 9px 14px rgba(50, 50, 50, 0.15))'}}/>
              </div>
 </div>
</div>
</section>


<section id='about' className='about_free_certification'>
  <div className='container'>
      <div className='row' style={{display:'flex',flexWrap:'wrap-reverse',justifyContent:'center'}}>
      {/* <div className='col-lg-6'>
        <div className='about_free_certification_left_inner'>
            <div className='hos_small_box'>
              <h3>130+</h3>
              <p>Lorem Etiam vel ullamcorper augue</p>
            </div>
            <div className='hos_small_box'>
              <h3>130+</h3>
              <p>Lorem Etiam vel ullamcorper augue</p>
            </div>
            <div className='hos_small_box'>
              <h3>130+</h3>
              <p>Lorem Etiam vel ullamcorper augue</p>
            </div>
            <div className='hos_small_box'>
              <h3>130+</h3>
              <p>Lorem Etiam vel ullamcorper augue</p>
            </div>
            </div>
      </div> */}
      <div className='col-lg-12'>
          <h2>About this Marketing Fundamentals Course  </h2>
          <p>In this course, you'll learn about the fundamentals of a marketing mix that make you ready for the most crucial stage of taking your product or services in front of the market, in what method, at which place? It is wrapped with a complete introduction to four pillars of the marketing mix: product, price, place, and promotion. It is beneficial for starters who are interested in having a taste of how marketing works. Including the basics of Digital Marketing and its power. Have a deeper look at digital marketing essentials and the transformation from traditional advertising 
            <br/><br/>
            In this course, you'll understand digital marketing strategies and techniques that perform best for your business and customers.  Getting deeper into the core concepts of marketing and knowing the modern advertising approaches will make you gear up to implement marketing strategies. 
          </p>
      </div>
      </div>
  </div>
</section>


<section id='skill_tools' className='skills_and_tools'>
  <div className='container'>
    <h2>Skills and Tools</h2>
    <p>The Digital Marketing course will transform you into a digital marketing expert through learning advanced skills, which will help you to be an industry-ready professional.  </p>
      <div className='row'>
      <div className='col-lg-6 '>
            <h3>Skills</h3>
            <ul className='skills_and_tools_inner'>
              <li><RedArrow/> Understanding Marketing Theory  </li>
              <li><RedArrow/> Search Engine Optimization  </li>
              <li><RedArrow/> Local Marketing </li>
              <li><RedArrow/> Pay-Per-Click </li>
              <li><RedArrow/> Email Marketing  </li>
              <li><RedArrow/> Social Media Marketing </li>
              <li><RedArrow/> Digital Marketing Strategy  </li>
              {/* <li><RedArrow/> Marketing plan implementation</li> */}
              <li><RedArrow/> Web Design & Website Optimization </li>
            </ul>
    
      </div>
      <div className='col-lg-6'>
          <h3>Tools</h3>
          <div className='skill_tools_con'>
        
            <div className='skill_tools'>
                <img src={require('../../../../images/website/Tools-you-wil-master/google-ads.png')} alt=""/>
            </div>
            <div className='skill_tools'>
                <img src={require('../../../../images/website/Tools-you-wil-master/google-anylitsis.png')} alt=""/>
            </div>  
            <div className='skill_tools'>
                <img src={require('../../../../images/website/Tools-you-wil-master/fb-ads.png')} alt=""/>
            </div>
            <div className='skill_tools'>
                <img src={require('../../../../images/website/Tools-you-wil-master/google-tag-manager.png')} alt=""/>
            </div>
            <div className='skill_tools'>
                <img src={require('../../../../images/website/Tools-you-wil-master/linkedin-ads.png')} alt=""/>
            </div>
            <div className='skill_tools'>
                <img src={require('../../../../images/website/Tools-you-wil-master/google-search-console.png')} alt=""/>
            </div>
            <div className='skill_tools'>
                <img src={require('../../../../images/website/Tools-you-wil-master/twitter-ads.png')} alt=""/>
            </div>
            <div className='skill_tools'>
                <img src={require('../../../../images/website/Tools-you-wil-master/mailchimp.png')} alt=""/>
            </div>
        </div>
        </div>
      </div>
  </div>
</section> 

<section id='syllabus' className='course_syllabus'>
  <div className='container'>
      <div className='row' style={{display:'flex',flexWrap:'wrap-reverse'}}>
      <div className='col-lg-6 course_syllabus_inner_left'>
            <ul>
              {
                courseSyllabus.map((course)=>(
                  <li id={'course_syllabus_acc'+course.id} onClick={()=>courseAccordian(course.id)} key={course.id}>
                  <h4> {course.title} </h4>
                  <p>{course.desc}</p>
                  </li>
                ))
              }
              
            </ul>
    
      </div>
      <div className='col-lg-6' style={{display:'flex',flexDirection:'column',justifyContent:'center'}}>
         <h2>Course Syllabus</h2>
         <p>Introduction to Marketing Fundamentals, Evolution of Digital Marketing: Traditional to Online Marketing, Overview of Digital Marketing: SEO, Social Media Strategy, Running Campaigns, Keyword Research. </p>
         <a href='/Ezio_Skill_Academy.pdf' download>
         <button>Download Syllabus</button>
              </a>
      </div>
      </div>
  </div>

</section>

<section id='certification' className="website_certificate section_spaces">

    <div className="container">
        <h2>Course Certificate  </h2>
        <p>Certificate  

Receiving the Marketing Fundamentals certificate from Ezio Skill Academy assists you to find a job and paves the way to starting a new career. You can easily include this qualification in your CV and upload it to your LinkedIn profile. Get the overall outline of the digital marketing domain. </p>
    <Slider {...certificate_settings}>
        {
            recognized_certification.map((certi,index)=>(
        <div key={index}>
        <div className='row'>
            <div className='col-lg-6 website_certificate_left_inner'>
                <h2>{certi.name}</h2>
                <p>{certi.desc}</p>
            </div>
            <div className='col-lg-6'>
                <img src={require(`../../../../images/website/certificate/${certi.img}`)} alt=""/>
            </div>
        </div>     
        </div> 
            ))
        }
        
      </Slider>
        </div>
    </section>

    <MoreCourse url={'marketing-fundamentals'}/>


</div>
  )
}

export default Marketing_Fundamentals



{/* <section className=''>
  <div className='container'>
      <div className='row'>
      <div className='col-lg-6 '>
            
    
      </div>
      <div className='col-lg-6'>
         
      </div>
      </div>
  </div>

</section> */}