import React from 'react'

function EditIcon1() {
    return (
       
            
<svg id="edit_3_" data-name="edit (3)" xmlns="http://www.w3.org/2000/svg" width="14.068" height="14.068" viewBox="0 0 14.068 14.068">
  <g id="Group_4388" data-name="Group 4388">
    <g id="Group_4387" data-name="Group 4387">
      <path id="Path_14080" data-name="Path 14080" d="M0,76.542v2.931H2.931l8.649-8.649L8.649,67.893Z" transform="translate(0 -65.405)" fill="#373737"/>
      <path id="Path_14081" data-name="Path 14081" d="M263.028,2.054,261.2.229a.783.783,0,0,0-1.106,0l-1.43,1.43L261.6,4.59l1.43-1.43A.783.783,0,0,0,263.028,2.054Z" transform="translate(-249.188)" fill="#373737"/>
    </g>
  </g>
</svg>

       
    )
}

export default EditIcon1
