import React from 'react'

export function Testiquote() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="101.114" height="101.114" viewBox="0 0 101.114 101.114">
            <g id="Group_4118" data-name="Group 4118" transform="translate(-326.776 -136.109)">
                <circle id="Ellipse_346" data-name="Ellipse 346" cx="38.333" cy="38.333" r="38.333" transform="matrix(0.915, -0.404, 0.404, 0.915, 326.776, 167.1)" fill="#e73d67"/>
                <g id="Group_4117" data-name="Group 4117">
                <path id="Path_13997" data-name="Path 13997" d="M365.9,179.713a12.417,12.417,0,0,1,3.261-5.332,1.637,1.637,0,0,0,.068-2.268l-.181-.2a1.62,1.62,0,0,0-1.946-.359c-12.93,6.786-12.768,15.991-12.64,17.554-.009.172-.027.342-.027.517a10.028,10.028,0,1,0,11.465-9.913Z" fill="#f7fbfc"/>
                <path id="Path_13998" data-name="Path 13998" d="M389.8,179.713a12.417,12.417,0,0,1,3.261-5.332,1.637,1.637,0,0,0,.068-2.268l-.181-.2a1.62,1.62,0,0,0-1.946-.359c-12.93,6.786-12.768,15.991-12.64,17.554-.009.172-.027.342-.027.517a10.028,10.028,0,1,0,11.465-9.913Z" fill="#f7fbfc"/>
                </g>
            </g>
        </svg>
    )
}

export function Testibg() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="1200" height="628" viewBox="0 0 1200 628">
            <g id="Group_4454" data-name="Group 4454" transform="translate(-10205 1900)">
                <g id="Group_4114" data-name="Group 4114" transform="translate(10205 -1900)">
                <g id="Group_4113" data-name="Group 4113">
                    <path id="Path_13994" data-name="Path 13994" d="M1200,628V378.069c-27.055-1.283-69.075,7.5-108.917,60.018-66,87-30,121.494-124.493,158.992A161.126,161.126,0,0,0,917.248,628Z" fill="#f59c95"/>
                    <circle id="Ellipse_343" data-name="Ellipse 343" cx="18.5" cy="18.5" r="18.5" transform="translate(894 574)" fill="#f59c95"/>
                    <circle id="Ellipse_344" data-name="Ellipse 344" cx="7.5" cy="7.5" r="7.5" transform="translate(858 600)" fill="#f59c95"/>
                </g>
                </g>
                <g id="Group_4115" data-name="Group 4115" transform="translate(10205 -1900)">
                <path id="Path_13995" data-name="Path 13995" d="M0,0V250.665a131.076,131.076,0,0,0,23.6-6.574c87-33,58.5-172.492,136.493-194.99,36.6-10.56,55.7-30.7,65.657-49.1Z" fill="#bee3e0"/>
                <path id="Path_13996" data-name="Path 13996" d="M266.4,5.523a42.09,42.09,0,1,0,84.18,0A42.466,42.466,0,0,0,350.213,0H266.777A42.466,42.466,0,0,0,266.4,5.523Z" fill="#bee3e0"/>
                <circle id="Ellipse_345" data-name="Ellipse 345" cx="7.5" cy="7.5" r="7.5" transform="translate(368.584 14.602)" fill="#bee3e0"/>
                </g>
            </g>
        </svg>
    )
}

