import { Breadcrumbs, Typography } from '@material-ui/core'
import React from 'react'
import { Link, Route, Switch } from 'react-router-dom'
import { AddCourse, EditCourse } from './add-edit-course'
import Courselist from './courselist'
import Sectionlist from './sectionlist'
import VideoList from './videolist'



function CourseManagementIndex() {
  return (
    <div>
	<Breadcrumbs aria-label="breadcrumb">
    			<Link color="inherit" to="/course" >
    			  Course
    			</Link>
				<Link color="inherit" to={"/course/"} >
                Course
    			</Link>
    			<Typography color="textPrimary">Course</Typography>

          </Breadcrumbs> 	
          <br/>
                <Switch>
                <Route exact path='/superadmin/course' component={Courselist} />
                <Route exact path='/superadmin/course/add' component={AddCourse} />
                <Route exact path='/superadmin/course/edit/:id' component={EditCourse} />

                <Route exact path='/superadmin/course/:section_id' component={Sectionlist} />
                <Route exact path='/superadmin/course/section/:video_id' component={VideoList} />
                
                </Switch>

    </div>
  )
}

export default CourseManagementIndex