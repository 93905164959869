import React from "react";

export function TwitterIcon() {
  return (
    <div>
      <svg
        id="twitter"
        xmlns="http://www.w3.org/2000/svg"
        width="28.286"
        height="22.982"
        viewBox="0 0 28.286 22.982"
      >
        <g id="Group_4096" data-name="Group 4096" transform="translate(0)">
          <path
            id="Path_14007"
            data-name="Path 14007"
            d="M28.286,50.721a12.09,12.09,0,0,1-3.341.916A5.766,5.766,0,0,0,27.5,48.431a11.589,11.589,0,0,1-3.677,1.4A5.8,5.8,0,0,0,13.787,53.8a5.971,5.971,0,0,0,.134,1.322A16.414,16.414,0,0,1,1.969,49.057a5.8,5.8,0,0,0,1.782,7.75,5.727,5.727,0,0,1-2.62-.714v.064a5.825,5.825,0,0,0,4.646,5.7,5.788,5.788,0,0,1-1.52.191,5.128,5.128,0,0,1-1.1-.1,5.854,5.854,0,0,0,5.418,4.04,11.652,11.652,0,0,1-7.19,2.473A10.859,10.859,0,0,1,0,68.38a16.325,16.325,0,0,0,8.9,2.6c10.671,0,16.5-8.839,16.5-16.5,0-.256-.009-.5-.021-.75A11.568,11.568,0,0,0,28.286,50.721Z"
            transform="translate(0 -48)"
          />
        </g>
      </svg>
    </div>
  );
}

export function TwitterLogo() {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        viewBox="0 0 40 40"
      >
        <g
          id="Group_4334"
          data-name="Group 4334"
          transform="translate(-515 -452)"
        >
          <circle
            id="Ellipse_373"
            data-name="Ellipse 373"
            cx="20"
            cy="20"
            r="20"
            transform="translate(515 452)"
            fill="#00b9ff"
          />
          <g id="twitter" transform="translate(525.53 464.681)">
            <g id="Group_4096" data-name="Group 4096" transform="translate(0)">
              <path
                id="Path_14007"
                data-name="Path 14007"
                d="M19.226,49.849a8.218,8.218,0,0,1-2.271.622,3.919,3.919,0,0,0,1.734-2.179,7.877,7.877,0,0,1-2.5.954,3.941,3.941,0,0,0-6.818,2.7,4.058,4.058,0,0,0,.091.9,11.156,11.156,0,0,1-8.124-4.123A3.942,3.942,0,0,0,2.55,53.986,3.892,3.892,0,0,1,.769,53.5v.043a3.96,3.96,0,0,0,3.158,3.873,3.934,3.934,0,0,1-1.033.13,3.485,3.485,0,0,1-.746-.067A3.979,3.979,0,0,0,5.83,60.225,7.92,7.92,0,0,1,.943,61.906,7.381,7.381,0,0,1,0,61.852a11.1,11.1,0,0,0,6.046,1.769A11.141,11.141,0,0,0,17.265,52.405c0-.174-.006-.342-.014-.509A7.863,7.863,0,0,0,19.226,49.849Z"
                transform="translate(0 -48)"
                fill="#fff"
              />
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
}
