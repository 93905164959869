import React from 'react'

function DbLogout() {
  return (
    
<svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_850_38)">
<path d="M13.5003 15.1382C14.8708 15.1382 15.9858 14.0232 15.9858 12.6527V2.48548C15.9858 1.11502 14.8708 0 13.5003 0C12.1297 0 11.0146 1.11502 11.0146 2.48553V12.6527C11.0146 14.0232 12.1297 15.1382 13.5003 15.1382V15.1382Z" fill="#F14A51"/>
<path d="M21.8668 6.86611C21.8604 6.8592 21.854 6.8525 21.848 6.84655C21.3786 6.37716 20.7544 6.11865 20.0906 6.11865C19.4266 6.11865 18.8025 6.3771 18.333 6.84655C17.8635 7.31599 17.6049 7.9402 17.605 8.60418C17.605 9.2574 17.8554 9.87213 18.3107 10.3389C18.3179 10.3467 18.3253 10.3544 18.3329 10.362C19.6237 11.6528 20.3346 13.3691 20.3346 15.1947C20.3346 18.9632 17.2687 22.029 13.5002 22.029C9.73175 22.029 6.66583 18.9632 6.66583 15.1947C6.66583 13.369 7.37669 11.6528 8.66757 10.362C9.63646 9.39304 9.63657 7.81622 8.66736 6.8467C8.19787 6.37742 7.57365 6.11892 6.90988 6.11892C6.24601 6.11892 5.6219 6.37742 5.15246 6.84691C2.92274 9.07668 1.69482 12.0413 1.69482 15.1947C1.69482 18.348 2.92274 21.3126 5.15251 23.5424C7.38228 25.7722 10.3468 27.0002 13.5002 27.0002C16.6536 27.0002 19.6181 25.7722 21.8479 23.5424C24.0777 21.3126 25.3056 18.348 25.3056 15.1947C25.3056 12.0507 24.0846 9.09361 21.8668 6.86611V6.86611Z" fill="#F14A51"/>
</g>
<defs>
<clipPath id="clip0_850_38">
<rect width="27" height="27" fill="white"/>
</clipPath>
</defs>
</svg>

  )
}

export default DbLogout