import React from 'react'

export default function TicketingIcon() {
  return (
<svg xmlns="http://www.w3.org/2000/svg" width="21.275" height="12.682" viewBox="0 0 21.275 12.682">
  <g id="Ticketing" transform="translate(0 -102.707)">
    <path id="Path_14056" data-name="Path 14056" d="M6.357,102.708H.669a.669.669,0,0,0-.669.669v3.393a.669.669,0,0,0,.669.669,1.608,1.608,0,0,1,0,3.216.669.669,0,0,0-.669.669v3.393a.669.669,0,0,0,.669.669H6.357Z" transform="translate(0 0)" fill="#143059" />
    <path id="Path_14057" data-name="Path 14057" d="M196.848,107.44a.669.669,0,0,0,.669-.669v-3.393a.669.669,0,0,0-.669-.669h-12.91v12.681h12.91a.669.669,0,0,0,.669-.669v-3.393a.669.669,0,0,0-.669-.669,1.608,1.608,0,0,1,0-3.216Z" transform="translate(-176.242 0)" fill="#143059" />
  </g>
</svg>

  )
}
