import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import Notifications from '../../../images/iconsSVG/Notification';
import moment from 'moment';

const headers={
  Authorization:`Bearer ${Cookies.get('token')}`
}

const Notification = () => {
  const [ notification, setNotification ] = useState();
  const [ isNotify, setisNotify ] = useState(false);
  
  const listNotification = async () => {
    
    // let blob = await fetch("https://ezio-video-courses.s3.ap-south-1.amazonaws.com/sub_section_file-1653998991646.pdf").then(r => r.blob());
    // // console.log("blob", blob);
    
    axios.get('https://api.ezioskillacademy.com/api/notification/list', { headers:{
      Authorization:`Bearer ${Cookies.get('token')}`
    } })
      .then((res) => {
        
        setNotification(res.data.data)
        setisNotify(res.data.data.length > 0 ? true : false)
      });
  }

  useEffect(() => {
    listNotification()
  }, [])

  useEffect(() => {
    // console.log(notification)
  }, [notification]);

  var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  function convertDate(date_str) {
    let temp_date = date_str.split("/");
    return temp_date[0] + " " + months[Number(temp_date[1]) - 1] + " " + temp_date[2];
  }

  const notiStyle={
    margin: "5px auto",
    width: '90%',
    maxWidth:'900px', 
    minHeight:'100px',
    boxShadow:'rgba(0, 0, 0, 0.1) 0px 4px 12px',
    backgroundColor:'#fff',
    cursor: 'pointer'
  }

  const handlereadNotification = (notification_id, isRead) => {
    axios.get(`https://api.ezioskillacademy.com/api/notification/${notification_id}`, { headers:{
      Authorization:`Bearer ${Cookies.get('token')}`
    } })
      .then((res) => {
        // // console.log(res)
        listNotification()
      })
  }

  return(
    <section className='' >
      
      <div className='user_notifcation'>
        {/* <h3>Notification</h3> */}
        { isNotify ?
          notification.slice(0).reverse().map((notify,index) => {
            return (
              <div
                className='user_notifcation_list'
                key={index}
                style={notify.receiver[0].isRead?{...notiStyle}:{...notiStyle,borderLeft:'solid 3px #02b3e4'}}
                onClick = { notify.receiver[0].isRead ? null : () => handlereadNotification(notify._id) }
              >
                <div style={{ display: 'flex', flexDirection: 'row',alignItems:'center' }}>
                  <img src={require('../../../images/account.png')} height="40"/>
               
                    <span style={{ marginLeft: '20px'}}>
                        { notify.subject   }
                    </span>
                    <div className='noti_time'>
                      {/* { convertDate(new Date(notify.createdAt).toLocaleDateString()) },
                      { new Date(notify.createdAt).toLocaleTimeString() } */}
                      { moment.utc(notify.createdAt).local().format("L LT") }
                    </div>
                  
                </div>
              </div>
            )  
          })
          :
          <div className='notification_no_data'>No Notifications to Shown</div>
        }
      </div>
    </section>
  )
};

export default Notification