import React from 'react'

export default function ChatIcon() {
  return (

    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={121} height={121} viewBox="0 0 121 121">
    <defs>
      <filter id="Ellipse_365" x={0} y={0} width={121} height={121} filterUnits="userSpaceOnUse">
        <feOffset input="SourceAlpha" />
        <feGaussianBlur stdDeviation="7.5" result="blur" />
        <feFlood floodOpacity="0.051" />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g id="Group_4291" data-name="Group 4291" transform="translate(-1783.5 -751.5)">
      <g transform="matrix(1, 0, 0, 1, 1783.5, 751.5)" filter="url(#Ellipse_365)">
        <circle id="Ellipse_365-2" data-name="Ellipse 365" cx={38} cy={38} r={38} transform="translate(22.5 22.5)" fill="#fff" />
      </g>
      <path id="Path_14063" data-name="Path 14063" d="M119.517,27.112l-5.334,1.617a13.129,13.129,0,0,0,26.167.13l-5.761-1.746a2.652,2.652,0,0,0-1.036-1.288,17.78,17.78,0,0,0,1.391-1.771,18.629,18.629,0,0,0,2.051-3.849,14.781,14.781,0,0,0,.615-1.618,2.943,2.943,0,0,0,1.05-2.248V14.212a2.932,2.932,0,0,0-.545-1.7V9.519A9.529,9.529,0,0,0,128.6,0H125.51a9.53,9.53,0,0,0-9.519,9.519v2.993a2.934,2.934,0,0,0-.545,1.7v2.126a2.946,2.946,0,0,0,1.05,2.248,14.992,14.992,0,0,0,.615,1.619,18.635,18.635,0,0,0,2.051,3.847,17.656,17.656,0,0,0,1.391,1.772A2.653,2.653,0,0,0,119.517,27.112Zm5.71,7.4a.61.61,0,0,1-.443.525.6.6,0,0,1-.164.023.611.611,0,0,1-.487-.243l-1.715-2.274a.612.612,0,0,1-.123-.368l0-3.646a.61.61,0,0,1,.9-.539,5.091,5.091,0,0,0,1.481.527.61.61,0,0,1,.5.6V30.64a.61.61,0,0,1,.384.631Zm6.582-2.337a.611.611,0,0,1-.123.368l-1.712,2.272a.609.609,0,0,1-.487.243.617.617,0,0,1-.164-.022.611.611,0,0,1-.443-.525l-.334-3.241a.608.608,0,0,1,.384-.631V29.119a.61.61,0,0,1,.5-.6,5.091,5.091,0,0,0,1.481-.527.61.61,0,0,1,.9.539v3.646ZM119.611,17.1l-.033-.253-.592-.385a.611.611,0,0,1-.277-.512v-1a.61.61,0,0,1,.61-.61h.249V13.252a.61.61,0,0,1,.338-.546,10.061,10.061,0,0,1,4.233-1.123,4.536,4.536,0,0,1,3.394,1.362,5.092,5.092,0,0,0,3.734,1.7,5.9,5.9,0,0,0,2.414-.551.611.611,0,0,1,.776.248h.333a.611.611,0,0,1,.61.61v1a.611.611,0,0,1-.278.512l-.592.385-.033.251a12.624,12.624,0,0,1-2.19,5.057c-1.492,2.163-2.89,3.132-3.614,3.132h-3.278c-.724,0-2.122-.969-3.615-3.133A12.62,12.62,0,0,1,119.611,17.1Z" transform="translate(1716.699 784.255)" fill="#4183e3" />
      <path id="XMLID_2538_" d="M23.23,299.521l-2.743-.4-1.227-2.486h0l-1.227,2.486-2.593.377.956-.932-3.3-.48-1.478-2.994-1.478,2.994-3.3.48.956.932L5.2,299.123,3.97,296.637h0l-1.226,2.485-2.743.4,1.985,1.935-.469,2.732L3.97,302.9h0l2.454,1.29-.469-2.732,1.921-1.872L9.224,300.9l-.564,3.291,2.955-1.554,2.955,1.554-.564-3.291,1.348-1.314,1.921,1.873-.469,2.732,2.454-1.29h0l2.454,1.29-.469-2.732,1.985-1.935Z" transform="translate(1832.001 533.375)" fill="#4183e3" />
    </g>
  </svg>


  )
}
