import React from 'react'

export default function HomeIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21.215" height="21.211" viewBox="0 0 21.215 21.211">
      <g id="Home_Trends" data-name="Home/Trends" transform="translate(-18156 20346)">
        <g id="Group_4374" data-name="Group 4374" transform="translate(18156 -20337.67)">
          <g id="Group_1192" data-name="Group 1192">
            <path id="Path_10446" data-name="Path 10446" d="M5.3,187.75H.759a.761.761,0,0,0-.759.759v11.362a.759.759,0,0,0,.759.759H5.3a.759.759,0,0,0,.759-.759V188.509A.759.759,0,0,0,5.3,187.75Z" transform="translate(0 -187.75)" fill="#143059"/>
          </g>
        </g>
        <g id="Group_4372" data-name="Group 4372" transform="translate(18163.574 -20346)">
          <g id="Group_1194" data-name="Group 1194" transform="translate(0 0)">
            <path id="Path_10447" data-name="Path 10447" d="M176,.05h-4.545A.758.758,0,0,0,170.7.8V20.5a.759.759,0,0,0,.759.759H176a.759.759,0,0,0,.759-.759V.809A.759.759,0,0,0,176,.05Z" transform="translate(-170.7 -0.05)" fill="#143059"/>
          </g>
        </g>
        <g id="Group_4369" data-name="Group 4369" transform="translate(18171.152 -20339.941)">
          <g id="Group_1196" data-name="Group 1196">
            <path id="Path_10448" data-name="Path 10448" d="M346.7,136.55h-4.545a.756.756,0,0,0-.759.759v13.634a.759.759,0,0,0,.759.759H346.7a.759.759,0,0,0,.759-.759V137.309A.759.759,0,0,0,346.7,136.55Z" transform="translate(-341.4 -136.55)" fill="#143059"/>
          </g>
        </g>
      </g>
    </svg>
  )
}
