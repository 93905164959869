// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".BackgroundVideo_Container__2ks0H {\n    position: relative;\n    min-height: 300px;\n    max-height: 800px;\n    overflow: hidden;\n    margin-bottom: 0px !important;\n}\n\n/* Style the video: 100% width and height to cover the entire window */\n.BackgroundVideo_Video__3Wp48 {\n    width: 100%;\n    height: 100%;\n  }\n  \n  /* Add some content at the bottom of the video/page */\n  .BackgroundVideo_Content__Y8BSi {\n    position: absolute;\n    top: 0;\n    color:#f1f1f1;\n    background-color: rgba(0, 0, 0, 0.3);\n    width: 100%;\n    height: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    padding: 0;\n  }\n  .BackgroundVideo_SubContent__83QI6 {\n      text-align: center;\n      padding: 10px;\n    }\n  .BackgroundVideo_SubContent__83QI6 p {\n      color: #eeeeeead;\n      font-size: 1.3rem;\n  }\n  .BackgroundVideo_SubContent__83QI6 button {\n    font-weight: 500;\n    margin-top: 0px;\n    color: #ffffff;\n    border: #009df8 1px solid;\n    padding: 8px 20px;\n    background-color: #009df8;\n    font-size: 18px;\n    letter-spacing: 2px;\n    border-radius: 5px;\n  }\n  .BackgroundVideo_SubContent__83QI6 button:hover {\n      background-color: #f1f1f1;\n      color: #000000c0;\n  }\n\n  .BackgroundVideo_SubContent__83QI6 img {\n    width: 200px;\n    height: 200px;\n    border-radius: 50%;\n    display: block;\n    margin: 15px auto;\n    border: #f1f1f1 1px solid;\n  }\n\n  @media only screen and (max-width:425px) {\n\n  .BackgroundVideo_SubContent__83QI6 button\n  {\n    padding: 6px 18px;\n    border: none;\n    font-size: 16px;\n  }\n\n  .BackgroundVideo_Container__2ks0H\n  {\n    min-height: auto;\n    max-height: none;\n    margin-top: 65px;\n  }\n\n  } ", ""]);
// Exports
exports.locals = {
	"Container": "BackgroundVideo_Container__2ks0H",
	"Video": "BackgroundVideo_Video__3Wp48",
	"Content": "BackgroundVideo_Content__Y8BSi",
	"SubContent": "BackgroundVideo_SubContent__83QI6"
};
module.exports = exports;
