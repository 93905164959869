import React from 'react'

export default function OverallRateIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15.931" height="32.457" viewBox="0 0 15.931 32.457">
      <g id="Overall_Ratings" data-name="Overall Ratings" transform="translate(-9130.882 17532.746)">
        <path id="Path_14063" data-name="Path 14063" d="M117.431,16.507l-3.248.984a7.993,7.993,0,0,0,15.931.079l-3.508-1.063a1.615,1.615,0,0,0-.631-.784,10.824,10.824,0,0,0,.847-1.078,11.342,11.342,0,0,0,1.249-2.343,9,9,0,0,0,.374-.985,1.792,1.792,0,0,0,.639-1.369V8.653a1.785,1.785,0,0,0-.332-1.036V5.8a5.8,5.8,0,0,0-5.8-5.8h-1.879a5.8,5.8,0,0,0-5.8,5.8V7.617a1.786,1.786,0,0,0-.332,1.035V9.947a1.793,1.793,0,0,0,.639,1.369,9.129,9.129,0,0,0,.374.986,11.346,11.346,0,0,0,1.249,2.342,10.751,10.751,0,0,0,.847,1.079A1.615,1.615,0,0,0,117.431,16.507Zm3.476,4.506a.371.371,0,0,1-.27.32.365.365,0,0,1-.1.014.372.372,0,0,1-.3-.148L119.2,19.814a.373.373,0,0,1-.075-.224V17.37a.371.371,0,0,1,.545-.328,3.1,3.1,0,0,0,.9.321.372.372,0,0,1,.307.366v.926a.371.371,0,0,1,.234.384Zm4.007-1.423a.372.372,0,0,1-.075.224L123.8,21.2a.371.371,0,0,1-.3.148.376.376,0,0,1-.1-.013.372.372,0,0,1-.27-.32l-.2-1.973a.37.37,0,0,1,.234-.384v-.926a.371.371,0,0,1,.306-.366,3.1,3.1,0,0,0,.9-.321.372.372,0,0,1,.545.328v2.22Zm-7.426-9.177-.02-.154-.36-.234a.372.372,0,0,1-.169-.312V9.1a.372.372,0,0,1,.371-.371h.152V8.068a.371.371,0,0,1,.206-.332,6.126,6.126,0,0,1,2.577-.684,2.761,2.761,0,0,1,2.066.829,3.1,3.1,0,0,0,2.273,1.034,3.59,3.59,0,0,0,1.47-.336.372.372,0,0,1,.472.151h.2a.372.372,0,0,1,.371.371v.612a.372.372,0,0,1-.169.312l-.36.234-.02.153a7.686,7.686,0,0,1-1.333,3.079c-.908,1.317-1.76,1.907-2.2,1.907h-2c-.441,0-1.292-.59-2.2-1.907A7.683,7.683,0,0,1,117.487,10.413Z" transform="translate(9016.699 -17532.746)" fill="#fff" />
        <path id="XMLID_2538_" d="M14.143,297.789l-1.67-.243-.747-1.513h0l-.747,1.513-1.579.229.582-.568-2.012-.292-.9-1.823-.9,1.823-2.012.292.582.567-1.578-.229-.747-1.513h0l-.747,1.513L0,297.789l1.209,1.178-.285,1.664,1.494-.785h0l1.494.785-.285-1.664,1.169-1.14.821.8-.344,2,1.8-.946,1.8.946-.344-2,.821-.8,1.17,1.14-.285,1.664,1.494-.785h0l1.494.785-.285-1.664,1.209-1.178Z" transform="translate(9131.563 -17800.92)" fill="#fff" />
      </g>
    </svg>
  )
}
