import React, { useEffect, useState } from 'react';
import { NavLink, Link, Switch, Route } from 'react-router-dom';
import $ from 'jquery'
import {useDispatch,useSelector} from 'react-redux'
import './styles/styles.scss'
import {superAdminRoutes} from './routes'
import LogoutIcon from './../../../images/iconsSVG/LogoutIcon'
import UserIcon from './../../../images/iconsSVG/UserIcon';
import NotificationIcon from './../../../images/iconsSVG/Notification'
import axios from 'axios';
import Cookies from 'js-cookie';

const headers={
  Authorization:`Bearer ${Cookies.get('token')}`
}

export default function SuperAdminIndex(){
  
    const dispatch=useDispatch()
    const reduxValue=useSelector(state=>state)

    const [ notifyCount, setNotifyCount ] = useState();

    // useEffect(() => {
    //   axios.get('https://api.ezioskillacademy.com/api/notification/count',{headers:{
    //     Authorization:`Bearer ${Cookies.get('token')}`
    //   }})
    //   .then((res)=>{
    //     // // console.log(res.data)
    //     setNotifyCount(res.data.notify_count)
    //   })
    // }, [window.location.href]);

    useEffect(()=>{
      // console.log("reduxValue",reduxValue)
    },[reduxValue])
    //closeSlideMenuHandler();
   const submnclick = (id) => {
      if (id !== "ab-business-upg") {
      $("#ab-business-upg").removeClass("nav-link-active");
    } else {
      $(".nav-link").removeClass("nav-link-active");
      $("#ab-business-upg").addClass("nav-link-active");
    }
    if (id === "ab-business-upg" && reduxValue.planName !== "trial") {
      if (document.body.offsetWidth <= 576) {
        $(".side-menu-top ul.menu_list >  li:nth-child(2)").css(
          "min-height",
          "250px"
        );
      }

      if (document.body.offsetWidth <= 767) {
        setTimeout(() => {
          document.querySelector(".lay-left-menu").style.transform =
            "translateX(0)";
        }, 50);

        $(".side-menu-top ul.menu_list >  li:nth-child(2)").css(
          "min-height",
          "300px"
        );

        $(".cu_menu").css({ position: "relative", left: "0", top: "14px" });
      }

      if ($(".nav-sub-menu").css("display") === "block") {
        $(".nav-sub-menu").css("display", "none");
      } else {
        $(".nav-sub-menu").css("display", "block");
      }
    } else {
      $(".nav-sub-menu").css("display", "none");
      $(".side-menu-top ul.menu_list >  li:nth-child(2)").css(
        "min-height",
        "auto"
      );

      $(".cu_menu").css({ position: "absolute", left: "auto", top: "40px" });
    }
  };

  const Logout=()=>{
    localStorage.clear()
    Cookies.remove('token')
  }

    return(
      <>
      <header>
      
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <div className="ezio_admin_logo" >
          <img src={require('../../../images/logo.png')} alt='logo' />
          
        </div>
        
        <Link
          to="/superadmin/notifications"
          className="menu-collapse-btn"
          style={{ zIndex: 10, marginRight: '25px' }}
        >
          {/* <NotificationIcon/> */}
          <NotificationIcon notifyCount = { notifyCount ? notifyCount : 0 }/>
        </Link>
      </div>

      <div className="title-section">
        <div className="title-container">
          <h5>
          </h5>
        </div>
        <div>
        </div>
      </div>
    </header>
    <div className='layout'>
          <div className="layout-content">
            <div className="lay-left-menu">
        <div
      className="side-menu"
     // ref={props.refProp}
      //onClick={props.setHeaderTitleFn}
      style={{ paddingTop: "0px" }}
    >
      {/* <div className="package_type">{reduxValue.planName} Package</div> */}
      <div className="set_sidemenu">
        <div className="side-menu-top">
          <ul className="menu_list">
          <li>
      <NavLink
        to='/superadmin/user-management'
        onClick={(e) => submnclick('user-management-id')}
        data-id='user-management-id'
        id='user-management-id'
        className="nav-link nav-link-title"
        activeClassName="nav-link-active"
      >
       {/* <img src={require('../../../images/home.png')} alt ='' /> */}
      <UserIcon/>
        <p className="link-name">
          User Management          
        </p>      
      </NavLink>
    </li>
    <li>
      <NavLink
        to='/superadmin/mentor-management'
        onClick={(e) => submnclick('mentor-management-id')}
        data-id='mentor-management-id'
        id='mentor-management-id'
        className="nav-link nav-link-title"
        activeClassName="nav-link-active"
      >
       {/* <img src={require('../../../images/home.png')} alt ='' /> */}
      <UserIcon/>
        <p className="link-name">
          Mentor Management          
        </p>      
      </NavLink>
    </li>
    <li>
      <NavLink
        to='/superadmin/batch-management'
        onClick={(e) => submnclick('batch-management-id')}
        data-id='batch-management-id'
        id='batch-management-id'
        className="nav-link nav-link-title"
        activeClassName="nav-link-active"
      >
       {/* <img src={require('../../../images/home.png')} alt ='' /> */}
      <UserIcon/>
        <p className="link-name">
          Batch Management          
        </p>      
      </NavLink>
    </li>
    <li>
      <NavLink
        to='/superadmin/course'
        onClick={(e) => submnclick('course-id')}
        data-id='course-id'
        id='course-id'
        className="nav-link nav-link-title"
        activeClassName="nav-link-active"
      >
       {/* <img src={require('../../../images/home.png')} alt ='' /> */}
      <UserIcon/>
        <p className="link-name">
          Course          
        </p>      
      </NavLink>
    </li>
    <li>
      <NavLink
        to='/superadmin/project-management'
        onClick={(e) => submnclick('project-management-id')}
        data-id='user-management-id'
        id='user-management-id'
        className="nav-link nav-link-title"
        activeClassName="nav-link-active"
      >
       {/* <img src={require('../../../images/home.png')} alt ='' /> */}
      <UserIcon/>
        <p className="link-name">
          Project Management          
        </p>      
      </NavLink>
    </li>

    <li>
      <NavLink
        to='/superadmin/project-submission'
        onClick={(e) => submnclick('project-submission-id')}
        data-id='project-submission'
        id='project-submission'
        className="nav-link nav-link-title"
        activeClassName="nav-link-active"
      >
       {/* <img src={require('../../../images/home.png')} alt ='' /> */}
      <UserIcon/>
        <p className="link-name">
          Project Submission          
        </p>      
      </NavLink>
    </li>

    {/* <li>
      <NavLink
        to='/superadmin/course-activation'
        onClick={(e) => submnclick('project-management-id')}
        data-id='user-management-id'
        id='user-management-id'
        className="nav-link nav-link-title"
        activeClassName="nav-link-active"
      >
      <UserIcon/>
        <p className="link-name">
          Course Unlock / Lock   
        </p>      
      </NavLink>
    </li> */}

    <li>
      <NavLink
        to='/superadmin/certificate'
        onClick={(e) => submnclick('certificate')}
        data-id='certificate-id'
        id='certificate'
        className="nav-link nav-link-title"
        activeClassName="nav-link-active"
      >
       {/* <img src={require('../../../images/home.png')} alt ='' /> */}
      <UserIcon/>
        <p className="link-name">
          Certificate          
        </p>      
      </NavLink>
    </li>
    <li>
      <NavLink
        to='/superadmin/chat'
        onClick={(e) => submnclick('chat')}
        data-id='user-management-id'
        id='user-management-id'
        className="nav-link nav-link-title"
        activeClassName="nav-link-active"
      >
       {/* <img src={require('../../../images/home.png')} alt ='' /> */}
      <UserIcon/>
        <p className="link-name">
          Chat          
        </p>      
      </NavLink>
    </li>
    <li>
      <NavLink
        to='/superadmin/payment-package'
        onClick={(e) => submnclick('chat')}
        data-id='payment-package-id'
        id='payment-package-id'
        className="nav-link nav-link-title"
        activeClassName="nav-link-active"
      >
       {/* <img src={require('../../../images/home.png')} alt ='' /> */}
      <UserIcon/>
        <p className="link-name">
          Payment Package          
        </p>      
      </NavLink>
    </li>
    
   
          </ul>
        </div>
        <div className="side-menu-bottom">
          <ul>
            {/* <li>
             
              <NavLink
                to="#"
                onClick={props.notificationFn}
                className="nav-link"
              >
                <BellIcon />
                <span className="nf-btn-collapse" style={{ display: "none" }}>
                  <p>{notification}</p>
                </span>
                <p className="link-name">
                  Notifications{" "}
                  <span className="nf-btn">
                    
                    {notification}
                  </span>
                </p>
              </NavLink>

            </li> */}
           
            <li onClick={Logout}>
              <NavLink to="/login" className="nav-link" >
                <LogoutIcon />
                <p className="link-name">Logout</p>
              </NavLink>
            </li>
          </ul>
        </div>

      </div>
    </div>
    </div>
    <div className="lay-right-content">
             <Switch>
                    {
                      superAdminRoutes.map((route,index)=>(
                                <Route
                                key={index} 
                                path={route.path} 
                                exact={route.exact}
                                children={<route.main />}/>
                                  
                      ))
                    }
                    
                  </Switch>     
    </div>
    </div>
    </div>
    </>
    )
}



