import React from 'react'

export default function SendIcon() {
  return (
            
<svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M26.7137 13.5C26.7137 14.4429 26.1053 15.2825 25.0474 15.812L3.83827 26.4166C3.32161 26.675 2.81786 26.8041 2.35286 26.8041C1.6799 26.8041 1.08702 26.52 0.71115 26.0175C0.389525 25.577 0.0795251 24.815 0.454108 23.575L2.84369 15.6054C2.92119 15.3729 2.97286 15.09 2.99869 14.7916H16.0833C16.7937 14.7916 17.3749 14.2104 17.3749 13.5C17.3749 12.7895 16.7937 12.2083 16.0833 12.2083H2.99869C2.97157 11.9112 2.9199 11.627 2.84369 11.3945L0.454108 3.42496C0.0795251 2.18496 0.389525 1.42287 0.712442 0.983706C1.34536 0.131206 2.54661 -0.0625436 3.83827 0.58329L25.0487 11.1879C26.1066 11.7175 26.7137 12.557 26.7137 13.5V13.5Z" fill="#F14A51"/>
</svg>
    
  )
}

