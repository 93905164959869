import React from 'react'

export default function EzioLogo() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="129.628" height="39.108" viewBox="0 0 129.628 39.108">
      <g id="ezio_logo" data-name="ezio logo" transform="translate(-68.001 -21.136)">
        <path id="Path_2" data-name="Path 2" d="M130.381,165.769A19.553,19.553,0,1,0,149.846,185.4,19.639,19.639,0,0,0,130.381,165.769Zm5.062,26.932c-.569,0-1.138.02-1.7-.012a3.331,3.331,0,0,0-2.311.729c-.849.648-1.749,1.229-2.631,1.835a3.193,3.193,0,0,1-.393.2l-.109-.1c.076-.172.146-.347.229-.516.786-1.56,1.586-3.11,2.337-4.685a1.176,1.176,0,0,1,1.236-.737c3.124.02,6.246.012,9.369.012a3.635,3.635,0,0,0,.718-.028,1.966,1.966,0,0,0,.726-.262,1.008,1.008,0,0,0-.177-1.884,3.145,3.145,0,0,0-1.147-.206c-1.7-.022-3.408-.012-5.112-.012h-17.1c-.275,0-.549-.026-.883-.043a4.171,4.171,0,0,1,.131-.5c.282-.709.585-1.412.856-2.125a.8.8,0,0,1,.844-.581h.655q10.186,0,20.373.008c.459,0,.918.018,1.374.059a4.411,4.411,0,0,1-.148,8.807C140.2,192.728,137.822,192.69,135.443,192.7Zm-21.728-7.431a7.663,7.663,0,0,1,.468-2.49,6.238,6.238,0,0,1,3.086-3.338,10.538,10.538,0,0,1,4.85-1.2q2.786-.018,5.569,0a2.243,2.243,0,0,0,1.4-.443c.991-.716,2-1.405,3-2.1.139-.1.292-.168.439-.252l.121.1c-.073.17-.131.347-.22.512-.786,1.573-1.6,3.146-2.372,4.74a1.1,1.1,0,0,1-1.127.7c-2.184-.017-4.369,0-6.554-.008a7.8,7.8,0,0,0-3.186.633,3.549,3.549,0,0,0-2.148,4.222,3.1,3.1,0,0,0,1.253,1.932,5.934,5.934,0,0,0,3.559,1.135c2.139.013,4.28.01,6.423.021.277,0,.553.039.9.066a6.141,6.141,0,0,1-.052.627c-.131.686-.262,1.37-.416,2.053-.093.431-.211.524-.687.524-2.053,0-4.107.03-6.161-.029a9.459,9.459,0,0,1-5.039-1.442A6.388,6.388,0,0,1,113.714,185.27Zm32.018-3.9a5.263,5.263,0,0,1-.706.109c-.938.01-1.877,0-2.816,0H132.65a1.175,1.175,0,0,1-.94-.219l1.412-2.987c.18-.017.328-.043.477-.043,2.334,0,4.67,0,7.006.014a6.276,6.276,0,0,1,3.641,1.1A3.511,3.511,0,0,1,145.732,181.368Z" transform="translate(-42.719 -144.633)" fill="#fff"/>
        <g id="Group_1" data-name="Group 1" transform="translate(114.119 26.063)">
          <path id="Path_3" data-name="Path 3" d="M459.77,202.39h21.378v3.594H463.542a7.418,7.418,0,0,0-.068,2.513h17.672v3.352c-1.966.008-3.923,0-5.88,0H463.448v2.438h17.606a15.179,15.179,0,0,1,.081,3.556c-.463.131-20.257.182-21.365.052Z" transform="translate(-459.152 -202.343)" fill="#fff"/>
          <path id="Path_4" data-name="Path 4" d="M949.76,206.458a3.758,3.758,0,0,0-1.115-2.708,5.176,5.176,0,0,0-3.869-1.62H933.248a5.429,5.429,0,0,0-3.685,1.455,3.67,3.67,0,0,0-1.283,2.893q.01,3.47,0,6.947a3.693,3.693,0,0,0,1.115,2.707,5.017,5.017,0,0,0,3.67,1.594h11.789a5.284,5.284,0,0,0,3.719-1.523,3.584,3.584,0,0,0,1.18-2.736C949.749,211.13,949.753,208.8,949.76,206.458Zm-4.965,2.112c-.029.98,0,1.966-.007,2.943a2.509,2.509,0,0,1-2.56,2.569c-1.049.013-2.1,0-3.139,0h-3.146a2.587,2.587,0,0,1-2.728-2.7q-.021-1.442,0-2.884a2.6,2.6,0,0,1,2.72-2.717c2.028-.01,4.063.037,6.083-.016A2.663,2.663,0,0,1,944.795,208.57Z" transform="translate(-866.251 -202.117)" fill="#fff"/>
          <path id="Path_5" data-name="Path 5" d="M671.7,206.03H657.974a14.926,14.926,0,0,1-.056-3.629h21.365v3.618l-14,8.178.025.109H679.2a16.83,16.83,0,0,1,.069,3.559c-.474.119-20.329.166-21.323.05a17.417,17.417,0,0,1-.051-3.654l13.827-8.08Z" transform="translate(-631.249 -202.352)" fill="#fff"/>
          <path id="Path_6" data-name="Path 6" d="M861.321,217.615H856.45V202.148a29.978,29.978,0,0,1,4.871-.031Z" transform="translate(-803.837 -202.033)" fill="#fff"/>
          <path id="Path_7" data-name="Path 7" d="M1055.391,359.058a1.154,1.154,0,0,0-1.262-1.258c-.632-.017-1.264,0-1.9,0h-2.289v1.992c0,.629-.033,1.289.031,1.957h4.181a1.208,1.208,0,0,0,1.229-1.311C1055.4,359.973,1055.4,359.516,1055.391,359.058Zm-1.262,1.076a.649.649,0,0,1-.665.667c-.714.024-1.43,0-2.215,0v-2.078h2.235a.633.633,0,0,1,.191.035C1054.087,358.907,1054.222,359.324,1054.129,360.134Z" transform="translate(-971.962 -337.376)" fill="#fff"/>
          <path id="Path_8" data-name="Path 8" d="M781.648,358v.856c-.173.017-.339.046-.506.047h-3.015c-.233,0-.493-.05-.634.25.028.345.288.371.56.372h2.029a3.983,3.983,0,0,1,.587.025,1.156,1.156,0,0,1,1,1.049,1.106,1.106,0,0,1-.775,1.22,1.546,1.546,0,0,1-.51.1c-1.311.008-2.622,0-3.932,0a.869.869,0,0,1-.207-.062v-.81a4.84,4.84,0,0,1,.5-.051h3.015c.254,0,.544.05.632-.305-.065-.328-.32-.313-.561-.315h-2.16a2.261,2.261,0,0,1-.644-.081,1.173,1.173,0,0,1-.028-2.219.76.76,0,0,1,.252-.066C778.71,358,780.165,358,781.648,358Z" transform="translate(-734.123 -337.557)" fill="#fff"/>
          <path id="Path_9" data-name="Path 9" d="M460.49,358v.849c-.185.017-.353.043-.524.045h-3.078c-.29,0-.594,0-.524.514a4.665,4.665,0,0,0,.665.1c.764.016,1.528-.017,2.291.017a1.166,1.166,0,0,1,1.168,1.409,1.2,1.2,0,0,1-1.231.967c-.72.025-1.442.008-2.16.008H455.1v-.891c.163-.013.329-.038.5-.039h3.015c.262,0,.54.031.627-.317-.085-.311-.333-.3-.577-.3H456.5a2.244,2.244,0,0,1-.644-.083,1.1,1.1,0,0,1-.8-1.074,1.069,1.069,0,0,1,.733-1.118.69.69,0,0,1,.246-.081C457.511,358,458.98,358,460.49,358Z" transform="translate(-455.055 -337.557)" fill="#fff"/>
          <path id="Path_10" data-name="Path 10" d="M503.257,357.7c-.455.029-.918,0-1.371,0h0c-.544,0-1.089-.026-1.631,0a1.134,1.134,0,0,0-1.134,1.18c-.01.5-.009,1,0,1.5a1.125,1.125,0,0,0,1.093,1.222c1.085.049,2.177.051,3.262,0a1.126,1.126,0,0,0,1.1-1.277,12.148,12.148,0,0,1,.009-1.311A1.229,1.229,0,0,0,503.257,357.7Zm.037,2.336a.606.606,0,0,1-.636.675,15.3,15.3,0,0,1-1.627,0c-.413-.025-.607-.262-.63-.684a6.429,6.429,0,0,1,0-.655c.024-.524.228-.721.772-.739.239-.008.477,0,.717,0h.655a.856.856,0,0,1,.32.035C503.512,358.959,503.285,359.55,503.293,360.039Z" transform="translate(-493.339 -337.288)" fill="#fff"/>
          <path id="Path_11" data-name="Path 11" d="M693.414,358.917a1.044,1.044,0,0,0-1.036-1.126c-1.112-.038-2.228-.042-3.337,0a1.082,1.082,0,0,0-1.049,1.18c-.009.457,0,.918,0,1.375A1.206,1.206,0,0,0,689.3,361.7c.457.017.918,0,1.375,0h.72c.262,0,.524.01.786,0a1.164,1.164,0,0,0,1.235-1.287C693.419,359.921,693.429,359.419,693.414,358.917Zm-1.229,1.071c-.026.484-.207.738-.611.786a7.569,7.569,0,0,1-.845.007l-.845-.008a.634.634,0,0,1-.616-.636.513.513,0,0,1,0-.064c0-.942-.17-1.465,1.251-1.378.28.017.564,0,.845,0a1.391,1.391,0,0,1,.324.022C692.089,358.828,692.22,359.171,692.185,359.988Z" transform="translate(-657.457 -337.349)" fill="#fff"/>
          <path id="Path_12" data-name="Path 12" d="M737.581,361.68H736.3l-2.838-2.6v2.591H732.17v-3.842a1.84,1.84,0,0,1,1.859.45c.607.655,1.3,1.223,1.958,1.827.09.084.193.155.367.292v-2.587h1.233v3.872Z" transform="translate(-695.847 -337.35)" fill="#fff"/>
          <path id="Path_13" data-name="Path 13" d="M873.33,357.805c-1.22-.012-2.441,0-3.661,0-.131,0-.252.018-.409.03v3.882h1.208c.02-.347.039-.663.06-1.018.248-.013.46-.033.672-.034h2.026c1.049-.013,1.458-.436,1.442-1.482C874.67,358.242,874.262,357.813,873.33,357.805Zm-.4,1.9c-.777.022-1.555.008-2.4.008v-.974c.824,0,1.62-.008,2.416,0a.483.483,0,1,1-.016.966Z" transform="translate(-814.968 -337.383)" fill="#fff"/>
          <path id="Path_14" data-name="Path 14" d="M587.376,358.05h1.269v1.966c0,.82.182,1,.99,1.012.349,0,.7.007,1.049,0,.676-.014.857-.191.865-.853.007-.693,0-1.387,0-2.121h1.278v2.6a1.171,1.171,0,0,1-1.224,1.3c-.456.026-.917.008-1.374.008-.524,0-1.049.013-1.573,0a1.2,1.2,0,0,1-1.283-1.321C587.364,359.805,587.376,358.949,587.376,358.05Z" transform="translate(-570.026 -337.6)" fill="#fff"/>
          <path id="Path_15" data-name="Path 15" d="M849.777,354.426h1.2v.882h-2.49V349.06h2.49v.853l-1.214.059v4.457Z" transform="translate(-796.92 -329.788)" fill="#fff"/>
          <path id="Path_16" data-name="Path 16" d="M914.618,355.276H912.18v-.868l1.18-.058v-4.443h-1.18V349h2.435v6.272Z" transform="translate(-852.262 -329.736)" fill="#fff"/>
          <path id="Path_17" data-name="Path 17" d="M548.9,361.188v.933c-1.8.024-3.6,0-5.424.013V358.24h1.236v2.948Z" transform="translate(-531.89 -337.765)" fill="#fff"/>
          <path id="Path_18" data-name="Path 18" d="M1009.454,358.664v3h-1.228c-.055-.963,0-1.935-.037-2.981h-2.049v-.918c1.78-.048,3.552-.021,5.356-.02v.907h-2.044Z" transform="translate(-933.906 -337.332)" fill="#fff"/>
          <path id="Path_19" data-name="Path 19" d="M962.89,358.16v2.947h4.137v.918h-5.28a19.5,19.5,0,0,1-.049-3.864Z" transform="translate(-895.227 -337.696)" fill="#fff"/>
          <path id="Path_20" data-name="Path 20" d="M635.427,362.012h-1.233v-2.982H632.13v-.9h5.31c.017.142.045.286.05.431a3.794,3.794,0,0,1-.03.438h-2.033v3.015Z" transform="translate(-608.92 -337.67)" fill="#fff"/>
          <path id="Path_21" data-name="Path 21" d="M675.859,358.09h1.115v3.879c-.184.012-.372.028-.561.034s-.345,0-.509,0A17.59,17.59,0,0,1,675.859,358.09Z" transform="translate(-646.842 -337.635)" fill="#fff"/>
          <path id="Path_25" data-name="Path 25" d="M599.214,414.075a.5.5,0,0,1-.034-.1v-1.686a.163.163,0,0,1,.172-.088h2.315a.14.14,0,0,1,.085.138.745.745,0,0,1-.021.291.262.262,0,0,1-.18.037H600.14c-.1,0-.2.006-.278.009a.186.186,0,0,0-.049.214.169.169,0,0,0,.131.047h1a2.29,2.29,0,0,1,.253.009.364.364,0,0,1,0,.4c-.1,0-.215.007-.326.008s-.248,0-.371,0h-.334a1.41,1.41,0,0,0-.32.013.148.148,0,0,0,.012.245c.084,0,.181.007.279.007h1.412a.234.234,0,0,1,.181.047.825.825,0,0,1,.008.346.14.14,0,0,1-.131.06h-2.395Z" transform="translate(-580.289 -384.648)" fill="#fff"/>
          <path id="Path_26" data-name="Path 26" d="M1069.085,413.316a.805.805,0,0,0-.01.1.111.111,0,0,0,.084.1c.073,0,.148.009.223.009h1.58a.131.131,0,0,1,.072.131c-.009.1.029.2-.034.284-.279.035-1.543.05-2.409.026a.606.606,0,0,1-.115-.028,1.306,1.306,0,0,1-.018-.143,10.712,10.712,0,0,1,.029-1.663.207.207,0,0,1,.173-.043h2.2a1.26,1.26,0,0,1,.131.014.249.249,0,0,1,.043.073.94.94,0,0,1,0,.326.154.154,0,0,1-.131.05h-1.633c-.131,0-.206.05-.166.212a.154.154,0,0,0,.131.055h.965c.1,0,.2,0,.3.008a.328.328,0,0,1,.046.1v.241a.147.147,0,0,1-.139.071h-1.114A.232.232,0,0,0,1069.085,413.316Z" transform="translate(-988.045 -384.555)" fill="#fff"/>
          <path id="Path_27" data-name="Path 27" d="M794.473,413.313c-.024-.043-.054-.083-.094-.146a2.58,2.58,0,0,0,.131-.248.448.448,0,0,0-.07-.455.531.531,0,0,0-.232-.181.831.831,0,0,0-.325-.064H792.1a.3.3,0,0,0-.1.034.334.334,0,0,0-.022.079v1.673a.163.163,0,0,0,.035.062.424.424,0,0,0,.218.022h1.749a.59.59,0,0,0,.372-.147A.485.485,0,0,0,794.473,413.313Zm-1.877-.607a.3.3,0,0,1,.094-.034h1.033a.361.361,0,0,1,.139.038.131.131,0,0,1,.063.122.139.139,0,0,1-.081.113.46.46,0,0,1-.072.013h-1.149A.262.262,0,0,1,792.6,412.706Zm1.26.918a.345.345,0,0,1-.1.031c-.368,0-.738,0-1.108-.008a.08.08,0,0,1-.033-.017.206.206,0,0,1,0-.248.884.884,0,0,1,.138-.024h.962a.6.6,0,0,1,.143.026.143.143,0,0,1,.076.118.147.147,0,0,1-.073.13Z" transform="translate(-747.817 -384.67)" fill="#fff"/>
          <path id="Path_28" data-name="Path 28" d="M493.4,414.058a.877.877,0,0,1,0-.372.223.223,0,0,1,.18-.05h1.152c.161,0,.321,0,.482-.01a.122.122,0,0,0,.11-.085.109.109,0,0,0-.037-.131l-.038-.031c-.076,0-.163-.009-.249-.009h-.929a.919.919,0,0,1-.292-.039.663.663,0,0,1-.322-.242.243.243,0,0,1-.035-.066.556.556,0,0,1,.338-.752.887.887,0,0,1,.29-.059l1.821.008a.132.132,0,0,1,.035.009.162.162,0,0,1,.043.056.8.8,0,0,1,0,.345.191.191,0,0,1-.157.045h-1.64a.153.153,0,0,0-.142.142.157.157,0,0,0,.131.117c.087,0,.173.008.262.008h.966a.626.626,0,0,1,.319.088.558.558,0,0,1-.181,1.038.771.771,0,0,1-.184.017H493.5A.477.477,0,0,1,493.4,414.058Z" transform="translate(-488.35 -384.661)" fill="#fff"/>
          <path id="Path_29" data-name="Path 29" d="M933.386,413.632H934.9c.087,0,.173-.007.262-.014a.119.119,0,0,0,0-.233c-.062,0-.123-.012-.185-.012h-.937a1.676,1.676,0,0,1-.223-.009.655.655,0,0,1-.435-.262.264.264,0,0,1-.037-.064.556.556,0,0,1,.284-.748.778.778,0,0,1,.36-.077h1.673a.254.254,0,0,1,.177.031.21.21,0,0,1,.033.064,1.123,1.123,0,0,1,0,.321.227.227,0,0,1-.185.049h-1.412a2.2,2.2,0,0,0-.221.012c-.113.012-.146.115-.071.216a.3.3,0,0,0,.042.034c.084,0,.182.009.281.009h.966a.655.655,0,0,1,.413.143.559.559,0,0,1-.043.893.7.7,0,0,1-.313.106h-1.819a.253.253,0,0,1-.177-.034C933.29,413.752,933.292,413.736,933.386,413.632Z" transform="translate(-870.608 -384.658)" fill="#fff"/>
          <path id="Path_30" data-name="Path 30" d="M627.771,413.01v-.262a.543.543,0,0,0-.374-.5.818.818,0,0,0-.291-.047h-1.783a.363.363,0,0,0-.094.031.245.245,0,0,0-.029.076v1.67a.269.269,0,0,0,.054.1h.447a.28.28,0,0,0,.1-.051c.008-.131.016-.271.024-.393l.042-.029a.079.079,0,0,1,.031-.018c.235,0,.469-.008.705-.008a.446.446,0,0,1,.1.022c.152.139.21.343.381.486.185-.01.381.009.578-.013a.189.189,0,0,0,.059-.054c-.059-.159-.2-.278-.262-.464.062-.06.1-.1.151-.144A.5.5,0,0,0,627.771,413.01Zm-.75.093a.457.457,0,0,1-.107.026h-1.1a1.2,1.2,0,0,1-.008-.442.445.445,0,0,1,.107-.038h.962a.6.6,0,0,1,.109.012.25.25,0,0,1,.183.208A.237.237,0,0,1,627.021,413.1Z" transform="translate(-602.898 -384.651)" fill="#fff"/>
          <path id="Path_31" data-name="Path 31" d="M820.365,413.489c.028-.025.06-.043.086-.069a1.01,1.01,0,0,0,.121-.139,1.041,1.041,0,0,0-.009-.786l-.021-.03a.637.637,0,0,0-.3-.214.963.963,0,0,0-.328-.045h-1.792a.416.416,0,0,0-.046.1v1.7a.179.179,0,0,0,.05.068q.2.01.393,0a.584.584,0,0,0,.114-.02c.075-.148-.021-.321.068-.463a3.692,3.692,0,0,1,.851,0,1.57,1.57,0,0,1,.24.32.335.335,0,0,0,.169.165h.534a.215.215,0,0,0,.086-.046c-.072-.181-.218-.307-.278-.474A.679.679,0,0,1,820.365,413.489Zm-.547-.363a1.086,1.086,0,0,1-.185.009h-.908a.6.6,0,0,1-.014-.45.211.211,0,0,1,.065-.028h.966a.5.5,0,0,1,.146.02.189.189,0,0,1,.139.159.223.223,0,0,1-.208.292Z" transform="translate(-770.496 -384.656)" fill="#fff"/>
          <path id="Path_32" data-name="Path 32" d="M750.02,413.4a.486.486,0,0,0,.147-.329v-.334a.483.483,0,0,0-.155-.326.7.7,0,0,0-.507-.191h-1.779a.393.393,0,0,0-.107.035,1.158,1.158,0,0,0-.016.148c0,.543-.02,1.085.014,1.628a.125.125,0,0,0,.017.031.237.237,0,0,0,.068.025c.147,0,.295,0,.442-.012a.081.081,0,0,0,.033-.02c.072-.144-.034-.32.075-.465a5.578,5.578,0,0,1,.839.012c.152.147.22.342.359.474.5.026.524.025.667-.027-.056-.178-.206-.3-.275-.471C749.869,413.487,749.961,413.459,750.02,413.4Zm-.686-.256a.184.184,0,0,1-.038,0h-.928c-.037,0-.073-.008-.143-.014a1.088,1.088,0,0,1-.014-.425.333.333,0,0,1,.1-.039h.966a.633.633,0,0,1,.111.01.216.216,0,0,1,.161.182.228.228,0,0,1-.218.286Z" transform="translate(-709.252 -384.666)" fill="#fff"/>
          <path id="Path_33" data-name="Path 33" d="M459.039,412.774a.709.709,0,0,0,0-.075.476.476,0,0,0-.2-.339.756.756,0,0,0-.454-.14H456.6a.3.3,0,0,0-.1.037c-.026.286-.026,1.5,0,1.814a.959.959,0,0,0,.131.018h1.753a1.435,1.435,0,0,0,.149,0,.564.564,0,0,0,.516-.537C459.039,413.294,459.039,413.033,459.039,412.774Zm-.615.632a.262.262,0,0,1-.208.232.7.7,0,0,1-.145.022h-.852a.161.161,0,0,1-.14-.075c-.016-.18,0-.376,0-.574,0-.1,0-.2.008-.287a.193.193,0,0,1,.069-.046c.321,0,.642-.009.963-.007a.416.416,0,0,1,.174.05.231.231,0,0,1,.139.2c0,.16,0,.313-.005.48Z" transform="translate(-456.293 -384.669)" fill="#fff"/>
          <path id="Path_34" data-name="Path 34" d="M900.2,412.747a.5.5,0,0,0-.326-.456.759.759,0,0,0-.324-.071c-.607,0-1.213,0-1.818.008a.22.22,0,0,0-.089.045c-.025.376-.021,1.534,0,1.785a.327.327,0,0,0,.064.033.288.288,0,0,0,.075,0h1.781a.707.707,0,0,0,.418-.125.462.462,0,0,0,.214-.372C900.21,413.316,900.211,413.031,900.2,412.747Zm-.6.615a.308.308,0,0,1-.253.282.8.8,0,0,1-.148.017h-.818a.648.648,0,0,1-.146-.041,7.944,7.944,0,0,1,0-.9.321.321,0,0,1,.1-.044h.891a.781.781,0,0,1,.111.008.323.323,0,0,1,.262.277A3.527,3.527,0,0,1,899.6,413.362Z" transform="translate(-839.618 -384.67)" fill="#fff"/>
          <path id="Path_35" data-name="Path 35" d="M872.286,412.889a3.1,3.1,0,0,0-.018.549c-.008.183.013.37-.012.545a.242.242,0,0,1-.075.038,4.59,4.59,0,0,1-.474,0,.193.193,0,0,1-.046-.066c-.016-.225-.012-1.48,0-1.743a.131.131,0,0,1,.131-.064h.45c.435.353.821.755,1.232,1.115l.1-.013a.5.5,0,0,0,.038-.131v-.786a.239.239,0,0,1,.065-.181,3.89,3.89,0,0,1,.5-.009.375.375,0,0,1,.048.113v1.671a.173.173,0,0,1-.032.063.08.08,0,0,1-.031.018h-.543c-.039-.033-.1-.079-.152-.131l-1.038-.956a.131.131,0,0,0-.146-.033Z" transform="translate(-817.045 -384.599)" fill="#fff"/>
          <path id="Path_36" data-name="Path 36" d="M698.117,412.732a.462.462,0,0,0-.131-.3.656.656,0,0,0-.5-.206h-1.3a.773.773,0,0,0-.288.064.509.509,0,0,0-.347.481q-.009.408,0,.817a.479.479,0,0,0,.233.4.61.61,0,0,0,.347.113h1.375a.7.7,0,0,0,.416-.131.431.431,0,0,0,.2-.343C698.125,413.326,698.124,413.028,698.117,412.732Zm-.6.655a.3.3,0,0,1-.262.262,1.257,1.257,0,0,1-.185.013h-.446a1.092,1.092,0,0,1-.111,0c-.2-.02-.37-.109-.366-.379v-.3a.317.317,0,0,1,.311-.312c.131-.01.271,0,.408,0h.372a.308.308,0,0,1,.275.262,2.971,2.971,0,0,1,0,.448Z" transform="translate(-664.022 -384.675)" fill="#fff"/>
          <path id="Path_37" data-name="Path 37" d="M1008.078,412.73a.462.462,0,0,0-.131-.3.655.655,0,0,0-.5-.2h-1.3a.77.77,0,0,0-.288.064.511.511,0,0,0-.347.481c-.007.273,0,.545,0,.818a.485.485,0,0,0,.233.393.618.618,0,0,0,.349.113h1.374a.69.69,0,0,0,.416-.131.431.431,0,0,0,.2-.343C1008.086,413.325,1008.085,413.028,1008.078,412.73Zm-.6.629a.3.3,0,0,1-.284.294c-.262.013-.524,0-.779,0a.2.2,0,0,1-.037-.008.32.32,0,0,1-.275-.3,2.587,2.587,0,0,1,0-.334.333.333,0,0,1,.362-.34h.707a.321.321,0,0,1,.308.312A2.926,2.926,0,0,1,1007.482,413.359Z" transform="translate(-933.357 -384.675)" fill="#fff"/>
          <path id="Path_38" data-name="Path 38" d="M547.783,412.378a.674.674,0,0,0-.447-.151h-1.261a.834.834,0,0,0-.254.042.505.505,0,0,0-.408.51c0,.271-.009.544,0,.815a.524.524,0,0,0,.436.484,1.241,1.241,0,0,0,.22.021h1.224a.917.917,0,0,0,.292-.035.506.506,0,0,0,.379-.489v-.817A.463.463,0,0,0,547.783,412.378Zm-.416,1.036a.275.275,0,0,1-.212.231.785.785,0,0,1-.145.022h-.63a.6.6,0,0,1-.18-.039.238.238,0,0,1-.174-.218c-.008-.161,0-.322,0-.482a.286.286,0,0,1,.21-.235.293.293,0,0,1,.073-.014h.742a.432.432,0,0,1,.143.035.248.248,0,0,1,.173.22C547.374,413.093,547.374,413.254,547.367,413.414Z" transform="translate(-533.566 -384.676)" fill="#fff"/>
          <path id="Path_39" data-name="Path 39" d="M1045.06,413.651a.668.668,0,0,1,.012.429.465.465,0,0,1-.1.024h-1.822a.7.7,0,0,1-.416-.131.444.444,0,0,1-.181-.273.875.875,0,0,1-.02-.184v-.707a.7.7,0,0,1,.009-.111.468.468,0,0,1,.183-.312.76.76,0,0,1,.412-.155h1.785a.205.205,0,0,1,.141.028.177.177,0,0,1,.036.06.89.89,0,0,1-.011.34.307.307,0,0,1-.1.034H1043.5a.308.308,0,0,0-.353.337,3.161,3.161,0,0,0,.009.371.283.283,0,0,0,.246.241,2.216,2.216,0,0,0,.223.009h1.434Z" transform="translate(-965.526 -384.677)" fill="#fff"/>
          <path id="Path_40" data-name="Path 40" d="M522.047,414.06a.318.318,0,0,1-.1.05h-1.751a1.789,1.789,0,0,1-.221-.01.565.565,0,0,1-.419-.277.356.356,0,0,1-.058-.173q-.008-.484,0-.966a.352.352,0,0,1,.067-.169.619.619,0,0,1,.429-.262,1.04,1.04,0,0,1,.185-.013h1.747a.564.564,0,0,1,.084.014.269.269,0,0,1,.039.089,1.692,1.692,0,0,1,0,.262.309.309,0,0,1-.058.1h-1.6a.284.284,0,0,0-.287.245,1.3,1.3,0,0,0,0,.48.282.282,0,0,0,.227.22,1.772,1.772,0,0,0,.221.013h1.454a.334.334,0,0,1,.043.1C522.047,413.862,522.047,413.96,522.047,414.06Z" transform="translate(-511.044 -384.686)" fill="#fff"/>
          <path id="Path_41" data-name="Path 41" d="M721.564,412.235a.8.8,0,0,1,.131-.026,2.923,2.923,0,0,1,.406.009.346.346,0,0,1,.043.106v.815a1.959,1.959,0,0,0,.009.221.287.287,0,0,0,.27.262q.408.01.815,0a.273.273,0,0,0,.256-.27v-.963a.2.2,0,0,1,.031-.14,1.069,1.069,0,0,1,.548-.021.356.356,0,0,1,.03.1v1.186a.986.986,0,0,1-.012.147.38.38,0,0,1-.093.2.62.62,0,0,1-.488.225H722.1a.584.584,0,0,1-.429-.19.464.464,0,0,1-.131-.3C721.539,413.447,721.547,412.441,721.564,412.235Z" transform="translate(-686.612 -384.657)" fill="#fff"/>
          <path id="Path_42" data-name="Path 42" d="M847.033,413.988q-.47-.8-.941-1.6a.287.287,0,0,0-.131-.145,4.116,4.116,0,0,0-.426,0,.521.521,0,0,0-.1.018q-.269.358-1.071,1.783l.042.072h.474c.035,0,.072-.007.1-.009.1-.056.122-.164.185-.239.039-.045.085-.085.119-.119h.834a.2.2,0,0,1,.068.025c.117.079.131.241.248.317a.28.28,0,0,0,.069.021h.524a.132.132,0,0,0,.046-.049A.423.423,0,0,0,847.033,413.988Zm-1.18-.7a.439.439,0,0,1-.29,0,.14.14,0,0,1-.029-.063.557.557,0,0,1,.161-.24.077.077,0,0,1,.033,0,.71.71,0,0,1,.157.245.13.13,0,0,1-.034.058Z" transform="translate(-793.331 -384.685)" fill="#fff"/>
          <path id="Path_43" data-name="Path 43" d="M572.913,414.076a.381.381,0,0,1-.062-.06q-.5-.845-1-1.694a.086.086,0,0,1,.027-.111,5.22,5.22,0,0,1,.557,0,.473.473,0,0,1,.18.208c.151.256.3.512.451.768a.216.216,0,0,0,.131.131l.031-.012a.078.078,0,0,0,.03-.021l.624-1.039a1.533,1.533,0,0,1,.655,0,.183.183,0,0,1-.007.071c-.333.592-.686,1.173-1.034,1.762A4.391,4.391,0,0,1,572.913,414.076Z" transform="translate(-556.536 -384.655)" fill="#fff"/>
          <path id="Path_44" data-name="Path 44" d="M978.812,412.212c.048.073.1.143.142.216.173.286.343.572.515.857a.069.069,0,0,0,.1.012c.029-.041.059-.079.085-.121.131-.212.252-.425.379-.637.063-.106.131-.21.2-.32a2.6,2.6,0,0,1,.555-.021.344.344,0,0,1,.1.026.107.107,0,0,1,0,.045c-.308.578-.663,1.129-.99,1.695a.736.736,0,0,1-.077.093,4.7,4.7,0,0,1-.553.017.761.761,0,0,1-.1-.107c-.1-.157-.191-.317-.284-.477l-.62-1.049a.857.857,0,0,1-.071-.166.066.066,0,0,1,.034-.054.306.306,0,0,1,.109-.016A3.074,3.074,0,0,1,978.812,412.212Z" transform="translate(-909.611 -384.646)" fill="#fff"/>
          <path id="Path_45" data-name="Path 45" d="M672.013,412.22h.472l.037.083c-.273.347-.607.645-.894.995-.034.25,0,.51-.022.755a.277.277,0,0,1-.083.039h-.443a.066.066,0,0,1-.031-.017.489.489,0,0,1-.037-.034c-.028-.224.008-.459-.018-.708a1.469,1.469,0,0,0-.131-.164q-.362-.393-.726-.793a.131.131,0,0,1-.039-.14,3.812,3.812,0,0,1,.488,0,2.093,2.093,0,0,1,.157.139c.131.131.252.273.377.409a1.389,1.389,0,0,0,.106.1.411.411,0,0,0,.071.039.309.309,0,0,0,.092-.047q.168-.173.328-.354A2.263,2.263,0,0,1,672.013,412.22Z" transform="translate(-641.904 -384.67)" fill="#fff"/>
          <path id="Path_46" data-name="Path 46" d="M482.985,412.28c.022.337.021,1.513,0,1.8a.319.319,0,0,1-.1.034h-.425a.168.168,0,0,1-.075-.142v-1.586a.151.151,0,0,1,.088-.138,4.049,4.049,0,0,1,.418,0A.387.387,0,0,1,482.985,412.28Z" transform="translate(-478.807 -384.686)" fill="#fff"/>
          <path id="Path_47" data-name="Path 47" d="M1031.564,412.232a3.041,3.041,0,0,1,.5,0,.264.264,0,0,1,.049.1V414a.092.092,0,0,1-.093.094h-.448a.262.262,0,0,1-.052-.1v-1.671A.374.374,0,0,1,1031.564,412.232Z" transform="translate(-955.959 -384.671)" fill="#fff"/>
        </g>
      </g>
    </svg>

  )
}
