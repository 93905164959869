import React from 'react'

function DbCourse() {
  return (
    
<svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_850_62)">
<path d="M23.0452 3.21631H21.6577C18.1722 3.21631 15.0402 5.19134 13.5001 8.18563C11.96 5.19139 8.82801 3.21631 5.34249 3.21631H3.95509C3.51786 3.21631 3.16406 3.57011 3.16406 4.00733V6.48124C8.02222 6.87432 11.6375 8.38001 13.504 9.27414C15.9099 8.13743 19.3863 6.84131 23.8362 6.48361V4.00733C23.8362 3.57011 23.4824 3.21631 23.0452 3.21631V3.21631Z" fill="#F14A51"/>
<path d="M0.179042 8.25208C0.0291688 8.43517 -0.0310546 8.67617 0.0152995 8.90868L3.1794 23.1471C3.23656 23.4329 3.44666 23.6647 3.72552 23.7489C4.00518 23.8362 4.30877 23.7566 4.51423 23.5511C5.37244 22.6929 6.61329 22.2008 7.87709 22.2008C8.65493 22.2008 8.94233 22.2889 11.3177 23.0923L12.709 23.5601V10.6518C10.335 9.52969 6.30437 7.9624 0.790873 7.9624C0.553724 7.9624 0.329705 8.06903 0.179042 8.25208V8.25208Z" fill="#F14A51"/>
<path d="M26.8209 8.25208C26.6703 8.06898 26.4463 7.9624 26.2091 7.9624C20.7378 7.9624 16.6107 9.56291 14.291 10.6529V23.5606L15.6135 23.117C18.1388 22.2657 18.3489 22.2009 19.1731 22.2009C20.3867 22.2009 21.6275 22.6929 22.4857 23.5511C22.6954 23.7609 23.0012 23.8325 23.2744 23.7489C23.5533 23.6647 23.7634 23.433 23.8205 23.1471L26.9846 8.90868C27.031 8.67617 26.9708 8.43517 26.8209 8.25208V8.25208Z" fill="#F14A51"/>
</g>
<defs>
<clipPath id="clip0_850_62">
<rect width="27" height="27" fill="white"/>
</clipPath>
</defs>
</svg>

  )
}

export default DbCourse