import React from 'react'

// Without background

export default function Notifications(props) {
  // alert(props.notifyCount)
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="29.25" height="25.485" viewBox="0 0 29.25 25.485">
        <g id="Group_3215" data-name="Group 3215" transform="translate(-1754.75 -42.758)">
          <g id="Icon_ionic-ios-notifications-outline" data-name="Icon ionic-ios-notifications-outline" transform="translate(1754.75 42.758)">
            <path id="Path_38237" data-name="Path 38237" d="M31.955,26.946c-1.229-1.577-3.646-2.5-3.646-9.562,0-7.247-3.287-10.16-6.35-10.859-.287-.07-.495-.163-.495-.458V5.841A1.93,1.93,0,0,0,19.55,3.93H19.5a1.93,1.93,0,0,0-1.915,1.911v.225c0,.287-.207.388-.495.458-3.071.707-6.35,3.612-6.35,10.859,0,7.061-2.417,7.977-3.646,9.562a1.539,1.539,0,0,0,1.268,2.47H30.711A1.54,1.54,0,0,0,31.955,26.946Zm-3.111.451h-18.6a.34.34,0,0,1-.263-.567,9.382,9.382,0,0,0,1.675-2.594,17.187,17.187,0,0,0,1.141-6.851,11.668,11.668,0,0,1,1.667-6.742A5.123,5.123,0,0,1,17.548,8.5a2.814,2.814,0,0,0,1.484-.816.642.642,0,0,1,.949-.016,2.91,2.91,0,0,0,1.5.831,5.123,5.123,0,0,1,3.087,2.144,11.668,11.668,0,0,1,1.667,6.742,17.188,17.188,0,0,0,1.141,6.851,9.492,9.492,0,0,0,1.715,2.633A.32.32,0,0,1,28.844,27.4Z" transform="translate(-6.775 -3.93)" fill="#0c2541"/>
          </g>
          { props.notifyCount > 0 ?
            <>
              <circle id="Ellipse_295" data-name="Ellipse 295" cx="8" cy="8" r="8" transform="translate(1768 45)" fill="red"/>
              <text id="_1" data-name="1" transform="translate(1773 56)" fill="#fff" font-size="9" font-family="OpenSans, Open Sans"><tspan x="0" y="0">{props.notifyCount}</tspan></text>
            </>:
             null
          }
          
        </g>
      </svg>
    )
}
