import React from 'react'

export default function VertbusLable() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="104.599" height="58.445" viewBox="0 0 104.599 58.445">
            <g id="action_button" data-name="action button" transform="translate(-1132.901 -1098.555)">
                <path id="Path_14440" data-name="Path 14440" d="M6520.5-6731H6415.9l104.6-58.445Z" transform="translate(-5283 7888)" fill="#0085bf"/>
            </g>
        </svg>
    )
}
