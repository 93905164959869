import React from "react";

function AddIcon() {
  return (
    <svg
      id="add_1_"
      data-name="add (1)"
      xmlns="http://www.w3.org/2000/svg"
      width="29.982"
      height="29.982"
      viewBox="0 0 29.982 29.982"
    >
      <g id="Group_4560" data-name="Group 4560">
        <g id="Group_4559" data-name="Group 4559">
          <path
            id="Path_14569"
            data-name="Path 14569"
            d="M14.991,0A14.991,14.991,0,1,0,29.982,14.991,15.008,15.008,0,0,0,14.991,0Zm0,27.66A12.669,12.669,0,1,1,27.66,14.991,12.684,12.684,0,0,1,14.991,27.66Z"
            fill="#438fff"
          />
        </g>
      </g>
      <g
        id="Group_4562"
        data-name="Group 4562"
        transform="translate(8.024 7.919)"
      >
        <g id="Group_4561" data-name="Group 4561">
          <path
            id="Path_14570"
            data-name="Path 14570"
            d="M149.8,141.044h-4.645V136.4a1.161,1.161,0,1,0-2.322,0v4.645h-4.645a1.161,1.161,0,1,0,0,2.322h4.645v4.645a1.161,1.161,0,1,0,2.322,0v-4.645H149.8a1.161,1.161,0,1,0,0-2.322Z"
            transform="translate(-137.022 -135.238)"
            fill="#438fff"
          />
        </g>
      </g>
    </svg>
  );
}

function EditIcon() {
  return (
    <svg
      id="edit_3_"
      data-name="edit (3)"
      xmlns="http://www.w3.org/2000/svg"
      width="14.068"
      height="14.068"
      viewBox="0 0 14.068 14.068"
    >
      <g id="Group_4388" data-name="Group 4388">
        <g id="Group_4387" data-name="Group 4387">
          <path
            id="Path_14080"
            data-name="Path 14080"
            d="M0,76.542v2.931H2.931l8.649-8.649L8.649,67.893Z"
            transform="translate(0 -65.405)"
            fill="#373737"
          />
          <path
            id="Path_14081"
            data-name="Path 14081"
            d="M263.028,2.054,261.2.229a.783.783,0,0,0-1.106,0l-1.43,1.43L261.6,4.59l1.43-1.43A.783.783,0,0,0,263.028,2.054Z"
            transform="translate(-249.188)"
            fill="#373737"
          />
        </g>
      </g>
    </svg>
  );
}

function DeleteIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12.016"
      height="15.445"
      viewBox="0 0 12.016 15.445"
    >
      <g id="delete_1_" data-name="delete (1)" transform="translate(0)">
        <path
          id="Path_14082"
          data-name="Path 14082"
          d="M126.4.932h2.581v.434h.932v-.5A.872.872,0,0,0,129.04,0h-2.7a.872.872,0,0,0-.871.872v.5h.932Zm0,0"
          transform="translate(-121.68 0)"
          fill="#373737"
        />
        <path
          id="Path_14083"
          data-name="Path 14083"
          d="M49.653,167.75H40.9a.411.411,0,0,0-.409.444l.732,9.047a.972.972,0,0,0,.968.894h6.17a.972.972,0,0,0,.968-.894l.732-9.047a.41.41,0,0,0-.409-.444Zm-6.632,9.42h-.029a.466.466,0,0,1-.465-.438l-.459-7.427a.466.466,0,0,1,.931-.057l.458,7.427A.466.466,0,0,1,43.02,177.17Zm2.728-.465a.466.466,0,0,1-.932,0v-7.427a.466.466,0,1,1,.932,0Zm2.738-7.4-.438,7.427a.466.466,0,0,1-.465.439h-.028a.466.466,0,0,1-.438-.493l.438-7.427a.466.466,0,0,1,.931.055Zm0,0"
          transform="translate(-39.269 -162.69)"
          fill="#373737"
        />
        <path
          id="Path_14084"
          data-name="Path 14084"
          d="M12,77.534l-.306-.918a.593.593,0,0,0-.562-.405H.889a.593.593,0,0,0-.562.405l-.306.918a.385.385,0,0,0,.365.506H11.631a.38.38,0,0,0,.2-.059A.384.384,0,0,0,12,77.534Zm0,0"
          transform="translate(0 -73.912)"
          fill="#373737"
        />
      </g>
    </svg>
  );
}

function UploadIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="49.353"
      height="49.284"
      viewBox="0 0 49.353 49.284"
    >
      <g id="upload_1_" data-name="upload (1)" transform="translate(0 -0.358)">
        <g
          id="Group_4505"
          data-name="Group 4505"
          transform="translate(0 30.46)"
        >
          <g id="Group_4504" data-name="Group 4504">
            <path
              id="Path_14523"
              data-name="Path 14523"
              d="M45.5,312.642v13.4a1.93,1.93,0,0,1-1.928,1.928H5.784a1.93,1.93,0,0,1-1.928-1.928v-13.4H0v13.4a5.79,5.79,0,0,0,5.784,5.784H43.57a5.79,5.79,0,0,0,5.784-5.784v-13.4Z"
              transform="translate(0 -312.642)"
              fill="#143059"
            />
          </g>
        </g>
        <g
          id="Group_4507"
          data-name="Group 4507"
          transform="translate(12.696 0.358)"
        >
          <g id="Group_4506" data-name="Group 4506" transform="translate(0 0)">
            <path
              id="Path_14524"
              data-name="Path 14524"
              d="M143.7.358l-11.98,11.98,2.726,2.726,7.326-7.326V37.786h3.856V7.739l7.326,7.326,2.726-2.726Z"
              transform="translate(-131.716 -0.358)"
              fill="#143059"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export { AddIcon, EditIcon, DeleteIcon, UploadIcon };
