import React from 'react'


const Footer = () =>{
	return(
	    <div>
		   <footer className="">
		   <div id="contact"> </div> 
				<div className='container'>
					<div className='row'>
						<div className='about_sec col-lg-offset-1 col-lg-4 col-md-5 col-sm-6 col-xs-12'>
							<h5>About Us</h5>
							<p>At Ezio, we believe there is a better way to do Branding and marketing, and we are highly passionate about it. As your partner, we invest ourselves in your company’s success and utmost focus on the least transparent opportunities of every business and deliver effective solutions to our clients. </p>
							<ul>
								<li><a href=''><img src={require('../../images/twitter.png')} alt='' /></a></li>
								<li><a href=''><img src={require('../../images/facebook.png')}  alt='' /></a></li>
								<li><a href=''><img src={require('../../images/linkedin.png')}  alt='' /></a></li>
								<li><a href=''><img src={require('../../images/whatsapp.png')} alt='' /></a></li>
								<li><a href=''><img src={require('../../images/instagram.png')} alt='' /></a></li>
							</ul>
						</div>
						
						<div className='col-lg-2 company col-md-2 col-sm-6 col-xs-12'>
							<h5>Quick Links</h5>
							<a href='' > Home </a>
							<a href='' > Courses </a>
							<a href='' > Syllabus </a>
							<a href='' > Our Program </a>
							<a href='' > Certification </a>
							<a href='' > Blog </a>
						</div>
						
						<div className='contact_set col-lg-4 col-md-3 col-sm-6 col-xs-12'>
							<h5>Contact us</h5>
							<div className='phone'>
								<h5>Phone</h5>
								<a href='tel:+6382657775'>+91 8284954626/+91 6382657775</a>
							</div>
							<div className='email'>
								<h5>Email</h5>
								<a href='mailto:connect@eziosolutios.com'>connect@eziosolutios.com</a>
								<a href='mailto:ragulnithin@eziosolutios.com'>ragulnithin@eziosolutios.com</a>
							</div>
							<div className='address'>
								<h5>Address</h5>
								<p>#33/1, 2nd Floor, Kathir Avenue, Aandal Street, Coimbatore, Tamil Nadu 641004</p>
							</div>
						</div>
					</div>
				</div>
			</footer>
		</div>
	)
}

export default Footer