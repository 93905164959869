import axios from 'axios';
import React,{useState,useEffect} from 'react'
import Cookies from 'js-cookie';
import CustomModal from './CustomModal';
import $ from 'jquery'
import ReactQuill from 'react-quill'; 
import 'react-quill/dist/quill.snow.css';
import Toaster from '../../toaster';
/*
{headers:{
    Authorization:`Bearer ${Cookies.get('token')}`
  } }
*/
function ProjectManagement(props) {
    const [projectList, setProjectList] = useState([])
    const [addPopup,setAddPopup] = useState(false)
    const [editPopupId,setEditPopupId] = useState(false)
    const [fields, Getfields] = useState({});
    const [fieldsEdit, GetfieldsEdit] = useState({});
    const [error,setError]=useState({})
    const [description,setDescription] = useState('')
    const [courseList, setCourseList] = useState([])
    const [selectedCourseId,setSelectedCourseId]= useState()
    const [sectionList, setSectionList] = useState([])
    const [selectedSectionId,setSelectedSectionId]= useState()

    const [tosOpen,setTosOpen]=useState()

    useEffect(() =>{
       GetCourseList()
      },[])

      useEffect(()=>{
        if(selectedCourseId){
          GetSectionList(selectedCourseId)
        }
      },[selectedCourseId])

      useEffect(()=>{
        if(selectedSectionId){
          GetProjectList(selectedSectionId)
        }
      },[selectedSectionId])
  
  const GetProjectList = (id) =>{
    
      axios.get(`https://api.ezioskillacademy.com/api/admin/project/${id}`, {headers:{
        Authorization:`Bearer ${Cookies.get('token')}`
      } })
      .then(res => {
            // console.log("project",res.data)
            setProjectList(res.data.data)    
      }).catch(e=>{
        alert(e.response.data.message)
      })
  }

  const addnewProject=()=>{

    // console.log(fields)
        let err={}
        let isValid=true
        if(!fields.project_title){
            err["project_title"]="Please Enter Project Title."
            isValid=false
        }
        if(!description){
            err["project_desc"]="Please Enter Description."
            isValid=false
        }
        if(!fields.project_file){
          err["project_file"]="Please Upload Project File."
          isValid=false
      }

        if(!fields.demo_video){
            err["demo_video"]="Please Enter Demo Video Link"
            isValid=false
        }
        setError(err)
    

        if(isValid){
            // console.log('success')
            const formData=new FormData()
            formData.append('project_title',fields.project_title)
            formData.append('project_desc',description)
            formData.append('demo_video',fields.demo_video)
            formData.append('project_file',fields.project_file)
            
       
        axios.post('https://api.ezioskillacademy.com/api/admin/project/'+selectedSectionId,formData,{headers:{
            Authorization:`Bearer ${Cookies.get('token')}`
          } })
        .then(res=>{
            // console.log("res",res.data)
           
                GetProjectList(selectedSectionId)
                $('#add_scs_msg').html(res.data.message)
                setTosOpen(res.data.message)
              //  setTimeout(()=>{
                    setAddPopup(false)      
             //   },3000)
                
            
        }).catch(e=>{
           alert(e.response.data.message)
        })
    }
}

const editBatch=()=>{
  let err={}
  let isValid=true
  if(!fieldsEdit.project_title){
    err["project_title"]="Please Enter Project Title."
    isValid=false
}
if(!description){
    err["project_desc"]="Please Enter Description."
    isValid=false
}

if(!fieldsEdit.demo_video){
    err["demo_video"]="Please Enter Demo Video Link"
    isValid=false
}
if(isValid){
  const formData=new FormData()
  formData.append('project_title',fieldsEdit.project_title)
  formData.append('project_desc',description)
  formData.append('demo_video',fieldsEdit.demo_video)
  if(fieldsEdit.project_file){
    formData.append('project_file',fieldsEdit.project_file)
  }
 
    axios.put('https://api.ezioskillacademy.com/api/admin/project/'+editPopupId,formData,{headers:{
        Authorization:`Bearer ${Cookies.get('token')}`
      }})
    .then(res=>{
        // console.log("res",res.data)
       
            GetProjectList(selectedSectionId)
            $('#edit_scs_msg').html(res.data.message)
            setTosOpen(res.data.message)
           // setTimeout(()=>{
                setEditPopupId(undefined)      
          //  },3000)
            
        
    }).catch(e=>{
       alert(e.response.data.message)
    })
  }
  }
  const HandleDelete=(id)=>{
    var result = window.confirm("Are you sure you want to delete?");
    if (result) {
      axios.delete(`https://api.ezioskillacademy.com/api/admin/project/${id}`, {headers:{
        Authorization:`Bearer ${Cookies.get('token')}`
      } })
      .then(res => {
        GetProjectList(selectedSectionId) 
        setTosOpen(res.data.message)             
            
  }).catch(e=>{
    alert(e.response.data.message)
  })
    }
  }
  const handleValue=(e)=>{
    if(e.target.name=="project_file"){
      Getfields({ ...fields, [e.target.name]:e.target.files[0] })
    }else{
      Getfields({ ...fields, [e.target.name]:e.target.value })
    }
      
    }
    const handleEditValue=(e)=>{
      if(e.target.name=="project_file"){
        GetfieldsEdit({ ...fieldsEdit, [e.target.name]:e.target.files[0] })
      }else{
        GetfieldsEdit({ ...fieldsEdit, [e.target.name]:e.target.value })
      }
      }
    const setEditPopupOpen=(project)=>{
        setEditPopupId(project._id)
        GetfieldsEdit(project)
        setDescription(project.project_desc)
        //GetBatchFields(id)
        

    }
    const GetCourseList = () =>{
    
      axios.get(`https://api.ezioskillacademy.com/api/admin/course`, {headers:{
        Authorization:`Bearer ${Cookies.get('token')}`
      } })
      .then(res => {  
                    // console.log("batch",res.data)
                    setCourseList(res.data.data)     
      }).catch(e=>{
        alert(e.response.data.message)
      })
  }
  const GetSectionList = (id) =>{
    
    axios.get(`https://api.ezioskillacademy.com/api/admin/course/section/`+id, {headers:{
      Authorization:`Bearer ${Cookies.get('token')}`
    } })
    .then(res => {  
                  // console.log("batch",res.data)
                  setSectionList(res.data.data)     
    }).catch(e=>{
      alert(e.response.data.message)
    })
}
const changeActive=(e,id)=>{
  // console.log(e.target.value=='enable')

  let enableStatus=e.target.value=='enable'
  axios.patch(`https://api.ezioskillacademy.com/api/admin/project/${id}?isenable=${enableStatus}`,{}, {headers:{
    Authorization:`Bearer ${Cookies.get('token')}`
  } })
    .then(res => {  
                  // console.log("status",res.data)
                  setTosOpen(res.data.message)
                  GetProjectList(selectedSectionId)     
    }).catch(e=>{
      alert(e.response.data.message)
    })
}
  return (
    <div className="dash_page_bg dash_section_ptb add_payment">
        <select onChange={(e)=>{setSelectedCourseId(e.target.value)}} 
         style={{padding:'10px',height:'45px'}} >
           <option selected disabled>Select Course</option>
          {
            courseList.map(course=>(
                <option value={course._id}>{course.course_name}</option>
            ))
          }
          
        </select>

        {
          sectionList.length>0&&(
            <select onChange={(e)=>{setSelectedSectionId(e.target.value)}} 
            style={{padding:'10px',height:'45px'}} >
              <option selected disabled>Select Section</option>
             {
               sectionList.map(section=>(
                   <option value={section._id}>{section.section_name}</option>
               ))
             }
             
           </select>
          )
        }
        <br/> <br/>

        {
          selectedSectionId&&(
            <button className="add_payment_btn" 
            onClick={() => setAddPopup(true)}
             >
               Create Project
             </button>
          )
        }
     
        <br />
        <br />
        <div className="clientrate_table">
          <div className="tabcomp-style">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <td>Project Title</td>
                    {/* <td>Project Description</td> */}
                    <td>Demo Video</td>   
                    <td style={{width:'300px'}}></td>                   
                  </tr>
                </thead>
                <tbody>
                  {projectList.length > 0 ? (
                    <>
                      {projectList
                        .map((project, id) => (
                          <tr key={id}>
                            <td>{project.project_title}</td>
                            {/* <td>{project.project_desc}</td> */}
                            <td>{project.demo_video}</td>  
                            <td style={{display:'flex',justifyContent:'center',width:'300px'}}>
                                <button className='blue_btn' onClick={()=>setEditPopupOpen(project)}>Edit</button>
                                <button 
                                onClick={()=>HandleDelete(project._id)} className="red_btn">Delete</button>
                                
                                <div onChange={(e)=>changeActive(e,project._id)} style={{display:'flex'}}>
                                  <input class="radio" id={"flexRadioDefault1"+project._id} type="radio" name={"flexRadioDefault"+project._id}  value="enable" checked={project.isEnable}/>
                                  <label for={"flexRadioDefault1"+project._id}>
                                    Enable
                                  </label>
                                  &nbsp;&nbsp;
                                  <input class="radio" id={"flexRadioDefault2"+project._id} type="radio" name={"flexRadioDefault"+project._id}  value="disable" checked={!project.isEnable}/>
                                  <label for={"flexRadioDefault2"+project._id}>
                                    Disable
                                  </label>
                                </div>
                               
                                </td>                         
                          </tr>
                        ))}
                    </>
                  ) : (
                    <tr>
                      <td colSpan="6" style={{ textAlign: "center" }}>
                        {selectedSectionId?'No Data':'Select Course and Section'}                         
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
       
        <CustomModal open={addPopup}>
        <div className="scsmdl-container" style={{ height: "auto" }}>
          <div className="scsmdl-header">
            <p>Create Project</p>
            {/* <img src={'/modalclose.jpg'} className="scsmdl-closeimg"  /> */}
            <img
              src={"/modalclose.jpg"}
              className="scsmdl-closeimg"
              onClick={() => setAddPopup(false)}
              alt="close img"
            />
          </div>
          <div className="scsmdl-body" style={{ padding: "20px" }}>
          <div className="form-group">
              <label>Project Title:</label>
              <input
                className="form-control"
                type="text"
                name="project_title"
                onChange={(e) => handleValue(e)}
              />
            <p style={{color:'red'}}>{error.project_title}</p>
            </div>

            <div className="form-group">
              <label>Project File:</label>
              <input
                className="form-control"
                type="file"
                name="project_file"
                onChange={(e) => handleValue(e)}
              />             
            <p style={{color:'red'}}>{error.project_file}</p>
            </div>

            <div className="form-group">
              <label>Project Description:</label>
              <ReactQuill value={description} onChange={(value)=>setDescription(value)} /> 
               <p style={{color:'red'}}>{error.project_desc}</p>
            </div>
            {/* <p style={{ padding: "0", color: "red" }}>{error.service}</p> */}
            <div className="form-group">
              <label>Demo Video:</label>
              <input
                className="form-control"
                type="text"
                name='demo_video'
                onChange={(e) => handleValue(e)}
              />{" "} <p style={{color:'red'}}>{error.demo_video}</p>
            </div>
                      
            <div className="row">
              <div className="col-lg-5">
                <button
                  onClick={addnewProject}
                  className="addpayment-submitbtn"
               //   disabled={loader}
                //  style={loader ? { opacity: "0.5" } : {}}
                >
                  Submit
                </button>
              </div>
             
            </div>
            <p id="add_scs_msg" style={{color:'green'}}></p>
          </div>
        </div>
      </CustomModal>

      <CustomModal open={editPopupId}>
        <div className="scsmdl-container" style={{ height: "auto" }}>
          <div className="scsmdl-header">
            <p>Edit Batch</p>
            {/* <img src={'/modalclose.jpg'} className="scsmdl-closeimg"  /> */}
            <img
              src={"/modalclose.jpg"}
              className="scsmdl-closeimg"
              onClick={() => setEditPopupId(undefined)}
              alt="close img"
            />
          </div>
          <div className="scsmdl-body" style={{ padding: "20px" }}>
         
          <div className="form-group">
              <label>Project Title:</label>
              <input
                className="form-control"
                type="text"
                name="project_title"
                value={fieldsEdit.project_title}
                onChange={(e) => handleEditValue  (e)}
              />             
            </div>

            <div className="form-group">
              <label>Project File:</label>
              <input
                className="form-control"
                type="file"
                name="project_file"
               // value={fieldsEdit.project_file}
                onChange={(e) => handleEditValue(e)}
              />             
            <p style={{color:'red'}}>{error.project_file}</p>
            </div>

            <p style={{color:'red'}}>{error.project_title}</p>
            <div className="form-group">
              <label>Project Description:</label>
              <ReactQuill value={description} onChange={(value)=>setDescription(value)} /> 
               <p style={{color:'red'}}>{error.project_desc}</p>
            </div>
            {/* <p style={{ padding: "0", color: "red" }}>{error.service}</p> */}
            <div className="form-group">
              <label>Demo Video:</label>
              <input
                className="form-control"
                type="text"
                name='demo_video'
                onChange={(e) => handleEditValue(e)}
                value={fieldsEdit.demo_video}
              />{" "} <p style={{color:'red'}}>{error.demo_video}</p>
            </div>
            <div className="row">
              <div className="col-lg-5">
                <button
                  onClick={editBatch}
                  className="addpayment-submitbtn"
                >
                  Submit
                </button>
              </div>
             
            </div>

            <p id="edit_scs_msg" style={{color:'green'}}></p>
          </div>
        </div>
      </CustomModal>
      <Toaster tosOpen={tosOpen} setTosOpen={setTosOpen}/>
      </div>
  )
}

export default ProjectManagement















// const ProjectList =(Props)=>{
//   const reduxValue=useSelector(state=>state)

//   const [Datas, GetData] = useState([])
//   const [editPopupId,setEditPopupId] = useState(false)

//   const [fileUpload,setFileUpload] = useState([])
//   const [fieldsEdit, GetfieldsEdit] = useState({});

//   const [selectedProject,setSelectedProject]= useState()


//   useEffect(() =>{
//    GetUserList()
//    // console.log("msg",Props)
//   },[])

// const GetUserList = () =>{

//   // console.log(Cookies.get('token'))
//     axios.get(`https://api.ezioskillacademy.com/api/admin/list/project-user/`+Props.user._id,{headers:{
//       Authorization:`Bearer ${Cookies.get('token')}`
//     }})
//     .then(res => {
//                     // console.log("res",res.data)
//                    GetData(res.data.data)
                 
             
//     }).catch((err)=>{
      
//         alert("Something Wrong.. Please Login again...")
   
//     })
// }

//   const editProject=(id)=>{
//     // console.log("msg",Props)
//     const formData=new FormData()
//     formData.append('project_data',fieldsEdit.file);
//     formData.append('user_id',localStorage.getItem('user_id'));
//     formData.append('course_name', selectedProject.course_name);
//     formData.append('remarks',fieldsEdit.remarks);
//     formData.append('type_of_user', "admin");
//     formData.append('student_id',Props.user._id);

//     axios.post('https://api.ezioskillacademy.com/api/project',formData, { headers:{
//       Authorization:`Bearer ${Cookies.get('token')}`
//     } })
//     .then(res=>{
//     // // console.log("res====",res.data,fieldsEdit)
//       // if(res.data.status=="success"){
//         setEditPopupId(false)
//         GetUserList()
//       // }
//     })
//   }
  
//   const handleEditValue=(e)=>{
//    // // console.log("file:",e.target.files[0])
//     if(e.target.name==='file'){
//       GetfieldsEdit({ ...fieldsEdit, [e.target.name]:e.target.files[0] })
//     }else{
//       GetfieldsEdit({ ...fieldsEdit, [e.target.name]:e.target.value })
//     }
     
//     }
//   const setEditPopupOpen=(project)=>{
//     //  GetprojectFields(id)
//     setSelectedProject(project)
//     // console.log('User Edit')
//       setEditPopupId(true)       

//   }

//   const setCompleteStatus=(project)=>{
//     // console.log('msg',project)
//     const jsonData={
//     'project_id':project._id,
//     'course_name': project.course_name,
//     'isComplete':true,
//     }
//     axios.put('https://api.ezioskillacademy.com/api/admin/project/complete',jsonData, { headers:{
//       Authorization:`Bearer ${Cookies.get('token')}`
//     }})
//     .then(res=>{
//       // console.log("msg",res)
//       GetUserList()
//     }).catch(err=>{
//       alert(err)
//     })
//   }
//   return(
//     <React.Fragment>
//       <button className="button-35" role="button" onClick={ () => window.location.reload() }>Back</button>
//     <h1>{Props.user.first_name} {Props.user.last_name}</h1>
//     <div className="clientrate_table">
//           <div className="tabcomp-style">
//             <div className="table-responsive">
//               <table className="table">
//                 <thead>
//                   <tr>
//                     <td>Course Name</td>
//                     <td>Project URL</td>
//                     <td>Updated At</td>    
//                     <td>Submitted By</td>    
//                     <td style={{width:'300px'}}></td>                   
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {Datas.length > 0 ? (
//                     <>
//                       {Datas
//                         .map((project, id) => (
//                           <tr key={id}>
//                             <td>{project.course_name}</td>
//                             <td><a href={project.project_url} download>{project.project_url}</a></td>
//                             <td>{project?.updated_at}</td>   
//                             <td>{project?.type_of_user}</td>   
//                             <td style={{justifyContent:'center',width:'300px'}}>
//                                 <button onClick={()=>setEditPopupOpen(project)} style={{backgroundColor:'blue',color:'#fff',borderRadius:'15px',padding:'5px 10px',border:'transparent',margin:'0 10px'}}>Edit</button>
//                                 <button onClick={()=>setCompleteStatus(project)} style={{backgroundColor:project.isComplete?'rgb(1 177 112 / 46%)':'#01B170',color:'#fff',borderRadius:'15px',padding:'5px 10px',border:'transparent',margin:'0 10px',}} disabled={project.isComplete}>Completed</button>

//                                 </td>                         
//                           </tr>
//                         ))}
//                     </>
//                   ) : (
//                     <tr>
//                       <td colSpan="3" style={{ textAlign: "center" }}>
//                         No data
//                       </td>
//                     </tr>
//                   )}
//                 </tbody>
//               </table>
//             </div>
//           </div>
//         </div>
    

//   <CustomModal open={editPopupId}>
//     <div className="scsmdl-container" style={{ height: "auto" }}>
//       <div className="scsmdl-header">
//         <p>Upload Project</p>
//         {/* <img src={'/modalclose.jpg'} className="scsmdl-closeimg"  /> */}
//         <img
//           src={"/modalclose.jpg"}
//           className="scsmdl-closeimg"
//           onClick={() => setEditPopupId(false)}
//           alt="close img"
//         />
//       </div>
//       <div className="scsmdl-body" style={{ padding: "20px" }}>
//       <div className="form-group">
//           <label>File:</label>
//           <div style={{display:'flex'}}>
//           <input
//             className="form-control"
//             type="file"
//             name="file"
//             onChange={(e) => handleEditValue(e)}
//             value={fieldsEdit.first_name}
//             placeholder="First Name"
          
//           />
         
//           </div>
                  
//         </div>
//         <div className="form-group">
//           <label>Remarks:</label>
//           <textarea
//             className="form-control"
//             type="email"
//             name="remarks"
//             onChange={(e) => handleEditValue(e)}
//             value={fieldsEdit.email}
//           />
        
//         </div>
      
       
//         <div className="row">
//           <div className="col-lg-5">
//             <button
//               onClick={editProject}
//               className="addpayment-submitbtn"
//            //   disabled={loader}
//             //  style={loader ? { opacity: "0.5" } : {}}
//             >
//               Submit
//             </button>
//           </div>
         
//         </div>
//          {/* <p style={{ padding: "0", color: "red" }}>{error.failed_status}</p> */}
//       </div>
//     </div>
//   </CustomModal>
//   </React.Fragment>
//   )
// }