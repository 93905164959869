import React from 'react'

export default function RatingIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={90} height={90} viewBox="0 0 90 90">
      <defs>
        <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2={1} gradientUnits="objectBoundingBox">
          <stop offset={0} stopColor="#4d95ff" />
          <stop offset={1} stopColor="#0a2f65" />
        </linearGradient>
        <filter id="Path_10465" x={0} y={0} width={90} height={90} filterUnits="userSpaceOnUse">
          <feOffset input="SourceAlpha" />
          <feGaussianBlur stdDeviation="3.5" result="blur" />
          <feFlood floodOpacity="0.251" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g id="Group_96" data-name="Group 96" transform="translate(10.097 10.556)">
        <g transform="matrix(1, 0, 0, 1, -10.1, -10.56)" filter="url(#Path_10465)">
          <path id="Path_10465-2" data-name="Path 10465" d="M34.5,0A34.5,34.5,0,1,1,0,34.5,34.5,34.5,0,0,1,34.5,0Z" transform="translate(10.5 10.5)" fill="url(#linear-gradient)" />
        </g>
        <path id="speak" d="M31.773,20.464a1.335,1.335,0,1,0-2.475,1l1.572,3.889L26.2,23.3a1.334,1.334,0,0,0-1.062-.007,12.876,12.876,0,0,1-5.081,1.026c-7.147,0-11.948-5.6-11.948-10.823,0-5.968,5.36-10.823,11.948-10.823S32,7.525,32,13.492a10.869,10.869,0,0,1-.119,1.529,1.335,1.335,0,1,0,2.641.394,13.484,13.484,0,0,0,.148-1.923A12.9,12.9,0,0,0,30.35,3.911,15.171,15.171,0,0,0,20.056,0,15.171,15.171,0,0,0,9.762,3.911a12.975,12.975,0,0,0-4.272,8.433A10.792,10.792,0,0,0,.5,21.328a10.357,10.357,0,0,0,2.047,6.189L.6,32.341a1.335,1.335,0,0,0,1.776,1.722l5.622-2.478a12.706,12.706,0,0,0,4.452.793c.033,0,.066,0,.1,0a12.637,12.637,0,0,0,6.281-1.7,11.492,11.492,0,0,0,4.039-3.933,15.379,15.379,0,0,0,2.773-.771L32.8,29.123A1.335,1.335,0,0,0,34.577,27.4ZM12.447,29.7l-.054,0a10.011,10.011,0,0,1-3.9-.8,1.335,1.335,0,0,0-1.062.006L4.3,30.293,5.312,27.8a1.335,1.335,0,0,0-.222-1.367,7.793,7.793,0,0,1-1.922-5.1,7.942,7.942,0,0,1,2.468-5.687,13.476,13.476,0,0,0,3.949,7.217,14.923,14.923,0,0,0,9.713,4.11A9.812,9.812,0,0,1,12.447,29.7Zm9.545-14.952a1.335,1.335,0,1,1,0,2.67h-8.01a1.335,1.335,0,1,1,0-2.67ZM26,12.082H13.982a1.335,1.335,0,1,1,0-2.67H26a1.335,1.335,0,1,1,0,2.67Zm0,0" transform="translate(17.491 16.746)" fill="#fff" />
      </g>
    </svg>


  )
}
