import React from 'react'

export default function HamBurger() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="312.548" height="200.479" viewBox="0 0 312.548 200.479">
      <g id="hamburger" transform="translate(-99.726 -155.76)">
        <g id="Group_34" data-name="Group 34">
          <g id="Group_33" data-name="Group 33">
            <path id="Path_10" data-name="Path 10" d="M396.853,240.579H115.147a15.421,15.421,0,1,0,0,30.842H396.853a15.421,15.421,0,0,0,0-30.842Z" fill="#fff"/>
          </g>
        </g>
        <g id="Group_36" data-name="Group 36">
          <g id="Group_35" data-name="Group 35">
            <path id="Path_11" data-name="Path 11" d="M376.291,155.76H135.709a15.422,15.422,0,0,0,0,30.843H376.291a15.422,15.422,0,1,0,0-30.843Z" fill="#fff"/>
          </g>
        </g>
        <g id="Group_38" data-name="Group 38">
          <g id="Group_37" data-name="Group 37">
            <path id="Path_12" data-name="Path 12" d="M376.291,325.4H135.709a15.421,15.421,0,1,0,0,30.842H376.291a15.421,15.421,0,1,0,0-30.842Z" fill="#fff"/>
          </g>
        </g>
      </g>
    </svg>
  )
}
