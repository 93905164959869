import axios from 'axios';
import React,{useState,useEffect} from 'react'
import Cookies from 'js-cookie';
import $ from 'jquery'
import ReactQuill from 'react-quill'; 
import 'react-quill/dist/quill.snow.css';
import CustomModal from '../superadmin/CustomModal';
import Toaster from '../../toaster';
/*
{headers:{
    Authorization:`Bearer ${Cookies.get('token')}`
  } }
*/
function ProjectSubmission(props) {
    const [projectList, setProjectList] = useState([])

    const [uploadPopupId,setUploadPopupId] = useState(false)
    const [completePopId,setCompletePopId] = useState(false)
    const [fields, Getfields] = useState({});
    const [fieldsComplete, GetfieldsComplete] = useState({});
    const [error,setError]=useState({})
    const [courseList, setCourseList] = useState([])
    const [selectedCourseId,setSelectedCourseId]= useState()

    const [sectionList, setSectionList] = useState([])
    const [sectionForFilter,setSectionForFilter]= useState()

    const [batchList, setBatchList] = useState([])   
    const [batchForFilter,setBatchForFilter]=useState()

    const [statusForFilter,setStatusForFilter]=useState()

    const [submissionList,setSubmissionList]= useState([])   

    const [filterParams,setFilterParams]= useState({}) 
    const [tosOpen,setTosOpen]=useState()


    useEffect(() =>{
       GetCourseList()
    //   GetProjectSubmissionList({})
      GetBatchList()
      },[])

      useEffect(()=>{
        if(selectedCourseId){
          GetSectionList(selectedCourseId)
        }
      },[selectedCourseId])

      useEffect(()=>{
        if(batchForFilter){
        GetProjectSubmissionList({})
        }
      },[batchForFilter])

      useEffect(()=>{
        if(sectionForFilter){
        GetProjectSubmissionList({'section':sectionForFilter})
        }
      },[sectionForFilter])

      useEffect(()=>{
        if(statusForFilter){
        GetProjectSubmissionList({'status':statusForFilter})
        }
      },[statusForFilter])
      

      const GetBatchList = () =>{
    
        axios.get(`https://api.ezioskillacademy.com/api/mentor/batch`, {headers:{
          Authorization:`Bearer ${Cookies.get('token')}`
        } })
        .then(res => {
                      // console.log("batch",res.data)
                      setBatchList(res.data.data)    
        }).catch(e=>{
          alert(e.response.data.message)
        })
    }
    const GetCourseList = () =>{
    
        axios.get(`https://api.ezioskillacademy.com/api/mentor/course`, {headers:{
          Authorization:`Bearer ${Cookies.get('token')}`
        } })
        .then(res => {  
                      // console.log("batch",res.data)
                      setCourseList(res.data.data)     
        }).catch(e=>{
          alert(e.response.data.message)
        })
    }
  
    function GetProjectSubmissionList(obj){
        let paramss=filterParams
        if(obj['section']){
            paramss['section_name']=obj['section']
        }
        if(obj['status']){
            paramss['project_status']=obj['status']
        }
        setFilterParams(paramss)
        // console.log("paramss",paramss,batchForFilter)
      axios.get('https://api.ezioskillacademy.com/api/mentor/project/list/'+batchForFilter, 
      {headers:{
          Authorization:`Bearer ${Cookies.get('token')}`
        } 
        ,params:paramss,
    })
        .then(res => {  
                      // console.log("submission",res.data)
                      setSubmissionList(res.data.data)    
        }).catch(e=>{
          alert(e.response.data.message)
        })
    }
    const GetSectionList = (id) =>{
      
      axios.get(`https://api.ezioskillacademy.com/api/mentor/course/section/`+id, {headers:{
        Authorization:`Bearer ${Cookies.get('token')}`
      } })
      .then(res => {  
                    // console.log("batch",res.data)
                    setSectionList(res.data.data)     
      }).catch(e=>{
        alert(e.response.data.message)
      })
  }

  const handleValue=(e)=>{
      if(e.target.name==='file_url'){
        Getfields({ ...fields, [e.target.name]:e.target.files[0] })
      }
      else{
        Getfields({ ...fields, [e.target.name]:e.target.value })
      }
     
    }
 
 

const setCompleteStatus=(submission)=>{
    axios.put(`https://api.ezioskillacademy.com/api/admin/project/completion/${submission._id}/${submission.users._id}`,{}, {headers:{
        Authorization:`Bearer ${Cookies.get('token')}`
      } })
        .then(res => {  
                      // console.log("status",res.data)
                      GetProjectSubmissionList({})     
        }).catch(e=>{
          alert(e.response.data.message)
        })
}

const uploadRemarks=()=>{
    let err={}
    let isValid=true
    if(!fields.file_url){
        err["file_url"]="Please Upload File"
        isValid=false
    }
    if(!fields.remarks){
        err["remarks"]="Please Enter Remarks"
        isValid=false
    }
    setError(err)
    if(isValid){
        const formData=new FormData()
        formData.append('file_url',fields.file_url)
        formData.append('remarks',fields.remarks)
        axios.post(`https://api.ezioskillacademy.com/api/mentor/project/completion/${uploadPopupId._id}/${uploadPopupId.users._id}`,formData,{headers:{
            Authorization:`Bearer ${Cookies.get('token')}`
          } })
        .then(res=>{
            // console.log("res",res.data)
                GetProjectSubmissionList({})
                setTosOpen(res.data.message)
                $('#add_scs_msg').html(res.data.message)
              //  setTimeout(()=>{
                    setUploadPopupId(undefined)      
               // },3000)
                
            
        }).catch(e=>{
           alert(e.response.data.message)
        })
    }
}
const handleCompleteValue=(e)=>{
  if(e.target.name==='file_url'){
    GetfieldsComplete({ ...fieldsComplete, [e.target.name]:e.target.files[0] })
  }else{
    GetfieldsComplete({ ...fieldsComplete, [e.target.name]:e.target.value })
  }
}
const completeProject=()=>{
  let err={}
  let isValid=true
  if(!fieldsComplete.file_url){
      err["file_url"]="Please Upload File"
      isValid=false
  }
  if(!fieldsComplete.feedback){
      err["feedback"]="Please Enter Feedback"
      isValid=false
  }
  console.log("mmmmm",fieldsComplete)
  setError(err)
  if(isValid){
    axios.put(`https://api.ezioskillacademy.com/api/mentor/project/completion/${completePopId._id}/${completePopId.users._id}`,{
      file_url:fieldsComplete.file_url,
      remarks:fieldsComplete.feedback
    }, {headers:{
      Authorization:`Bearer ${Cookies.get('token')}`
    } })
      .then(res => {  
                   // console.log("status",res.data)
                   setCompletePopId(undefined)
                    setTosOpen(res.data.message)
                    GetProjectSubmissionList({})     
      }).catch(e=>{
        alert(e.response.data.message)
      })
  }
}

  return (
    <div className="dash_page_bg dash_section_ptb add_payment">
         <select style={{padding:'10px',height:'45px',margin:'0 20px'}} onChange={(e)=>setBatchForFilter(e.target.value)}>
              <option disabled selected>Select Batch</option>
              {
                batchList.map(batch=><option value={batch._id} >{batch.batch_name}</option>)
              }
            </select>

          {
            batchForFilter&&(
              <select onChange={(e)=>{setSelectedCourseId(e.target.value)}} 
              style={{padding:'10px',height:'45px'}} >
                <option selected disabled>Select Course</option>
               {
                 courseList.map(course=>(
                     <option value={course._id}>{course.course_name}</option>
                 ))
               }               
             </select>
            )
          }
     

        {
          sectionList.length>0&&(
            <select onChange={(e)=>{setSectionForFilter(e.target.value)}} 
            style={{padding:'10px',height:'45px'}} >
              <option selected disabled>Select Section</option>
             {
               sectionList.map(section=>(
                   <option value={section.section_name}>{section.section_name}</option>
               ))
             }
             
           </select>
          )
        }
      
      {
            batchForFilter&&(
            <select style={{padding:'10px',height:'45px',margin:'0 20px'}} onChange={(e)=>setStatusForFilter(e.target.value)}>
              <option disabled selected>Select Status</option>
              <option value={'submitted'}>submitted</option>
              <option value={'reviwed'}>reviwed</option>
              <option value={'completed'}>completed</option>
               <option value={'none'}>No Filter</option>
            </select>
            )
      }
        <br/> <br/>

     
        <div className="clientrate_table">
          <div className="tabcomp-style">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <td>Name</td>
                    <td>Batch Name</td>
                    <td>Status</td>  
                    <td>Submitted File</td>   
                    <td style={{width:'200px'}}></td>                   
                  </tr>
                </thead>
                <tbody>
                  {submissionList.length > 0 ? (
                    <>
                      {submissionList
                        .map((submission, id) => (
                          <tr key={id}>
                            <td>{submission.users.name}</td>
                            {/* <td>{submission.project_desc}</td> */}
                            <td>{submission.batches.batch_name}</td>  
                            <td>{submission.project.status}</td>   
                            <td>
                            <a href={submission.project.file_url} download>
                            <button className="blue_btn">Download File</button>
                                </a>  
                                </td> 
                                <td>
                                <button className="green_btn" onClick={()=>setUploadPopupId(submission)} style={submission.project.status=='completed'?{backgroundColor:'rgb(1 177 112 / 46%)'}:{}} disabled={submission.project.status=='completed'}>Redo</button>
                            <button className="green_btn"  onClick={()=>setCompletePopId(submission)} style={submission.project.status=='completed'?{backgroundColor:'rgb(1 177 112 / 46%)'}:{}} disabled={submission.project.status=='completed'}>Completed</button>
                                
                                </td> 
                            {/* <td style={{display:'flex',justifyContent:'center',width:'200px'}}>
                                <button className='blue_btn' onClick={()=>setEditPopupOpen(project)}>Edit</button>
                                <div onChange={(e)=>changeActive(e,project._id)} style={{display:'flex'}}>
                                  <input class="radio" id={"flexRadioDefault1"+project._id} type="radio" name={"flexRadioDefault"+project._id}  value="enable" checked={project.isEnable}/>
                                  <label for={"flexRadioDefault1"+project._id}>
                                    Enable
                                  </label>
                                  &nbsp;&nbsp;
                                  <input class="radio" id={"flexRadioDefault2"+project._id} type="radio" name={"flexRadioDefault"+project._id}  value="disable" checked={!project.isEnable}/>
                                  <label for={"flexRadioDefault2"+project._id}>
                                    Disable
                                  </label>
                                </div>
                                <button 
                                onClick={()=>HandleDelete(project._id)} className="red_btn">Delete</button>
                                </td>                          */}
                          </tr>
                        ))}
                    </>
                  ) : (
                    <tr>
                      <td colSpan="6" style={{ textAlign: "center" }}>
                       {batchForFilter?'No Data':'Please Select Batch'}                     
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <CustomModal open={uploadPopupId}>
     <div className="scsmdl-container" style={{ height: "auto" }}>
       <div className="scsmdl-header">
         <p>Redo Project</p>
        
         <img
          src={"/modalclose.jpg"}
          className="scsmdl-closeimg"
          onClick={() => setUploadPopupId(undefined)}
          alt="close img"
        />
      </div>
      <div className="scsmdl-body" style={{ padding: "20px" }}>
      <div className="form-group">
          <label>File:</label>
          <div style={{display:'flex'}}>
          <input
            className="form-control"
            type="file"
            name="file_url"
            onChange={(e) => handleValue(e)}
          
          />
          </div>
          <p style={{color:'red'}}>{error.file_url}</p>        
        </div>
        <div className="form-group">
          <label>Remarks:</label>
          <textarea
            className="form-control"
            name="remarks"
            onChange={(e) => handleValue(e)}
          />
        
        </div>
        <p style={{color:'red'}}>{error.remarks}</p>
      <br/>
       
        <div className="row">
          <div className="col-lg-5">
            <button
              onClick={uploadRemarks}
              className="addpayment-submitbtn"
           //   disabled={loader}
            //  style={loader ? { opacity: "0.5" } : {}}
            >
              Submit
            </button>
          </div>
         
        </div>
        <p id="add_scs_msg" style={{color:'green'}}></p>
      </div>
    </div>
  </CustomModal>

  <CustomModal open={completePopId}>
     <div className="scsmdl-container" style={{ height: "auto" }}>
       <div className="scsmdl-header">
         <p>Complete Project</p>
        
         <img
          src={"/modalclose.jpg"}
          className="scsmdl-closeimg"
          onClick={() => setCompletePopId(undefined)}
          alt="close img"
        />
      </div>
      <div className="scsmdl-body" style={{ padding: "20px" }}>
      <div className="form-group">
          <label>File:</label>
          <div style={{display:'flex'}}>
          <input
            className="form-control"
            type="file"
            name="file_url"
            onChange={(e) => handleCompleteValue(e)}
          
          />
          </div>
          <p style={{color:'red'}}>{error.file_url}</p>        
        </div>
        <div className="form-group">
          <label>Feedback:</label>
          <textarea
            className="form-control"
            name="feedback"
            onChange={(e) => handleCompleteValue(e)}
          />
        </div>
        <p style={{color:'red'}}>{error.feedback}</p>
      <br/>
       
        <div className="row">
          <div className="col-lg-5">
            <button
              onClick={completeProject}
              className="addpayment-submitbtn"
           //   disabled={loader}
            //  style={loader ? { opacity: "0.5" } : {}}
            >
              Submit
            </button>
          </div>
         
        </div>
        <p id="add_scs_msg" style={{color:'green'}}></p>
      </div>
    </div>
  </CustomModal>
  <Toaster tosOpen={tosOpen} setTosOpen={setTosOpen}/>
      </div>
  )
}

export default ProjectSubmission





