import React from 'react'

export function EditIcon() {
    return (
        <svg id="edit_1_" data-name="edit (1)" xmlns="http://www.w3.org/2000/svg" width="22.374" height="23.32" viewBox="0 0 22.374 23.32">
            <g id="Group_1725" data-name="Group 1725" transform="translate(0 0)">
                <g id="Group_1724" data-name="Group 1724" transform="translate(0 0)">
                <path id="Path_11776" data-name="Path 11776" d="M26.609,18.656h.126l6.082-1.324a.6.6,0,0,0,.315-.158l10.3-10.3A3.971,3.971,0,0,0,44.6,4.034a4.1,4.1,0,0,0-1.166-2.868A3.97,3.97,0,0,0,40.6,0a3.876,3.876,0,0,0-2.836,1.2L27.491,11.5a.567.567,0,0,0-.189.284l-1.323,6.082a.693.693,0,0,0,.189.6A.63.63,0,0,0,26.609,18.656ZM40.6,1.261A2.742,2.742,0,0,1,43.343,4q0,.016,0,.032a2.647,2.647,0,0,1-.788,1.922L38.679,2.049A2.71,2.71,0,0,1,40.6,1.261Zm-2.8,1.7,3.876,3.876-8.981,8.95-3.876-3.845ZM28.311,13.2l3.12,3.12-4,.882Z" transform="translate(-24.876 0)"/>
                <path id="Path_11777" data-name="Path 11777" d="M29.582,365.717H8.468a.63.63,0,1,0,0,1.26H29.582a.63.63,0,1,0,0-1.26Z" transform="translate(-7.838 -343.658)"/>
                </g>
            </g>
        </svg>
    )
}
