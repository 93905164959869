import axios from 'axios';
import React,{useState,useEffect} from 'react'
import Cookies from 'js-cookie';
import CustomModal from './CustomModal';
import $ from 'jquery'
import Toaster from '../../toaster';

/*
{headers:{
    Authorization:`Bearer ${Cookies.get('token')}`
  } }
*/
function MentorManagement(props) {
    const [details, setDetails] = useState([])
    const [mentorList, setMentorList] = useState([])
    const [addPopup,setAddPopup] = useState(false)
    const [editPopupId,setEditPopupId] = useState()
    const [fields, Getfields] = useState({});
    const [fieldsEdit, GetfieldsEdit] = useState({});
    const [error,setError]=useState({})

    const [batchAllocationPopup,setBatchAllocationPopup]=useState(false)
    const [batchList, setBatchList] = useState([])
    const [selectedBatch,setSelectedBatch]=useState()
    const [selectedMentor,setSelectedMentor]=useState()

    const [tosOpen,setTosOpen]=useState()

    useEffect(() =>{
      GetMentorList()
      getBatchList()
      
  },[])
  
  const GetMentorList = () =>{    
      axios.get(`https://api.ezioskillacademy.com/api/admin/mentor`, {headers:{
        Authorization:`Bearer ${Cookies.get('token')}`
      } })
      .then(res => {  
                    // console.log("batch",res.data)
                    setMentorList(res.data.data)     
      }).catch(e=>{
        alert(e.response.data.message)
      })
  }
  const HandleDelete=(id)=>{
    var result = window.confirm("Are you sure you want to delete?");
    if (result) {
      axios.delete(`https://api.ezioskillacademy.com/api/admin/mentor/${id}`,{headers:{
        Authorization:`Bearer ${Cookies.get('token')}`
      } })
      .then(res => {
            GetMentorList()              
            setTosOpen(res.data.message)   
      }).catch(e=>{
        alert(e.response.data.message)
      })
    }
  }
  const addnewMentor=()=>{

    // console.log(fields)
        let err={}
        let isValid=true
        if(!fields.name){
            err["name"]="Please Enter Batch Name"
            isValid=false
        }
        if(!fields.email || !/\S+@\S+\.\S+/.test(fields.email)){
            err["email"]="Please Enter Email"
            isValid=false
        }
        if(!fields.phoneno){
            err["phoneno"]="Please Enter phone number"
            isValid=false
        }
       
        setError(err)
    // console.log({error})

        if(isValid){
        const jsonData={
            "name": fields.name,
            "email":fields.email,
            "phoneno": fields.phoneno
        }
        axios.post('https://api.ezioskillacademy.com/api/admin/mentor',jsonData,{headers:{
            Authorization:`Bearer ${Cookies.get('token')}`
          } })
        .then(res=>{
            // console.log("res",res.data)
           
                GetMentorList()
                setTosOpen(res.data.message)
                $('#add_scs_msg').html(res.data.message)
                setAddPopup(false)
                // setTimeout(()=>{
                //     setAddPopup(false)      
                // },3000)
                
            
        }).catch(e=>{
           alert(e.response.data.message)
        })
    }
}

const editBatch=()=>{

    const jsonData={
        "name": fieldsEdit.name,
        "email":fieldsEdit.email,
        "phoneno": fieldsEdit.phoneno
    }
    axios.put('https://api.ezioskillacademy.com/api/admin/mentor/'+editPopupId,jsonData,{headers:{
        Authorization:`Bearer ${Cookies.get('token')}`
      } })
    .then(res=>{
            GetMentorList()
            $('#edit_scs_msg').html(res.data.message)
            setTosOpen(res.data.message)
          //  setTimeout(()=>{
                setEditPopupId(undefined)      
         //   },3000)
            
        
    }).catch(e=>{
       alert(e.response.data.message)
    })

  }

  const handleValue=(e)=>{
      Getfields({ ...fields, [e.target.name]:e.target.value })
    }
    const handleEditValue=(e)=>{
        GetfieldsEdit({ ...fieldsEdit, [e.target.name]:e.target.value })
      }
    const setEditPopupOpen=(mentor)=>{
        setEditPopupId(mentor._id)
        GetfieldsEdit(mentor)
        //GetBatchFields(id)       

    }

    function getBatchList(){
      axios.get(`https://api.ezioskillacademy.com/api/admin/batch`, {headers:{
        Authorization:`Bearer ${Cookies.get('token')}`
      } })
      .then(res => {
        // console.log("batch",res.data)
        setBatchList(res.data.data)    
      }).catch(e=>{
        alert(e.response.data.message)
      })
    }

    const allocateBatch=()=>{
     // // console.log(selectedBatch,selectedMentor)
      if(selectedBatch){
      const jsonData={
        "mentor_id": selectedMentor._id,
      }
      axios.put('https://api.ezioskillacademy.com/api/admin/batch/mentor/allocate/'+selectedBatch,jsonData,{headers:{
        Authorization:`Bearer ${Cookies.get('token')}`
      } })
      .then(res=>{
        // console.log("res",res.data)
       
            $('#allocate_scs_msg').html(res.data.message)
            setSelectedBatch(undefined)
            setSelectedMentor(undefined)
            getBatchList()
            setTimeout(()=>{
                setBatchAllocationPopup(false)      
            },3000)
            
        
    }).catch(e=>{
       alert(e.response.data.message)
    })
  }
  else{
    alert('Please Select Required Data')
  }
  
    }
  return (
    <div className="dash_page_bg dash_section_ptb add_payment">
        <button className="add_payment_btn" 
       onClick={() => setAddPopup(true)}
        >
          Add Mentor
        </button>
        <br />
        <br />
        <div className="clientrate_table">
          <div className="tabcomp-style">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <td>Name</td>
                    <td>Email</td>
                    <td>Phone Number</td>  
                    <td style={{width:'300px'}}></td>                   
                  </tr>
                </thead>
                <tbody>
                  {mentorList.length > 0 ? (
                    <>
                      {mentorList
                        .map((mentor, id) => (
                          <tr key={id}>
                            <td>{mentor.name}</td>
                            <td>{mentor.email}</td>
                            <td>{mentor.phoneno}</td>     
                            <td style={{display:'flex',justifyContent:'center',width:'300px'}}>
                        
                                <button className='green_btn' onClick={()=>{setBatchAllocationPopup(true);setSelectedMentor(mentor);}}>Assign Batch</button>
                                <button className='blue_btn' onClick={()=>setEditPopupOpen(mentor)}>Edit</button>
                                <button 
                                onClick={()=>HandleDelete(mentor._id)} className='red_btn'>Delete</button>
                                </td>                         
                          </tr>
                        ))}
                    </>
                  ) : (
                    <tr>
                      <td colSpan="4" style={{ textAlign: "center" }}>
                        No data
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {/* pagination */}
        <div style={{ paddingTop: "32px" }}>
          {/* <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            pageCount={Math.ceil(paymentList.length / cardsPerPage)}
            onPageChange={changePage}
            containerClassName={"paginationBttns"}
            previousLinkClassName={"previousBttn"}
            nextLinkClassName={"nextBttn"}
            disabledClassName={"paginationDisabled"}
            activeClassName={"paginationActive"}
          /> */}
        </div>
        {/* pagination */}
        <CustomModal open={addPopup}>
        <div className="scsmdl-container" style={{ height: "auto" }}>
          <div className="scsmdl-header">
            <p>Add Mentor</p>
            {/* <img src={'/modalclose.jpg'} className="scsmdl-closeimg"  /> */}
            <img
              src={"/modalclose.jpg"}
              className="scsmdl-closeimg"
              onClick={() => setAddPopup(false)}
              alt="close img"
            />
          </div>
          <div className="scsmdl-body" style={{ padding: "20px" }}>
          <div className="form-group">
              <label>Mentor Name:</label>
              <input
                className="form-control"
                type="text"
                name="name"
                onChange={(e) => handleValue(e)}
              />
             
            </div>
            <p style={{color:'red'}}>{error.name}</p>
            <div className="form-group">
              <label>Email:</label>
              <input
                className="form-control"
                type="text"
                name="email"
                onChange={(e) => handleValue(e)}
              />
               <p style={{color:'red'}}>{error.email}</p>
            </div>
            {/* <p style={{ padding: "0", color: "red" }}>{error.service}</p> */}
            <div className="form-group">
              <label>Phone Number:</label>
              <input
                className="form-control"
                type="text"
                name='phoneno'
                onChange={(e) => handleValue(e)}
              />{" "} <p style={{color:'red'}}>{error.phoneno}</p>
            </div>
           
            <div className="row">
              <div className="col-lg-5">
                <button
                  onClick={addnewMentor}
                  className="addpayment-submitbtn"
               //   disabled={loader}
                //  style={loader ? { opacity: "0.5" } : {}}
                >
                  Submit
                </button>
              </div>
             
            </div>
            <p id="add_scs_msg" style={{color:'green'}}></p>
          </div>
        </div>
      </CustomModal>

      <CustomModal open={editPopupId}>
        <div className="scsmdl-container" style={{ height: "auto" }}>
          <div className="scsmdl-header">
            <p>Edit Mentor  </p>
            {/* <img src={'/modalclose.jpg'} className="scsmdl-closeimg"  /> */}
            <img
              src={"/modalclose.jpg"}
              className="scsmdl-closeimg"
              onClick={() => setEditPopupId(undefined)}
              alt="close img"
            />
          </div>
          <div className="scsmdl-body" style={{ padding: "20px" }}>
         
          <div className="form-group">
              <label>Mentor Name:</label>
              <input
                className="form-control"
                type="text"
                name="name"
                onChange={(e) => handleEditValue(e)}
                value={fieldsEdit.name}
              />
            </div>
            <div className="form-group">
              <label>Email:</label>
              <input
                className="form-control"
                type="text"
                name="email"
                onChange={(e) => handleEditValue(e)}
                value={fieldsEdit.email}
              />
            </div>
            <div className="form-group">
              <label>Phone Number:</label>
              <input
                className="form-control"
                type="text"
                name='phoneno'
                onChange={(e) => handleEditValue(e)}
                value={fieldsEdit.phoneno}
              />
            </div>
            <br/>       
            <div className="row">
              <div className="col-lg-5">
                <button
                  onClick={editBatch}
                  className="addpayment-submitbtn"
                >
                  Submit
                </button>
              </div>
            </div>
            <p id="edit_scs_msg" style={{color:'green'}}></p>
          </div>
        </div>
      </CustomModal>

      <CustomModal open={batchAllocationPopup}>
        <div className="scsmdl-container" style={{ height: "auto" }}>
          <div className="scsmdl-header">
            <p>Batch Allocation</p>
            {/* <img src={'/modalclose.jpg'} className="scsmdl-closeimg"  /> */}
            <img
              src={"/modalclose.jpg"}
              className="scsmdl-closeimg"
              onClick={() => setBatchAllocationPopup(false)}
              alt="close img"
            />
          </div>
          <div className="scsmdl-body" style={{ padding: "20px" }}>
          
          {/* <ul>
            {
              selectedUserList.map(user=>(<li style={{listStyle:'inside'}}>{user.name}</li>))
            }
          </ul> */}
            <select style={{width:'100%',height:'40px',border: '1px solid #e8e8e8',margin:'15px 0'}} onChange={(e)=>setSelectedBatch(e.target.value)}>
              <option disabled selected>Select Batch</option>
              {
                batchList.map(batch=>(
                  <>
                  {
                    !batch.mentor&&(
                      <option value={batch._id} >{batch.batch_name}</option>
                    )
                  }
                  </>               
                ))
              }
            </select>
          
               <button
                  onClick={allocateBatch}
                  className="addpayment-submitbtn"
                >
                  Submit
                </button>
                <p id="allocate_scs_msg" style={{color:'green'}}></p>
          </div>
        </div>
      </CustomModal>
          <Toaster tosOpen={tosOpen} setTosOpen={setTosOpen}/>
      </div>
  )
}

export default MentorManagement