import React from 'react'

export default function BillingIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20.001" height="20.73" viewBox="0 0 20.001 20.73">
      <g id="Billing" transform="translate(-14659 20777)">
        <g id="bill_1_" data-name="bill (1)" transform="translate(14650 -20777)">
          <path id="Path_11674" data-name="Path 11674" d="M24.145,18.909c0-.629,0,.491,0-16.346A2.566,2.566,0,0,0,21.579,0H11.563A2.566,2.566,0,0,0,9,2.563V16.519a4.216,4.216,0,0,0,4.211,4.211H24.753a3.019,3.019,0,0,1-.608-1.821Zm-9.031-6.965H12.037a.607.607,0,0,1,0-1.215h3.077a.607.607,0,1,1,0,1.215Zm0-2.591H12.037a.607.607,0,0,1,0-1.215h3.077a.607.607,0,1,1,0,1.215Zm4.676,7.318v.42a.607.607,0,1,1-1.215,0v-.364a2.92,2.92,0,0,1-1.445-.542.607.607,0,0,1,.665-1.017,1.813,1.813,0,0,0,.78.338V13a4.408,4.408,0,0,1-.9-.526,2,2,0,0,1,.9-3.385V8.724a.607.607,0,1,1,1.215,0v.323a2.461,2.461,0,0,1,1.139.545A.608.608,0,0,1,20.5,10.63c-.322,0-.347-.181-.713-.323v1.879l.31.112a2.287,2.287,0,0,1-.31,4.373Zm1.317-9.909H12.037a.607.607,0,0,1,0-1.215h9.069a.607.607,0,1,1,0,1.215Zm0-2.267H12.037a.607.607,0,0,1,0-1.215h9.069a.607.607,0,1,1,0,1.215Z" fill="#143059"/>
          <path id="Path_11675" data-name="Path 11675" d="M237.888,258.257c.045.036.1.07.148.1V257.15A.79.79,0,0,0,237.888,258.257Z" transform="translate(-219.461 -246.739)" fill="#143059"/>
          <path id="Path_11676" data-name="Path 11676" d="M275.48,332.94v1.889A1.055,1.055,0,0,0,275.48,332.94Z" transform="translate(-255.691 -319.46)" fill="#143059"/>
          <path id="Path_11677" data-name="Path 11677" d="M415.313,241H413c0,10.65,0,8.6,0,9.152a1.821,1.821,0,0,0,3.641,0v-7.821A1.333,1.333,0,0,0,415.313,241Z" transform="translate(-387.643 -231.242)" fill="#143059"/>
        </g>
      </g>
    </svg>
  )
}
