import React from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import axios from 'axios';
import Cookies from 'js-cookie'

const headers={
	Authorization:`Bearer ${Cookies.get('token')}`
}
export const Video = (props) => {
  const videoRef = React.useRef(null);
  const playerRef = React.useRef(null);
  const {project} = props;

  const videoJsOptions = {
   // autoplay: true,
    controls: true,
    responsive: true,
    fluid: true,
    playbackRates: [0.5, 1, 1.5, 2],
    sources: [{
      src: project.demo_video,
      type: 'video/mp4'
    }],
   // poster:require('./../images/website/certificate.png'),

    //'https://png.pngtree.com/thumb_back/fh260/backgr  ound/20200714/pngtree-modern-double-color-futuristic-neon-background-image_351866.jpg'
  };

  React.useEffect(() => {

    // Make sure Video.js player is only initialized once
    if (!playerRef.current) {
      const videoElement = videoRef.current;

      if (!videoElement) return;

      const player = playerRef.current = videojs(videoElement, videoJsOptions, () => {
        player.log('player is ready');
        handlePlayerReady && handlePlayerReady(player);
      });
    //  player.volume(0)

    // You can update player in the `else` block here, for example:
    }
 
     else {
         // console.log("Something problem")
    //   player.autoplay(videoJsOptions.autoplay);
    //   player.src(videoJsOptions.sources);
    }

  }, [videoJsOptions, videoRef]);


  function handlePlayerReady (player) {
    playerRef.current = player;
    // You can handle player events here, for example:
    player.on('play', function() {
      player.log('playing',player.currentTime());
    });
   
    player.on('waiting', () => {
      player.log('player is waiting',player.currentTime());
    });

    player.on('pause', () => {
      player.log('player is paused',player.currentTime());
    });
    player.on('dispose', () => {
      player.log('player will dispose',player.currentTime());
    });
    player.on('ended', () => {
      
        player.log('player ended');
  //       const jsonData={
  //         "course_title": currentSection.section_name,
  //         "section": currentSection.sid,
  //         "video": currentVideo.vid,
  //         "isComplete": true
  //     }
  //       axios.post('https://api.ezioskillacademy.com/api/user/course/completion',jsonData,{headers})
	// 	.then((res)=>{
	// 		// console.log(res.data)
	//  })
      });
      
  };

  // Dispose the Video.js player when the functional component unmounts
  React.useEffect(() => {
    const player = playerRef.current;
    // console.log("dur",playerRef.duration)
    return () => {
      if (player) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <div data-vjs-player>
      <video ref={videoRef} controls className='video-js vjs-big-play-centered' />
    </div>
  );
}

export default Video;