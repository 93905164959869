import React from 'react';
export const ourCourses=[
    {
        id:'1',
        name:'Marketing Fundamentals',
        icon:'',
        description:'Learn the strategies and approaches for promoting the products and services to consumers.<span> From market research to writing ad copy falls within the realm of marketing. You will gather some interesting tricks to which ignites your creativity.</span>',
        image:'Marketing Fundamentals',
        rating:'4.7',
        url:'/marketing-fundamentals',
    
    },
    {
        id:'2',
        name:'SEO',
        icon:'',
        description:'Learn what it takes to be a SEO professional with this complete training course.<span> Get a good understanding of 50+ SEO factors & Essential tools to rank websites high and technically sound through “Learn by Doing” hands on approach.</span>',
        image:'SEO',
        rating:'4.3',
        url:'/seo',
    
    },
    {
        id:'3',
        name:'Content Marketing',
        icon:'',
        description:'Learn to create content that sells. Increase sales, traffic, and brand recognition<span> using the power of digital content. Master content creation, promotion, copywriting SEO, email marketing & more to create stunning content that grows your business. Become an expert content creator to stay ahead of the pack.</span>',
        image:'content-Marketing',
        rating:'4.8',
        url:'/content-marketing',
    
    },
    
    {
        id:'4',
        name:'Email Marketing',
        icon:'',
        description:'Learn how to create an effective Email Marketing Campaign that makes your business more successful.<span> Develop an in-depth understanding of various advanced tools to automate your emails. Learn to create an email marketing strategy that effectively builds lasting engagement and stronger relationships with your customers to grow and scale your business.</span>',
        image:'Email-Marketing',
        rating:'4.3',
        url:'/email-marketing',
    
    },
    {
        id:'5',
        name:'PPC',
        icon:'',
        description:'An all-inclusive course designed to make you understand all the common PPC techniques to <span>increase your PPC performance by at least 100%. Upon completion of this course, you will know how to create, develop, and optimize your own profitable PPC Campaigns</span>',
        image:'ppc',
        rating:'4.5',
        url:'',
    
    },
    {
        id:'6',
        name:'Social Media Marketing',
        icon:'',
        description:'Understand the social media foundations to grow a business online from scratch. <span>Learn social media marketing, management & advertising through Watch over my Shoulder to Understand. Master online marketing on various ad platforms including Twitter, Instagram, Facebook & others. Understand how social media really works for your business. Gain skills to monetize the social platform.</span>',
        image:'Digital-Marketing-Deep-Study',
        rating:'4.7',
        url:'/smm',
    
    },
    {
        id:'7',
        name:'Facebook Marketing',
        icon:'',
        description:'Learn to set up profitable Facebook Ad campaigns to drive traffic & increase conversions. <span>No boring PowerPoints. Clear, direct, and over-the-shoulder lessons on everything you need to know about Facebook Ads.</span>',
        image:'facebook-marketing',
        rating:'4.7',
        url:'',
    
    },
    {
        id:'8',
        name:'LinkedIn Marketing',
        icon:'',
        description:'Learn powerful & proven B2B marketing strategies that will transform your marketing impact on LinkedIn.<span> Expand your skillset in LinkedIn Advertising to create Pipeline-Driven Campaigns. Learn Campaign optimization techniques and the strategies to measure campaign performance and ROI.</span>',
        image:'linkedin-marketing',
        rating:'4.4',
        url:'',
    
    },
    {
        id:'9',
        name:'NFT Marketing',
        icon:'',
        description:'Learn to promote or advertise NFT’s in the Market. NFT stands for Non-Fungible Token,<span> and this should be the next big thing in digital marketing. This is a unique digital asset from art to a video clip to a music sample. Anything that lives on a machine could be an NFT. Interesting?</span>',
        image:'nft-marketing',
        rating:'4.7',
        url:'',
    
    },
    {
        id:'10',
        name:'Crypto Marketing',
        icon:'',
        description:'Learn Cryptocurrency marketing that revolves around better branding, website,<span> and digital platform design. As the number of coins and ICOs increase in the market, new and existing crypto coins need to stand out. Know more!</span>',
        image:'crypto-marketing',
        rating:'4.2',
        url:'',
    
    },
    
]