import React,{useState,useEffect} from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import axios from 'axios';
import Cookies from 'js-cookie'
import { useHistory } from 'react-router-dom';


const headers={
	Authorization:`Bearer ${Cookies.get('token')}`
}
export const Video = (props) => {
  const history=useHistory()
  const [playNextBtnActive,setPlayNextBtnActive]=useState(false)
  const [isLastVideo,setIsLastVideo]=useState(false)
  const [projectBtnActive,setProjectBtnActive]=useState(false)
  const [projectData,setProjectData]= useState()
  const [ blobUrl, setBlobUrl ] = useState()

  const videoRef = React.useRef(null);
  const playerRef = React.useRef(null);
  const {video,courseId,sectionId,videoList,videoId,thumbnail} = props;

  const blobImages = async (video_id) => {
		await fetch(video_id)
      .then(res => res.blob()) // Gets the response and returns it as a blob
      .then(blob => {
        let objectURL = URL.createObjectURL(blob);
				setBlobUrl(objectURL)
			});
	}

  useEffect(()=>{
   // // console.log("hello",{videoList},videoList[videoList.length-1]._id==videoId)
    if(videoList[videoList.length-1]._id==videoId){
      setIsLastVideo(true)
    }

    // blobImages(video.video_url)

  },[])

  const videoJsOptions = {
   // autoplay: true,
    controls: true,
    responsive: true,
    fluid: true,
    playbackRates: [0.5, 1, 1.5, 2],
    sources: [{
      src: video.video_url,
      type: 'video/mp4'
    }],
    poster:thumbnail,

    //'https://png.pngtree.com/thumb_back/fh260/backgr  ound/20200714/pngtree-modern-double-color-futuristic-neon-background-image_351866.jpg'
  };

  React.useEffect(() => {

    // Make sure Video.js player is only initialized once
    if (!playerRef.current) {
      const videoElement = videoRef.current;

      if (!videoElement) return;

      const player = playerRef.current = videojs(videoElement, videoJsOptions, () => {
        player.log('player is ready');
        handlePlayerReady && handlePlayerReady(player);
      });
    //  player.volume(0)

    // You can update player in the `else` block here, for example:
    }
 
     else {
         // console.log("Something problem")
    //   player.autoplay(videoJsOptions.autoplay);
    //   player.src(videoJsOptions.sources);
    }

  }, [videoJsOptions, videoRef]);


  function handlePlayerReady (player) {
    playerRef.current = player;
    // You can handle player events here, for example:
    player.on('play', function() {
      player.log('playing',player.currentTime());
      sendStatus(player.currentTime())
      setPlayNextBtnActive(false)
      setProjectBtnActive(false)
    });
   
    player.on('waiting', () => {
      player.log('player is waiting',player.currentTime());
      sendStatus(player.currentTime())
      setPlayNextBtnActive(false)
       setProjectBtnActive(false)
    });

    player.on('pause', () => {
      player.log('player is paused',player.currentTime());
      sendStatus(player.currentTime())
      setPlayNextBtnActive(false)
       setProjectBtnActive(false)
    });
    player.on('dispose', () => {
      player.log('player will dispose',player.currentTime());
      sendStatus(player.currentTime())
      setPlayNextBtnActive(false)
       setProjectBtnActive(false)
    });
    player.on('ended', () => {
      
        player.log('player ended');
        sendStatus(player.currentTime())
        setPlayNextBtnActive(true)
        setProjectBtnActive(true)
  //       const jsonData={
  //         "course_title": currentSection.section_name,
  //         "section": currentSection.sid,
  //         "video": currentVideo.vid,
  //         "isComplete": true
  //     }
  //       axios.post('https://api.ezioskillacademy.com/api/user/course/completion',jsonData,{headers})
	// 	.then((res)=>{
	// 		// console.log(res.data)
	//  })
      });
      
  };

  function sendStatus(time){
    let jsonData={
      "seconds": time
  }
  axios.put('https://api.ezioskillacademy.com/api/user/video/monitor/'+video._id,jsonData,{headers:{
    Authorization:`Bearer ${Cookies.get('token')}`
  } })
  .then(res=>{
    // console.log("res",res.data)     
}).catch(e=>{
   alert(e.response.data.message)
})
  }
  // Dispose the Video.js player when the functional component unmounts
  React.useEffect(() => {
    const player = playerRef.current;
    // console.log("dur",playerRef.duration)
    return () => {
      if (player) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  useEffect(()=>{
    if(projectBtnActive){
      getProjectStatus(sectionId)
    }
  },[projectBtnActive])

  function getProjectStatus(id){
		axios.get(`https://api.ezioskillacademy.com/api/user/project/${id}`, { headers:{
			Authorization:`Bearer ${Cookies.get('token')}`
		  } })
		  .then((res) => {
					// console.log("Status", res.data.data);
					if(!res.data?.data){
						// $('#project_btn').css('display','none')
						
					}else{
						setProjectData(res.data.data)
					}
				
		  }).catch((err)=>{
			
		  })
	}

  const playNextVideo=()=>{
    let index=0
    videoList.find((video,i)=>{
      // console.log(index)
      index++;
      return video._id==videoId;
 
    
    })
    // console.log({index},videoList[0]._id)
    const anchor=document.createElement('a')
    anchor.href=`/course/learn/${courseId}/${sectionId}/${videoList[index]._id}`
    anchor.click()
//   history.push()

  }

  const goToProject=()=>{
    const anchor=document.createElement('a')
    anchor.href=`/course/learn/project/${sectionId}/${projectData._id}`
    anchor.click()
    
  }

  return (
    <div className='video_player_con'>
    <div data-vjs-player>
      <video ref={videoRef} controls className='video-js vjs-big-play-centered' />
    </div>
    {
      !isLastVideo&&playNextBtnActive&&(
          <button className='play_next_btn' onClick={playNextVideo}>Play Next</button>
      )
    }
    {
      isLastVideo&&projectBtnActive&&(
        <button className='go_to_project_btn' onClick={goToProject}>Go to Project</button>
      )
    }
    
    </div>
  );
}

export default Video;