import React, { useEffect } from 'react'
import Arrow from '../../../../images/iconsSVG/Arrow'
import RedArrow from '../../../../images/iconsSVG/RedArrow'
import $ from 'jquery'
import Slider from "react-slick";
import { ourCourses } from '../data/our_courses';
import MoreCourse from './common/more_courses';


function Email_Marketing() {
  useEffect(()=>{

    const sectionAll = document.querySelectorAll("section");
            const observer = new IntersectionObserver(
              (entries) => {
                entries.forEach((entry) => {
                   
                  if (entry.isIntersecting) {
                    
                    entry.target.classList.add("scroll_ani");
                    
                  }
                });
              },
              {
                rootMargin: "-100px",
              }
            );
            sectionAll.forEach((section) => {
              observer.observe(section);
            });

    courseAccordian('1')
  },[])
  const courseSyllabus=[
    {
      id:'1',
      title:' What is Email Marketing?  ',
      desc:`Email Marketing is one of the marketing strategies, which is used to segment your customers depending on their priorities via highly customized content. So, the crafted email is to promote your products or services and build a strong relationship with targeted customers or prospects.  `
    },
    {
      id:'2',
      title:' How to Set-up an Effective Email Marketing Strategy?  ',
      desc:`To implement any campaign, you need to plan it without any flaws. A well-designed strategy will assist you to drive conversions and increase revenue and customer relationships. Defining your target audience, having a perfect email list, creating appealing subject lines, scheduling and optimizing it for mobile, and more. `
    },
    {
      id:'3',
      title:'What is Email List Building? ',
      desc:'List building refers to the technique of gathering email addresses from visitors, customers, and sources of business. This can be achieved by websites, social media accounts, promotional events.  '
    },
    {
      id:'4',
      title:'Email Automation ',
      desc:` Email automation is a technique to set up an email that hits the right people with the right message at the right time. It reduces your complete task without working each time and sends an email automatically with the help of a marketing automation tool. `
    },
    {
      id:'5',
      title: `How to Strengthen Your Customer Relationship?  `,
      desc:`Email marketing is a productive communication tool to develop a strong connection with your customers. Maintain strong communication, customer loyalty, customer feedback, customizing your email, and wishing during a special occasion.  `
    },
    {
      id:'6',
      title:' How to Create Excellent Email Content?  ',
      desc:`Your email content will decide how well your campaign will work. Everything matters when it comes to email. Right from subject lines, call to action, customized content, and attractive email signature. `
    },
    {
      id:'7',
      title:'Rules to Avoid Spamming  ',
      desc:`Target the right audience, focus on subject lines, create good content, ignore sending large attachments, and concentrate on the subscriber list. `
    },
  ]
  function courseAccordian(id){
    $('.course_syllabus_inner_left ul li').removeClass('course_syllabus_acc_active')
    $('#course_syllabus_acc'+id).addClass('course_syllabus_acc_active')

  }
  const recognized_certification=[
    {
        id:'1',
        name:'Ezio Skill Academy Certificate',
        desc:'Master the Talents You Required for becoming A Marketing Expert in Any verticals across the globe. Experiential learning through advance syllabus, case studies & live project centric. ',  
        img:'Ezio-Skill-Academy.jpg',
    },
    {
        id:'2',
        name:'Ezio Audit Tool Certificate  ',
        desc:'Expertise in search engine audit tools, our hands-on training let you easily analyze the website and know what is working and what is not. Experience the site audit tool usage and turn the website weaker side into a stronger one.  ',  
        img:'Ezio-Skill-Academy.jpg',
    },
    {
        id:'3',
        name:'LinkedIn Certificate',
        desc:'Become exceptionally skilled and gain the knowledge of DM strategy to transform you into a “Qualified Digital Marketer”.',  
        img:'linkedi-certificate-1.jpg',
    },
    {
        id:'4',
        name:'HubSpot Academy Certificate   ',
        desc:'The candidates will gain top-notch skills in implementing digital marketing strategies with high-standard materials and training exercises. A digital marketing certificate from HubSpot Academy is recognized by the universities.   ',  
        img:'hubspot-academy.jpg',
    },
    {
        id:'5',
        name:'Facebook Blueprint Certificate  ',
        desc:'Earn Facebook blueprint certified "digital marketing associate” becoming an expert level proficiency in Facebook advertising. This is excluded in the Course cost & separate cost will be applicable for this certification ',  
        img:'Facebook-Blueprint.jpg',
    },
    {
        id:'6',
        name:'Google Analytics   ',
        desc: `Qualified in fundamental and advanced Google analytics concepts and Google's measurement tools. Google Analytics Certificates make provision for implementing SEO strategy through learning high-in-demand skills.  `,  
        img:'Google-Analytics.jpg',
    },
    {
        id:'7',
        name:'Google Ads  ',
        desc:'It is an industry-recognized marketing certificate from Google. Professional accreditation is provided by Google. Recognized you as a master in Google Ads.     ',  
        img:'adwords.jpg',
    },
    {
        id:'8',
        name:'Bing Ads   ',
        desc:'Knowing the hack to push your business forward from the effective Microsoft advertising campaigns and enhance your skillset on ads through this program.  With the Bind ads certification, upskill your Microsoft advertising proficiency. ',  
        img:'Bing-Ads-certification.jpg',
    },
]

const certificate_settings = {
  dots: true,
  infinite: true,
  speed: 500,
  autoplay: false,
  slidesToShow: 1,
  slidesToScroll: 1
};


  return (
    <div id='courses'>
    <section className="banner_sec">     
<div className="container">
 <div className="row">
           <div className="col-lg-6 col-md-6 centered_content website_left_inner_banner">
                {/* <p>Academic &gt; &nbsp;<span>Digital Marketing </span></p> */}
                <h1>
                Email Marketing  
                </h1> 
                <p style={{margin:'20px 0'}}> 
                Understands the fundamentals of email marketing and masters how to establish an email marketing strategy. Thereby creating a successful advertisement, brand exposure, and initiating a connection with your customers. 
                </p>
                <div style={{display: "flex"}}>
                <a href='#syllabus'>
                    <button className="btn btn-outline-primary my-2 my-sm-0 ml-3 active_btn apply_now_btn" type="submit"> Explore Course </button> 
               </a>
                </div> 
            </div>

            <div className="col-lg-6 col-md-6" >
                <img className='website_banner_standing_girl' src={require('../../../../images/website/Courses_page/women-png.png')} alt='' style={{display:'block',margin:'auto'}} />
                <img className='banner_round_icon' src={require('../../../../images/website/Courses_page/1.png')} alt='' style={{left:'8%',top:'10%',filter: 'drop-shadow(0px 9px 14px rgba(50, 50, 50, 0.15))'}}/>
                <img className='banner_round_icon' src={require('../../../../images/website/Courses_page/archer.png')} alt='' style={{right:'10%',top:'16%'}}/>
                <img className='banner_round_icon' src={require('../../../../images/website/Courses_page/peoples.png')} alt='' style={{right:'5%',bottom:'10%',height:'65px'}}/>
                <img className='banner_round_icon' src={require('../../../../images/website/Courses_page/analytics.png')} alt='' style={{left:'15%',bottom:'5%',height:'65px',filter: 'drop-shadow(0px 9px 14px rgba(50, 50, 50, 0.15))'}}/>
              </div>
 </div>
</div>
</section>


<section id='about' className='about_free_certification'>
  <div className='container'>
      <div className='row' style={{display:'flex',flexWrap:'wrap-reverse',justifyContent:'center'}}>
      {/* <div className='col-lg-6'>
        <div className='about_free_certification_left_inner'>
            <div className='hos_small_box'>
              <h3>130+</h3>
              <p>Lorem Etiam vel ullamcorper augue</p>
            </div>
            <div className='hos_small_box'>
              <h3>130+</h3>
              <p>Lorem Etiam vel ullamcorper augue</p>
            </div>
            <div className='hos_small_box'>
              <h3>130+</h3>
              <p>Lorem Etiam vel ullamcorper augue</p>
            </div>
            <div className='hos_small_box'>
              <h3>130+</h3>
              <p>Lorem Etiam vel ullamcorper augue</p>
            </div>
            </div>
      </div> */}
      <div className='col-lg-12'>
          <h2>About This Email Marketing Course      </h2>
          <p>Email Marketing is one of the strong forms of digital marketing and in the digital era, introduced a bunch of social media marketing channels. You know what? Email marketing is believed to be one of the dominant marketing media. Also, it's popularly known as one of the productive communication tools, and of course, it flows under the successful marketing platform. It has a high power of turning out prospective leads into sales by sending customized and tailor-made content to your target audience's inbox. Well, the Email Marketing course will provide you with knowledge of what email marketing is, a mailing list, and worthwhile techniques. Thereafter, campaign techniques, crafting personalized content, building a strong relationship with your customers, and more. 
            <br/><br/>
            Get an insight into email marketing tools to run your email marketing campaigns in an effective manner. At Ezio Skill Academy, we offer lots of digital marketing courses, you can enroll in our learning environment today and discover your interest. 
          </p>
      </div>
      </div>
  </div>
</section>


<section id='skill_tools' className='skills_and_tools'>
  <div className='container'>
    <h2>Skills and Tools</h2>
    <p> Get an insight into email marketing tools to run your email marketing campaigns in an effective manner. At Ezio Skill Academy, we offer lots of digital marketing courses, you can enroll in our learning environment today and discover your interest.  </p>
      <div className='row'>
      <div className='col-lg-6 '>
            <h3>Skills</h3>
            <ul className='skills_and_tools_inner'>
              <li><RedArrow/> Core Concepts of Email Marketing   </li>
              <li><RedArrow/>Email Marketing Strategy   </li>
              <li><RedArrow/>Email Campaigns      </li>
              <li><RedArrow/> Increase Traffic Through Emails    </li>
              <li><RedArrow/> Developing Email List    </li>
              <li><RedArrow/> Crafting Personalized Content  </li>
              <li><RedArrow/> Automation    </li>
              <li><RedArrow/>Contact Management  </li>
            </ul>
    
      </div>
      <div className='col-lg-6'>
          <h3>Tools</h3>
          <div className='skill_tools_con'>
        
            <div className='skill_tools'>
                <img src={require('../../../../images/website/Tools-you-wil-master/google-ads.png')} alt=""/>
            </div>
            <div className='skill_tools'>
                <img src={require('../../../../images/website/Tools-you-wil-master/google-anylitsis.png')} alt=""/>
            </div>  
            <div className='skill_tools'>
                <img src={require('../../../../images/website/Tools-you-wil-master/fb-ads.png')} alt=""/>
            </div>
            <div className='skill_tools'>
                <img src={require('../../../../images/website/Tools-you-wil-master/google-tag-manager.png')} alt=""/>
            </div>
            <div className='skill_tools'>
                <img src={require('../../../../images/website/Tools-you-wil-master/linkedin-ads.png')} alt=""/>
            </div>
            <div className='skill_tools'>
                <img src={require('../../../../images/website/Tools-you-wil-master/google-search-console.png')} alt=""/>
            </div>
            <div className='skill_tools'>
                <img src={require('../../../../images/website/Tools-you-wil-master/twitter-ads.png')} alt=""/>
            </div>
            <div className='skill_tools'>
                <img src={require('../../../../images/website/Tools-you-wil-master/mailchimp.png')} alt=""/>
            </div>
        </div>
        </div>
      </div>
  </div>
</section> 

<section id='syllabus' className='course_syllabus'>
  <div className='container'>
      <div className='row' style={{display:'flex',flexWrap:'wrap-reverse'}}>
      <div className='col-lg-6 course_syllabus_inner_left'>
            <ul>
              {
                courseSyllabus.map((course)=>(
                  <li id={'course_syllabus_acc'+course.id} onClick={()=>courseAccordian(course.id)} key={course.id}>
                  <h4> {course.title} </h4>
                  <p>{course.desc}</p>
                  </li>
                ))
              }
              
            </ul>
    
      </div>
      <div className='col-lg-6' style={{display:'flex',flexDirection:'column',justifyContent:'center'}}>
         <h2>Course Syllabus</h2>
         <p>Introduction to Email Marketing, Various Email Marketing Techniques, Building High-Performing Email, Improving Relationship with Prospective Leads, Email Marketing Tools, How to Avoid Spamming. </p>
         <a href='/Ezio_Skill_Academy.pdf' download>
         <button>Download Syllabus</button>
         </a>
      </div>
      </div>
  </div>

</section>

<section id='certification' className="website_certificate section_spaces">

    <div className="container">
        <h2>  Certificate   </h2>
        <p>At the end of this program, you'll have the capabilities to develop a powerful email list and implement fruitful email marketing campaigns. Earn an Email Marketing completion certificate which contributes as proof of the knowledge and skill you have obtained.  </p>
    <Slider {...certificate_settings}>
        {
            recognized_certification.map((certi,index)=>(
        <div key={index}>
        <div className='row'>
            <div className='col-lg-6 website_certificate_left_inner'>
                <h2>{certi.name}</h2>
                <p>{certi.desc}</p>
            </div>
            <div className='col-lg-6'>
                <img src={require(`../../../../images/website/certificate/${certi.img}`)} alt=""/>
            </div>
        </div>     
        </div> 
            ))
        }
        
      </Slider>
        </div>
    </section>

    <MoreCourse url={'email-marketing'}/>


</div>
  )
}

export default Email_Marketing



{/* <section className=''>
  <div className='container'>
      <div className='row'>
      <div className='col-lg-6 '>
            
    
      </div>
      <div className='col-lg-6'>
         
      </div>
      </div>
  </div>

</section> */}