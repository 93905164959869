import axios from 'axios';
import React,{useState,useEffect} from 'react'
import Cookies from 'js-cookie';
import $ from 'jquery'
import CustomModal from '../CustomModal'
import { Link,useHistory } from 'react-router-dom';
import Toaster from '../../../toaster';

/*
{headers:{
    Authorization:`Bearer ${Cookies.get('token')}`
  } }
*/
function Courselist(props) {
    const history=useHistory()

    const [details, setDetails] = useState([])
    const [courseList, setCourseList] = useState([])
    const [addPopup,setAddPopup] = useState(false)
    const [editPopupId,setEditPopupId] = useState()
    const [fields, Getfields] = useState({});
    const [fieldsEdit, GetfieldsEdit] = useState({});
    const [error,setError]=useState({})

    const [tosOpen,setTosOpen]=useState()

    useEffect(() =>{
      GetCourseList()
  },[])
  
  const GetCourseList = () =>{
    
      axios.get(`https://api.ezioskillacademy.com/api/admin/course`, {headers:{
        Authorization:`Bearer ${Cookies.get('token')}`
      } })
      .then(res => {  
                    // console.log("batch",res.data)
                    setCourseList(res.data.data)     
      }).catch(e=>{
        alert(e.response.data.message)
      })
  }
  const HandleDelete=(e,id)=>{
    e.stopPropagation()
    var result = window.confirm("Are you sure you want to delete?");
    if (result) {
      axios.delete(`https://api.ezioskillacademy.com/api/admin/course/${id}`,{headers:{
        Authorization:`Bearer ${Cookies.get('token')}`
      } })
      .then(res => {
            GetCourseList()  
            setTosOpen(res.data.message)            
                
      }).catch(e=>{
        alert(e.response.data.message)
      })
    }
  }
 
const editBatch=()=>{

    const jsonData={
        "name": fieldsEdit.name,
        "email":fieldsEdit.email,
        "phoneno": fieldsEdit.phoneno
    }
    axios.put('https://api.ezioskillacademy.com/api/admin/course/'+editPopupId,jsonData,{headers:{
        Authorization:`Bearer ${Cookies.get('token')}`
      } })
    .then(res=>{
            GetCourseList()
            $('#edit_scs_msg').html(res.data.message)
            setTimeout(()=>{
                setEditPopupId(undefined)      
            },3000)
            
        
    }).catch(e=>{
       alert(e.response.data.message)
    })

  }

  const handleValue=(e)=>{
      Getfields({ ...fields, [e.target.name]:e.target.value })
    }
    const handleEditValue=(e)=>{
        GetfieldsEdit({ ...fieldsEdit, [e.target.name]:e.target.value })
      }
    const setEditPopupOpen=(e,course)=>{
      e.stopPropagation()
        setEditPopupId(course._id)
        GetfieldsEdit(course)
        //GetBatchFields(id)  
    }
    
      
   
  return (
    <div className="dash_page_bg dash_section_ptb add_payment">
        <Link to='/superadmin/course/add'>
        <button className="add_payment_btn" 
        >
          Add Course
        </button>
        </Link>
        <br />
        <br />
        <div className="clientrate_table">
          <div className="tabcomp-style">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <td>Course Name</td>
                    <td>Duration</td>
                    <td>Price</td>  
                    <td style={{width:'200px'}}></td>                   
                  </tr>
                </thead>
                <tbody>
                  {courseList.length > 0 ? (
                    <>
                      {courseList
                        .map((course, id) => (
                          <tr key={id} className="admin_course_list" onClick={()=>history.push('/superadmin/course/'+course._id)}>
                            <td>{course.course_name}</td>
                            <td>{course.duration}</td>
                            <td>{course.price}</td>     
                            <td style={{display:'flex',justifyContent:'center',width:'200px'}}>
                              <Link to={'/superadmin/course/edit/'+course._id}>
                                <button className='blue_btn' onClick={(e)=>setEditPopupOpen(e,course)}>Edit</button>
                                </Link>
                                <button 
                                  onClick={(e)=>HandleDelete(e,course._id)} className='red_btn'>Delete</button>
                                </td>                         
                          </tr>
                        ))}
                    </>
                  ) : (
                    <tr>
                      <td colSpan="4" style={{ textAlign: "center" }}>
                        No data
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {/* pagination */}
        <div style={{ paddingTop: "32px" }}>
          {/* <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            pageCount={Math.ceil(paymentList.length / cardsPerPage)}
            onPageChange={changePage}
            containerClassName={"paginationBttns"}
            previousLinkClassName={"previousBttn"}
            nextLinkClassName={"nextBttn"}
            disabledClassName={"paginationDisabled"}
            activeClassName={"paginationActive"}
          /> */}
        </div>
        {/* pagination */}
        <Toaster tosOpen={tosOpen} setTosOpen={setTosOpen}/>
      </div>
  )
}

export default Courselist