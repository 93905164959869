import React from 'react'

function Attachment() {
  return (
    
<svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_850_54)">
<path d="M13.5 27C8.84702 27 5.0625 23.2155 5.0625 18.5625V6.75C5.0625 6.1279 5.56657 5.62507 6.18743 5.62507C6.8085 5.62507 7.31257 6.1279 7.31257 6.75V18.5625C7.31257 21.9735 10.0879 24.7499 13.5 24.7499C16.9121 24.7499 19.6874 21.9735 19.6874 18.5625V6.18743C19.6874 4.01626 17.9212 2.25007 15.7501 2.25007C13.5787 2.25007 11.8125 4.01626 11.8125 6.18743V17.4376C11.8125 18.3678 12.5695 19.1251 13.5 19.1251C14.4305 19.1251 15.1875 18.3678 15.1875 17.4376V6.75C15.1875 6.1279 15.6916 5.62507 16.3124 5.62507C16.9335 5.62507 17.4376 6.1279 17.4376 6.75V17.4376C17.4376 19.6087 15.6712 21.3749 13.5 21.3749C11.3288 21.3749 9.56243 19.6087 9.56243 17.4376V6.18743C9.56243 2.77659 12.3378 0 15.7501 0C19.1621 0 21.9375 2.77659 21.9375 6.18743V18.5625C21.9375 23.2155 18.153 27 13.5 27Z" fill="#F14A51"/>
</g>
<defs>
<clipPath id="clip0_850_54">
<rect width="27" height="27" fill="white"/>
</clipPath>
</defs>
</svg>

  )
}

export default Attachment