import React from 'react';
import Notification from '../user/notification';
import SocketChatClass from '../user/socket-chat-class_compoent';
import ProjectSubmission from './project-submission';
import Users from './user-management';


export const adminRoutes=[
    {
      path: "/admin/user-management",
      exact: true,
      main: () => <Users/>
    },
    {
      path: "/admin/project-submission",
      exact: true,
      main: () => <ProjectSubmission/>
    },
    {
      path: "/admin/chat",
      exact: true,
      main: () => <SocketChatClass/>
    },
    {
      path: "/admin/notifications",
      exact: true,
      main: () => <Notification/>
    },
]