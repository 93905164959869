import React, { useState } from 'react';
import Popup  from  './Popup.js'
import classes from './BackgroundVideo.module.css';

const Bgvideo = () => {

	const [isOpen, setIsOpen] = useState(false);
 
	const togglePopup = () => {
	  setIsOpen(!isOpen);
	}

    const videoSource = "https://storage.googleapis.com/coverr-main/mp4/Mt_Baker.mp4"
    return (
        <div className={classes.Container} >
            <video autoPlay="autoplay" loop="loop" muted className={classes.Video} >
                <source src={videoSource} type="video/mp4" />
                Your browser does not support the video tag.
            </video>

            <div className={classes.Content}>
                <div className={classes.SubContent} >
                <div className='be_content_set'>
							<h1>Become a Digital Marketing Expert</h1>
							<p className='nurture_set'>Learn the practices & plans, of the world’s most efficient digital marketers.</p>
							<div className='entroll_but'>
							  <div className='al_center'>
							  <div className="popup_btn">
									<input
									type="button"
									value="Entroll Now"
									onClick={togglePopup}
									/>
									{isOpen && <Popup handleClose={togglePopup} />}
								</div>
							  </div>
							</div>
							
							{/* <ul id="basicUsage">
								<li>
									<p>11</p>
									<p>Days</p>
								</li>
								<li>
									<p>00</p>
									<p>Hrs</p>
								</li>
								<li>
									<p>00</p>
									<p>Mins</p>
								</li>
								<li>
									<p>00</p>
									<p>Sec</p>
								</li>
							</ul> */}
							 
				</div>
				<div id="courses"></div>	
                </div>
            </div>
        </div>
    )
}

export default Bgvideo