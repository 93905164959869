import React from 'react'

export default function KnowBaseIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24.369" height="24.381" viewBox="0 0 24.369 24.381">
      <g id="Knowledge_Base" data-name="Knowledge Base" transform="translate(11503.791 -8070.41)">
        <g id="knowledge_1_" data-name="knowledge (1)" transform="translate(-11503.791 8070.411)">
          <path id="Path_14001" data-name="Path 14001" d="M22.373,347.326a2.116,2.116,0,0,0-.7.121L14.963,349.8a2.143,2.143,0,0,0-2.1-2.578H6.6a7.214,7.214,0,0,0-5.135,2.127L.118,350.7v4.381H15.633l7.665-3.734a2.117,2.117,0,0,0-.925-4.02Zm.3,2.736-7.369,3.59H1.546V351.29l.927-.927A5.8,5.8,0,0,1,6.6,348.654h6.268a.714.714,0,1,1,0,1.428H8.328v1.428h6.087l7.727-2.716a.688.688,0,0,1,.53,1.268Z" transform="translate(-0.118 -330.699)" fill="#fff"/>
          <path id="Path_14002" data-name="Path 14002" d="M140.159,10.032a1.572,1.572,0,0,1,.606,1.237v1.843l.884,1.836h3.987l.884-1.836V11.274a1.582,1.582,0,0,1,.609-1.244A5.621,5.621,0,0,0,143.643,0c-.072,0-.146,0-.218,0a5.621,5.621,0,0,0-3.265,10.028Zm4.58,3.488h-2.192l-.353-.734v-.8h2.9v.8Zm-4.117-10.8a4.162,4.162,0,0,1,2.857-1.29c.055,0,.11,0,.164,0a4.193,4.193,0,0,1,2.6,7.482,3.013,3.013,0,0,0-1.063,1.645h-3.074a3,3,0,0,0-1.062-1.644,4.184,4.184,0,0,1-.423-6.191Z" transform="translate(-131.458 0)" fill="#fff"/>
          <path id="Path_14003" data-name="Path 14003" d="M63.426,103.35h2.122v1.428H63.426Z" transform="translate(-60.413 -98.431)" fill="#fff"/>
          <path id="Path_14004" data-name="Path 14004" d="M0,0H1.428V2.122H0Z" transform="matrix(0.5, -0.866, 0.866, 0.5, 3.885, 1.666)" fill="#fff"/>
          <path id="Path_14005" data-name="Path 14005" d="M0,0H2.122V1.428H0Z" transform="matrix(0.866, -0.5, 0.5, 0.866, 3.885, 9.6)" fill="#fff"/>
          <path id="Path_14006" data-name="Path 14006" d="M404.21,103.35h2.122v1.428H404.21Z" transform="translate(-384.977 -98.431)" fill="#fff"/>
          <path id="Path_14007" data-name="Path 14007" d="M0,0H2.122V1.428H0Z" transform="matrix(0.866, -0.5, 0.5, 0.866, 17.931, 1.491)" fill="#fff"/>
          <path id="Path_14008" data-name="Path 14008" d="M0,0H1.428V2.122H0Z" transform="matrix(0.5, -0.866, 0.866, 0.5, 17.932, 9.776)" fill="#fff"/>
        </g>
      </g>
    </svg>

  )
}
