import React from 'react'

function CustomModal({open,children,onClose,top,left,bgdark,zindex,width,pxtop}) {
  if(!open) return null
    const overLayStyle={
       right:0,
        left:0,
        top:'-10%',
        bottom:'-13%',
        backgroundColor:(bgdark===undefined)?"rgba(0,0,0,.5)":(bgdark===true)?"rgba(0,0,0,.5)":"transparent",
        position:'fixed',
        zIndex:'1000'
    }
    const modalStyle={
        position:"fixed",
        height:"auto",
        width:(width===undefined)?'auto':width+'px',
        backgroundColor:"white",
        top:(top!==undefined)?top+"%":(pxtop!==undefined)?pxtop+"px":'50%',
        left:(left===undefined)?"50%":left+"%",
        transform:"translate(-50%,-50%)",
        zIndex:(zindex===undefined)?'1000':1000+zindex+"",
        color:"black"
    }
   const styling={
        transition:"all 0.5s"
   }
   //// console.log("width",window.innerWidth)
    return (
        <div style={styling}>
        <div style={overLayStyle} onClick={onClose}></div>
        <div style={modalStyle}>
          {children}
        </div>
       </div>
    )
}

export default CustomModal