import React from 'react'

function Payment() {
  return (
      <div className='payment_page'>
   <div className='payment_history'>
        <h4>Payment History</h4>
   </div>
   <div className='pending_payment'>
        <h4>Payment History</h4>
   </div>
   </div>
  )
}

export default Payment