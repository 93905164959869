import React , { useEffect,useState } from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import $ from 'jquery'
import AboutIcon from '../../../images/iconsSVG/AboutIcon';
import Arrow from '../../../images/iconsSVG/Arrow';
import Star from '../../../images/iconsSVG/Star';
import IndividualPlanIcon from '../../../images/iconsSVG/Individual';
import PackagePlanIcon from '../../../images/iconsSVG/PackagePlan';
import CustomizePlanIcon from '../../../images/iconsSVG/CustomizePlan';
import {Link} from 'react-router-dom'
import {RegisterPopup} from '../header'
import { ourCourses } from './data/our_courses';
import { Backdrop, Fade, makeStyles, Modal } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor:theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }));


const Home = () => {
    const classes = useStyles();

    const [ratingBox,setRatingBox]=useState(1)
    const [ourCourseTab,setOurCourseTab]=useState('1')

    const [individualActive,setIndividualActive]= useState()
    const [packageActive,setPackageActive]= useState()
    const [customizedActive,setCustomizedActive]= useState()
    const [customizeCount,setCustomizeCount]=useState(0)

    const [openRegisterPopup,setOpenRegisterPopup]=useState(false)

    const [highlightPopup,setHighlightPopup]=useState(false)

    const [batchReadMore,setBatchReadMore]=useState(5)
  

    useEffect(()=>{
            const sectionAll = document.querySelectorAll("section");
            const observer = new IntersectionObserver(
              (entries) => {
                entries.forEach((entry) => {
                   
                  if (entry.isIntersecting) {
                    
                    entry.target.classList.add("scroll_ani");
                    // console.log("ANI", entry.target.classList.contains('highlights_our_course'));
                    if(entry.target.classList.contains('highlights_our_course')){
                        entry.target.classList.add("animated_highlights_our_course");
                    }
                    if(entry.target.classList.contains('tools_you_master')){
                        // console.log("exe ani")  
                        entry.target.classList.add("tools_you_master_ani");
                    }
                  }
                });
              },
              {
                rootMargin: "-100px",
              }
            );
            sectionAll.forEach((section) => {
              observer.observe(section);
            });
            // // console.log("COMMMON3");
        $('.customizeCheck').prop('disabled',true)
        $('.individualCheck').prop('disabled',true)
        $('#batch_pricing_box2').css('filter','drop-shadow(0px 0px 44px rgba(96, 96, 96, 0.25))')  

        document.getElementById('website_courses_con').onscroll=()=>{
			// console.log('scrolling',document.getElementById('website_courses_con').scrollTop,window.innerWidth)
        }


       

    },[])

    const certificate_settings = {
        dots: true,
        infinite: true,
        speed: 500,
        autoplay: true,
        slidesToShow: 1,
        slidesToScroll: 1
      };
    const rating_settings={
        dots: true,
        infinite: true,
        speed: 500,
        autoplay: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        afterChange: function(index) {
            let value=index===5?0:index+1;
           // // console.log(index, document.getElementById('website_feedback'+(value)).classList,'#website_feedback'+(value))
          //  $('website_feedback').removeClass('website_feedback_active')
         //   $('#website_feedback'+(value)).addClass('website_feedback_active')
          //  document.getElementById('website_feedback'+(value)).classList.add("website_feedback_active")
            setRatingBox(value);
          },
          responsive: [
            {
              breakpoint: 768,
              settings: {
                arrows: false,
                slidesToShow: 1,
                afterChange: function(index) {
                    setRatingBox(index)
                  },
              },
            },
           
          ],
    }

    // const ourCourses=[
    //     {
    //         id:'1',
    //         name:'Digital Marketing Landscape',
    //         icon:''
    //     },
    //     {
    //         id:'2',
    //         name:'Digital Marketing Deep Study (Phase-1)',
    //         icon:''
    //     },
    //     {
    //         id:'3',
    //         name:'Digital Marketing Deep Study (Phase-2) ',
    //         icon:''
    //     },
        
    //     {
    //         id:'4',
    //         name:'Digital Marketing Deep Study (Phase-3)',
    //         icon:''
    //     },
    //     {
    //         id:'5',
    //         name:'Email Marketing',
    //         icon:''
    //     },
    //     {
    //         id:'6',
    //         name:'Extra Dive – Growth ',
    //         icon:''
    //     },
    // ]

    


    const recognized_certification=[
        {
            id:'1',
            name:'Ezio Skill Academy Certificate',
            desc:'Master the Talents You Required for becoming A Marketing Expert in Any verticals across the globe. Experiential learning through advance syllabus, case studies & live project centric. ',  
            img:'Ezio-Skill-Academy.jpg',
        },
        {
            id:'2',
            name:'Ezio Audit Tool Certificate  ',
            desc:'Expertise in search engine audit tools, our hands-on training let you easily analyze the website and know what is working and what is not. Experience the site audit tool usage and turn the website weaker side into a stronger one.  ',  
            img:'Ezio-Skill-Academy.jpg',
        },
        {
            id:'3',
            name:'LinkedIn Certificate',
            desc:'Become exceptionally skilled and gain the knowledge of DM strategy to transform you into a “Qualified Digital Marketer”.',  
            img:'linkedi-certificate-1.jpg',
        },
        {
            id:'4',
            name:'HubSpot Academy Certificate   ',
            desc:'The candidates will gain top-notch skills in implementing digital marketing strategies with high-standard materials and training exercises. A digital marketing certificate from HubSpot Academy is recognized by the universities.   ',  
            img:'hubspot-academy.jpg',
        },
        {
            id:'5',
            name:'Facebook Blueprint Certificate  ',
            desc:'Earn Facebook blueprint certified "digital marketing associate” becoming an expert level proficiency in Facebook advertising. This is excluded in the Course cost & separate cost will be applicable for this certification ',  
            img:'Facebook-Blueprint.jpg',
        },
        {
            id:'6',
            name:'Google Analytics   ',
            desc: `Qualified in fundamental and advanced Google analytics concepts and Google's measurement tools. Google Analytics Certificates make provision for implementing SEO strategy through learning high-in-demand skills.  `,  
            img:'Google-Analytics.jpg',
        },
        {
            id:'7',
            name:'Google Ads  ',
            desc:'It is an industry-recognized marketing certificate from Google. Professional accreditation is provided by Google. Recognized you as a master in Google Ads.     ',  
            img:'adwords.jpg',
        },
        {
            id:'8',
            name:'Bing Ads   ',
            desc:'Knowing the hack to push your business forward from the effective Microsoft advertising campaigns and enhance your skillset on ads through this program.  With the Bind ads certification, upskill your Microsoft advertising proficiency. ',  
            img:'Bing-Ads-certification.jpg',
        },
    ]
    function handleCourseTab(id){
        $('.list_courses').removeClass('list_courses_active');
        $('#list_courses'+id).addClass('list_courses_active')

       // let breakpoints=[0,300,635,925,1225,1600]
        let breakpoints=[];
        let count=0
        for(let i=0;i<10;i++){
            breakpoints.push(count)
            count=count+290
        }
        // console.log("innerwidth",window.innerWidth)
        const minusVal=window.innerWidth>767?50:200
        $('#website_courses_con').scrollTop(breakpoints[+id-1]-minusVal)

        $('.website_courses').removeClass('website_courses_active');
        $('#website_courses'+id).addClass('website_courses_active')
        
       // setOurCourseTab(id)
    }
 
    
    function learnMore(id){
        $('.website_courses>div span').css('display',"none !important")
        $('#website_courses'+id+">div span").css('display',"block")
    }

    
    function individualPlan(e,id){
        
        // console.log(e.target.checked,id)
        // if(e.target.checked){
        // $('.individualCheck').css('visibility',"hidden")
        // $('#individualCheck'+id).css('visibility',"visible")
        // //style for active plan
        // $('.batch_pricing_box').addClass('batch_pricing_box_inactive')
        // $('#batch_pricing_box1').removeClass('batch_pricing_box_inactive')
        // $('#batch_pricing_box1').css('filter','drop-shadow(0px 0px 44px rgba(96, 96, 96, 0.25))')
        // //other checkbox disable
        // $('.customizeCheck').prop('disabled',true)
        // //submit btn enable
        // $('#batch_pricing_box1 button').prop('disabled',false)

        // $('#batch_pricing_box2 button').prop('disabled',true)
        
        // }
        // else{
        //     $('.individualCheck').css('visibility',"visible")
        //     $('.batch_pricing_box').removeClass('batch_pricing_box_inactive')
        //     $('#batch_pricing_box1').css('filter','none')
        //     $('.customizeCheck').prop('disabled',false)
        //     $('#batch_pricing_box1 button').prop('disabled',true)
        //     $('#batch_pricing_box2 button').prop('disabled',false)
        // }
    }
    
    function customizePlan(e,id){
    //    let count=customizeCount;
    
    //     if(e.target.checked){
    //     $('.batch_pricing_box').addClass('batch_pricing_box_inactive')
    //     $('#batch_pricing_box3').removeClass('batch_pricing_box_inactive')
    //     $('#batch_pricing_box3').css('filter','drop-shadow(0px 0px 44px rgba(96, 96, 96, 0.25))')
    //     $('.individualCheck').prop('disabled',true)
    //     $('#batch_pricing_box3 button').prop('disabled',false)
    //     $('#batch_pricing_box2 button').prop('disabled',true)
    //     count=count+1
    //     setCustomizeCount(count)
    //     }
    //     else{
    //         count=count-1
    //         setCustomizeCount(count)
    //     }
    //     if(count===0){
    //         // console.log("count 0")
    //         $('.batch_pricing_box').removeClass('batch_pricing_box_inactive')
    //         $('#batch_pricing_box3').css('filter','none')
    //         $('.individualCheck').prop('disabled',false)
    //         $('#batch_pricing_box3 button').prop('disabled',true)
    //         $('#batch_pricing_box2 button').prop('disabled',false)
    //     }
        //// console.log(e.target.checked,customizeCount)
    }

    function MoreTools(){
        if(document.getElementById('master_tools_con').style.maxHeight!="3500px"){
            document.getElementById('more_tools').innerHTML='View Less'
            document.getElementById('master_tools_con').style.maxHeight="3500px"
        }
        else{
            document.getElementById('more_tools').innerHTML='More Tools'
            document.getElementById('master_tools_con').style.maxHeight="300px"
        }    
    }

    function ourCourseAcc(id){
        // console.log('list',document.getElementById('courseacc'+id)?.classList)
        if(document.getElementById('courseacc'+id)?.classList.contains('show')){
            $('#courseacc'+id).removeClass('show')
       }else{  
        $(".courseacchead>div").addClass('collapsed')
        $('.courseacc_collapse').removeClass('show')
        $('#courseacc'+id).addClass('show')
        $("#courseacchead"+id+">div").removeClass('collapsed') 
       }
      
    }
   const batch2=[
    'Digital Marketing Fundamentals , Website Planning and Structure',
    'Search Engine Optimization (SEO)',
    'Web Analytics and Webmaster Tool',
    'Google Adwords and PPC Advertising - SEM',
    'Social Media Marketing - SMM',
    'Ecommerce SEO',
    'YouTube and Video Marketing (Vlogging)',
    'Lead Generation & Marketing Automation and Email Marketing for Business',
    'Remarketing Strategies - Advance Level and eCommerce with Payment Gateway',
    'Affiliate Marketing & Google AdSense',
    'Case Studies & Practical Assignments, Internship and Google Certification Program Training',

   ]
    return(
        <div id="home">


{/* <!-- Navbar section     --> */}
{/* 
<section className="header_section">   

    <div className="container">

        <nav className="website_navbar">
            <a className="website_navbar-brand" href="#"> <img alt='digital marketing course fees in Coimbatore' src={require('../../../images/website/ezio_skill_logo_horizontal.svg')} />  </a>
            <button className="website_navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03" aria-expanded="false" aria-label="Toggle navigation">
              <span className="website_navbar-toggler-icon"></span>
            </button>
            
          
            <div className="collapse navbar-collapse ml-auto" id="navbarTogglerDemo03">
              <ul className="navbar-nav ml-auto mt-2 mt-lg-0">
                <li className="website_nav-item active">
                  <a className="website_nav-link" href="#">Home <span className="sr-only">(current)</span></a>
                </li>
                <li className="website_nav-item">
                  <a className="website_nav-link" href="#">About Us</a>
                </li>
                <li className="website_nav-item">
                  <a className="website_nav-link "> For Students </a>
                </li>
                <li className="website_nav-item">
                    <a className="website_nav-link "> For Professionals </a>
                  </li>
                  <li className="website_nav-item">
                    <a className="website_nav-link "> For Entrepreneur </a>
                  </li>
                  
                  <li className="website_nav-item">
                    <button className="btn btn-outline-primary my-2 my-sm-0" type="submit">Sign In</button>
                  </li>
                  <li className="website_nav-item">
                    <button className="btn btn-outline-primary my-2 my-sm-0 active_btn" type="submit">Get Startrd</button>
                  </li>
              </ul>
              
            </div>
          </nav>

    </div>

</section>      */}



{/* <!-- Banner Section  --> */}

<section className="banner_sec">  
    
<div className="container">

 <div className="row">

           <div className="col-md-6 centered_content website_left_inner_banner">

                <p> <span>Maestro in Digital Marketing </span></p>

                <h1>
                Executive Digital Marketing Program 
                </h1> 

                <p style={{margin:'15px 0'}}> 
                Attain real-world experience to run live campaigns as you learn from top professionals in the field. Start your career with a 360-degree understanding of digital marketing.
                </p>

                <div style={{display: "flex"}}>
                    <button onClick={()=>setOpenRegisterPopup(true)} className="btn btn-outline-primary my-2 my-sm-0 ml-3 active_btn apply_now_btn" type="submit"> Apply Now &nbsp; <Arrow/> </button> &nbsp;&nbsp;&nbsp;
                    <a href='/Ezio_Skill_Academy.pdf' download>
                    <button className="btn btn-outline-primary my-2 my-sm-0 ml-3 download_brochure_btn" type="submit" >Download Brochure </button> 
                    </a>
                </div>
            

            </div>

            <div className="col-md-6 text-center">

                <img alt="digital marketing course in coimbatore" className='website_banner_standing_girl' src={require('../../../images/website/banner-module.png')}  />
                <img alt="digital marketing course in coimbatore" className='banner_round_icon' src={require('../../../images/website/face_1.png')}  style={{left:'15%',top:'20%'}}/>
                <img alt="digital marketing course in coimbatore" className='banner_round_icon' src={require('../../../images/website/face_2.png')}  style={{right:'0%',bottom:'10%'}}/>
                <img alt="digital marketing course in coimbatore" className='banner_round_icon' src={require('../../../images/website/face_3.png')}  style={{right:'10%',top:'20%'}}/>
                

            </div>
 </div>
</div>

</section>

{/* Counter Section */}

<div className="counter_area">


<div className="container" style={{maxWidth: "1120px !important"}}>

<div className="row counter_section" style={{display:'flex',flexWrap:'wrap'}}>

    <div className="col-md-3 col-sm-3 col-6 centered_content text-center">
       <span> 100%  </span>
       Career Transition
    </div>

    <div className="col-md-3 col-sm-3 col-6 centered_content text-center">
        <span> 100%   </span>
         Placement Assistance
    </div>

    <div className="col-md-3 col-sm-3 col-6 centered_content text-center">
        <span> 5+  </span> 
        Live Projects
    </div>

    <div className="col-md-3 col-sm-3 col-6 centered_content text-center">
        <span> 50+  </span> 
        Learners
    </div>

 </div>

</div>
</div>


<section id='highlights' className="highlights_our_course section_spaces">
 <div className="container">
    <div className='row'>
        <div className='col-lg-7'>
            <div className='highlights_our_course_inner_left'>
                <div className='hos_small_box with_heading hsb1'>
                    <div>
                    <h4>60+  </h4>
                    <p>Hours of Learning </p>
                    </div>                  
                </div>
                <div className='hos_small_box with_heading hsb2'>
                    <div>
                    <h4>21</h4>
                    <p>days Integrated Program</p>
                    </div>                  
                </div>
                <div className='hos_small_box hsb3'>
                    <div>
                    {/* <h4>1,000+</h4> */}
                    <p>Networking & Program Support</p>
                    </div>                  
                </div>
                <div className='hos_big_box hsb4'>
                    <div>
                    <h4>1 – 1 </h4>
                    <p>Mentor Sessions</p>
                    </div>                  
                </div>
                <div className='hos_big_box hsb5'>
                    <div>
                    <h4>50 + </h4>
                    <p>Industrial Tools</p>
                    </div>                  
                </div>
                <div className='hos_small_box hsb6'>
                    <div>
                    {/* <h4>1,000+</h4> */}
                    <p>Brand Kit</p>
                    </div>                  
                </div>
                <div className='hos_small_box hsb7'>
                    <div>
                    {/* <h4>1,000+</h4> */}
                    <p>Access to LMS</p>
                    </div>                  
                </div>
                <div className='hos_small_box with_heading hsb8'>
                    <div>
                    <h4>10+ </h4>
                    <p>Professional Certificates</p>
                    </div>                  
                </div>
                <div className='dummy_smallest_box hsb9' style={{backgroundColor:'#01B170'}}/>
                <div className='dummy_small_box hsb10' style={{backgroundColor:'#F14A51'}}/>      
                <div className='dummy_small_box hsb11' style={{backgroundColor:'#01B170'}}/>
            </div>
        </div>
        <div className='col-lg-5'>
            <div className='highlights_our_course_inner_right'>
            <h2>Highlights {/* of The Our Course*/}</h2> 
            <p>Enhance your career or business with online digital skills from core fundamentals to marketing and beyond the limits. We have framed out-of-the-way courses that make you transform your future. Upgrade Your Digital Skill Now </p>
            <p className='learn_more' style={{display:'flex',alignItems:'center'}} onClick={()=>setHighlightPopup(true)}>Learn More &nbsp;<Arrow/></p>
            </div>           
        </div>    
    </div>    
     </div>
 </section>


 <section id='syllabus' className="website_our_courses section_spaces">

 <div className="container">
     <h2>Our Courses & Syllabus</h2>
     <p>Your Dream Job with 10+ Professional Programs</p>
     <p>Ezio Skill Academy courses focus on building a strong foundation skill for Digital Marketing career development. The syllabus targets the advance topics and assessments which help you to evaluate your career path. </p>
    <div id='desk_website_our_courses' className='row' >
            <div className='col-lg-4'>
                <div className='list_courses_con' style={{marginTop:'23px'}}>
                    {
                        ourCourses.map((courses,index)=>(
                            <div key={index} id={"list_courses"+courses.id} className={courses.id==='1'?'list_courses list_courses_active':'list_courses'} onClick={()=>handleCourseTab(courses.id)}>
                                <div className='lc_side_number' >{courses.id}</div>
                                <p>{courses.name}</p>
                                <Arrow/>
                            </div>
                        ))
                    }
                  

                </div>
            </div>
            <div className='col-lg-8'>
               
              <div id='website_courses_con' className='website_courses_con'>
                  {
                      ourCourses.map((course)=>(
                        <div id={"website_courses"+course.id} className='website_courses' onClick={()=>handleCourseTab(course.id)}>
                        <img alt='digital marketing training institute in Coimbatore' src={require(`../../../images/website/Our-Courses-Syllabus/${course.image}.jpg`)}/>
                        <div>
                            <div> <h4>{course.id}. {course.name} </h4><div><Star/>&nbsp;({course.rating})</div></div>
                          <p dangerouslySetInnerHTML={{ __html: course.description }}>
                          
                          </p>
                         
                          
                          <br/>
                          <div style={{justifyContent:'right'}}>
                              
                              <a style={course.url===''?{pointerEvents:'none'}:{}} href={course.url}>Learn More &nbsp;&nbsp;<Arrow/></a>
                          </div> 
                        </div>
                        </div>
                      ))
                  }
                  
                            </div>         
    </div>
    </div>

    <div id='mbl_website_our_courses'>
    <div id="courseacc_sec">
    <div className="accordion" id="courseacc">
        {
            ourCourses.map((course)=>(
                <div className="card" 
                onClick={()=>ourCourseAcc(course.id)}   
                key={course.id}
                >
                <div className="card-header courseacchead" id={"courseacchead"+course.id}>
                    <div className="btn btn-header-link collapsed" data-target={"#faq"+course.id}
                    aria-expanded="true" aria-controls={"faq"+course.id}> {course.id}. {course.name}</div>
                </div>

                <div id={"courseacc"+course.id} className="collapse courseacc_collapse" aria-labelledby={"faqhead"+course.id} data-parent="#faq">
                    <div className="card-body" >
                        <div className='course_card'>
                        <img alt='digital marketing training institute in Coimbatore' src={require(`../../../images/website/Our-Courses-Syllabus/${course.image}.jpg`)}/>
                        <p dangerouslySetInnerHTML={{ __html: course.description }}>                       
                        </p>                    
                        <a style={course.url===''?{display:'block',fontWeight:'bold',display:'flex',alignItems:'center',pointerEvents:'none'}:{display:'block',fontWeight:'bold',display:'flex',alignItems:'center'}} href={course.url}>Learn More &nbsp;&nbsp;<Arrow/></a>
                        </div>                   
                    </div>
                </div>
            </div>
            ))
        }
                             
                          </div>
                          </div>
                          </div>

     </div>
 </section>

{/* <!-- Methodology section --> */}

<section className="methodology_sec section_spaces">

    <div className="container">

        <div className="text-center">

            <span>
                Learning, Teaching, Evaluation
            </span>
    
            <h2 className="main_title"> Learning Methodology </h2>
    
            <p className="description" style={{maxWidth:'900px',margin:'auto'}}>
            A learning methodology that engages any learners to stay interested with the course throughout the journey. We ensure the course is wrapped with all necessary requirements to successfully complete the program with full satisfaction.
             </p>

        </div>

        <div className="row mt-5" style={{display:'flex',flexWrap:'wrap'}}>

            <div className="col-md-4 col-xs-6">

                <img alt='digital marketing training institute in Coimbatore' src={require('../../../images/website/learning-Method-icons/png/1.png')} />

                <p className="sub_title"> Integrated Programme </p>

                <p className="description"> 
                An intense learning program that uses an integrated learning methodology for the fastest and most effective way to achieve desired corporate training outcomes in digital marketing skills. 
                </p>

            </div>

            <div className="col-md-4 col-xs-6">

                <img alt='digital marketing training institute in Coimbatore' src={require('../../../images/website/learning-Method-icons/png/2.png')} />

                <p className="sub_title"> Live Projects Centric Learning </p>

                <p className="description"> 
                Learn Digital Marketing through 10+ intensive hands-on live projects with different domains. Hands-on learning environment which enhances critical thinking and paves the way to attain real-world industrial experience.
                </p>

            </div>


            <div className="col-md-4 col-xs-6">

                <img alt='digital marketing training institute in Coimbatore' src={require('../../../images/website/learning-Method-icons/png/3.png')} />

                <p className="sub_title"> LMS Access for Learning & Projects  </p>

                <p className="description"> 
                Get 24/7 access to high-value learning content on emerging trends and regulatory updates to get skilled from Anywhere! Anytime! At all times! 
                </p>

            </div>
            

       

            <div className="col-md-4 col-xs-6">

                <img alt='digital marketing training institute in Coimbatore' src={require('../../../images/website/learning-Method-icons/png/4.png')} />

                <p className="sub_title">Professional Learning Assistances  </p>

                <p className="description"> 
                Get trained at every step of your learning journey by industry experts. Personalized mentoring, planning, coordination & overseeing of the projects. 
                </p>

            </div>

            <div className="col-md-4 col-xs-6">

                <img alt='digital marketing training institute in Coimbatore' src={require('../../../images/website/learning-Method-icons/png/5.png')} />

                <p className="sub_title"> Self-Study Materials  </p>

                <p className="description"> 
                Get self-study materials that cover A-Z in digital marketing which will include PDFs, online content, video lectures, & pre-recorded Case Studies. 
                </p>

            </div>


            <div className="col-md-4 col-xs-6">

                <img alt='digital marketing training institute in Coimbatore' src={require('../../../images/website/learning-Method-icons/png/6.png')} />

                <p className="sub_title"> Industry Tools & Case Studies  </p>

                <p className="description"> 
                Get in-depth knowledge of 50 + most powerful digital marketing tools available today with several case studies involving growth strategies of various domains to be better equipped to unleash the potential of all digital channels. 
                </p> 

            </div>
            

        </div>

        


    </div>
    
</section>



<section className="tools_you_master section_spaces">

<div className="container">
    <h2>Tools You’ll Master</h2>
    <p>Tools are the keystone for digital marketing, as they make your task easier, and give more valuable insight and data to improve your performance. Get ready to use 50+ tools.</p>
    <div id='master_tools_con' className='master_tools_con'>
        <div className='master_tools_col'>
            <div className='master_tools'>
                <img alt='digital marketing training institute in Coimbatore' src={require('../../../images/website/Tools-you-wil-master/google-ads.png')} />
            </div>
            <div className='master_tools'>
                <img alt='digital marketing training institute in Coimbatore' src={require('../../../images/website/Tools-you-wil-master/google-anylitsis.png')} />
            </div>
        </div>
        <div className='master_tools_col'>
            <div className='master_tools'>
                <img alt='digital marketing training institute in Coimbatore' src={require('../../../images/website/Tools-you-wil-master/fb-ads.png')} />
            </div>
            <div className='master_tools'>
                <img alt='digital marketing training institute in Coimbatore' src={require('../../../images/website/Tools-you-wil-master/google-tag-manager.png')} />
            </div>
        </div>
        <div className='master_tools_col'>
            <div className='master_tools'>
                <img alt='digital marketing training institute in Coimbatore' src={require('../../../images/website/Tools-you-wil-master/linkedin-ads.png')} />
            </div>
            <div className='master_tools'>
                <img alt='digital marketing training institute in Coimbatore' src={require('../../../images/website/Tools-you-wil-master/google-search-console.png')} />
            </div>
        </div>
        <div className='master_tools_col'>
            <div className='master_tools'>
                <img alt='digital marketing training institute in Coimbatore' src={require('../../../images/website/Tools-you-wil-master/twitter-ads.png')} />
            </div>
            <div className='master_tools'>
                <img alt='best digital marketing course in Coimbatore' src={require('../../../images/website/Tools-you-wil-master/mailchimp.png')} />
            </div>
        </div>
        <div className='master_tools_col'>
            <div className='master_tools'>
                <img alt='best digital marketing course in Coimbatore' src={require('../../../images/website/Tools-you-wil-master/woedpress-ads.png')} />
            </div>
            <div className='master_tools'>
                <img alt='best digital marketing course in Coimbatore' src={require('../../../images/website/Tools-you-wil-master/shopify.png')} />
            </div>
        </div>
        <div className='master_tools_col'>
            <div className='master_tools'>
                <img alt='best digital marketing course in Coimbatore' src={require('../../../images/website/Tools-you-wil-master/google-ad-sense.png')} />
            </div>
            <div className='master_tools'>
                <img alt='best digital marketing course in Coimbatore' src={require('../../../images/website/Tools-you-wil-master/semrush.png')} />
            </div>
        </div>
        <div className='master_tools_col'>
            <div className='master_tools'>
                <img alt='best digital marketing course in Coimbatore' src={require('../../../images/website/Tools-you-wil-master/Mask Group 2.png')} />
            </div>
            <div className='master_tools'>
                <img alt='best digital marketing course in Coimbatore' src={require('../../../images/website/Tools-you-wil-master/Mask Group 3.png')} />
            </div>
        </div>
        <div className='master_tools_col'>
            <div className='master_tools'>
                <img alt='best digital marketing course in Coimbatore' src={require('../../../images/website/Tools-you-wil-master/Mask Group 5.png')} />
            </div>
            <div className='master_tools'>
                <img alt='best digital marketing course in Coimbatore' src={require('../../../images/website/Tools-you-wil-master/Mask Group 4.png')} />
            </div>
        </div>
        <div className='master_tools_col'>
            <div className='master_tools'>
                <img alt='best digital marketing course in Coimbatore' src={require('../../../images/website/Tools-you-wil-master/Mask Group 6.png')} />
            </div>
            <div className='master_tools'>
                <img alt='best digital marketing course in Coimbatore' src={require('../../../images/website/Tools-you-wil-master/Mask Group 38.png')} />
            </div>
        </div>
        <div className='master_tools_col'>
            <div className='master_tools'>
                <img alt='best digital marketing course in Coimbatore' src={require('../../../images/website/Tools-you-wil-master/Mask Group 8.png')} />
            </div>
            <div className='master_tools'>
                <img alt='best digital marketing course in Coimbatore' src={require('../../../images/website/Tools-you-wil-master/Mask Group 9.png')} />
            </div>
        </div>
        <div className='master_tools_col'>
            <div className='master_tools'>
                <img alt='best digital marketing institute in Coimbatore' src={require('../../../images/website/Tools-you-wil-master/Mask Group 11.png')} />
            </div>
            <div className='master_tools'>
                <img alt='best digital marketing institute in Coimbatore' src={require('../../../images/website/Tools-you-wil-master/Mask Group 10.png')} />
            </div>
        </div>
        <div className='master_tools_col'>
            <div className='master_tools'>
                <img alt='best digital marketing institute in Coimbatore' src={require('../../../images/website/Tools-you-wil-master/Mask Group 12.png')} />
            </div>
            <div className='master_tools'>
                <img alt='best digital marketing institute in Coimbatore' src={require('../../../images/website/Tools-you-wil-master/Mask Group 13.png')} />
            </div>
        </div>
        <div className='master_tools_col'>
            <div className='master_tools'>
                <img alt='best digital marketing institute in Coimbatore' src={require('../../../images/website/Tools-you-wil-master/Mask Group 15.png')} />
            </div>
            <div className='master_tools'>
                <img alt='best digital marketing institute in Coimbatore' src={require('../../../images/website/Tools-you-wil-master/Mask Group 14.png')} />
            </div>
        </div>
        <div className='master_tools_col'>
            <div className='master_tools'>
                <img alt='best digital marketing institute in Coimbatore' src={require('../../../images/website/Tools-you-wil-master/Mask Group 16.png')} />
            </div>
            <div className='master_tools'>
                <img alt='best digital marketing institute in Coimbatore' src={require('../../../images/website/Tools-you-wil-master/Mask Group 17.png')} />
            </div>
        </div>
        <div className='master_tools_col'>
            <div className='master_tools'>
                <img alt='best digital marketing institute in Coimbatore' src={require('../../../images/website/Tools-you-wil-master/Mask Group 19.png')} />
            </div>
            <div className='master_tools'>
                <img alt='best digital marketing institute in Coimbatore' src={require('../../../images/website/Tools-you-wil-master/Mask Group 18.png')} />
            </div>
        </div>
        <div className='master_tools_col'>
            <div className='master_tools'>
                <img alt='best digital marketing institute in Coimbatore' src={require('../../../images/website/Tools-you-wil-master/Mask Group 20.png')} />
            </div>
            <div className='master_tools'>
                <img alt='best digital marketing institute in Coimbatore' src={require('../../../images/website/Tools-you-wil-master/Mask Group 21.png')} />
            </div>
        </div>
        <div className='master_tools_col'>
            <div className='master_tools'>
                <img alt='best digital marketing institute in Coimbatore' src={require('../../../images/website/Tools-you-wil-master/Mask Group 23.png')} />
            </div>
            <div className='master_tools'>
                <img alt='best digital marketing institute in Coimbatore' src={require('../../../images/website/Tools-you-wil-master/Mask Group 22.png')} />
            </div>
        </div>
        <div className='master_tools_col'>
            <div className='master_tools'>
                <img alt='seo course in Coimbatore' src={require('../../../images/website/Tools-you-wil-master/Mask Group 24.png')} />
            </div>
            <div className='master_tools'>
                <img alt='seo course in Coimbatore' src={require('../../../images/website/Tools-you-wil-master/Mask Group 25.png')} />
            </div>
        </div>
        <div className='master_tools_col'>
            <div className='master_tools'>
                <img alt='seo course in Coimbatore' src={require('../../../images/website/Tools-you-wil-master/Mask Group 27.png')} />
            </div>
            <div className='master_tools'>
                <img alt='seo course in Coimbatore' src={require('../../../images/website/Tools-you-wil-master/Mask Group 26.png')} />
            </div>
        </div>
        <div className='master_tools_col'>
            <div className='master_tools'>
                <img alt='seo course in Coimbatore' src={require('../../../images/website/Tools-you-wil-master/Mask Group 28.png')} />
            </div>
            <div className='master_tools'>
                <img alt='seo course in Coimbatore' src={require('../../../images/website/Tools-you-wil-master/Mask Group 29.png')} />
            </div>
        </div>
        <div className='master_tools_col'>
            <div className='master_tools'>
                <img alt='seo course in Coimbatore' src={require('../../../images/website/Tools-you-wil-master/Mask Group 31.png')} />
            </div>
            <div className='master_tools'>
                <img alt='seo course in Coimbatore' src={require('../../../images/website/Tools-you-wil-master/Mask Group 30.png')} />
            </div>
        </div>
        <div className='master_tools_col'>
            <div className='master_tools'>
                <img alt='seo course in Coimbatore' src={require('../../../images/website/Tools-you-wil-master/Mask Group 32.png')} />
            </div>
            <div className='master_tools'>
                <img alt='seo course in Coimbatore' src={require('../../../images/website/Tools-you-wil-master/Mask Group 33.png')} />
            </div>
        </div>
        <div className='master_tools_col'>
            <div className='master_tools'>
                <img alt='seo course in Coimbatore' src={require('../../../images/website/Tools-you-wil-master/Mask Group 35.png')} />
            </div>
            <div className='master_tools'>
                <img alt='seo course in Coimbatore' src={require('../../../images/website/Tools-you-wil-master/Mask Group 34.png')} />
            </div>
        </div>
        <div className='master_tools_col'>
            <div className='master_tools'>
                <img alt='seo course in Coimbatore' src={require('../../../images/website/Tools-you-wil-master/Mask Group 36.png')} />
            </div>
            <div className='master_tools'>
                <img alt='seo course in Coimbatore' src={require('../../../images/website/Tools-you-wil-master/Mask Group 37.png')} />
            </div>
        </div>
        {/* <div className='master_tools_col'>
            <div className='master_tools'>
                <img alt='seo course in Coimbatore' src={require('../../../images/website/Tools-you-wil-master/Mask Group 7.png')} />
            </div>
            <div className='master_tools'>
                <img alt='seo course in Coimbatore' src={require('../../../images/website/Tools-you-wil-master/Mask Group 38.png')} />
            </div>
        </div>
        <div className='master_tools_col'>
            <div className='master_tools'>
                <img alt='seo course in Coimbatore' src={require('../../../images/website/Tools-you-wil-master/Mask Group 38.png')} />
            </div>
            <div className='master_tools'>
                <img alt='seo course in Coimbatore' src={require('../../../images/website/Tools-you-wil-master/Mask Group 38.png')} />
            </div>
        </div> */}
        
    </div>
    <button id="more_tools" onClick={MoreTools}>More Tools</button>
    </div>
</section>




{/* <!-- live Projects Section --> */}

<section id='case_study' className="projects_sec section_spaces">

<div className="container">

  <div className="text-center">

    <h2 className="main_title"> Live Projects & Case Studies </h2>

    {/* <p className="description" style={{maxWidth:'900px',margin:'auto'}}>
        lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s
    </p> */}

  </div>

  <div className="row" style={{display:"flex",flexWrap:'wrap'}}>

     <div className="col-md-6 mt-5">

        <div className="row">

            <div className="col-md-6 col-xs-12">
                <img alt='seo course in Coimbatore' className='thumb_img' src={require('../../../images/website/Live-Projects-Case-Studies/WP-Website -Yoast-SEO.jpg')} />
               <div className="image_icon">
                <img alt='seo course in Coimbatore' src={require('../../../images/website/Live-Projects-Case-Studies/WP-Website-Yoast-SEO.svg')} />
                </div>
            </div>

            <div className="col-md-6 col-xs-12 centered_content ">
                <p className="sub_title">WP Website & Yoast SEO </p>

                <p className="description"> 
                Get hands on experience in Static, Ecommerce Website in WordPress. And Implementing Yoast SEO Plugin to analyze and understand the user base. 
                </p> 
            </div>

        </div>

     </div>

     <div className="col-md-6 mt-5">

        <div className="row">

            <div className="col-md-6 col-xs-12">
                <img alt='seo course in Coimbatore' className='thumb_img' src={require('../../../images/website/card01.png')} />
                <div className="image_icon">
                <img alt='seo course in Coimbatore' src={require('../../../images/website/Live-Projects-Case-Studies/Updated-SEO-Strategy.svg')} />    
                </div>
            </div>

            <div className="col-md-6 col-xs-12 centered_content ">
                <p className="sub_title"> Updated SEO Strategy </p>

                <p className="description"> 
                Work on live SEO projects and gain practical expertise on key elements of SEO strategies used during projects and know about quality back links (organic & paid)
                </p> 
            </div>

        </div>

     </div>

     <div className="col-md-6 mt-5">

        <div className="row">

            <div className="col-md-6 col-xs-12">
               <img alt='seo course in Coimbatore' className='thumb_img' src={require('../../../images/website/Live-Projects-Case-Studies/Social-Media-Optimizing-Branding.jpg')} />
               <div className="image_icon">
                <img alt='seo course in Coimbatore' src={require('../../../images/website/Live-Projects-Case-Studies/Social-Media-Optimizing-Branding.svg')} />
                </div>
            </div>

            <div className="col-md-6 col-xs-12 centered_content ">
                <p className="sub_title">Social Media Optimizing & Branding</p>

                <p className="description"> 
                Organic is the word for clients looking for cost-effective marketing strategies. Learn how to create post and re-post to drive organic traffic through client accounts.
                </p> 
            </div>

        </div>

     </div>

     <div className="col-md-6 mt-5">

        <div className="row">

            <div className="col-md-6 col-xs-12">
                <img alt='digital marketing course fees in Coimbatore' className='thumb_img' src={require('../../../images/website/Live-Projects-Case-Studies/Create-Paid-Campaigns.jpg')} />
                <div className="image_icon">
                <img alt='digital marketing course fees in Coimbatore' src={require('../../../images/website/Live-Projects-Case-Studies/Create-Paid-Campaigns.svg')} />
                </div>
            </div>

            <div className="col-md-6 col-xs-12 centered_content ">
                <p className="sub_title">Create Paid Campaigns </p>

                <p className="description"> 
                Get a chance to work on different paid campaigns for various domain, from social media ads, strategize and plan real-time projects.
                </p> 
            </div>

        </div>

     </div>
     
     <div className="col-md-6 mt-5">

<div className="row">

    <div className="col-md-6 col-xs-12">
        <img alt='digital marketing course fees in Coimbatore' className='thumb_img' src={require('../../../images/website/Live-Projects-Case-Studies/content-Marketing.jpg')} />
        <div className="image_icon">
        <img alt='digital marketing course fees in Coimbatore' src={require('../../../images/website/Live-Projects-Case-Studies/Content-marketing.svg')} />
        </div>
    </div>

    <div className="col-md-6 col-xs-12 centered_content ">
        <p className="sub_title">Content Marketing </p>

        <p className="description"> 
        Content being a fundamental principle of digital marketing, expertise in this area is a mandate. Learn how to create result driven advanced content strategy.    </p> 
    </div>

</div>

</div>
<div className="col-md-6 mt-5">

<div className="row">

    <div className="col-md-6 col-xs-12">
        <img alt='digital marketing course fees in Coimbatore' className='thumb_img' src={require('../../../images/website/Live-Projects-Case-Studies/Case-Study-Analysis.jpg')} />
        <div className="image_icon">
        <img alt='digital marketing course fees in Coimbatore' src={require('../../../images/website/Live-Projects-Case-Studies/Case-Study-Analysis.svg')} />
        </div>
    </div>

    <div className="col-md-6 col-xs-12 centered_content ">
        <p className="sub_title">Case Study Analysis </p>

        <p className="description"> 
        Work on case studies to examine and study a business problem. Support the professionals to suggest the most effective solutions to the clients.
        </p> 
    </div>

</div>

</div>

  </div>

</div>

</section>


<section id='certification' className="website_certificate section_spaces">

    <div className="container">
        <h2>Industrial Recognized Certificates </h2>
    <Slider {...certificate_settings}>
        {
            recognized_certification.map((certi,index)=>(
        <div key={index}>
        <div className='row'>
            <div className='col-lg-6 website_certificate_left_inner'>
                <h2>{certi.name}</h2>
                <p>{certi.desc}</p>
                {/* <button>View All</button> */}
            </div>
            <div className='col-lg-6'>
                <img alt='digital marketing course fees in Coimbatore' src={require(`../../../images/website/certificate/${certi.img}`)} />
            </div>
        </div>     
        </div> 
            ))
        }
        
      </Slider>
        </div>
    </section>


    
    <section className="rating_feedback section_spaces">

<div className="container">
   <h2>Rating / Feedback / Testimonials</h2>
   {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur in dignissim dolor, nec tempor quam. Etiam vel ullamcorper augue, eget porta eros. Sed eu lorem vulputate, finibus libero nec, faucibus mauris.</p> */}
   <div className='website_feedback_container'>
   <Slider {...rating_settings}>
       <div>
       <div id="website_feedback0" className={`website_feedback ${ratingBox===0?'website_feedback_active':''}`}>
           <p>Ezio Skill Academy introduced me to the most trending technology, digital marketing. I feel really happy to get sufficient guidance which helps me to make up my career and grow digital skills as well. Ezio Skill Academy wraps complete digital marketing topics right from fundamental to advanced concepts in SEO, SEM, PPC, and more. </p>
           <img alt='digital marketing course fees in Coimbatore' src={require('../../../images/website/star_5.svg')}/>
           <div>
               {/* <img alt='digital marketing course fees in Coimbatore' src={require('../../../images/website/icon-1.png')}/> */}
               <div style={{height:'50px',width:'50px',backgroundColor:'rgb(252 208 59)',borderRadius:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}>
                   RR
               </div>
               <div>
                   <h5>Reshma Ravi </h5>
                   {/* <p>Digital Marketing Manager</p> */}
               </div>
           </div>
       </div>
       </div>
       <div>
       <div id="website_feedback1" className={`website_feedback ${ratingBox===1?'website_feedback_active':''}`}>
           <p>I had a wonderful experience with Ezio Skill Academy! The course has high-quality content with engaging videos. So, I can easily understand the technical concepts with mentor support. On top of that, I had a chance to work with live projects.  </p>
           <img alt='digital marketing course fees in Coimbatore' src={require('../../../images/website/star_5.svg')}/>
           <div>
               {/* <img alt='digital marketing course fees in Coimbatore' src={require('../../../images/website/icon-1.png')}/> */}
               <div style={{height:'50px',width:'50px',backgroundColor:'rgb(252 208 59)',borderRadius:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}>
                   RK
               </div>
               <div>
                   <h5>Rahul Karthick </h5>
                   {/* <p>Digital Marketing Manager</p> */}
               </div>
           </div>
       </div>
       </div>
       <div>
       <div id="website_feedback2" className={`website_feedback ${ratingBox===2?'website_feedback_active':''}`}>
           <p>I actually enjoyed the Digital Marketing Course at Ezio Skill Academy. In this program, I've learned many SEO concepts and PPC techniques, which really found beneficial to improving my website ranking and marketing tactics.  I strongly suggest this course to everyone. Develop and amplify your knowledge in marketing.  </p>
           <img alt='digital marketing course fees in Coimbatore' src={require('../../../images/website/star_5.svg')}/>
           <div>
               {/* <img alt='digital marketing course fees in Coimbatore' src={require('../../../images/website/icon-1.png')}/> */}
               <div style={{height:'50px',width:'50px',backgroundColor:'rgb(252 208 59)',borderRadius:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}>
                   A
               </div>
               <div>
                   <h5>Arulnithi  </h5>
                   {/* <p>Digital Marketing Manager</p> */}
               </div>
           </div>
       </div>
       </div>
       
       <div>
       <div id="website_feedback3" className={`website_feedback ${ratingBox===3?'website_feedback_active':''}`}>
           <p>If you're looking for ways to shift your current field to digital marketing, then this program is for you people. It is excellent and has more hands-on training than theoretical. Mentors served their best to give their assistance in my career support.</p>
           <img alt='digital marketing course fees in Coimbatore' src={require('../../../images/website/star_5.svg')}/>
           <div>
               {/* <img alt='digital marketing course fees in Coimbatore' src={require('../../../images/website/icon-1.png')}/> */}
               <div style={{height:'50px',width:'50px',backgroundColor:'rgb(252 208 59)',borderRadius:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}>
                   JP
               </div>
               <div>
                   <h5>Jaya Prabha  </h5>
                   {/* <p>Digital Marketing Manager</p> */}
               </div>
           </div>
       </div></div><div>
       <div id="website_feedback4" className={`website_feedback ${ratingBox===4?'website_feedback_active':''}`}>
           <p>Ezio Skill Academy is the perfect example of practical learning. When you ask about my experience it is just beyond, what they provide a full-scale package which well-tutored and trained in hands-on experience and real-world experience and how to handle it. Within a 3-months of teaching, it makes up you for industry-ready and how to tackle real-life scenarios. </p>
           <img alt='digital marketing course fees in Coimbatore' src={require('../../../images/website/star_5.svg')}/>
           <div>
               {/* <img alt='digital marketing course fees in Coimbatore' src={require('../../../images/website/icon-1.png')}/> */}
               <div style={{height:'50px',width:'50px',backgroundColor:'rgb(252 208 59)',borderRadius:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}>
                  A
               </div>
               <div>
                   <h5>Ashwini </h5>
                   {/* <p>Digital Marketing Manager</p> */}
               </div>
           </div>
       </div>
       </div>
       <div>
       <div id="website_feedback5" className={`website_feedback ${ratingBox===5?'website_feedback_active':''}`}>
           <p>I've understood that the skills in marketing are actually under strengths. The universe is growing rapidly with many new technologies. If you're serious in the marketing domain, then digital marketing is a perfect fit. Hence, I myself in Ezio Skill Academy. In this course, I've learned several advertising methods and techniques which are really beneficial to growing my business. </p>
           <img alt='digital marketing course fees in Coimbatore' src={require('../../../images/website/star_5.svg')}/>
           <div>
               {/* <img alt='digital marketing course fees in Coimbatore' src={require('../../../images/website/icon-1.png')}/> */}
               <div style={{height:'50px',width:'50px',backgroundColor:'rgb(252 208 59)',borderRadius:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}>
                   S
               </div>
               <div>
                   <h5>Sanjai   </h5>
                   {/* <p>Digital Marketing Manager</p> */}
               </div>
           </div>
       </div>
       </div>
       
    
   </Slider>
     
   </div>
    </div>
</section>

 <section id='pricing' className="website_batch_detail section_spaces">
 <div className="container">
     <h2 >Batch Details & Pricing</h2>
     {/* batch_pricing_box_inactive */}

    <div className='row' style={{marginTop:'40px'}}>
                <div className='col-lg-4 col-md-4'>
                    <div id='batch_pricing_box1'  className='batch_pricing_box'>
                        <div className='batch_pricing_box_header'>
                            <IndividualPlanIcon/>
                            <h3>Executive Digital Marketing Program </h3>
                            <br/>      
                            <h6>(45 Days)</h6>
                        </div>
                        <ul className='batch_pricing_box_pricing_con '>                            
                                <li>                                
                                    <div>
                                        <h5>Fundamentals of Marketing</h5>
                                    </div>
                                </li>
                                <li>                                
                                    <div>
                                        <h5>Search Engine Optimization - SEO</h5>
                                    </div>
                                </li>
                                <li>                                
                                    <div>
                                        <h5>Social Media Marketing - SMM</h5>
                                    </div>
                                </li>
                                <li>                                
                                    <div>
                                        <h5>PPC </h5>
                                    </div>
                                </li>
                                <li>                                
                                    <div>
                                        <h5>FB Ads</h5>
                                    </div>
                                </li>
                                
                               
                            </ul>
                            <button onClick={()=>setOpenRegisterPopup(true)}>
                           Contact Us
                            </button>
                    </div>
                </div>
                <div className='col-lg-4 col-md-4'>
                    <div id='batch_pricing_box2' className='batch_pricing_box '>
                        <div className='batch_pricing_box_header'>
                        <PackagePlanIcon/>
                            <h3 style={{marginTop:'15px'}}>
                            Professional Diploma Program in Digital Marketing</h3><br/>      
                            <h6>(60 Days)</h6>
                        </div>
                        <ul className='batch_pricing_box_pricing_con'>
                            {
                                batch2.map((batch,id)=>(
                                    <>
                                    
                                    {
                                 (id<batchReadMore)&&(
                                    <li>                                
                                    <div>
                                        <h5>{batch}</h5>
                                    </div>
                                </li>
                                )
                                
                                    }
                                    
                                    </>
                                   
                                   
                                ))
                            }
                              <p onClick={()=>{setBatchReadMore(batchReadMore===5?15:5)}}>{batchReadMore===5?'Read More':'Read Less'}</p>
                            </ul>
                            <button onClick={()=>setOpenRegisterPopup(true)}>
                            Contact Us
                            </button>
                    </div>
                </div>
                <div className='col-lg-4 col-md-4'> 
                    <div id='batch_pricing_box3' className='batch_pricing_box'>
                        <div className='batch_pricing_box_header'>
                        <CustomizePlanIcon/>
                            <h3>Short Study Digital Marketing Program</h3><br/>      
                            <h6>(20 Days)</h6>
                        </div>
                        <ul className='batch_pricing_box_pricing_con'>
                                <li>                                
                                    <div>
                                        <h5>Fundamentals of Marketing</h5>
                                    </div>
                                </li>
                                <li>                                
                                    <div>
                                        <h5>Search Engine Optimization - SEO</h5>
                                    </div>
                                </li>
                                <li>                                
                                    <div>
                                        <h5>Social Media Marketing - SMM</h5>
                                    </div>
                                </li>
                                <li>                                
                                <div>
                                        <h5>FB Ads</h5>
                                    </div>
                                </li>
                                <li>                                
                                <div>
                                        <h5></h5>
                                    </div>
                                </li>
                        </ul>
                            <button onClick={()=>setOpenRegisterPopup(true)}>
                            Contact Us 
                            </button>
                    </div>
                </div>
    </div>
    
     </div>
 </section>

{/* <!-- Q & A Section  --> */}

<section className="qa_sec section_spaces mt-5">

    <div className="container">
        
        <div className="text-center mb-5">

            <h2 className="main_title"> Frequently Asked Question </h2>

            <p className="description">
            Do you have any doubt about recording your online digital marketing program? Check out our frequently asked questions section to clear your confusion and get an overall idea of this course. 
            </p>
        

        </div>


          <div id="faq_sec">
            <div className="container">
          <div className="accordion" id="faq">
                              <div className="card">
                                  <div className="card-header" id="faqhead1">
                                      <div className="btn btn-header-link" data-toggle="collapse" data-target="#faq1"
                                      aria-expanded="true" aria-controls="faq1"> 01.   Is there eligibility to enroll in the digital marketing program? </div>
                                  </div>
          
                                  <div id="faq1" className="collapse show" aria-labelledby="faqhead1" data-parent="#faq">
                                      <div className="card-body">
                                      The best part of the digital marketing course is that any student who is from either a technical or non-technical background can apply for the digital marketing course. Yes, any student or professional who is willing to learn can join the course.  
                                      </div>
                                  </div>
                              </div>
                              <div className="card">
                                  <div className="card-header" id="faqhead2">
                                      <div className="btn btn-header-link collapsed" data-toggle="collapse" data-target="#faq2"
                                      aria-expanded="true" aria-controls="faq2"> 02.  Does Digital Marketing is a demanding career?   </div>
                                  </div>
          
                                  <div id="faq2" className="collapse" aria-labelledby="faqhead2" data-parent="#faq">
                                      <div className="card-body">
                                      Digital Marketing has been on a constant increase in recent years. This growth is likely to fly up in future years as well. The opportunities will always be high in this domain. So, there is no doubt it's worth learning digital marketing. 
                                      </div>
                                  </div>
                              </div>
                              <div className="card">
                                  <div className="card-header" id="faqhead3">
                                      <div className="btn btn-header-link collapsed" data-toggle="collapse" data-target="#faq3"
                                      aria-expanded="true" aria-controls="faq3"> 03.  Is there any duration to complete the courses?   </div>
                                  </div>
          
                                  <div id="faq3" className="collapse" aria-labelledby="faqhead3" data-parent="#faq">
                                      <div className="card-body">
                                      This is based on the package you select. You can learn from 2 hours to 21 hours and access the LMS portal for 90 days. 
                                      </div>
                                  </div>
                              </div>
                              <div className="card">
                                <div className="card-header" id="faqhead4">
                                    <div className="btn btn-header-link collapsed" data-toggle="collapse" data-target="#faq4"
                                    aria-expanded="true" aria-controls="faq4"> 04.  How long can I access the study materials?    </div>
                                </div>
        
                                <div id="faq4" className="collapse" aria-labelledby="faqhead4" data-parent="#faq">
                                    <div className="card-body">
                                    You'll have access to your own LMS, which has videos and study material for up to 90 days and can extend based on request.  
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header" id="faqhead5">
                                    <div className="btn btn-header-link collapsed" data-toggle="collapse" data-target="#faq5"
                                    aria-expanded="true" aria-controls="faq5"> 05.   What kind of payment option will you offer?  </div>
                                </div>
        
                                <div id="faq5" className="collapse" aria-labelledby="faqhead5" data-parent="#faq">
                                    <div className="card-body">
                                    Based on your convenience, you can pay your fees through UPI, credit, debit cards, cash, cheque, or bank transfers. 
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header" id="faqhead6">
                                    <div className="btn btn-header-link collapsed" data-toggle="collapse" data-target="#faq6"
                                    aria-expanded="true" aria-controls="faq6"> 06.  Why should I enroll in Ezio Skill Academy?  </div>
                                </div>
        
                                <div id="faq6" className="collapse" aria-labelledby="faqhead6" data-parent="#faq">
                                    <div className="card-body">
                                    Ezio Skill Academy offers a competitive learning space and framework for professionals, business owners, and students. Apart from cutting-edge study material we are dedicated to our work and offer real-time projects to experience live exposure to the latest industry trends which provide more excelling insights.  
                                    <br/><br/>
                                    Any digital marketing aspirants can learn and groom their digital skills and shape their personal and business growth.    
                                
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header" id="faqhead7">
                                    <div className="btn btn-header-link collapsed" data-toggle="collapse" data-target="#faq7"
                                    aria-expanded="true" aria-controls="faq7"> 07.   Are there any benefits of getting an online certificate?   </div>
                                </div>
        
                                <div id="faq7" className="collapse" aria-labelledby="faqhead7" data-parent="#faq">
                                    <div className="card-body">
                                    Earning an online certificate can upgrade your skills and enhance your professional credentials. By the way, you can save time and study based on your convenience. 
                                    </div>
                                </div>
                            </div>
                          </div>
              </div>
            </div>

    </div>

</section>





<RegisterPopup openRegisterPopup={openRegisterPopup} setOpenRegisterPopup={setOpenRegisterPopup}/>

<Modal
      aria-labelledby="spring-modal-title"
      aria-describedby="spring-modal-description"
      className={classes.modal}
      open={highlightPopup}
        onClose={()=>setHighlightPopup(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={highlightPopup}>
        <div className='highlight_popup'>
            <div className='hp_close_btn' onClick={()=>setHighlightPopup(false)}>
                x
            </div>
            <div className='highlight_popup_top'>
                <h1>For Every Batch Best Performers</h1>
                <div className='highlight_popup_top_right'>
                    <h3>Refunds</h3>
                    <div>
                        <div className='green_round_con'>
                            <h5>1<span>st</span></h5>
                            <div className='green_round'>100%</div>
                        </div>
                        <div className='green_round_con'>
                            <h5>2<span>nd</span></h5>
                            <div className='green_round'>50%</div>
                        </div>
                        <div className='green_round_con'>
                            <h5>3<span>rd</span></h5>
                            <div className='green_round'>25%</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='highlight_popup_medium'>
                <div>
                    <img src={require('../../../images/website/batch.png')}/>
                    <div>
                        <p>Assured Placement for <br/> <span>1st Best Performers</span></p>
                    </div>
                </div>

                <div>
                    <img src={require('../../../images/website/presentation.png')}/>
                    <div>
                        <p>Assured  <br/><span>Internship Opportunity</span> for  <br/><span>2nd & 3rd Best Performers</span> </p>
                    </div>
                </div>
            </div>
            <div className='highlight_popup_bottom'>
                <div>
                    <img src={require('../../../images/website/personal-file.png')}/>
                    <div>
                        <p> <span>Profile Building</span><br/>(Resume & Linkedin)</p>
                    </div>
                </div>
                <div>
                    <img src={require('../../../images/website/portfolio.png')}/>
                    <div>
                        <p> <span>80%</span><br/>Placement Assured</p>
                    </div>
                </div>
                <div>
                    <img src={require('../../../images/website/professional.png')}/>
                    <div>
                        <p> <span>100%</span><br/>Trained Professionals</p>
                    </div>
                </div>
            </div>
        </div>
          </Fade>
          </Modal>
        </div>
    )
}

export default Home


