import React from 'react'


const Header = () =>{
	return(
	    <div>
		   	 
			<nav className="navbar navbar-expand-sm navbar-default navbar-fixed-top">
			  <div className="container-fluid">
				<div className="navbar-header">
				  <button type="button" className="navbar-toggle" data-toggle="collapse" data-target="#myNavbar">
					<span className="icon-bar"></span>
					<span className="icon-bar"></span>
					<span className="icon-bar"></span>                        
				  </button>
				  <a className="navbar-brand website_brand" href="#myPage"><img src={require('../../images/logo.png')} alt='logo' /></a>
				</div>
				<div className="collapse navbar-collapse" id="myNavbar">
				  <ul className="nav navbar-nav">
					<li><a href="#">Home</a></li>
					<li><a href="#courses">Courses</a></li>
					<li><a href="#syllabus">Syllabus</a></li>
					<li><a href="#our_program"> Our Program </a></li>
					<li><a href="#certification_sec">Certification</a></li>
					<li><a href="#contact">Contact</a></li>
					<li><a href="#">Blog</a></li>
					{/* <li><button className=' sign_in btn btn-default'>sign in</button></li> */}
				  </ul>
				</div>
			  </div>
			</nav>

		</div>
	)
}

export default Header