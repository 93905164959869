import React from 'react'

export default function ServicesIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17.279" height="17.281" viewBox="0 0 17.279 17.281">
      <g id="Other_Services" data-name="Other Services" transform="translate(-18153 20227)">
        <g id="Group_4373" data-name="Group 4373" transform="translate(18104 -20441)">
          <path id="Path_10456" data-name="Path 10456" d="M14.13,10.559a.309.309,0,0,1,.143.081l4.4,4.4.8-.8-4.4-4.4a.309.309,0,0,1-.081-.143l-.277-1.1-1.836-1.05-.9.9,1.05,1.837Zm0,0" transform="translate(37.141 206.529)" fill="#143059"/>
          <path id="Path_10457" data-name="Path 10457" d="M6.558,13.125,13.54,6.143a.308.308,0,0,1,.292-.081,3.062,3.062,0,0,0,3.822-2.983c0-.058,0-.116-.005-.175L16.026,4.528a.308.308,0,0,1-.315.074l-1.847-.616a.307.307,0,0,1-.195-.194l-.616-1.847a.307.307,0,0,1,.074-.315L14.752.007a3.063,3.063,0,0,0-3.158,3.817.308.308,0,0,1-.081.293L4.531,11.1a.311.311,0,0,1-.292.081,3.033,3.033,0,0,0-.744-.1,3.1,3.1,0,1,0,2.983,2.334A.308.308,0,0,1,6.558,13.125ZM4.244,15.393h-1.5l-.739-1.231.739-1.232h1.5l.739,1.232Zm1.188-3.6L12.2,5.017l.435.435L5.867,12.224Zm0,0" transform="translate(48.625 213.999)" fill="#143059"/>
          <path id="Path_10458" data-name="Path 10458" d="M1459.5,1457.544a1.867,1.867,0,0,0,2.4-2.4Zm0,0" transform="translate(-1395.744 -1226.424)" fill="#143059"/>
          <path id="Path_10459" data-name="Path 10459" d="M877.328,870.251l-3.458-3.459a.961.961,0,0,1-1.354-1.353l-.49-.49-2.643,2.643.493.492a.961.961,0,0,1,1.354,1.353l3.455,3.456c.038.038.078.073.118.107l2.632-2.632C877.4,870.328,877.365,870.288,877.328,870.251ZM875.5,871.5l-3.078-3.078.435-.435,3.078,3.079Zm0,0" transform="translate(-811.595 -642.202)" fill="#143059"/>
        </g>
      </g>
    </svg>

  )
}
