import { Button } from '@material-ui/core';
import React,{Fragment,useEffect,useState} from 'react'
import Cookies from 'js-cookie';
import $ from 'jquery'
import ReactQuill from 'react-quill'; 
import 'react-quill/dist/quill.snow.css'; 
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import Toaster from '../../../toaster';

export function AddCourse() {
    const history=useHistory()

    const [fields, Getfields] = useState({});
    const [description,setDescription] = useState('')
    const [error,setError] = useState({})

    const [tosOpen,setTosOpen]=useState()

    const handleValue=(e)=>{
        Getfields({ ...fields, [e.target.name]:e.target.value })
    }


    const addCourse=()=>{
      let err={}
      let isValid=true
      if(!fields.course_name){
          err["course_name"]="Please Enter Course Name"
          isValid=false
      }
      if(!fields.course_desc){
          err["course_desc"]="Please Course description"
          isValid=false
      }

      if(!fields.duration){
          err["duration"]="Please Enter Duration"
          isValid=false
      }
      if(!fields.author){
          err["author"]="Please Enter Author"
          isValid=false
      }
      if(!fields.price){
          err["price"]="Please Enter End time"
          isValid=false
      }
      setError(err)

      if(isValid){
        const jsonData={
          "course_name": fields.course_name,
          "course_desc": fields.course_desc,
          "duration":fields.duration,
          "author": fields.author,
          "price": fields.price,
      }
      axios.post('https://api.ezioskillacademy.com/api/admin/course',jsonData,{headers:{
          Authorization:`Bearer ${Cookies.get('token')}`
        } })
      .then(res=>{
          // console.log("res",res.data)
         
              //GetBatchList()
              setTosOpen(res.data.message)
              //alert(res.data.message)
              history.push('/superadmin/course/')

              
          
      }).catch(e=>{
         alert(e.response.data.message)
      })
      }
    }
  return (
   <section className='admin_add_course'>
       <h1>Add Course</h1>
       <br/>
         <div className="form-group">
              <label>Course Name:</label>
              <input
                className="form-control"
                type="text"
                name="course_name"
                onChange={(e) => handleValue(e)}
              />
              <p style={{color:'red'}}>{error.course_name}</p>
            </div>
           

            <div className="form-group">
              <label>Duration:</label>
              <input
                className="form-control"
                type="text"
                name="duration"
                onChange={(e) => handleValue(e)}
              />
               <p style={{color:'red'}}>{error.duration}</p>
            </div>
            

            <div className="form-group">
              <label>Author:</label>
              <input
                className="form-control"
                type="text"
                name='author'
                onChange={(e) => handleValue(e)}
              />
             <p style={{color:'red'}}>{error.author}</p>
            </div>

            <div className="form-group">
              <label>Price:</label>
              <input
                className="form-control"
                type="text"
                name='price'
                onChange={(e) => handleValue(e)}
              />
             <p style={{color:'red'}}>{error.price}</p>
            </div>

            <div className="form-group">
              <label>Description:</label>
              <input
                className="form-control"
                type="text"
                name='course_desc'
                onChange={(e) => handleValue(e)}
              />
              {/* <ReactQuill value={description} onChange={(value)=>setDescription(value)} />  */}
             <p style={{color:'red'}}>{error.course_desc}</p>
            </div>

            <Button onClick={addCourse} variant="contained" size="large" color="primary" style={{backgroundColor:'#4267B2',color:'white',fontSize:'14px',height:'40px',width:'100px'}}>
                Submit
              </Button>
              <Toaster tosOpen={tosOpen} setTosOpen={setTosOpen}/>
   </section>
  )
}




export function EditCourse() {  
  const history=useHistory()

  const [fields, Getfields] = useState({});
  const [description,setDescription] = useState('')
  const [error,setError] = useState({})
  const [courseId,setCourseId]= useState()

  const [tosOpen,setTosOpen]=useState()

  const handleValue=(e)=>{
      Getfields({ ...fields, [e.target.name]:e.target.value })
  }

  useEffect(()=>{
    let pathArr=window.location.pathname.split('/')
    // console.log(pathArr[pathArr.length-1])
    setCourseId(pathArr[pathArr.length-1])
    getCourse(pathArr[pathArr.length-1])
  },[])

  function getCourse(id){
    axios.get(`https://api.ezioskillacademy.com/api/admin/course/`+id, {headers:{
      Authorization:`Bearer ${Cookies.get('token')}`
    } })
    .then(res => {  
        // console.log("course",res.data.data)
        Getfields(res.data.data)
        setDescription(res.data.data?.course_desc)     
    }).catch(e=>{
      alert(e.response.data.message)
    })
  }

  const editCourse=()=>{
    let err={}
    let isValid=true
    if(!fields.course_name){
        err["course_name"]="Please Enter Course Name"
        isValid=false
    }
    if(!fields.course_desc){
        err["course_desc"]="Please Course description"
        isValid=false
    }

    if(!fields.duration){
        err["duration"]="Please Enter Duration"
        isValid=false
    }
    if(!fields.author){
        err["author"]="Please Enter Author"
        isValid=false
    }
    if(!fields.price){
        err["price"]="Please Enter End time"
        isValid=false
    }
    setError(err)

    if(isValid){
      const jsonData={
        "course_name": fields.course_name,
        "course_desc": fields.course_desc,
        "duration":fields.duration,
        "author": fields.author,
        "price": fields.price,
    }
    axios.put('https://api.ezioskillacademy.com/api/admin/course/'+courseId,jsonData,{headers:{
        Authorization:`Bearer ${Cookies.get('token')}`
      } })
    .then(res=>{
        // console.log("res",res.data)
       
            //GetBatchList()
           // alert(res.data.message)
           setTosOpen(res.data.message)
            history.push('/superadmin/course/')

            
        
    }).catch(e=>{
       alert(e.response.data.message)
    })
    }
  }
return (
 <section className='admin_add_course'>
     <h1>Edit Course</h1>
     <br/>
       <div className="form-group">
            <label>Course Name:</label>
            <input
              className="form-control"
              type="text"
              name="course_name"
              value={fields.course_name}
              onChange={(e) => handleValue(e)}
            />
            <p style={{color:'red'}}>{error.course_name}</p>
          </div>
         

          <div className="form-group">
            <label>Duration:</label>
            <input
              className="form-control"
              type="text"
              name="duration"
              value={fields.duration}
              onChange={(e) => handleValue(e)}
            />
             <p style={{color:'red'}}>{error.duration}</p>
          </div>
          

          <div className="form-group">
            <label>Author:</label>
            <input
              className="form-control"
              type="text"
              name='author'
              value={fields.author}
              onChange={(e) => handleValue(e)}
            />
           <p style={{color:'red'}}>{error.author}</p>
          </div>

          <div className="form-group">
            <label>Price:</label>
            <input
              className="form-control"
              type="text"
              name='price'
              value={fields.price}
              onChange={(e) => handleValue(e)}
            />
           <p style={{color:'red'}}>{error.price}</p>
          </div>

          <div className="form-group">
            <label>Description:</label>
            <input
              className="form-control"
              type="text"
              name='course_desc'
              value={fields.course_desc}
              onChange={(e) => handleValue(e)}
            />
            {/* <ReactQuill value={description} onChange={(value)=>setDescription(value)} />  */}
           <p style={{color:'red'}}>{error.course_desc}</p>
          </div>

          <Button onClick={editCourse} variant="contained" size="large" color="primary" style={{backgroundColor:'#4267B2',color:'white',fontSize:'14px',height:'40px',width:'100px'}}>
              Submit
            </Button>
            <Toaster tosOpen={tosOpen} setTosOpen={setTosOpen}/>
 </section>
)
}
