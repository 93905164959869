import axios from 'axios';
import React,{useState,useEffect} from 'react'
import Cookies from 'js-cookie';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import $ from 'jquery'
import CustomModal from '../superadmin/CustomModal';
const headers={
  Authorization:`Bearer ${Cookies.get('token')}`
}

function UserManagement(props) {
    const [userList, setUserList] = useState([])
    const [fields, Getfields] = useState({});
    const [fieldsEdit, GetfieldsEdit] = useState({});

    const [userDetail,setUserDetail] = useState()

    const [selectedUserList,setSelectedUserList] = useState([])
    const [batchAllocationPopup,setBatchAllocationPopup]=useState(false)
    const [batchList, setBatchList] = useState([])
    const [selectedBatch,setSelectedBatch]=useState()

    const [batchForFilter,setBatchForFilter]=useState()

    useEffect(()=>{
      getBatchList() 
    },[])

    useEffect(() =>{
      if(batchForFilter){
        GetUserList() 
      }     
  },[batchForFilter])

  
  const GetUserList = () =>{
    if (Cookies.get('token')) {
      //let data=batchForFilter?`/${batchForFilter}`:''
      axios.get('https://api.ezioskillacademy.com/api/mentor/user/batch/'+batchForFilter,{headers:{
        Authorization:`Bearer ${Cookies.get('token')}`
      } })
        .then(res => {
          // console.log("response",res.data)  
          setUserList(res.data.data.users)  
                      
                  
        }).catch((err)=>{
            // console.log(err)
            alert("Something Wrong.. Please Login again...")
        
        })
    } else {
      GetUserList()
    }
  }

    function handleViewUser(id){
      axios.get(`https://api.ezioskillacademy.com/api/mentor/user/${id}`,{headers:{
        Authorization:`Bearer ${Cookies.get('token')}`
      } })
        .then(res => {
          // console.log("response",res.data)     
          setUserDetail(res.data.data.user)
         // setUserList(res.data.data)  
                      
                  
        }).catch((err)=>{
             // console.log(err)
            alert("Something Wrong.. Please Login again...")
        
        })
    }

 
  function getBatchList(){
    axios.get(`https://api.ezioskillacademy.com/api/mentor/batch`, {headers:{
      Authorization:`Bearer ${Cookies.get('token')}`
    } })
    .then(res => {
      // console.log("batch",res.data)
      setBatchList(res.data.data)    
    }).catch(e=>{
      alert(e.response.data.message)
    })
  }

  return (
    <div className="dash_page_bg dash_section_ptb add_payment">
     
        <div style={{width:'100%',display:'flex'}}>
   
        <select style={{padding:'10px',height:'45px',margin:'0 20px'}} onChange={(e)=>setBatchForFilter(e.target.value)}>
              <option disabled selected>Select Batch</option>
              {
                batchList.map((batch,i)=><option value={batch._id}>{batch.batch_name}</option>)
              }
              
            </select>
       
       
        </div>
       
        <br />
        <br />
        <div className="clientrate_table">
          <div className="tabcomp-style">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>               
                    <td>Name</td>
                    <td>Email</td>
                    <td>Phone Number</td>    
                    <td>Course Completed</td>    
                    {/* <td>Batch Allocation</td>     */}
                    {/* <td style={{width:'200px'}}></td>                    */}
                  </tr>
                </thead>
                <tbody>
                  {userList.length > 0 ? (
                    <>
                      {userList
                        .map((user, id) => {
                          let user1=user 
                          
                          return (                          
                            <tr key={id}>
                             
                              <td>{user1.name} </td>
                              <td>{user1.email}</td>
                              <td>{user1.phoneno}</td>  
                              <td>{user1.isCourseComplete?'Completed':'Not Completed'}</td>  
                              {/* <td style={{display:'flex',justifyContent:'center',width:'200px'}}>
                                
                                  <button onClick={()=>handleViewUser(user1._id)} className="green_btn">View</button>
                                  </td>                          */}
                            </tr>
                          )
                        }
                        
                      )}
                    </>
                  ) : (
                    <tr>
                      <td colSpan="7" style={{ textAlign: "center" }}>
                        {batchForFilter?'No Data':'Please Select the Batch'}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {/* pagination */}
        <div style={{ paddingTop: "32px" }}>
       
        </div>
        {/* pagination */}
        

     

      <CustomModal open={userDetail}>
        <div className="scsmdl-container" style={{ height: "auto" }}>
          <div className="scsmdl-header">
            <p>User Details</p>
            {/* <img src={'/modalclose.jpg'} className="scsmdl-closeimg"  /> */}
            <img
              src={"/modalclose.jpg"}
              className="scsmdl-closeimg"
              onClick={() => setUserDetail(undefined)}
              alt="close img"
            />
          </div>
          <div className="scsmdl-body" style={{ padding: "20px" }}>
          <div style={{display:'grid',gridTemplateColumns:'1fr 2fr'}}>
            <div><b>Name:</b></div>
            <div>{userDetail?.name}</div>

            <div><b>Phone No:</b></div>
            <div>{userDetail?.phoneno}</div>

            <div><b>Email:</b></div>
            <div>{userDetail?.email}</div>

            <div><b>Batch Allocated:</b></div>
            <div>{userDetail?.batch.isBatchAllocated?"Allocated":"Not Allocated"}</div>

            <div></div>
            <div></div>
          </div>
           
           
          </div>
        </div>
      </CustomModal>

     
      </div>
  )
}

export default UserManagement