import React from 'react'

export default function CheckIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14.242" height="10.133" viewBox="0 0 14.242 10.133">
      <path id="check" d="M4.779,9.9A.669.669,0,0,1,4.3,9.7L.2,5.592a.671.671,0,0,1,.948-.948L4.779,8.278,12.521.536a.671.671,0,0,1,.948.948L5.253,9.7a.669.669,0,0,1-.474.2Zm0,0" transform="translate(0.26 -0.023)" fill="#129a6c" stroke="#129a6c" strokeWidth="0.52" />
    </svg>

  )
}
