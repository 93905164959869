import React from "react";

export function LinkedinIcon() {
  return (
   
      <svg
        id="linkedin_2_"
        data-name="linkedin (2)"
        xmlns="http://www.w3.org/2000/svg"
        width="20.463"
        height="20.465"
        viewBox="0 0 20.463 20.465"
      >
        <path
          id="Path_14008"
          data-name="Path 14008"
          d="M21.709,21.58h.005V14.074c0-3.672-.79-6.5-5.083-6.5A4.456,4.456,0,0,0,12.618,9.78h-.06V7.917H8.489V21.579h4.238V14.814c0-1.781.338-3.5,2.543-3.5,2.173,0,2.206,2.033,2.206,3.618V21.58Z"
          transform="translate(-1.251 -1.116)"
        />
        <path
          id="Path_14009"
          data-name="Path 14009"
          d="M.4,7.977H4.639V21.639H.4Z"
          transform="translate(-0.058 -1.175)"
        />
        <path
          id="Path_14010"
          data-name="Path 14010"
          d="M2.457,0A2.469,2.469,0,1,0,4.915,2.457,2.458,2.458,0,0,0,2.457,0Z"
        />
      </svg>
   
  );
}

export function LinkedinLogo() {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        viewBox="0 0 40 40"
      >
        <g id="in" transform="translate(-505 -452)">
          <circle
            id="Ellipse_373"
            data-name="Ellipse 373"
            cx="20"
            cy="20"
            r="20"
            transform="translate(505 452)"
            fill="#127094"
          />
          <g
            id="linkedin_2_"
            data-name="linkedin (2)"
            transform="translate(515 462)"
          >
            <path
              id="Path_14008"
              data-name="Path 14008"
              d="M21.709,21.58h.005V14.074c0-3.672-.79-6.5-5.083-6.5A4.456,4.456,0,0,0,12.618,9.78h-.06V7.917H8.489V21.579h4.238V14.814c0-1.781.338-3.5,2.543-3.5,2.173,0,2.206,2.033,2.206,3.618V21.58Z"
              transform="translate(-1.251 -1.116)"
              fill="#fff"
            />
            <path
              id="Path_14009"
              data-name="Path 14009"
              d="M.4,7.977H4.639V21.639H.4Z"
              transform="translate(-0.058 -1.175)"
              fill="#fff"
            />
            <path
              id="Path_14010"
              data-name="Path 14010"
              d="M2.457,0A2.469,2.469,0,1,0,4.915,2.457,2.458,2.458,0,0,0,2.457,0Z"
              fill="#fff"
            />
          </g>
        </g>
      </svg>
    </div>
  );
}
