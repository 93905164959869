import React from 'react'

function AddIcon() {
    return (
       
            
<svg id="add_1_" data-name="add (1)" xmlns="http://www.w3.org/2000/svg" width="29.982" height="29.982" viewBox="0 0 29.982 29.982">
  <g id="Group_4560" data-name="Group 4560">
    <g id="Group_4559" data-name="Group 4559">
      <path id="Path_14569" data-name="Path 14569" d="M14.991,0A14.991,14.991,0,1,0,29.982,14.991,15.008,15.008,0,0,0,14.991,0Zm0,27.66A12.669,12.669,0,1,1,27.66,14.991,12.684,12.684,0,0,1,14.991,27.66Z" fill="#438fff"/>
    </g>
  </g>
  <g id="Group_4562" data-name="Group 4562" transform="translate(8.024 7.919)">
    <g id="Group_4561" data-name="Group 4561">
      <path id="Path_14570" data-name="Path 14570" d="M149.8,141.044h-4.645V136.4a1.161,1.161,0,1,0-2.322,0v4.645h-4.645a1.161,1.161,0,1,0,0,2.322h4.645v4.645a1.161,1.161,0,1,0,2.322,0v-4.645H149.8a1.161,1.161,0,1,0,0-2.322Z" transform="translate(-137.022 -135.238)" fill="#438fff"/>
    </g>
  </g>
</svg>

       
    )
}

export default AddIcon
