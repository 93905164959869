import React, { useEffect, useState } from 'react'
import $ from 'jquery'
import { Backdrop, Button, Fade, FormControl, FormControlLabel, InputLabel, makeStyles, MenuItem, Modal, Radio, RadioGroup, Select, TextField,CircularProgress } from '@material-ui/core';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import PopupClose from '../../images/iconsSVG/PopupClose';
import axios from 'axios';
import Arrow from '../../images/iconsSVG/Arrow';
import PackagePopup from './packagePopup';
import {useSelector,useDispatch} from 'react-redux'
import Cookies from 'js-cookie';
import Toaster from '../toaster';


const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }));

export function Header(props) {
 
    const [openRegisterPopup,setOpenRegisterPopup]=useState(false)

    const [url,setUrl]=useState('/')
  
   
    useEffect(()=>{
      window.scrollTo(0,0)
      
      window.onscroll = function () {
        // console.log(document.documentElement.scrollTop)
      
        if (document.documentElement.scrollTop > 630) {
          $("#scrollTopId").css({
            transform: "scale(1)",
           // border: "solid 2px var(--blu)",
          });
        } else {
          $("#scrollTopId").css({
            transform: "scale(0)",
          //  border: "transparent",
          });
        }
      };

      setUrl(window.location.pathname)

    

    },[])
      
      function handleToggle() {
        $("#nav_con_id").toggleClass("nav_view");
      }
      // function handleClose(){
      //   setOpenRegisterPopup(false)
      // }
      
      const handleNac = (num) => {
        // const anchor = document.createElement("a");
        // let secId = `#sec${num}`;
        // // console.log(secId);
        // anchor.href = secId;
        // anchor.click();
        handleToggle()
        // console.log(window.pageYOffset);
        setTimeout(() => {
          window.scrollTo(0, window.pageYOffset - 100);
        }, 1000);
      };
  function scrollToTop(){
    window.scrollTo(0,0)
  }
  return (
    <>
    <section className="container-fluid website_header">
<div className="header_inner">
    <a href='/'><img alt='seo course in Coimbatore' src={require('../../images/website/ezio_academy_logo.svg')} /></a>    
    
    <ul id="nav_con_id" className="nav_con">
    {/* <li className="nav_link "><a href="/">Home</a></li> */}
        {/* <li className="nav_link"><a href="#">About</a></li>
        <li className="nav_link"><a href="#">For Students</a></li>
   
        <li className="nav_link"><a href="#">For Professionals</a></li>
      
        <li className="nav_link"><a href="#">For Entrepreneur</a></li> */}
        {
          // console.log("headerss",props)
        }
        {
          url==='/'?(<>
           <li className="nav_link" onClick={()=>handleNac(1)}><a href="#highlights">Highlights</a></li>
        <li className="nav_link" onClick={()=>handleNac(2)}><a href="#syllabus">Syllabus</a></li>
   
        <li className="nav_link" onClick={()=>handleNac(3)}><a href="#case_study">Case Study</a></li>
        <li className="nav_link" onClick={()=>handleNac(4)}><a href="#pricing">Pricing</a></li>
        <li className="nav_link" onClick={()=>handleNac(5)}><a href="#certification">Certification</a></li>
          </>):url==='/terms-condition'?(
            <>
             <li className="nav_link" style={{flex:'0.8',}}><a href="/" id='terms_condition_header' style={{}}>Home</a></li>
            </>
          ):(
            <>
             <li className="nav_link"><a href="/">Home</a></li>
             <li className="nav_link" onClick={()=>handleNac(1)}><a href="#about">About</a></li>
             <li className="nav_link" onClick={()=>handleNac(2)}><a href="#skill_tools">Skill & Tools</a></li>
             <li className="nav_link" onClick={()=>handleNac(3)}><a href="#syllabus">Syllabus</a></li>
             <li className="nav_link" onClick={()=>handleNac(5)}><a href="#certification">Certification</a></li>
             <li className="nav_link" onClick={()=>handleNac(4)}><a href="#more_courses">More Courses</a></li>
            </>
          )
        }
       
        <li className="nav_link_btn_white"><a href="/login" target="_blank"><button>Sign In</button></a></li>
        <li className="nav_link_btn_green" onClick={()=>setOpenRegisterPopup(true)}><button className='ezskill_getstart'>Get Started</button></li>
    </ul>
    <button className="toggle_button" onClick={handleToggle}> <i className="fa fa-bars nav_fa_bars"></i>
        <i className="fa fa-times nav_fa_times" aria-hidden="true"></i>
    </button>
</div>

</section>
 
<RegisterPopup openRegisterPopup={openRegisterPopup} setOpenRegisterPopup={setOpenRegisterPopup}/>
<div id='scrollTopId' onClick={scrollToTop}>
<Arrow/>
</div>
    </>

  )
}


//-----------------------------------------------------------------------------------


export const RegisterPopup=(props)=>{
  const classes = useStyles();

  const [successMsg,setSuccessMsg] = useState();
  const [countryCode, setCountryCode] = useState();
  const [fields,setFields] = useState({});
  const [errors,setErrors] = useState({});
  const [loader,setLoader] = useState(false);

  const [packagePopup,setPackagePopup]= useState(false);

  const [tosOpen,setTosOpen]=useState()


  const reduxValue=useSelector(state=>state)
  const dispatch=useDispatch()


  // useEffect(()=>{
  //   setTimeout(()=>{
  //     setPackagePopup(true)
  //   },1000)
  // },[])

  const handleChange = (e) => {
    // console.log(e.target.name,e.target.value)
    setFields({ ...fields, [e.target.name]:e.target.value })
};
const setValuesPhone = (value, country) => {
  setFields({...fields,'phone':value})
  setCountryCode(country.dialCode);
};
function register(){
  // console.log({countryCode})
  if(validate()){
    setLoader(true)
    axios.post('https://api.ezioskillacademy.com/api/register',{
      "name": fields.name,
      "email": fields.email,
      "phoneno": countryCode=='91'?fields.phone.slice(2):fields.phone,
    //  "country_code":countryCode,
      // "college_name": "KIOT",
      // "year_of_passed_out": "2022",
      // "city": "Erode",
      // "district": "Erode",
      // "state": "Tamil Nadu",
      // "session": "morning",
      "price_plan": fields.interested_in,
      "login_type": fields.occupation,
      "comments": fields.comments,
    })
    .then(res=>{
      Cookies.set('token', res.data.data.token.access_token, { expires: 1 })
      // console.log("RESP",res.data.data.token.access_token)
      setSuccessMsg(res.data.message)
      setTosOpen(res.data.message)
      setLoader(false)
 
        props.setOpenRegisterPopup(false)
        setTimeout(() => {
         // setPackagePopup(true)
        }, 1000);
    
    })
    .catch(err=>{
      setSuccessMsg("Something wrong...Please try again")
    })
  }
}
function validate(){
    let errorss={}
    let formIsValid=true
    if(!fields['name']){
      errorss['name']="*Enter the name"
      formIsValid = false;
    }
    if(!fields['phone']){
      errorss['phone']="*Enter the phone number"
      formIsValid = false;
    }
    else{
      var pattern = new RegExp(/^[0-9\b]+$/);
      if (!pattern.test(fields['phone'])) {
        formIsValid = false;
        errorss['phone']= '*Please enter only number.';
        fields['phone'] = null;
      }
    }
   
    if(!fields['email'] || !/\S+@\S+\.\S+/.test(fields['email'])){
      errorss['email']="*Enter the email"
      formIsValid = false;
    }
    // else {
    //   var pattern = new RegExp(
    //     /^[a-zA-Z0-9][-a-z0-9.@%!#$%&'*+-=?^_`{|}~\/]+@[a-zA-Z0-9]+\.[A-Za-z]+$/,
    //   );
    //   if (!pattern.test(fields['email'])) {
    //     formIsValid = false;
    //     errors['email'] = '*Please enter valid Email.';
    //     fields['email'] = null;
    //   }
    // }
    
    // if(!fields['interested_in']){
    //   errorss['interested_in']="*Enter the interest"
    //   formIsValid = false;
    // }
    if(!fields['occupation']){
      errorss['occupation']="*Enter the occupation"
      formIsValid = false;
    }
    if(!fields['comments']){
      errorss['comments']="*Enter the comments"
      formIsValid = false;
    }
    // console.log({errors})
    setErrors(errorss)
    return formIsValid
}

  return(
    <>
    <Modal
      aria-labelledby="spring-modal-title"
      aria-describedby="spring-modal-description"
      className={classes.modal}
      open={props.openRegisterPopup}
      onClose={()=>props.setOpenRegisterPopup(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.openRegisterPopup}>
        <div 
      
        className='register_popup'
        >
            <div onClick={()=>props.setOpenRegisterPopup(false)}>
            <PopupClose />
            </div>
        
         
          <div className='register_popup_inner_left'>
                <img src={require('../../images/website/Right-side-image.png')}/>  
                <h1>You are One Step Closer to your Digital Marketing Dream </h1>    
                <p>Become a Digital Marketing Expert! Get Ready to Witness the Latest Trending Technology that is booming in the world right now. </p> 
          </div>
          <div className='register_popup_inner_right'>
          <h1>Start Learning Digital Marketing Today!  </h1> 

          {/* <TextField size="small" fullWidth className="col-ls-12" id="outlined-basic" label="Full Name" variant="outlined" /> */}

                <input type="text" placeholder="Full Name" name="name" onChange={handleChange}/>
                <p style={{color:'red',margin:'0'}}>{errors.name}</p>
            <PhoneInput
                          className="col-ls-12"
                          country="in"
                          enableSearch
                          disableSearchIcon
                          placeholder="Phone Number"
                          inputStyle={{
                            width: '100%',
                            padding: '22px 42px',
                            // border: phonenumberErr
                            //   ? 'red solid 2px'
                            //   : 'solid 1px #CACACA',
                          }}
                          // inputClass={sp.inputphonenum}
                          // className="usermobile"
                          preferredCountries={['in', 'us', 'sg']}
                          priority={{ in: 0, us: 1, sg: 2 }}
                          onChange={(value, country) =>
                            setValuesPhone(value, country)
                          }
                          //  value={phone}
                        />
                         <p style={{color:'red',margin:'0'}}>{errors.phone}</p>

            <input type="email" name="email" placeholder="Email Address" onChange={handleChange}  />
            <p style={{color:'red',margin:'0'}}>{errors.email}</p>
            
            {/* <select onChange={handleChange} name="interested_in">
                <option disabled selected>Interested In*</option>
                <option>Package Plan</option>
                <option>Custom Plan</option>
                <option>Individual Plan</option>
            </select>
            <p style={{color:'red',margin:'0'}}>{errors.interested_in }</p> */}
            
            <FormControl component="fieldset">     
              <RadioGroup aria-label="occupation" name="occupation" onChange={handleChange} style={{flexDirection:'row',justifyContent:'space-between'}}>
                <FormControlLabel value="student" control={<Radio />} label="Student" style={{color:'#F14A51 !important'}}/>
                <FormControlLabel value="professional" control={<Radio />} label="Professional" />
                <FormControlLabel value="entrepreneur" control={<Radio />} label="Entrepreneur" />
              </RadioGroup>
            </FormControl>
            <p style={{color:'red',margin:'0'}}>{errors.occupation}</p>

            <textarea placeholder='Your Comments' name="comments" onChange={handleChange} />
            <p style={{color:'red',margin:'0'}}>{errors.comments}</p>
          
               
            <Button variant="contained" onClick={register}>
              {loader?<CircularProgress color="success" style={{width:'25px',height:'25px'}}/>:'Register'}
            </Button>
            {successMsg&&<p style={{color:'green'}}>{successMsg}</p>}
          </div>

        </div>
      </Fade>
    </Modal>
    <PackagePopup packagePopup={packagePopup} setPackagePopup={setPackagePopup}/>
    <Toaster tosOpen={tosOpen} setTosOpen={setTosOpen}/>
    </>
  )
}