import React from 'react'

function RedArrow() {
  return (
<svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.27417 17.3177C7.04665 17.319 6.8218 17.2687 6.6165 17.1706C6.41121 17.0725 6.2308 16.9292 6.08882 16.7514L1.92782 11.6541C1.731 11.413 1.60552 11.1217 1.56556 10.813C1.52561 10.5043 1.57278 10.1907 1.70173 9.9074C1.83069 9.62414 2.03629 9.38258 2.29532 9.21003C2.55434 9.03747 2.85646 8.9408 3.16754 8.93093C3.18541 8.93038 3.20338 8.93009 3.22129 8.93009C3.63651 8.92986 4.03682 9.08484 4.34364 9.3646L7.16583 11.9328C7.24655 12.0063 7.35177 12.047 7.46092 12.047C7.46881 12.047 7.4767 12.0468 7.48459 12.0463C7.5426 12.0432 7.59941 12.0286 7.65172 12.0033C7.70402 11.978 7.75078 11.9426 7.78929 11.8991L16.4429 2.12184C16.4467 2.11753 16.4504 2.11314 16.4541 2.10869C16.4962 2.05701 16.5487 2.01463 16.608 1.9842C16.6674 1.95377 16.7324 1.93596 16.799 1.93188C16.8106 1.93111 16.8226 1.93067 16.8346 1.93067C16.9584 1.93006 17.0778 1.97705 17.168 2.06192C17.3333 2.21498 17.3872 2.47575 17.2859 2.6314C17.2842 2.63404 8.63881 16.5574 8.63881 16.5574C8.34146 17.0115 7.87552 17.2877 7.35996 17.3154C7.33139 17.3169 7.3026 17.3177 7.27432 17.3177H7.27417Z" fill="#F14A51"/>
<path d="M16.8347 2.36911C16.8413 2.36883 16.8479 2.36999 16.854 2.37251C16.8601 2.37504 16.8656 2.37886 16.8701 2.38373C16.8824 2.39601 16.8913 2.41137 16.8958 2.42823L8.26908 16.3214C8.04587 16.6599 7.71483 16.8573 7.33618 16.8777C7.31543 16.8788 7.29449 16.8793 7.27389 16.8793C7.11149 16.88 6.95104 16.8439 6.80459 16.7738C6.65813 16.7036 6.52948 16.6011 6.42828 16.4741L2.26728 11.377C2.12212 11.1993 2.02957 10.9845 2.00009 10.7569C1.9706 10.5293 2.00536 10.298 2.10043 10.0892C2.19549 9.88029 2.34708 9.70217 2.53806 9.57494C2.72905 9.44771 2.95181 9.37643 3.18118 9.36915C3.19455 9.36874 3.20795 9.36853 3.22137 9.36853C3.52745 9.36838 3.82253 9.48264 4.04867 9.6889L6.87087 12.2571C7.03231 12.404 7.24275 12.4854 7.46104 12.4854C7.47678 12.4854 7.49261 12.485 7.50854 12.4842C7.62455 12.4779 7.73815 12.4486 7.84276 12.3981C7.94736 12.3475 8.04088 12.2767 8.11789 12.1897L16.7713 2.41241C16.7789 2.40379 16.7864 2.39502 16.7937 2.3861C16.7978 2.38076 16.8031 2.37646 16.8091 2.37355C16.8152 2.37063 16.8218 2.36919 16.8285 2.36933C16.8306 2.36933 16.8327 2.36911 16.8348 2.36911H16.8347ZM16.8348 1.49219C16.8128 1.49219 16.7909 1.49292 16.769 1.49446C16.6427 1.50257 16.5194 1.53667 16.4068 1.59462C16.2943 1.65257 16.1949 1.73312 16.115 1.83123L7.46093 11.6084L4.63873 9.0402C4.25147 8.68649 3.7457 8.49078 3.22122 8.49168C3.19874 8.49168 3.17621 8.49203 3.15363 8.49274C2.76081 8.5052 2.37931 8.62728 2.05223 8.84516C1.72514 9.06305 1.46551 9.36807 1.30267 9.72576C1.13983 10.0835 1.08027 10.4796 1.13071 10.8693C1.18116 11.2591 1.3396 11.627 1.58814 11.9314L5.74917 17.0285C5.93267 17.2562 6.16496 17.4398 6.4289 17.5658C6.69284 17.6917 6.9817 17.7568 7.27414 17.7561C7.31034 17.7561 7.34672 17.7551 7.38328 17.7532C8.04174 17.7178 8.63378 17.3684 9.00866 16.7928L17.6533 2.87068C17.8713 2.53595 17.789 2.03939 17.4659 1.74018C17.2946 1.58028 17.0688 1.49161 16.8345 1.49219H16.8348Z" fill="#ED1D25"/>
</svg>
  )
}

export default RedArrow