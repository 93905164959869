import React from 'react'

export default function TimesIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={12} height={23} viewBox="0 0 12 23">
      <text id="x" transform="translate(0 19)" fill="#f22323" fontSize={23} fontFamily="CircularStd-Book, Circular Std Book"><tspan x={0} y={0}>x</tspan></text>
    </svg>

  )
}
