import React , {useEffect} from 'react'
import Footer   from '../Footer/footer.js'
import Header  from  '../Header/Header.js'
import Bgvideo  from  './Bgvideo.js'

import $ from "jquery";



const Landing = () =>{

 
 var x=3;
 useEffect(() =>{
	 $('.set_wide').slice(0, 2).show();
 },[])
	
	const loadeMore =(e) =>{
			e.preventDefault();
			x = x+1;
			$('.set_wide').slice(0, x).slideDown();
			$('#loadMore').css('display', 'none');
			$('#load_Less').fadeIn();
	}
	
	const loadLess = (e) =>{
		e.preventDefault();
			$('.set_wide').slice(0, 1).slideUp();
			$('#load_Less').css('display', 'none');
			$('#loadMore').fadeIn();
	}
 
	
	return(
	    <div>
		<Header />

		<Bgvideo />
		
		    {/* <div id="myCarousel" className="" >
				  <div className='container'>
					<div className='col-lg-12 banner_content'>
					<div className='be_content_set'>
							<h1>Become a Digital Marketing Expert</h1>
							<p className='nurture_set'>Learn the practices & plans, of the world’s most efficient digital marketers.</p>
							 
							<ul id="basicUsage">
								<li>
									<p>11</p>
									<p>Days</p>
								</li>
								<li>
									<p>00</p>
									<p>Hrs</p>
								</li>
								<li>
									<p>00</p>
									<p>Mins</p>
								</li>
								<li>
									<p>00</p>
									<p>Sec</p>
								</li>
							</ul>
							 

							<div className='entroll_but'>
							  <div className='al_center'>
								<button className='syllabus btn btn-default'>Download Syllabus</button>
								<button className='entroll btn btn-default'>Entroll Now</button>
							</div>
							</div>
							
							<div className='pop_video'>
								<a href='images/promo.mp4?autoplay=1' className='video'>
									  <img src='images/play.png' alt='' />
									</a>
							</div>
							
				</div>
					</div>
						<video autoplay="" muted="" loop="" id="myVideo">
						  <source src="images/promo.mp4" type="video/mp4" />
						  Your browser does not support HTML5 video.
						</video>
						 
					</div>

					  
				</div> */}
				
				<div id="banner_bottom" className="cont_block  text-center">
					 <div className='col-lg-12 padding_none bg_grey'>
					 <div className='collabration_set'>
								<img src={require('../../images/online_course.png')} alt='' />
								<h1 className='on_set'>Online Course</h1>
							</div>
						<div className='row bot_in'>
						<div className='time_set row m-0 col-lg-12 col-md-12 col-sm-12 col-xs-12 '>
							<div className='col-lg-4 time_res col-md-4 col-sm-4 col-xs-12 '>
							 
								<div className='margin_set'>
									
									<div className='set_img note_pad'>
										<img src={require('../../images/learning.png')} alt='' />
									</div>
									<div className='pre_desc'>
										<h3>Executive learning</h3>
										<p>Learn DM Basics, PPC Campaign, Hands -on tools</p>
									</div>
								</div>
							</div>
							<div className='col-lg-4 time_res col-md-4 col-sm-4 col-xs-12'>
							
								<div className='margin_set'>
									<div className='set_img'>
										<img src={require('../../images/knowledge.png')} alt='' />
									</div>
									<div className='pre_desc'>
										<h3>Management knowledge</h3>
										<p>Learn to Build Links, Run Campaigns with live projects</p>
									</div>
								</div>
								
							</div>
							<div className='col-lg-4 col-md-4  time_res col-sm-4 col-xs-12'>
							 
								<div className='margin_set'>
									<div className='set_img'>
										<img src={require('../../images/Skills.png')} alt='' />
									</div>
									<div className='pre_desc'>
										<h3>Leadership skills</h3>
										<p>Learn to plan budget, monitor and optimize results</p>
									
									</div>
								</div>
							</div>
							</div>
							</div>
						</div>
						<div className='col-lg-12 padding_none collabe_with '>
							<div className='container'>
								<div className='collabration_set'>
								<img src='images/hand.png' alt='' />
								<h1>In Collaboration With</h1>
							</div>
							<div className='col-lg-12 col-sm-12 social_set'>
								<ul>
									<li><img src={require('../../images/hubspot.png')} alt='' /></li>
									<li><img src={require('../../images/mailchimp.png')} alt='' /></li>
									<li><img src={require('../../images/hootsuite.png')} alt='' /></li>
									<li className='border_unset'><img src={require('../../images/fbp.png')} alt='' /></li>
								</ul>
							</div>
							</div>
							<div id="syllabus"></div>
							</div>
					
				</div>
			    <div id="what_learn" className="cont_block">
						<div className='collabration_set'>
										<img src={require('../../images/learn.png')} alt='' />
										<h1>What You Will Learn</h1>
									</div>
						  <div className="learn_back">
							  <div className='container'>
								
								<div className='train_set'>
									<div className='trv_desc'>
										
										<h4>Let’s Explore Digital World</h4>
										<p>The prominence of digital marketing is growing leaps and bounds every day, and so is Digital marketing career opportunities. Ezio’s digital marketing course is indeed a great way to get a head start on your digital marketing career and make you stand out. With our training classes, we help you go beyond the hype and learn how to put together online marketing strategies for the better establishment of your career.</p>
										<button className="entroll btn btn-default">Download Syllabus</button>
									</div>
								</div>
							</div>
						  </div>
						  
						  <div className='perequiste'>
						  <div className="container">
							<div className="page-header">
								<h1 id="">Perequiste Knowledge</h1>
								<p>Student Should have Basic Computer Skils And Be Comfortable Navigating Online.</p>
							</div>
							<div id="timeline">
							<div className="row timeline-movement first_setting"></div>

				 

						<div className="row timeline-movement">


							<div className="timeline-badge"></div>
							 <div className="timeline-badge three_set"></div>
							<div className="timeline-badge-two"></div>
							<div className="timeline-badge-two three_set"></div>

							<div className="col-sm-6   timeline-item">
								<div className="row">
									<div className="col-sm-11">
										<div className="timeline-panel credits">
											<ul className="timeline-panel-ul">
												<li className='shade_img'><img src={require('../../images/voice.png')}  alt='' /></li>
												<li><h2>01.Marketing fundamentals</h2></li>
												<li><p>In this program, you will get to discover the core basics of marketing and the way it evolved. Also,
						we bring you a deeper understanding of marketing to help you plan efficacious strategies and gain
						real real-world insights.</p> </li>
											</ul>
										</div>

									</div>
								</div>
							</div>

							<div className="col-sm-6   off_set timeline-item">
								<div className="row">
									<div className="col-sm-11">
										<div className="timeline-panel credits">
											<ul className="off_set_ul timeline-panel-ul">
												<li className='shade_img'><img src={require('../../images/content.png')} alt='' /></li>
												<li><h2>02.Content strategy</h2></li>
												<li><p>Here you will learn how to effectively plan your content marketing and specifically, how to develop,
						organize, and implement a content marketing strategy. Also, get to know how to put these into
						practice in building your brand through content marketing.
						</p> </li>
											</ul>
										</div>

									</div>
								</div>
							</div>


						</div>
						<div className="row timeline-movement">


						  <div className="timeline-badge"></div>
						   <div className="timeline-badge three_set"></div>
							<div className="timeline-badge-two"></div>
							<div className="timeline-badge-two three_set"></div>

							<div className="col-sm-6   timeline-item">
								<div className="row">
									<div className="col-sm-11">
										<div className="timeline-panel credits">
											<ul className="timeline-panel-ul">
												<li className='shade_img'><img src={require('../../images/social.png')} alt='' /></li>
												<li><h2>03.Social media marketing</h2></li>
												<li><p>As you know social media is a boon for marketing. From social media marketing origin to
						mainstream trends, here we will explore more about social media platforms, how to build social
						media presence and create strategies.
						 </p> </li>
											</ul>
										</div>

									</div>
								</div>
							</div>

							<div className="col-sm-6   off_set timeline-item">
								<div className="row">
									<div className="col-sm-11">
										<div className="timeline-panel credits">
											<ul className="off_set_ul timeline-panel-ul">
												<li className='shade_img'><img src={require('../../images/facebook_set.png')} alt='' /></li>
												<li><h2>04.Social media advertising</h2></li>
												<li><p>In this topic, we unpack several media advertising such as Facebook, Instagram, Linkedin, Twitter,
						and so on. .From basic campaigns to advanced advertising and audience targeting, learn how to
						precisely advertise on major social media platforms.</p> </li>
											</ul>
										</div>

									</div>
								</div>
							</div>


						</div>
						<div className="row timeline-movement">


						  <div className="timeline-badge"></div>
						   <div className="timeline-badge three_set"></div>
							<div className="timeline-badge-two"></div>
						<div className="timeline-badge-two three_set"></div>
							<div className="col-sm-6   timeline-item">
								<div className="row">
									<div className="col-sm-11">
										<div className="timeline-panel credits">
											<ul className="timeline-panel-ul">
												<li className='shade_img'><img src={require('../../images/search.png')} alt='' /></li>
												<li><h2>05.Search engine optimization</h2></li>
												<li><p>Here we are intended to make you learn how to optimize the website for search engine presence
						through on-page and off-page practices. This comprises of knowing and researching target keyword
						list, optimizing website UX and design and executing link building campaign. </p> </li>
											</ul>
										</div>

									</div>
								</div>
							</div>

							<div className="col-sm-6  off_set timeline-item">
								<div className="row">
									<div className="col-sm-11">
										<div className="timeline-panel credits">
											<ul className="off_set_ul timeline-panel-ul">
												<li className='shade_img'><img src={require('../../images/ppc.png')}alt='' /></li>
												<li><h2>06.PPC Advertising</h2></li>
												<li><p>Here we bring you the most preferred search engine marketing with a deeper understanding of ad
						campaign. We will guide you through each aspect of marketing for achieving findability.</p> </li>
											</ul>
										</div>

									</div>
								</div>
							</div>


						</div>
						<div className="row timeline-movement">


						   <div className="timeline-badge"></div>
							<div className="timeline-badge three_set"></div>
							
							<div className="col-sm-6    timeline-item">
								<div className="row">
									<div className="col-sm-11">
										<div className="timeline-panel credits">
											<ul className="timeline-panel-ul">
												<li className='shade_img'><img src={require('../../images/adwords.png')} alt='' /></li>
												<li><h2>07.Adwords</h2></li>
												<li><p>In this section, we will help you with various ways of advertising through text , display, shopping,
						videos and other advertising formats. We also help you understand how to set up a advertising
						through Google Adwords.</p> </li>
											</ul>
										</div>
									</div>
								</div>
							</div>

							 


						</div>
						<div id="our_program"></div>
						</div>
						</div>
						</div>
						</div>
						
				<div id="all_ourprogram" className="cont_block">
					  <div className="container whole_slide">
							<div className='collabration_set'>
									<img src={require('../../images/programe.png')} alt='' />
									<h1>All Our Program Include</h1>
								</div>	
								
								<div className='rating_slick'>
									   
											<div className='col-lg-4 sm_setting f_child'>
												 <div className='slide_img'>
													<img src={require('../../images/banner.png')} alt='' />
													<img src={require('../../images/rate.png')} alt='' />
												 </div>
												 <div className='content_set'>
													<h1>Learn & Explore Technique</h1>
													<p>Learn the digital marketing skills most in-demand today with our hugely interactive classes and
					real-world projects. Master every aspect of Digital marketing and make a big step in your career.</p>
												 </div>
											</div>
								 
											<div className='col-lg-4 sm_setting'>
												 <div className='slide_img'>
												<img src={require('../../images/banner.png')} alt='' />
													<img src={require('../../images/rate.png')} alt='' />
												 </div>
												 <div className='content_set'>
													<h1>Support from Effective Digital Marketer</h1>
													<p>Our expertized and well-versed mentors help you guide your learning and are focused on keeping
					the course interesting. Our experts help you advance your knowledge of building a digital
					marketing career</p>
												 </div>
											</div>
									  
					 
											<div  className='col-lg-4 sm_setting l_child'>
												 <div className='slide_img'>
													<img src={require('../../images/banner.png')} alt='' />
													<img src={require('../../images/rate.png')} alt='' />
												 </div>
												 <div className='content_set'>
													<h1>Merits</h1>
													<p>With renowned digital marketing expert classes, put your career in high-gear with the most
					comprehensive digital marketing course. Go ahead and pursue your digital career through a clear
					cut and professional learning.</p>
												 </div>
											</div>
									   
								</div>
											
					  </div>
					</div>
				
				
				<div id="tools" className="cont_block">
					<div className='container'>
					<div className='collabration_set'>
								<img src={require('../../images/tools.png')}  alt='' />
								<h1>Tools Used</h1>
							</div>	
						<div className='row set_wide'>
							<div className='col-lg-3 col-md-3 col-sm-3 col-xs-6'>
								<div className='set_small'><img src={require('../../images/fbsminsight.png')} alt='img' /></div>
							</div>
							<div className='col-lg-3 col-md-3 col-sm-3 col-xs-6'>
								<div className='set_small'><img src={require('../../images/fbads.png')} alt='img' /></div>
							</div>
							<div className='col-lg-3 col-md-3 col-sm-3 col-xs-6'>
								<div className='set_small'><img src={require('../../images/qlik.png')} alt='img' /></div>
							</div>
							<div className='col-lg-3 col-md-3 col-sm-3 col-xs-6'>
								<div className='set_small'><img src={require('../../images/youtubeanalytics.png')} alt='img' /></div>
							</div>
						</div>
						<div className='row set_wide'>
							<div className='col-lg-3 col-md-3 col-sm-3 col-xs-6'>
								<div className='set_small'><img src={require('../../images/ganalytics.png')}  alt='img' /></div>
							</div>
							<div className='col-lg-3 col-md-3 col-sm-3 col-xs-6'>
								<div className='set_small'><img src={require('../../images/gads.png')}  alt='img' /></div>
							</div>
							<div className='col-lg-3 col-md-3 col-sm-3 col-xs-6'>
								<div className='set_small'><img src={require('../../images/gadmanager.png')}  alt='img' /></div>
							</div>
							<div className='col-lg-3 col-md-3 col-sm-3 col-xs-6'>
								<div className='set_small'><img src={require('../../images/twitterads.png')}  alt='img' /></div>
							</div>
						</div>
						<div className='row set_wide'>
							<div className='col-lg-3 col-md-3 col-sm-3 col-xs-6'>
								<div className='set_small'><img src={require('../../images/buffer.png')} alt='img' /></div>
							</div>
							<div className='col-lg-3 col-md-3 col-sm-3 col-xs-6'>
								<div className='set_small'><img src={require('../../images/gdatastudio.png')} alt='img' /></div>
							</div>
							<div className='col-lg-3 col-md-3 col-sm-3 col-xs-6'>
								<div className='set_small'><img src={require('../../images/liads.png')} alt='img' /></div>
							</div>
							<div className='col-lg-3 col-md-3 col-sm-3 col-xs-6'>
								<div className='set_small'><img src={require('../../images/hootsm.png')}  alt='img' /></div>
							</div>
						</div>
						
						<button id='loadMore' onClick={e => loadeMore(e)} className='btn btn1 btn-success'>View More</button>
						<button id='load_Less' onClick={e => loadLess(e)} className='btn btn1 btn-success'>View less</button>
					</div>
					<div id="certification_sec"></div>
				</div>
				
				<div id="certification" className="cont_block">
					<div className='container'>
					<div className='collabration_set'>
								<img src={require('../../images/certification.png')} alt='' />
								<h1>Certification</h1>
							</div>	
						<div className=''>
							<div className='content_certi col-md-6 col-lg-6 col-sm-6 col-xs-12'>
								<ul>
									<li>
										<h3>Earn your certificate</h3>
										<p>Our Masters program is exhaustive and this certificate is proof that you have taken a big leap in mastering the domain.</p>
									</li>
									
									<li>
										<h3>Differentiate yourself with a Masters Certificate</h3>
										<p>The knowledge and skills you've gained working on projects, simulations, case studies will set you ahead of competition.</p>
									</li>
									
									<li>
										<h3>Share your achievement</h3>
										<p>Talk about it on Linkedin, Twitter, Facebook, boost your resume or frame it - tell your friends and colleagues about it.</p>
									</li>
								</ul>
							</div>
							<div className='col-lg-6 col-md-6 col-sm-6 col-xs-12'>
								<div className='certi_set'>
									<img src={require('../../images/SEO.png')} alt='certificatie' />
								</div>
							</div>
						</div>
					</div>
					</div>
<div id="degree_exp" className="cont_block">
	<div className='container'>
		<div className='row'>
			<div className='col-lg-12 col-md-12 whole_deg col-sm-12 col-xs-12'>
				<div className='digi_mrk'>
					<h3>Get Started With</h3>
					<h1>Digital Marketing Nanodegeree Program</h1>
					<div className='col-lg-12 col-md-12 res_setting col-sm-12col-xs-12'>
						<div className='col-lg-5 col-md-5 res_setting col-sm-5 col-xs-5'>
						   <h5><span className='sm_iconset'><img src={require('../../images/learn_small.png')} alt='' /></span>Study</h5>
						   <p>Learn and prove your digital knowledge with our course. Get proficiency in Digital marketing from
marketing experts and get certified. </p>
						</div>
						<div className='col-lg-7 col-md-7 res_setting col-sm-7 col-xs-7'>
						 <h5><span className='sm_iconset'><img src={require('../../images/smm_time.png')} alt='' /></span>Average Time</h5>
						 <p>On average, successful students take 3 months to complete this program.</p>
						</div>
					</div>
					<div className='col-lg-12 col-md-12 res_setting col-sm-12 col-xs-12'>
						<div className='col-lg-5 col-md-5 res_setting col-sm-5 col-xs-5'>
						 <h5> <span className='sm_iconset'><img src={require('../../images/sm_benifits.png')} alt='' /></span>Program Guarantees</h5>
						 <ul>
							<li>In-depth learning with interactive sessions and real-world projects</li>
							<li>Get hands-on with complete guidance from experts</li>
							<li>Career support and services from mentors</li>
						 </ul>
						</div>
						<div className='col-lg-7 col-md-7 col-sm-7 res_setting col-xs-7'>
						<h5><span className='sm_iconset'><img src={require('../../images/sm_group.png')} alt='' /></span>We’ve Got to Keep Winning, Stay Grounded</h5>
						 <ul>
							<li>Join us today to get special offers</li>
							<li>Start learning something new today </li>
							<li>Make a brighter career with our course </li>
						 </ul>
						</div>
					</div>
				</div>
			</div>
			<div className='sm_out col-md-12 col-sm-12 res_setting col-xs-12'>
				<div className='sm_box col-lg-5 col-md-5 col-sm-6  col-xs-6'>
					<h1>Lorum lipsum</h1>
					<p>Special offers available worldwide worldwide worldwide</p>
					<span><i className="fa fa-inr" aria-hidden="true"></i>22869</span><sub>per month</sub>
					<button className='btn btn1 btn-success'>Entroll Now</button>
				</div>
				<div className='sm_box col-lg-5 col-md-5 col-sm-6  col-xs-6'>
					<h1>Lorum lipsum</h1>
					<p>Special offers available worldwide worldwide worldwide</p>
					<span><i className="fa fa-inr" aria-hidden="true"></i>22869</span><sub>per month</sub>
					<button className='btn btn1 btn-success'>Entroll Now</button>
				</div>
			</div>
		</div>
	</div>
</div> 
<Footer />
		</div>
	)
}

export default Landing