import React from 'react'

export default function CaseStudyIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21.333" height="20" viewBox="0 0 21.333 20">
      <g id="Case_Study" data-name="Case Study" transform="translate(-14658 20724)">
        <g id="open-book" transform="translate(14658 -20740)">
          <path id="Path_13989" data-name="Path 13989" d="M20.708,76h-.625V89.125A1.913,1.913,0,0,1,18.167,91H12.435A1.876,1.876,0,0,1,8.9,91H3.167A1.913,1.913,0,0,1,1.25,89.125V76H.625A.625.625,0,0,0,0,76.625v15a.625.625,0,0,0,.625.625H7.648A1.879,1.879,0,0,0,9.417,93.5h2.5a1.879,1.879,0,0,0,1.768-1.25h7.023a.625.625,0,0,0,.625-.625v-15A.625.625,0,0,0,20.708,76Z" transform="translate(0 -57.5)" fill="#143059"/>
          <path id="Path_13990" data-name="Path 13990" d="M126,31.792V17.456A6.6,6.6,0,0,0,121.625,16a.625.625,0,0,0-.625.625v12.5a.625.625,0,0,0,.625.625A4.917,4.917,0,0,1,126,31.792Z" transform="translate(-115.958)" fill="#143059"/>
          <path id="Path_13991" data-name="Path 13991" d="M277.875,61a.625.625,0,0,0,.625-.625V46.625a.625.625,0,0,0-.625-.625h-5a3.1,3.1,0,0,0-1.875.641V61.625a.625.625,0,0,1,.625-.625Z" transform="translate(-259.708 -28.75)" fill="#143059"/>
          <path id="Path_13992" data-name="Path 13992" d="M61.625,46a.625.625,0,0,0-.625.625v13.75a.625.625,0,0,0,.625.625h5.66a3.894,3.894,0,0,0-3.16-1.25,1.877,1.877,0,0,1-1.875-1.875V46Z" transform="translate(-58.458 -28.75)" fill="#143059"/>
        </g>
      </g>
    </svg>
  )
}
