import React from "react";

function GroupIcon() {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="4.655"
        height="20.949"
        viewBox="0 0 4.655 20.949"
      >
        <g
          id="Group_4563"
          data-name="Group 4563"
          transform="translate(4467 4730)"
        >
          <g
            id="Group_4381"
            data-name="Group 4381"
            transform="translate(-11763 1187)"
          >
            <g
              id="more_1_"
              data-name="more (1)"
              transform="translate(7296 -5917)"
            >
              <g
                id="Group_4382"
                data-name="Group 4382"
                transform="translate(0 0)"
              >
                <g id="Group_4381-2" data-name="Group 4381">
                  <circle
                    id="Ellipse_374"
                    data-name="Ellipse 374"
                    cx="2.328"
                    cy="2.328"
                    r="2.328"
                    fill="#707070"
                  />
                </g>
              </g>
              <g
                id="Group_4384"
                data-name="Group 4384"
                transform="translate(0 8.147)"
              >
                <g id="Group_4383" data-name="Group 4383">
                  <circle
                    id="Ellipse_375"
                    data-name="Ellipse 375"
                    cx="2.328"
                    cy="2.328"
                    r="2.328"
                    fill="#707070"
                  />
                </g>
              </g>
              <g
                id="Group_4386"
                data-name="Group 4386"
                transform="translate(0 16.294)"
              >
                <g id="Group_4385" data-name="Group 4385">
                  <circle
                    id="Ellipse_376"
                    data-name="Ellipse 376"
                    cx="2.328"
                    cy="2.328"
                    r="2.328"
                    fill="#707070"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
}

export default GroupIcon;
