import React, { useEffect } from 'react'
import Arrow from '../../../../images/iconsSVG/Arrow'
import RedArrow from '../../../../images/iconsSVG/RedArrow'
import $ from 'jquery'
import Slider from "react-slick";
import { ourCourses } from '../data/our_courses';
import MoreCourse from './common/more_courses';


function Content_Marketing() {
  useEffect(()=>{
    const sectionAll = document.querySelectorAll("section");
            const observer = new IntersectionObserver(
              (entries) => {
                entries.forEach((entry) => {
                   
                  if (entry.isIntersecting) {
                    
                    entry.target.classList.add("scroll_ani");
                    
                  }
                });
              },
              {
                rootMargin: "-100px",
              }
            );
            sectionAll.forEach((section) => {
              observer.observe(section);
            });

    courseAccordian('1')
  },[])
  const courseSyllabus=[
    {
      id:'1',
      title:' What is Content Marketing? ',
      desc:`Content Marketing is one of the marketing techniques, it centers on creating and sharing informative, relevant, regular content to entice and hold a precisely defined audience, of course, to gain worthwhile customer action. `
    },
    {
      id:'2',
      title:' Why Content Marketing is Important? ',
      desc:`Content Marketing is worth, it since answers your people's questions, creates trust, initiates relationships, and boosts conversion. In the end, generate leads, yes through content marketing, you influence the customer's attention towards the attractive content. `
    },
    {
      id:'3',
      title:'What Can You Expect from Content Marketing?',
      desc:'You can receive lots and lots of benefits from content marketing. First, Google loves content yes it focuses on offering its people the search experiences that deliver what they are actually looking for. High-quality content boosts your visibility, enhances SEO traffic, and provides worth to customers.   '
    },
    {
      id:'4',
      title:'How to Craft Effective Content? ',
      desc:`Well, designed content will provide value to the readers that are informative, emotionally satisfying, entertaining, and socially valuable. All those mixed pieces of stuff will make the content effective and captivating. `
    },
    {
      id:'5',
      title: `Content Marketing for the Buying Process `,
      desc:`
      <b>Step1: Awareness</b><br/>
                    Your primary action is to figure out your customer's pain points.<br/>  
                    <b>Step2: Research  </b><br/>
                    Once the audience figures out there are solutions, they keep researching the right solutions. <br/> 
                    <b>Step3: Evaluation </b><br/>
                    In this stage, your customer undergoes an evaluation  <br/>
                    <b>Step4: Decision </b><br/>
                    At last, they'll make a decision.
                    `
    },
    {
      id:'6',
      title:'How to Begin Your Content Marketing? ',
      desc:`A winning content marketing campaign must be attainable and strong. To get started with content marketing, you need to check Identify your audience, stick with the right format, Perfect media to share Determine a strategy.  `
    },
    {
      id:'7',
      title:'The Role of Content Marketing in SEO ',
      desc:`Content is one of the cornerstones of SEO. Especially, web pages that contain textual, and visual are the crucial factor behind the traffic increase to the website. So, the content has to be instructive, effective, structured in perfect format, and easily digestible. `
    },
  ]
  function courseAccordian(id){
    $('.course_syllabus_inner_left ul li').removeClass('course_syllabus_acc_active')
    $('#course_syllabus_acc'+id).addClass('course_syllabus_acc_active')

  }
  const recognized_certification=[
    {
        id:'1',
        name:'Ezio Skill Academy Certificate',
        desc:'Master the Talents You Required for becoming A Marketing Expert in Any verticals across the globe. Experiential learning through advance syllabus, case studies & live project centric. ',  
        img:'Ezio-Skill-Academy.jpg',
    },
    {
        id:'2',
        name:'Ezio Audit Tool Certificate  ',
        desc:'Expertise in search engine audit tools, our hands-on training let you easily analyze the website and know what is working and what is not. Experience the site audit tool usage and turn the website weaker side into a stronger one.  ',  
        img:'Ezio-Skill-Academy.jpg',
    },
    {
        id:'3',
        name:'LinkedIn Certificate',
        desc:'Become exceptionally skilled and gain the knowledge of DM strategy to transform you into a “Qualified Digital Marketer”.',  
        img:'linkedi-certificate-1.jpg',
    },
    {
        id:'4',
        name:'HubSpot Academy Certificate   ',
        desc:'The candidates will gain top-notch skills in implementing digital marketing strategies with high-standard materials and training exercises. A digital marketing certificate from HubSpot Academy is recognized by the universities.   ',  
        img:'hubspot-academy.jpg',
    },
    {
        id:'5',
        name:'Facebook Blueprint Certificate  ',
        desc:'Earn Facebook blueprint certified "digital marketing associate” becoming an expert level proficiency in Facebook advertising. This is excluded in the Course cost & separate cost will be applicable for this certification ',  
        img:'Facebook-Blueprint.jpg',
    },
    {
        id:'6',
        name:'Google Analytics   ',
        desc: `Qualified in fundamental and advanced Google analytics concepts and Google's measurement tools. Google Analytics Certificates make provision for implementing SEO strategy through learning high-in-demand skills.  `,  
        img:'Google-Analytics.jpg',
    },
    {
        id:'7',
        name:'Google Ads  ',
        desc:'It is an industry-recognized marketing certificate from Google. Professional accreditation is provided by Google. Recognized you as a master in Google Ads.     ',  
        img:'adwords.jpg',
    },
    {
        id:'8',
        name:'Bing Ads   ',
        desc:'Knowing the hack to push your business forward from the effective Microsoft advertising campaigns and enhance your skillset on ads through this program.  With the Bind ads certification, upskill your Microsoft advertising proficiency. ',  
        img:'Bing-Ads-certification.jpg',
    },
]

const certificate_settings = {
  dots: true,
  infinite: true,
  speed: 500,
  autoplay: false,
  slidesToShow: 1,
  slidesToScroll: 1
};


  return (
    <div id='courses'>
    <section className="banner_sec">     
<div className="container">
 <div className="row">
           <div className="col-lg-6 col-md-6 centered_content website_left_inner_banner">
                {/* <p>Academic &gt; &nbsp;<span>Digital Marketing </span></p> */}
                <h1>
                Content Marketing  
                </h1> 
                <p style={{margin:'20px 0'}}> 
                In this content marketing course, you'll grasp the best marketing content techniques. Understand how to create engaging content to drive visitors to your desired page or site. Become a specialist in content marketing. 
                </p>
                <div style={{display: "flex"}}>
                  <a href='#syllabus'>
                    <button className="btn btn-outline-primary my-2 my-sm-0 ml-3 active_btn apply_now_btn" type="submit"> Explore Course </button> 
                    </a>
                </div> 
            </div>

            <div className="col-lg-6 col-md-6" >
                <img className='website_banner_standing_girl' src={require('../../../../images/website/Courses_page/women-png.png')} alt='' style={{display:'block',margin:'auto'}} />
                <img className='banner_round_icon' src={require('../../../../images/website/Courses_page/1.png')} alt='' style={{left:'8%',top:'10%',filter: 'drop-shadow(0px 9px 14px rgba(50, 50, 50, 0.15))'}}/>
                <img className='banner_round_icon' src={require('../../../../images/website/Courses_page/archer.png')} alt='' style={{right:'10%',top:'16%'}}/>
                <img className='banner_round_icon' src={require('../../../../images/website/Courses_page/peoples.png')} alt='' style={{right:'5%',bottom:'10%',height:'65px'}}/>
                <img className='banner_round_icon' src={require('../../../../images/website/Courses_page/analytics.png')} alt='' style={{left:'15%',bottom:'5%',height:'65px',filter: 'drop-shadow(0px 9px 14px rgba(50, 50, 50, 0.15))'}}/>
              </div>
 </div>
</div>
</section>


<section id='about' className='about_free_certification'>
  <div className='container'>
      <div className='row' style={{display:'flex',flexWrap:'wrap-reverse',justifyContent:'center'}}>
      {/* <div className='col-lg-6'>
        <div className='about_free_certification_left_inner'>
            <div className='hos_small_box'>
              <h3>130+</h3>
              <p>Lorem Etiam vel ullamcorper augue</p>
            </div>
            <div className='hos_small_box'>
              <h3>130+</h3>
              <p>Lorem Etiam vel ullamcorper augue</p>
            </div>
            <div className='hos_small_box'>
              <h3>130+</h3>
              <p>Lorem Etiam vel ullamcorper augue</p>
            </div>
            <div className='hos_small_box'>
              <h3>130+</h3>
              <p>Lorem Etiam vel ullamcorper augue</p>
            </div>
            </div>
      </div> */}
      <div className='col-lg-12'>
          <h2>About this Content Marketing Course   </h2>
          <p>In this modernistic marketing environment, nearly 90% of marketer's strategy is occupied by content marketing. Content Marketing has phenomenal advantages for businesses and entrepreneurs. You all know the importance of content in digital marketing, the ultimate aim of this marketing is to attract and engage the audience by developing and sharing relevant content. Well, the content marketing course begins with a brief description of what it is like and the impact of content marketing. Learn some of the effective examples of content marketing used currently. Along with how to create interesting content on different media platforms.  
            <br/><br/>
            This course will help you to strengthen your content marketing by creating stuff according to the buyer persona. As well as content marketing tools help you to craft trending content and give more insight into your target audience. 
          </p>
      </div>
      </div>
  </div>
</section>


<section id='skill_tools' className='skills_and_tools'>
  <div className='container'>
    <h2>Skills and Tools</h2>
    <p> This content marketing course will allow you to get the knowledge of the following skills which help to build a perfect content marketing strategy.    </p>
      <div className='row'>
      <div className='col-lg-6 '>
            <h3>Skills</h3>
            <ul className='skills_and_tools_inner'>
              <li><RedArrow/>Content Marketing Strategy    </li>
              <li><RedArrow/>Tools for Content Marketing </li>
              <li><RedArrow/>User Behavior      </li>
              <li><RedArrow/> Craft Appropriate Content    </li>
              <li><RedArrow/> Content Marketing Channel   </li>
              <li><RedArrow/> Increase Brand awareness  </li>
              <li><RedArrow/>Boost Traffic    </li>
              <li><RedArrow/>Buyer Persona  </li>
            </ul>
    
      </div>
      <div className='col-lg-6'>
          <h3>Tools</h3>
          <div className='skill_tools_con'>
        
            <div className='skill_tools'>
                <img src={require('../../../../images/website/Tools-you-wil-master/google-ads.png')} alt=""/>
            </div>
            <div className='skill_tools'>
                <img src={require('../../../../images/website/Tools-you-wil-master/google-anylitsis.png')} alt=""/>
            </div>  
            <div className='skill_tools'>
                <img src={require('../../../../images/website/Tools-you-wil-master/fb-ads.png')} alt=""/>
            </div>
            <div className='skill_tools'>
                <img src={require('../../../../images/website/Tools-you-wil-master/google-tag-manager.png')} alt=""/>
            </div>
            <div className='skill_tools'>
                <img src={require('../../../../images/website/Tools-you-wil-master/linkedin-ads.png')} alt=""/>
            </div>
            <div className='skill_tools'>
                <img src={require('../../../../images/website/Tools-you-wil-master/google-search-console.png')} alt=""/>
            </div>
            <div className='skill_tools'>
                <img src={require('../../../../images/website/Tools-you-wil-master/twitter-ads.png')} alt=""/>
            </div>
            <div className='skill_tools'>
                <img src={require('../../../../images/website/Tools-you-wil-master/mailchimp.png')} alt=""/>
            </div>
        </div>
        </div>
      </div>
  </div>
</section> 

<section id='syllabus' className='course_syllabus'>
  <div className='container'>
      <div className='row' style={{display:'flex',flexWrap:'wrap-reverse'}}>
      <div className='col-lg-6 course_syllabus_inner_left'>
            <ul>
              {
                courseSyllabus.map((course)=>(
                  <li id={'course_syllabus_acc'+course.id} onClick={()=>courseAccordian(course.id)} key={course.id}>
                  <h4> {course.title} </h4>

                    
                <p dangerouslySetInnerHTML={{ __html: course.desc }}></p>
                  </li>
                ))
              }
              
            </ul>
    
      </div>
      <div className='col-lg-6' style={{display:'flex',flexDirection:'column',justifyContent:'center'}}>
         <h2>Course Syllabus</h2>
         <p>Introduction to Content Marketing, Content Marketing Techniques, Impact of Content Marketing, Advantage of Content Marketing, Steps for Content Marketing in Buying Process, How to Craft Effective Content. </p>
         <a href='/Ezio_Skill_Academy.pdf' download>
         <button>Download Syllabus</button>
         </a>
      </div>
      </div>
  </div>

</section>

<section id='certification' className="website_certificate section_spaces">

    <div className="container">
        <h2>  Certificate   </h2>
        <p>This content marketing course makes you an expert in multiple aspects of planning and executing content. Learn the finest practices to frame effective content marketing strategy. Once you have done the course, you’ll receive the content marketing certificate from Ezio Skill Academy. It is shareable and can be uploaded to your CV.  </p>
    <Slider {...certificate_settings}>
        {
            recognized_certification.map((certi,index)=>(
        <div key={index}>
        <div className='row'>
            <div className='col-lg-6 website_certificate_left_inner'>
                <h2>{certi.name}</h2>
                <p>{certi.desc}</p>
            </div>
            <div className='col-lg-6'>
                <img src={require(`../../../../images/website/certificate/${certi.img}`)} alt=""/>
            </div>
        </div>     
        </div> 
            ))
        }
        
      </Slider>
        </div>
    </section>

    <MoreCourse url={'content-marketing'}/>


</div>
  )
}

export default Content_Marketing



{/* <section className=''>
  <div className='container'>
      <div className='row'>
      <div className='col-lg-6 '>
            
    
      </div>
      <div className='col-lg-6'>
         
      </div>
      </div>
  </div>

</section> */}