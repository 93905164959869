import React from 'react'

export default function EzioIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="129.628" height="39.108" viewBox="0 0 129.628 39.108">
      <g id="ezio_logo" data-name="ezio logo" transform="translate(-68.001 -21.136)">
        <path id="Path_2" data-name="Path 2" d="M130.381,165.769A19.553,19.553,0,1,0,149.846,185.4,19.639,19.639,0,0,0,130.381,165.769Zm5.062,26.932c-.569,0-1.138.02-1.7-.012a3.331,3.331,0,0,0-2.311.729c-.849.648-1.749,1.229-2.631,1.835a3.193,3.193,0,0,1-.393.2l-.109-.1c.076-.172.146-.347.229-.516.786-1.56,1.586-3.11,2.337-4.685a1.176,1.176,0,0,1,1.236-.737c3.124.02,6.246.012,9.369.012a3.635,3.635,0,0,0,.718-.028,1.966,1.966,0,0,0,.726-.262,1.008,1.008,0,0,0-.177-1.884,3.145,3.145,0,0,0-1.147-.206c-1.7-.022-3.408-.012-5.112-.012h-17.1c-.275,0-.549-.026-.883-.043a4.171,4.171,0,0,1,.131-.5c.282-.709.585-1.412.856-2.125a.8.8,0,0,1,.844-.581h.655q10.186,0,20.373.008c.459,0,.918.018,1.374.059a4.411,4.411,0,0,1-.148,8.807C140.2,192.728,137.822,192.69,135.443,192.7Zm-21.728-7.431a7.663,7.663,0,0,1,.468-2.49,6.238,6.238,0,0,1,3.086-3.338,10.538,10.538,0,0,1,4.85-1.2q2.786-.018,5.569,0a2.243,2.243,0,0,0,1.4-.443c.991-.716,2-1.405,3-2.1.139-.1.292-.168.439-.252l.121.1c-.073.17-.131.347-.22.512-.786,1.573-1.6,3.146-2.372,4.74a1.1,1.1,0,0,1-1.127.7c-2.184-.017-4.369,0-6.554-.008a7.8,7.8,0,0,0-3.186.633,3.549,3.549,0,0,0-2.148,4.222,3.1,3.1,0,0,0,1.253,1.932,5.934,5.934,0,0,0,3.559,1.135c2.139.013,4.28.01,6.423.021.277,0,.553.039.9.066a6.141,6.141,0,0,1-.052.627c-.131.686-.262,1.37-.416,2.053-.093.431-.211.524-.687.524-2.053,0-4.107.03-6.161-.029a9.459,9.459,0,0,1-5.039-1.442A6.388,6.388,0,0,1,113.714,185.27Zm32.018-3.9a5.263,5.263,0,0,1-.706.109c-.938.01-1.877,0-2.816,0H132.65a1.175,1.175,0,0,1-.94-.219l1.412-2.987c.18-.017.328-.043.477-.043,2.334,0,4.67,0,7.006.014a6.276,6.276,0,0,1,3.641,1.1A3.511,3.511,0,0,1,145.732,181.368Z" transform="translate(-42.719 -144.633)" fill="#fff"/>
      </g>
    </svg>

  )
}
