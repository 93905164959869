import React from 'react'

export default function InviteFrnIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="59.061" height="59.061" viewBox="0 0 59.061 59.061">
      <g id="Invite_Friends" data-name="Invite Friends" transform="translate(-398 -797)">
        <path id="Path_14028" data-name="Path 14028" d="M29.531,0A29.531,29.531,0,1,1,0,29.531,29.531,29.531,0,0,1,29.531,0Z" transform="translate(398 797)" fill="#efefef"/>
        <g id="invitation_1_" data-name="invitation (1)" transform="translate(413 811)">
          <g id="Group_1200" data-name="Group 1200" transform="translate(0 8.414)">
            <g id="Group_1199" data-name="Group 1199">
              <path id="Path_10461" data-name="Path 10461" d="M13.462,145.619a.632.632,0,0,1-.236-.5h-.01v-4.336l-1.863,1.552a1.907,1.907,0,0,0-.687,1.469v17.254a1.893,1.893,0,0,0,.242.913l10.663-8.343,1.091-.853Z" transform="translate(-10.666 -140.778)" fill="#143059"/>
            </g>
          </g>
          <g id="Group_1202" data-name="Group 1202" transform="translate(17.333 8.414)">
            <g id="Group_1201" data-name="Group 1201">
              <path id="Path_10462" data-name="Path 10462" d="M311.978,142.33l-1.863-1.552v4.336h-.01a.624.624,0,0,1-.236.5l-9.2,7.148,2.36,1.832,9.42,7.314a1.9,1.9,0,0,0,.213-.859V143.8A1.907,1.907,0,0,0,311.978,142.33Z" transform="translate(-300.672 -140.778)" fill="#143059"/>
            </g>
          </g>
          <g id="Group_1204" data-name="Group 1204" transform="translate(1.211 20.4)">
            <g id="Group_1203" data-name="Group 1203">
              <path id="Path_10463" data-name="Path 10463" d="M45.428,341.685a1.716,1.716,0,0,0-2.091,0l-12.4,9.706a1.884,1.884,0,0,0,.7.138h25.5a1.9,1.9,0,0,0,.766-.163Z" transform="translate(-30.933 -341.331)" fill="#143059"/>
            </g>
          </g>
          <g id="Group_1206" data-name="Group 1206" transform="translate(3.825)">
            <g id="Group_1205" data-name="Group 1205">
              <path id="Path_10464" data-name="Path 10464" d="M74.667,0V12.439l9.227,7.171a2.958,2.958,0,0,1,3.222,0l9.226-7.171V0ZM89.33,10.2H86.142v3.188a.638.638,0,1,1-1.275,0V10.2H81.68a.638.638,0,1,1,0-1.275h3.188V5.738a.638.638,0,1,1,1.275,0V8.925H89.33a.638.638,0,1,1,0,1.275Z" transform="translate(-74.667)" fill="#143059"/>
            </g>
          </g>
        </g>
      </g>
    </svg>

  )
}
