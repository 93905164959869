import Cookies from "js-cookie";
import React,{useEffect} from "react";
import socketClient from "socket.io-client";
import { Breadcrumbs, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import Notifications from "../../../images/iconsSVG/Notification";
import ScrollToBottom from 'react-scroll-to-bottom';
import UserIcon from "../../../images/iconsSVG/UserIcon";
import Attachment from "../../../images/iconsSVG/Attachment";
import axios from "axios";
import CloseIcon from "../../../images/iconsSVG/CloseIcon";
import {useSelector,useDispatch} from 'react-redux'
import { Backdrop, Button, Fade, FormControl, FormControlLabel, InputLabel, makeStyles, MenuItem, Modal, Radio, RadioGroup, Select, TextField,CircularProgress } from '@material-ui/core';
import PdfViewer from "../../pdf_viewer";


class SocketChatClass extends React.Component {
  
    state = {
        chatList:[],
        channel: null,  
        routeUrl:null,
        message:'',
        imageFile:null,
        imagePath:null,
        file:null,
        mentionUserList:null,
        mentionUserListExecutedCount:0,
        initAtSymbol:false,
        selectedUser:null,
        batchList:[],
        selectedBatchId:null,
        notiCount:null,
        viewFile:null,
        selectedUserforAdmin:null,
        userListforAdmin:[],
        room_id: null
    }

    componentDidMount() {
      this.configureSocket();
      this.setState({routeUrl:window.location.pathname})
      if(window.location.pathname=='/superadmin/chat'||window.location.pathname=='/admin/chat'){
        this.getBatchList()
      }
      if (this.socket.connected) {
        this.sendMessage(null);
      }
        setInterval(() => {
          this.getMessages();
        }, 1000)
      }
      
      
      componentDidUpdate(prevProps, prevState) {
        if(prevState.selectedBatchId!==this.state.selectedBatchId){
          this.configureSocket()
          if(window.location.pathname=='/admin/chat'){
            this.GetUserList(this.state.selectedBatchId)
          }
        }
        if(prevState.selectedUserforAdmin!==this.state.selectedUserforAdmin){
          this.configureSocket()
        }
      }
      
    

      configureSocket = () => {
        // // console.log('selectedSS',this.state.selectedUserforAdmin|| localStorage.getItem('batch_id')|| this.state.selectedBatchId || null )
        var socket = socketClient("https://api.ezioskillacademy.com", {
          transports: ['websocket', 'polling', 'flashsocket'],        
          query: { token: `Bearer ${Cookies.get('token')}`, room_id:this.state.selectedUserforAdmin|| localStorage.getItem('batch_id')|| this.state.selectedBatchId || null }        
        });
        this.setState({ room_id: this.state.selectedUserforAdmin|| localStorage.getItem('batch_id')|| this.state.selectedBatchId || null })
        // console.log(socket.connected)
        this.socket = socket;
        
        socket.on('connection', () => {
          // console.log('Connected')
          if (socket.connected) {

            // console.log(socket.connected)
            this.getMessages();
          }
        });
        
        // Join chatroom
        socket.emit('joinRoom', {});
        
        socket.on('message', message => {
          // console.log('Message', message)
          if (message.room_id === this.state.room_id) {
            this.setState({ chatList:message.messages })
          }
        });
        socket.on('notification', message => {
          // console.log('notification', message.notifocation_count)
          this.setState({notiCount:message.notifocation_count})
            //this.setState({ chatList:message })
        });
        socket.emit('get-notification-count',null,(n)=>{
          // console.log('noti',n)
        })
        socket.emit('get-message-count',null,(n)=>{
          
          // console.log('noti mess',n)
        })
    }

    GetUserList = (id) =>{
      
      //let data=batchForFilter?`/${batchForFilter}`:''
      axios.get('https://api.ezioskillacademy.com/api/mentor/user/batch/'+id,{headers:{
        Authorization:`Bearer ${Cookies.get('token')}`
      } })
        .then(res => {
          // console.log("response",res.data.data)  
          //setUserList(res.data.data.users)  
          this.setState({userListforAdmin:res.data.data.users})       
                  
        }).catch((err)=>{
            // console.log(err)
            alert("Something Wrong.. Please try again...")
        
        })
   
  } 
    getMessages = () => {
      // // console.log('Emot')
      // // console.log("Messages", this.state.selectedUserforAdmin)
      // // console.log("Messages", this.state.selectedBatchId)
      // // console.log("Messages", localStorage.getItem('batch_id'))
      this.socket.emit('get-message',{ "batch_id": localStorage.getItem('batch_id') || this.state.selectedUserforAdmin|| this.state.selectedBatchId || null });
    }

    sendMessage = (text) => {
      // console.log(this.socket)
      let channel_id=1
     
      if(text?.trim() || this.state.imageFile || this.state.file){
      let requiredFile=this.state.imageFile||this.state.file
      let requiredFilename=this.state.imageFile?.name||this.state.file?.name
      // console.log(localStorage.getItem('batch_id'))
      // console.log(this.state.selectedBatchId || null)
      // console.log(
      //   'send-message',
      //   {
      //     channel_id,
      //     text:text,
      //     file:requiredFile,
      //     file_name:requiredFilename,
      //     senderName: localStorage.getItem('user_name'),
      //     id: Date.now(),
      //     to: this.state?.selectedUser?._id||null,
      //     batch_id: localStorage.getItem('batch_id') || this.state.selectedBatchId||null
      //   }
      // )
      this.socket.emit(
        'send-message',
        {
          channel_id,
          text:text,
          file:requiredFile,
          file_name:requiredFilename,
          senderName: localStorage.getItem('user_name'),
          id: Date.now(),
          to: this.state?.selectedUser?._id||null,
          batch_id: localStorage.getItem('batch_id') || this.state.selectedUserforAdmin||this.state.selectedBatchId || null 
        }
      ); 
    
      this.setState({message:''})
      this.setState({imageFile:null})
      this.setState({file:null})
      this.setState({selectedUser:null})
      this.setState({mentionUserListExecutedCount:0})
      this.setState({initAtSymbol:false})
      
      }
    }
     attachFile=(e)=>{
        //const previewImg=document.getElementById('preview_img')
          if(e.target.files[0].type.includes('image')){
              this.setState({imageFile:e.target.files[0]})
              this.setState({imagePath:URL.createObjectURL(e.target.files[0])})
            
          }
          else{
              this.setState({file:e.target.files[0]})
          }
      }

    changeMessage(e){
      let input=e.target.value
      this.setState({message:input})
      if(input[0]=='@' || input.includes(' @')){
        if(((input.match(new RegExp(" @", "g")) || [1]).length!==this.state.mentionUserListExecutedCount) || (input[0]=='@' && !this.state.initAtSymbol)){
         
          this.setState({initAtSymbol:true})
        axios.get('https://api.ezioskillacademy.com/api/batch/user/62863d2146d2cf7f5b0ab431',{ headers:{
	            	Authorization:`Bearer ${Cookies.get('token')}`
	             } })
	      .then((res)=>{
                  this.setState({mentionUserList:res.data.data})
                  this.setState({mentionUserListExecutedCount:this.state.mentionUserListExecutedCount+1   })
	        })
        }
      }
      else{
        this.setState({mentionUserList:null})
        this.setState({initAtSymbol:false})
      }
      
    }
    mentionUser(user){
      this.setState({mentionUserList:null})
      this.setState({message:this.state.message+user.name})
      this.setState({selectedUser:user})

    }
    
    getBatchList(){
      let apiChar=window.location.pathname=='/superadmin/chat'?'admin':'mentor'
      axios.get(`https://api.ezioskillacademy.com/api/${apiChar}/batch`, {headers:{
        Authorization:`Bearer ${Cookies.get('token')}`
      } })
      .then(res => {
        this.setState({batchList:res.data.data})    
      }).catch(e=>{
        alert(e.response.data.message)
      })
    }

    handleSelectChange = (e) => {
      // console.log(e.target.value)
      this.setState({selectedBatchId:e.target.value});
      this.getMessages()
    }

    render() {  
        return ( 
            <>
         
         {
           ((localStorage.getItem('isUser')&&localStorage.getItem('batch_id'))||localStorage.getItem('isSuperadmin')||localStorage.getItem('isAdmin'))&&(
            <section className={(this.state.routeUrl=='/superadmin/chat'||window.location.pathname=='/admin/chat')?'':'user_chat_section'}>           
            {
              (this.state.routeUrl=='/superadmin/chat'||window.location.pathname=='/admin/chat')&&(
               <select style={{width:'200px',height:'40px',border: '1px solid #e8e8e8',margin:'15px 0'}} 
               onChange={ this.handleSelectChange }
               >
               <option disabled selected>Select Batch</option>
               { 
                 this.state.batchList.map(batch=>(
                   <>
                   {
                       <option value={batch._id} >{batch.batch_name}</option>                    
                   }
                   </>               
                 ))
               }
             </select>
               )
             }

      {
              (window.location.pathname=='/admin/chat')&&(
               <select style={{width:'200px',height:'40px',border: '1px solid #e8e8e8',margin:'15px 0'}} 
               onChange={(e)=>this.setState({selectedUserforAdmin:e.target.value})}
               >
               <option disabled selected>Select User</option>
               { 
                 this.state.userListforAdmin?.map(user=>(
                   <>
                   {
                       <option value={user._id} >{user.name}</option>                    
                   }
                   </>               
                 ))
               }
             </select>
               )
             }

             {
               (localStorage.getItem('isSuperadmin')||localStorage.getItem('isAdmin'))&&(
                 <>
                 {
                   (this.state.selectedBatchId&&this.state.selectedUserforAdmin)?<h1>Individual Chat</h1>:this.state.selectedBatchId?<h1>Group Chat</h1>:null
                 }
                 </>
               )
             }
             
              <div className='user_chat'>
                <div className='user_chat_message_con'>
                 <ScrollToBottom className="message-container">
               
                  {
                    this.state.chatList.length>0?(
                      <>
                      {
                        this.state.chatList.map((chat,index)=>{
                          let date=new Date(chat.createdAt)
                         const isImg=chat?.file_url? [ "jpeg", "jpg", "gif", "png" ].includes(chat.file_url.split('.')[chat.file_url.split('.').length-1]):null
 
                         const fileName=chat?.file_url?.split('/')[chat?.file_url?.split('/').length-1]
                         const sender=localStorage.getItem('user_id')==chat.users._id
                          return (
                            <div key={index} 
                            className={sender?'user_individual_message user_individual_message_sender':'user_individual_message'}
                            >
                              {
                                !sender&&(
                                 <UserIcon/>
                                )
                              }
                           
                            <div className='user_message_name'>
                                 <div><h4>{chat.users.name}</h4>&nbsp;&nbsp;<span>{date.getHours()}:{date.getMinutes()}:{date.getSeconds()}</span></div>
                                 {
                                   chat?.file_url &&(
                                     <a className="download_btn" href={chat.file_url} download>
                                     <i class="fa fa-download" aria-hidden="true"></i>
                                     </a>
                                   )}
                                 {
                                   chat.message &&(
                                    <p>{chat.message}</p>
                                   )
                                 }
                                 {
                                   chat?.file_url &&
                                   <>
                                   {
                                     isImg?(
                                     
                                      <img onClick={()=>this.setState({viewFile:{type:'image',url:chat.file_url}})} src={chat.file_url} style={{cursor:'pointer'}}/>
                                    
                                     ):(
                                      
                                       <div className='input_file_box' 
                                      // onClick={()=>this.setState({viewFile:{type:'file',url:chat.file_url}})}
                                       >
                                         <img src={require('../../../images/dashboard/files.svg')}/>
                                         <h5>{fileName?.length>10?fileName.slice(0,10)+'...':fileName||"File"}</h5>                                        
                                       </div>
                                      
                                     )
                                   }                           
                                  
                                   </>
                                     
                                   
                                 }
                                
                            </div> 
                            </div>
                          )
                        }
                         
                        )
                      }
                      
                      </>                
                    ):(
                      <h1>No Message Found</h1>
                    )
                  }
                      
               
               </ScrollToBottom>
                </div>
               
      
                <div className='chat_input_con'>
                 
                      {
                        this.state.imageFile?(
                          <div className='chat_input chat_input_img'>
                          <div>
                            <img id="preview_img" src={this.state.imagePath} alt="" style={{maxHeight:'100px'}}/>
                          </div>
                          </div>
                        ):this.state.file?(
                          <div className='chat_input'>
                            {this.state.file.name}
                          </div>
                        ):null
                      }
                     
                     <div className='chat_input'>
                     {
                    this.state.mentionUserList&&(
                     <ul className="mention_user_list">
                       {
                        this.state.mentionUserList.map(user=>(
                             <li key={user._id} onClick={()=>this.mentionUser(user)}>{user.name}</li>
                        )) 
                       }
                     </ul>
                    )
                  }
                       {
                         this.state.selectedUser&&(
                           <div className="mentioned_user">{this.state.selectedUser.name}<div onClick={()=>this.setState({selectedUser:''})}><CloseIcon/></div></div>
                         )
                       }
                          
                          <input type="text" placeholder='Start Your Conversation Here...' autoFocus onChange={(e=>this.changeMessage(e))} onKeyPress={(e=>{e.key==='Enter'&&this.sendMessage(this.state.message)})} value={this.state.message}/>
                     </div>
 
                <div className='chat_input_bottom'>
                
                   
                    <input id='attachFile' type="file" onChange={this.attachFile} style={{display:'none'}}/>
                    <div onClick={()=>{document.getElementById('attachFile').click()}}>
                    <Attachment />
                    </div>
                    <div onClick={()=>this.sendMessage(this.state.message)} style={{display:'flex',alignItems:'center'}}>
                  
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21">
                            <g id="Group_2" data-name="Group 2" transform="translate(541 125.96)">
                              <path id="Path_21" data-name="Path 21" d="M5.073,13,2.079,22.245a.609.609,0,0,0,.127.6.5.5,0,0,0,.332.144.447.447,0,0,0,.2,0L22.049,13Z" transform="translate(-543.049 -127.96)"/>
                              <path id="Path_22" data-name="Path 22" d="M5.125,23.752l-3.046-10a.609.609,0,0,1,.127-.6.5.5,0,0,1,.332-.144.447.447,0,0,1,.2,0l19.3,10.9Z" transform="translate(-543.049 -138.96)"/>
                            </g>
                          </svg>
      
                    </div>
                   
                   
                    {/* <img src={require('../../../images/dashboard/attachment.svg')}  alt=""/>
                    <img src={require('../../../images/dashboard/send.svg')}  alt=""/> */}
                  
      
                    </div>
      
                </div>
                
              </div>
          </section>
           )
         }
        
     
         {/* <ForNotificationCount noti_count={this.state.notiCount}/> */}
         <Modal
      aria-labelledby="spring-modal-title"
      aria-describedby="spring-modal-description"
      className={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      open={this.state.viewFile}
      onClose={()=>this.setState({viewFile:null})}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={this.state.viewFile}>
        <div className="view_file_popup">
        
          {
            this.state.viewFile?.type=='image'&&(
              <img src={this.state.viewFile?.url} alt="" />
            )
          }
          {
            this.state.viewFile?.type=='file'&&(
              <iframe 
              //src="http://www.africau.edu/images/default/sample.pdf" 
              src={this.state.viewFile?.url}
              frameBorder="0" height="500px" width="100%"></iframe>
            )
          }
        </div>
        </Fade></Modal>

        {/* <PdfViewer/> */}
         </>
         );
    }
}
 
export default SocketChatClass;



// function ForNotificationCount({noti_count}){
//   const reduxValue=useSelector(state=>state)
//   const dispatch=useDispatch()
//   // console.log("noti New Comp",noti_count)

//   useEffect(()=>{
//   dispatch({
//           type:'MESSAGE_COUNT',
//           message_count:noti_count
//         })
//   },[noti_count])


//     return (
//       <></>
//     )
// }