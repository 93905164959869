import React, { Component , useState ,useEffect } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    NavLink ,
    Link
  } from "react-router-dom";
import Progress from 'react-progressbar';
import Index from '../index'
// import {routes} from '../index'
import Background from '../../../images/banner.png'
import { course_detail } from './data/course_details_arr';
import { Breadcrumbs, Typography } from '@material-ui/core';
import Notifications from '../../../images/iconsSVG/Notification';
import axios from 'axios';
import Cookies from 'js-cookie'
import $ from 'jquery'
import PadLock from '../../../images/iconsSVG/Lock';
import {  useDispatch } from 'react-redux'
import parser from 'html-react-parser';

const headers={
	Authorization:`Bearer ${Cookies.get('token')}`
}



const Lessons = () => {
	const dispatch=useDispatch()

	const [ notifyCount, setNotifyCount ] = useState();
	const [ isCourseActive, setIsCourseActive ] = useState();
	const [currentSection,setCurrentSection] = useState();

	const [sectionCompleted,setSectionCompleted]=useState(false)
	const [videoWatched,setVideoWatched]=useState(0)

	const [project,setProject]=useState()
	const [courseId,setCourseId]= useState()
	const [sectionId,setSectionId]=useState()
	const [videoList,setVideoList]=useState()

	const [projectData,setProjectData]= useState()
	
	const ProgressBar  = (props) =>{
		
		return(
			<div className='progress-bar'>
				<div className='filter'  style={{width:`${props.percentage}%`}} />
			</div>
		)
	}
	
	// useEffect(()=>{
	// 	setCurrentSection(course_detail.filter(course=>course.section_url===window.location.pathname.split('/')[2])[0])
	// },[])
	
	useEffect(() => {
	// 	axios.get('https://api.ezioskillacademy.com/api/notification/count',{ headers:{
	// 		Authorization:`Bearer ${Cookies.get('token')}`
	// 	  } })
	// 	.then((res)=>{
	// 		setNotifyCount(res.data.notify_count)
	//  })
		let pathArr=window.location.pathname.split('/')
		let _sectionid=pathArr[pathArr.length-1]
		setCourseId(pathArr[pathArr.length-2])
		setSectionId(_sectionid)
		getVideolist(_sectionid)
		getProjectStatus(_sectionid)
 	}, [])

	 function getProjectStatus(id){
		axios.get(`https://api.ezioskillacademy.com/api/user/project/${id}`, { headers:{
			Authorization:`Bearer ${Cookies.get('token')}`
		  } })
		  .then((res) => {
					// console.log("Status", res.data.data);
					if(!res.data?.data){
						 $('#project_btn').css('display','none')
						
					}else{
						setProjectData(res.data.data)
					}
					//setVideoList(res.data.data)
					// if (!res.data.status) {
					// 	window.location.href = "/course"
					// }
		  }).catch((err)=>{
			//$('project_btn').css('display','none')
		  })
	}

	 useEffect(()=>{
		if(currentSection){
			getCompletedVideo()
			//getProjectStatus()
		}
	 },[currentSection])

	//  function getProjectStatus(){		
	// 		const jsonData={
	// 			"course_title": currentSection?.section_url,
	// 			"student_id": localStorage.getItem('user_id'),
	// 		}
	// 		axios.post('https://api.ezioskillacademy.com/api/user/project-get',jsonData, { headers:{
	// 			Authorization:`Bearer ${Cookies.get('token')}`
	// 		  } })
	// 		.then(res=>{
	// 			// console.log('project',res.data.data)
	// 			setProject(res.data.data)
	// 			//alert('done')
	// 		})			
	//  }

	const getVideolist = (id) => {
    axios.get(`https://api.ezioskillacademy.com/api/user/video/sub-section/${id}`, { headers:{
		Authorization:`Bearer ${Cookies.get('token')}`
	  } })
      .then((res) => {
        		// console.log("Data", res.data);
				setVideoList(res.data.data.sub_section)
				dispatch({
					type:'USER_BREADCRUMPS',
					breadcrump:[
					{name:'course',url:'/course/learn'},
					{name:res.data.data.course.course_name,url:`/course/learn/${res.data.data.course._id}`},
					{name:res.data.data.sections.section_name,url:``},
				]
				  }) 
				// if (!res.data.status) {
				// 	window.location.href = "/course"
				// }
      })
  };

  function getCompletedVideo(){
	axios.get(`https://api.ezioskillacademy.com/api/user/course/completion`, { headers:{
		Authorization:`Bearer ${Cookies.get('token')}`
	  } })
	.then((res) => {
			  if(res.data.data){
			  const {section,video}=res.data.data;
			  setVideoWatched(video)
			  if(section>currentSection?.sid){
				  setSectionCompleted(true)
			  }else if(section==currentSection?.sid && video==currentSection?.videos[currentSection?.videos.length-1].vid){
					setSectionCompleted(true)
			  }
			  // console.log("Data1", section,video,currentSection,course_detail,section==currentSection?.sid , video,currentSection?.videos[currentSection?.videos.length-1].vid);
			}
	})
  }

   return (
           <React.Fragment>
			   {
				  
					   <>
							

<br/>
<br/>
<div className='cousre_videos container'>
{
	projectData&&(
<div id='project_btn' className={`row last_activity project_upload_btn_top ${projectData.project_status.toLowerCase()=='locked'&&'project_upload_btn_top_inactive'} ${project?.isComplete&&'project_upload_btn_top_completed'}`}>
	<p>You have completed the project... You can view it also</p>
	<Link to={`/course/learn/project/${sectionId}/${projectData._id}`}>
	<button><span>Project Upload</span></button>
	</Link>					 
</div>
	)
}

<h1>{currentSection?.section_name}</h1>
 {
	 
		 videoList?.map((video,index)=>{
			 const currentPercentage=video.percentage>100?100:video.percentage
			 return(
			 <div className='row' key={index}>						 
	<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 last_activity padding_none course_video' key={index}>
	<div className='col-lg-6 col-md-12 watch_video' style={{backgroundImage: `url(${video.thumbnail})`}}>
		<a href={`/course/learn/${courseId}/${sectionId}/${video._id}`}><img src={require('../../../images/play.png')} alt='' /></a>
	</div>
	
	<div className='col-lg-6 col-md-12 watch_course'>
	  <h6>Leasson {index+1}</h6>
	  <h5>  {video.sub_title}</h5>
	  <p>{parser(video.description.slice(0,140))}</p>
	  <div className='pr_out'>
		<span><b>{currentPercentage}%</b> Viewed</span>
		<ProgressBar percentage={currentPercentage} />
	  </div>
	  <br/><br/>
	  {
		  video.status.toLowerCase()!=='locked'?<Link to={`/course/learn/${courseId}/${sectionId}/${video._id}`} className='course_continue' style={video.status.toLowerCase()=='completed'?{backgroundColor:'#00B170'}:{backgroundColor:'#009EF9'}}>
			  {video.status}
			  </Link>
		  :
		  <div  className='course_continue' style={{ backgroundColor: 'grey',  cursor: 'auto' }}>
		  <PadLock />Locked
	  </div>	
	  }
	  
	   
	  

	</div>
</div>
</div>
		 )
	})
	
 }


</div>
					   </>
				   
			   }
				

			 </React.Fragment>
    )
}

export default Lessons