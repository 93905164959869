import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { course_detail } from './data/course_details_arr';
import Notifications from '../../../images/iconsSVG/Notification';
import PadLock from '../../../images/iconsSVG/Lock';
import axios from 'axios';
import Cookies from 'js-cookie'
import { useDispatch } from 'react-redux'

const headers={
	Authorization:`Bearer ${Cookies.get('token')}`
}

function CourseList() {
	const dispatch=useDispatch()


	const [notifyCount, setNotifyCount] = useState();
  	const [courseList, setCourseList] = useState();
  	const [completedSection,setCompletedSection] = useState();

	useEffect(() => {
		// axios.get('https://api.ezioskillacademy.com/api/notification/count',{headers:{
		// 	Authorization:`Bearer ${Cookies.get('token')}`
		// }})
		//  .then((res)=>{
		// 	 setNotifyCount(res.data.notify_count)
		// })
		getCourselist()
		//getCompletedVideo()
		dispatch({
			type:'USER_BREADCRUMPS',
			breadcrump:[{name:'course',url:''}]
		  }) 
	}, []);
	
	const getCourselist = () => {
    axios.get('https://api.ezioskillacademy.com/api/user/video/course', { headers:{
		Authorization:`Bearer ${Cookies.get('token')}`
	} })
      .then((res) => {
        // console.log("res",res.data.data);
        setCourseList(res.data.data);
      })
  };

	const checkLockCourses = (course_url) => {
		const checks = courseList?.filter((courses) => courses.course_title === course_url);
		let isActive = false;
		if (checks) {
			isActive = checks[0].isActive;
		}
		return isActive
	}
	function getCompletedVideo(){
		axios.get(`https://api.ezioskillacademy.com/api/user/course/completion`, { headers:{
			Authorization:`Bearer ${Cookies.get('token')}`
		} })
		.then((res) => {
				  // console.log("video",res.data)
				  if(res.data.data){
					const {section}=res.data.data;
					setCompletedSection(section)
				  }
				 
				 
				  //// console.log("Data1", section,video,currentSection,course_detail,section==currentSection.sid , video==currentSection.videos.length);
				 
		})
	  }
  return (
	  <>
	
      <section className='user_course_list course_list' >
	  		{
					courseList?.map((course,index)=>(
					<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 last_activity' key={index}>		   				
						<div className='col-lg-10 col-md-10 col-sm-9 col-xs-12'>
							{/* <h4><span></span>Course</h4> */}
							<h5> {course.course_name}</h5>
							<p>
								{course.course_desc}
							</p>
							<div className='bottom_info'><p>{course.duration}</p><p>-{course.author}</p></div>
						</div>
						<div className='col-lg-2 col-md-2 col-sm-3 col-xs-12'>
							{
							//checkLockCourses(course.section_url)
							true  ? 
								<Link  to={`/course/learn/${course._id}`} className='course_continue'>
									{completedSection>=course.sid?'Continue':'Start'}
								</Link>
								:
								<div  className='course_continue' style={{ backgroundColor: 'grey',  cursor: 'auto' }}>
									<PadLock />Locked
								</div>	
							}
							
						</div>
					</div>
					))
				}
      </section>
	  </>
  )
}

export default CourseList	