import React from 'react'

export default function WhatsAppIcon() {
  return (
    <svg id="whatsapp_1_" data-name="whatsapp (1)" xmlns="http://www.w3.org/2000/svg" width="62.725" height="62.724" viewBox="0 0 62.725 62.724">
      <path id="Path_14049" data-name="Path 14049" d="M53.629,9.014A31.733,31.733,0,0,0,31.48,0C7.514,0-7.535,25.965,4.433,46.62L0,62.724l16.557-4.318c7.213,3.9,13.122,3.552,14.939,3.782,27.79,0,41.623-33.62,22.095-53.073Z" transform="translate(0)" fill="#eceff1"/>
      <path id="Path_14050" data-name="Path 14050" d="M28.1,53.675l-.016,0h-.042a26.334,26.334,0,0,1-14.152-4.166l-9.8,2.548,2.627-9.526-.625-.98A25.792,25.792,0,0,1,2.13,27.8C2.13,4.821,30.212-6.671,46.463,9.572,62.675,25.645,51.293,53.675,28.1,53.675Z" transform="translate(3.437 3.172)" fill="#4caf50"/>
      <path id="Path_14051" data-name="Path 14051" d="M36.112,27.142l-.024.2c-.787-.392-4.618-2.266-5.332-2.525-1.6-.593-1.15-.094-4.226,3.429-.457.51-.912.549-1.688.2a21.211,21.211,0,0,1-6.28-3.881,23.711,23.711,0,0,1-4.338-5.41c-.766-1.322.836-1.511,2.295-4.27a1.437,1.437,0,0,0-.065-1.369c-.2-.392-1.756-4.234-2.41-5.765-.627-1.526-1.273-1.333-1.756-1.333-1.505-.131-2.606-.11-3.575.9-4.218,4.636-3.155,9.419.455,14.505C16.26,31.1,20.039,32.805,26.949,35.179a10.813,10.813,0,0,0,4.913.316c1.5-.238,4.618-1.884,5.269-3.727a6.469,6.469,0,0,0,.47-3.724c-.193-.353-.706-.549-1.49-.9Z" transform="translate(9.643 10.25)" fill="#fafafa"/>
    </svg>
  )
}