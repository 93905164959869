import React from 'react'

function DbChat() {
  return (
   
<svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_850_27)">
<path d="M21.7617 25.5733C21.9932 25.5733 22.2216 25.6613 22.3954 25.8292C23.5062 26.9018 25.1265 27.259 26.5669 26.8119C25.6399 24.8807 25.5115 22.6681 26.2298 20.6196C27.1214 18.0707 27.3448 15.516 26.3302 12.9538C25.4388 10.703 23.7186 8.83297 21.5415 7.7666C21.6755 8.48063 21.743 9.20816 21.743 9.94338C21.743 13.0641 20.543 15.9992 18.3642 18.2081C16.185 20.4173 13.264 21.6595 10.1391 21.706C9.55855 21.7151 8.98059 21.6809 8.40918 21.6056C10.1266 24.6159 13.3655 26.5765 16.9247 26.6293C18.4817 26.6533 19.9766 26.3271 21.3688 25.6623C21.4942 25.6023 21.6285 25.5733 21.7617 25.5733V25.5733Z" fill="#F14A51"/>
<path d="M10.1649 0.00198384C4.62151 -0.108088 0.0133964 4.37645 3.00584e-05 9.91867C-0.0032447 11.2727 0.261075 12.5856 0.785704 13.8209C0.793323 13.8389 0.800407 13.8571 0.806823 13.8755C1.5252 15.9241 1.39681 18.1366 0.469723 20.0679C1.91028 20.5147 3.53042 20.1578 4.64129 19.085C4.91497 18.8207 5.32445 18.7541 5.66783 18.9182C7.05987 19.5831 8.55502 19.9103 10.1119 19.8853C15.612 19.8036 19.9203 15.4365 19.9202 9.94313C19.9203 4.56966 15.544 0.110118 10.1649 0.00198384V0.00198384ZM4.64731 11.4281C3.85087 11.4281 3.20521 10.7835 3.20521 9.98844C3.20521 9.19335 3.85087 8.54876 4.64731 8.54876C5.44374 8.54876 6.0894 9.19335 6.0894 9.98844C6.08933 10.7835 5.44374 11.4281 4.64731 11.4281ZM9.96016 11.4281C9.16373 11.4281 8.51807 10.7835 8.51807 9.98844C8.51807 9.19335 9.16373 8.54876 9.96016 8.54876C10.7566 8.54876 11.4023 9.19335 11.4023 9.98844C11.4023 10.7835 10.7566 11.4281 9.96016 11.4281ZM15.2731 11.4281C14.4766 11.4281 13.831 10.7835 13.831 9.98844C13.831 9.19335 14.4766 8.54876 15.2731 8.54876C16.0695 8.54876 16.7152 9.19335 16.7152 9.98844C16.7152 10.7835 16.0695 11.4281 15.2731 11.4281Z" fill="#F14A51"/>
</g>
<defs>
<clipPath id="clip0_850_27">
<rect width="27" height="27" fill="white"/>
</clipPath>
</defs>
</svg>

  )
}

export default DbChat