import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { course_detail } from './data/course_details_arr';
import Notifications from '../../../images/iconsSVG/Notification';
import PadLock from '../../../images/iconsSVG/Lock';
import axios from 'axios';
import Cookies from 'js-cookie'
import {  useDispatch } from 'react-redux'
const headers={
	Authorization:`Bearer ${Cookies.get('token')}`
}

function Section_list() {
	const dispatch=useDispatch()
	
	const [ notifyCount, setNotifyCount ] = useState();
	const [sectionList, setSectionList] = useState([])
  const [completedSection,setCompletedSection] = useState();
  const [courseId,setCourseId]= useState()

//   const dispatch = useDispatch()
//   const reduxValue = useSelector(state => state)

	useEffect(() => {
		// axios.get('https://api.ezioskillacademy.com/api/notification/count',{headers:{
		// 	Authorization:`Bearer ${Cookies.get('token')}`
		// }})
		//  .then((res)=>{
		// 	 setNotifyCount(res.data.notify_count)
		// })
		let pathArr=window.location.pathname.split('/')
		setCourseId(pathArr[pathArr.length-1])
		GetSectionList(pathArr[pathArr.length-1])
		
		//getCompletedVideo()
	}, []);

	const GetSectionList = (id) =>{
    
		axios.get(`https://api.ezioskillacademy.com/api/user/video/section/`+id, { headers:{
			Authorization:`Bearer ${Cookies.get('token')}`
		} })
		.then(res => {  
					  // console.log("sectionlist",res.data)
					  setSectionList(res.data.data.sections)  
					  dispatch({
						type:'USER_BREADCRUMPS',
						breadcrump:[{name:'course',url:'/course/learn'},{name:res.data.data.course.course_name,url:``}]
					  })   
		}).catch(e=>{
		  alert(e.response.data.message)
		})
	}
	// const checkLockCourses = (course_url) => {
	// 	const checks = courselist?.filter((courses) => courses.course_title === course_url);
	// 	let isActive = false;
	// 	if (checks) {
	// 		isActive = checks[0].isActive;
	// 	}
	// 	return isActive
	// }
	function getCompletedVideo(){
		axios.get(`https://api.ezioskillacademy.com/api/user/course/completion`, { headers:{
			Authorization:`Bearer ${Cookies.get('token')}`
		} })
		.then((res) => {
				  // console.log("video",res.data)
				  if(res.data.data){
					const {section}=res.data.data;
					setCompletedSection(section)
				  }
				 
				 
				  //// console.log("Data1", section,video,currentSection,course_detail,section==currentSection.sid , video==currentSection.videos.length);
				 
		})
	  }
  return (
	  <>
		{/* <div className='lessons_only course_list_top white_bg'>
		<div className='lat_activity' >					
						<div className='up_section' style={{width:'100%'}}>
							<div className='row'>
							<div className='col-lg-12' style={{display:'flex',justifyContent:'space-between'}}>
							
								<h1><img src={require('../../../images/book.png')} style={{height:'28px',marginRight:'10px'}} alt='' />Digital Marketing Course</h1>
								<div style={{display:'flex',justifyContent:'space-between',alignItems:'center',minWidth:'90px'}}>								
									<span style={{ marginRight: "20px" }}>
										<a href='/course/notifications'><Notifications notifyCount = { notifyCount }/></a>
									</span>
									<p style={{textTransform:'capitalize',fontFamily:'circular-regular'}}>Hi, Siva </p>
									<img src={require('../../../images/account.png')} alt="" style={{width:'30px',marginLeft:'15px'}}/>
								</div>
								
								</div>						
							</div>
						</div>					
					</div>	
					</div> */}
      <section className='course_list' >
	  		{
					sectionList.map((section,index)=>(
					<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 last_activity' key={index}>		   				
						<div className='col-lg-10 col-md-10 col-sm-9 col-xs-12'>
							{/* <h4><span></span>Course</h4> */}
							<h5> {section.section_name}</h5>
							<p>{section.section_desc}</p>
						</div>
						<div className='col-lg-2 col-md-2 col-sm-3 col-xs-12'>
							{
							//checkLockCourses(section.section_url)
							section.status.toLowerCase()!=='locked'  ? 
								<Link  
								to={`/course/learn/${courseId}/${section._id}`} 
								className='course_continue' 
								style={section.status.toLowerCase()=='completed'?{backgroundColor:'#00B170'}:{backgroundColor:'#009EF9'}} 
								
								>
									{
									section.status	
									}
								</Link>
								:
								<div  className='course_continue' style={{ backgroundColor: 'grey',  cursor: 'auto' }}>
									<PadLock />Locked
								</div>	
							}
							
						</div>
					</div>
					))
				}
      </section>
	  </>
  )
}

export default Section_list