import axios from 'axios';
import React,{useState,useEffect} from 'react'
import Cookies from 'js-cookie';
import CustomModal from './CustomModal';
import $ from 'jquery'
import Toaster from '../../toaster';
/*
{headers:{
    Authorization:`Bearer ${Cookies.get('token')}`
  } }
*/
function BatchManagement(props) {
    const [details, setDetails] = useState([])
    const [batchList, setBatchList] = useState([])
    const [addPopup,setAddPopup] = useState(false)
    const [editPopup,setEditPopup] = useState(false)
    const [fields, Getfields] = useState({});
    const [fieldsEdit, GetfieldsEdit] = useState({});
    const [error,setError]=useState({})

    const [tosOpen,setTosOpen]=useState()

    useEffect(() =>{
      GetBatchList()
  },[])
  
  const GetBatchList = () =>{
    
      axios.get(`https://api.ezioskillacademy.com/api/admin/batch`, {headers:{
        Authorization:`Bearer ${Cookies.get('token')}`
      } })
      .then(res => {
                    // console.log("batch",res.data)
                    setBatchList(res.data.data)    
      }).catch(e=>{
        alert(e.response.data.message)
      })
  }
//   const HandleDelete=(id)=>{
//     var result = window.confirm("Are you sure you want to delete?");
//     if (result) {
//       var formdata = new FormData();
//       formdata.append('action' , 'delete') 
//       formdata.append('referer' , 'ezioaws')
//       formdata.append('id' , id)
//       axios.post(`https://api.ezioskillacademy.com/api/admin/digital-marketing-details.php`, formdata)
//       .then(res => {
//                 if(res.status == 200){
//                     GetBatchList()
//                 }else{
//                     alert(res.data)
//                 }
                
//       })
//     }
//   }
  const addnewBatch=()=>{

    // console.log(fields)
        let err={}
        let isValid=true
        if(!fields.name){
            err["name"]="Please Enter Batch Name"
            isValid=false
            // console.log('name req........')
        }
        if(!fields.session){
            err["session"]="Please Select session"
            isValid=false
        }

        if(!fields.strength){
            err["strength"]="Please Enter strength"
            isValid=false
        }
        if(!fields.start_time){
            err["start_time"]="Please Enter Start time"
            isValid=false
        }
        if(!fields.end_time){
            err["end_time"]="Please Enter End time"
            isValid=false
        }
        setError(err)
    // console.log({error})

        if(isValid){
            // console.log('success')
        const jsonData={
            "batch_name": fields.name,
            "batch_session": fields.session ,
            "batch_strength": fields.strength ,
            "batch_start_time": fields.start_time ,
            "batch_end_time": fields.end_time 
        }
        axios.post('https://api.ezioskillacademy.com/api/admin/batch',jsonData,{headers:{
            Authorization:`Bearer ${Cookies.get('token')}`
          } })
        .then(res=>{
            // console.log("res",res.data)
           
                GetBatchList()
                $('#add_scs_msg').html(res.data.message)
                setTosOpen(res.data.message)
               // setTimeout(()=>{
                    setAddPopup(false)      
              //  },3000)
                
            
        }).catch(e=>{
           alert(e.response.data.message)
        })
    }
}

const editBatch=()=>{

    const jsonData={
        "batch_name": fieldsEdit.batch_name,
        "batch_session": fieldsEdit.batch_session ,
        "batch_strength": fieldsEdit.batch_strength ,
        "batch_start_time": fieldsEdit.batch_start_time ,
        "batch_end_time": fieldsEdit.batch_end_time 
    }
    axios.put('https://api.ezioskillacademy.com/api/admin/batch',jsonData,{headers:{
        Authorization:`Bearer ${Cookies.get('token')}`
      } })
    .then(res=>{
        // console.log("res",res.data)
       
            GetBatchList()
            $('#edit_scs_msg').html(res.data.message)
            setTosOpen(res.data.message)
           // setTimeout(()=>{
                setEditPopup(false)      
           // },3000)
            
        
    }).catch(e=>{
       alert(e.response.data.message)
    })

  }

  const handleValue=(e)=>{
      Getfields({ ...fields, [e.target.name]:e.target.value })
    }
    const handleEditValue=(e)=>{
        GetfieldsEdit({ ...fieldsEdit, [e.target.name]:e.target.value })
      }
    const setEditPopupOpen=(batch)=>{
        setEditPopup(true)
        GetfieldsEdit(batch)
        //GetBatchFields(id)
        

    }
  return (
    <div className="dash_page_bg dash_section_ptb add_payment">
        <button className="add_payment_btn" 
       onClick={() => setAddPopup(true)}
        >
          Create Batch
        </button>
        <br />
        <br />
        <div className="clientrate_table">
          <div className="tabcomp-style">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <td>Name</td>
                    <td>Session</td>
                    <td>Strength</td> 
                    <td>Time</td>
                    <td>Mentor</td>   
                    <td style={{width:'200px'}}></td>                   
                  </tr>
                </thead>
                <tbody>
                  {batchList.length > 0 ? (
                    <>
                      {batchList
                        .map((batch, id) => (
                          <tr key={id}>
                            <td>{batch.batch_name}</td>
                            <td>{batch.batch_session}</td>
                            <td>{batch.batch_strength}</td>  
                            <td>{batch.batch_start_time} - {batch.batch_end_time}</td>  
                            <td style={!batch.mentor?{color:'red'}:{}}>{batch.mentor?batch.mentor.name:'Not Allocated'}</td>   
                            <td style={{display:'flex',justifyContent:'center',width:'200px'}}>
                                <button className='blue_btn' onClick={()=>setEditPopupOpen(batch)}>Edit</button>
                                {/* <button 
                                onClick={()=>HandleDelete(batch.id)}  style={{backgroundColor:'red',color:'#fff',borderRadius:'15px',padding:'5px 10px',border:'transparent',margin:'0 10px'}}>Delete</button> */}
                                </td>                         
                          </tr>
                        ))}
                    </>
                  ) : (
                    <tr>
                      <td colSpan="6" style={{ textAlign: "center" }}>
                        No data
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
       
        <CustomModal open={addPopup}>
        <div className="scsmdl-container" style={{ height: "auto" }}>
          <div className="scsmdl-header">
            <p>Create Batch</p>
            {/* <img src={'/modalclose.jpg'} className="scsmdl-closeimg"  /> */}
            <img
              src={"/modalclose.jpg"}
              className="scsmdl-closeimg"
              onClick={() => setAddPopup(false)}
              alt="close img"
            />
          </div>
          <div className="scsmdl-body" style={{ padding: "20px" }}>
          <div className="form-group">
              <label>Batch Name:</label>
              <input
                className="form-control"
                type="text"
                name="name"
                onChange={(e) => handleValue(e)}
              />
             
            </div>
            <p style={{color:'red'}}>{error.name}</p>
            <div className="form-group">
              <label>Session:</label>
              <select style={{width:'100%',height:'40px',border: '1px solid #e8e8e8'}} name='session'  onChange={(e) => handleValue(e)}>
                  <option selected disabled>Select Session</option>
                  <option value="Morning Session (FN)" >Morning Session (FN)</option>
                  <option value="Evening Session (AN)">Evening Session (AN)</option>
                </select>
               <p style={{color:'red'}}>{error.session}</p>
            </div>
            {/* <p style={{ padding: "0", color: "red" }}>{error.service}</p> */}
            <div className="form-group">
              <label>Strength:</label>
              <input
                className="form-control"
                type="text"
                name='strength'
                onChange={(e) => handleValue(e)}
              />{" "} <p style={{color:'red'}}>{error.strength}</p>
            </div>
            <div className='row' style={{display:'flex'}}>
                <div className='col-lg-6'>
                <div className="form-group">
              <label>Start Time:</label>
              <input
                className="form-control"
                type="time"
                name='start_time'
                onChange={(e) => handleValue(e)}
              />{" "} <p style={{color:'red'}}>{error.start_time}</p>
            </div>
                </div>
                <div className='col-lg-6'>
                <div className="form-group">
              <label>End Time:</label>
              <input
                className="form-control"
                type="time"
                name='end_time'
                onChange={(e) => handleValue(e)}
              />{" "} <p style={{color:'red'}}>{error.end_time}</p>
            </div>
                    </div>
            </div>
           
           

           
           
            <div className="row">
              <div className="col-lg-5">
                <button
                  onClick={addnewBatch}
                  className="addpayment-submitbtn"
               //   disabled={loader}
                //  style={loader ? { opacity: "0.5" } : {}}
                >
                  Submit
                </button>
              </div>
             
            </div>
            <p id="add_scs_msg" style={{color:'green'}}></p>
          </div>
        </div>
      </CustomModal>

      <CustomModal open={editPopup}>
        <div className="scsmdl-container" style={{ height: "auto" }}>
          <div className="scsmdl-header">
            <p>Edit Batch</p>
            {/* <img src={'/modalclose.jpg'} className="scsmdl-closeimg"  /> */}
            <img
              src={"/modalclose.jpg"}
              className="scsmdl-closeimg"
              onClick={() => setEditPopup(false)}
              alt="close img"
            />
          </div>
          <div className="scsmdl-body" style={{ padding: "20px" }}>
         
          <div className="form-group">
              <label>Batch Name:</label>
              <input
                className="form-control"
                type="text"
                name="batch_name"
                onChange={(e) => handleEditValue(e)}
                value={fieldsEdit.batch_name}
              />
             
            </div>
            <div className="form-group">
              <label>Session:</label>
              <select 
              style={{width:'100%',height:'40px',border: '1px solid #e8e8e8'}} 
              name='batch_session'  onChange={(e) => handleEditValue(e)}
              value={fieldsEdit.batch_session}
              >
                  <option selected>Select Session</option>
                  <option value="Morning Session (FN)" >Morning Session (FN)</option>
                  <option value="Evening Session (AN)">Evening Session (AN)</option>
                </select>
            </div>
            {/* <p style={{ padding: "0", color: "red" }}>{error.service}</p> */}
            <div className="form-group">
              <label>Strength:</label>
              <input
                className="form-control"
                type="text"
                name='batch_strength'
                onChange={(e) => handleEditValue(e)}
                value={fieldsEdit.batch_strength}
              />
            </div>
            <div className='row' style={{display:'flex'}}>
                <div className='col-lg-6'>
                <div className="form-group">
              <label>Start Time:</label>
              <input
                className="form-control"
                type="time"
                name='batch_start_time'
                onChange={(e) => handleEditValue(e)}
                value={fieldsEdit.batch_start_time}
              />
            </div>
                </div>
                <div className='col-lg-6'>
                <div className="form-group">
              <label>End Time:</label>
              <input
                className="form-control"
                type="time"
                name='batch_end_time'
                onChange={(e) => handleEditValue(e)}
                value={fieldsEdit.batch_end_time}
              />
            </div>
                    </div>
            </div>
           
            <div className="row">
              <div className="col-lg-5">
                <button
                  onClick={editBatch}
                  className="addpayment-submitbtn"
                >
                  Submit
                </button>
              </div>
             
            </div>

            <p id="edit_scs_msg" style={{color:'green'}}></p>
          </div>
        </div>
      </CustomModal>
      <Toaster tosOpen={tosOpen} setTosOpen={setTosOpen}/>
      </div>
  )
}

export default BatchManagement