import React from 'react'

export default function LockIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="28.32" height="39.998" viewBox="0 0 28.32 39.998">
      <g id="lock" transform="translate(-2834.879 -8616.121)">
        <path id="Path_13876" data-name="Path 13876" d="M5348.795,1388.291V1381.6a8.2,8.2,0,1,1,16.4,0v6.689h3.874V1381.6a12.076,12.076,0,1,0-24.151,0v6.689Z" transform="translate(-2507.727 7246.593)" fill="#029894"/>
        <path id="Path_13875" data-name="Path 13875" d="M5319.508,1579.786v20.853h28.32v-20.853Zm15.116,10.093v3.111h-2v-3.111a2.616,2.616,0,0,1-1.191-2.089,2.35,2.35,0,0,1,2.363-2.258,2.247,2.247,0,0,1,2.245,2.258A2.6,2.6,0,0,1,5334.624,1589.879Z" transform="translate(-2484.628 7055.48)" fill="#029894"/>
      </g>
    </svg>
  )
}
