import {React} from 'react'

export const certification_detail=[
      {
        id:'1',
        certification_name:'Ezio Skill Acadamy Graduate',
        field: 'ezio_skill',
        logo_img:"ezio_certi.png"
     },
     {
        id:'2',
        certification_name:'Ezio Audit Tools',
        field: 'ezio_audit',
        logo_img:"logo_certi.png"
     },
     {
        id:'3',
        certification_name:'Google Analytics Certification',
        field: 'google_analytics',
        logo_img:"logo_google_analytics_certi.png"
     },
     {
        id:'4',
        certification_name:'Google Ads Certification',
        field: 'google_ads',
        logo_img:"google_ads_certi.png"
     },
     {
        id:'5',
        certification_name:'Hubspot Certification',
        field: 'hubspot',
        logo_img:"hubspot_certi.png"
     },
     {
        id:'6',
        certification_name:'Linkedin Certification',
        field: 'linkedin',
        logo_img:"linkedIn_logo_certi.png"
     },
]