import React from 'react';
import LessonsDetail from './user/lessondetail.js'
import Chat from './user/chat';
import Certificate from './user/certificate';
import Faq from './user/faq';
import ProjectUpload from './user/project_upload';
import Notification from './user/notification';
import SocketChatClass from './user/socket-chat-class_compoent.js'
import Course_list from './user/course_list.js';
import Section_list from './user/sectionlist';
import VideoList from './user/videolist.js';
import Payment from './user/payment.js';

export const routes = [
    // {
    //    path: "/dashboard/login",
    //    exact: true,
    //    sidebar: () => <li className=""><a href="#"><i className="fa fa-home" aria-hidden="true"></i> &nbsp;&nbsp;Home</a></li>,
    //    main: () => <Login/>
    //  },
    {
      path: "/course/learn",
      exact: true,
      sidebar: () => <li className=""><a href="#"><i className="fa fa-home" aria-hidden="true"></i> &nbsp;&nbsp;Home</a></li>,
      main: () => <Course_list/>
    },
    {
      path: "/course/learn/project/:section_id/:project_id",
      exact: true,
      sidebar: () => <li className=""><a href="#"><i className="fa fa-home" aria-hidden="true"></i> &nbsp;&nbsp;Home</a></li>,
      main: () => <ProjectUpload/>
    },
    {
      path: "/course/learn/:course_id",
      exact: true,
      sidebar: () => <li className=""><a href="#"><i className="fa fa-home" aria-hidden="true"></i> &nbsp;&nbsp;Home</a></li>,
      main: () => <Section_list/>
    },
    {
      path: "/course/learn/:course_id/:section_id",
      exact: true,
      sidebar: () => <li className=""><a href="#"><i className="fa fa-home" aria-hidden="true"></i> &nbsp;&nbsp;Home</a></li>,
      main: () => <VideoList/>
    },
    {
      path: "/course/learn/:course_id/:section_id/:video_id",
      exact: true,
      sidebar: () => <li className=""><a href="#"><i className="fa fa-home" aria-hidden="true"></i> &nbsp;&nbsp;Home</a></li>,
      main: () => <LessonsDetail/>
    },
  
    {
      path: "/course/chat",
      exact: true,
      sidebar: () => <li className=""><a href="#"><i className="fa fa-home" aria-hidden="true"></i> &nbsp;&nbsp;Home</a></li>,
      main: () => <SocketChatClass/>
    },
    {
      path: "/course/certificate",
      exact: true,
      sidebar: () => <li className=""><a href="#"><i className="fa fa-home" aria-hidden="true"></i> &nbsp;&nbsp;Home</a></li>,
      main: () => <Certificate/>
    },
    {
      path: "/course/faq",
      exact: true,
      sidebar: () => <li className=""><a href="#"><i className="fa fa-home" aria-hidden="true"></i> &nbsp;&nbsp;Home</a></li>,
      main: () => <Faq/>
    },

    {
      path: "/course/notifications",
      exact: true,
      sidebar: () => <li className=""><a href="#"><i className="fa fa-home" aria-hidden="true"></i> &nbsp;&nbsp;Home</a></li>,
      main: () => <Notification />
    },
    {
      path: "/course/payment",
      exact: true,
      sidebar: () => <li className=""><a href="#"><i className="fa fa-home" aria-hidden="true"></i> &nbsp;&nbsp;Home</a></li>,
      main: () => <Payment/>
    },
    //add new routes here
    {
      path: "/course/certificate/:id",
      exact: true,
      sidebar: () => <li className=""><a href="#"><i className="fa fa-home" aria-hidden="true"></i> &nbsp;&nbsp;Home</a></li>,
      main: () => <Certificate/>
    },


    //  {
    //    path: "/course/:section_url",
    //    exact: true,
    //    sidebar: () => <li className=""><a href="#"><i className="fa fa-home" aria-hidden="true"></i> &nbsp;&nbsp;Home</a></li>,
    //    main: () => <Lessons/>
    //  },
    //  {
    //   path: "/course/:section_url/project",
    //   exact: true,
    //   sidebar: () => <li className=""><a href="#"><i className="fa fa-home" aria-hidden="true"></i> &nbsp;&nbsp;Home</a></li>,
    //   main: () => <ProjectUpload/>
    // },
    //  {
    //   path: "/course/:section_url/:video_url",
    //   exact: true,
    //   sidebar: () => <li className=""><a href="#"><i className="fa fa-home" aria-hidden="true"></i> &nbsp;&nbsp;Home</a></li>,
    //   main: () => <LessonsDetail/>
    // },

    

    //  {
    //   path: "/dashboard/admin",
    //   exact: true,
    //   //sidebar: () => <li className=""><a href="#"><i className="fa fa-home" aria-hidden="true"></i> &nbsp;&nbsp;Home</a></li>,
    //   main: () => <AdminIndex/>
    // },
    //  {
    //    path: "/dashboard/settings",
    //    exact: true,
    //    sidebar: () => <li ><a href="#"><i className="fa fa-cog" aria-hidden="true"></i>&nbsp;&nbsp; Settings</a></li>,
    //    main: () => <div>settings</div>
    //  },
    //  {
    //    path: "/dashboard/logout",
    //    exact: true,
    //    sidebar: () => <li ><a href="#"> <i className="fa fa-sign-out" aria-hidden="true"></i>&nbsp;&nbsp; Logout</a></li>,
    //    main: () => <div>OUT</div>
    //  },
   
    
   ];


