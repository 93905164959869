import axios from 'axios';
import React,{useState,useEffect} from 'react'
import CustomModal from './CustomModal';
import Cookies from 'js-cookie';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import $ from 'jquery'
import Toaster from '../../toaster';
const headers={
  Authorization:`Bearer ${Cookies.get('token')}`
}

function UserManagement(props) {
    const [details, setDetails] = useState([])
    const [Datas, GetData] = useState([])
    const [addPopup,setAddPopup] = useState(false)
    const [editPopup,setEditPopup] = useState(false)
    const [fields, Getfields] = useState({});
    const [fieldsEdit, GetfieldsEdit] = useState({});
    const [error,setError] = useState({})

    const [filteredUser,setFilteredUser] = useState('all')
    const [userDetail,setUserDetail] = useState()

    const [selectedUserList,setSelectedUserList] = useState([])
    const [batchAllocationPopup,setBatchAllocationPopup]=useState(false)
    const [batchList, setBatchList] = useState([])
    const [selectedBatch,setSelectedBatch]=useState()

    const [batchForFilter,setBatchForFilter]=useState()

    const [tosOpen,setTosOpen]=useState()

    useEffect(() =>{
      setTimeout(()=>{
        GetUserList()
        getBatchList() 
      },1000)
  },[filteredUser])

  useEffect(()=>{
    // console.log('else',batchForFilter)
    if(batchForFilter&& Datas&& batchForFilter!=='none'){
      // console.log("dd",batchForFilter?Datas.filter(d=>d.batch.batch_id==batchForFilter):Datas)
      let newData=batchForFilter?Datas.filter(d=>d.batch.batch_id==batchForFilter):Datas
      // console.log({newData})
      GetData(newData)
    }else{
      // console.log("else")
      GetUserList()
    }
  },[batchForFilter])
  
  const GetUserList = () =>{
    if (Cookies.get('token')) {
      axios.get(`https://api.ezioskillacademy.com/api/admin/user?batch_status=${filteredUser}`,{headers:{
        Authorization:`Bearer ${Cookies.get('token')}`
      } })
        .then(res => {
          // console.log("response",res.data)  
          GetData(res.data.data)  
                      
                  
        }).catch((err)=>{
            // console.log(err)
            alert("Something Wrong.. Please Login again...")
        
        })
    } else {
      GetUserList()
    }
    // // console.log(Cookies.get('token'))
    
  }
  const HandleDelete=(id)=>{
    var result = window.confirm("Are you sure you want to delete?");
    if (result) {
    
      axios.delete(`https://api.ezioskillacademy.com/api/admin/user/${id}`, {headers})
      .then(res => {
                    GetUserList()             
                
      }).catch((err)=>{
        alert(err)
      })
    }
  }


const editUser=( )=>{
  const jsonData={
    name: fieldsEdit.name,
    email: fieldsEdit.email,
    phoneno: fieldsEdit.phone_number,
    type_of_user: 'user',
  }
    axios.put('https://api.ezioskillacademy.com/api/admin/user/'+fieldsEdit._id,jsonData,{
      headers
    })
    .then(res=>{
   // // console.log("res====",res.data,fieldsEdit)
      
        setEditPopup(false)
        GetUserList()
     
    }).catch(err=>{
      alert(err)
    })
  }

  const handleValue=(e)=>{
      Getfields({ ...fields, [e.target.name]:e.target.value })
    }
    const handleEditValue=(e)=>{
        GetfieldsEdit({ ...fieldsEdit, [e.target.name]:e.target.value })
      }
    const setEditPopupOpen=(id)=>{
       // GetUserFields(id)
        setEditPopup(true)       

    }

    function handleViewUser(id){
      axios.get(`https://api.ezioskillacademy.com/api/admin/user/${id}`,{headers:{
        Authorization:`Bearer ${Cookies.get('token')}`
      } })
        .then(res => {
          // console.log("response",res.data)     
          setUserDetail(res.data.data.user)
         // GetData(res.data.data)  
                      
                  
        }).catch((err)=>{
             // console.log(err)
            alert("Something Wrong.. Please Login again...")
        
        })
    }

  const changeActive=(e,id)=>{
    // console.log(e.target.value)
    if(e.target.value=='enable'){
      axios.post(`https://api.ezioskillacademy.com/api/admin/user/${id}`,{},{headers:{
        Authorization:`Bearer ${Cookies.get('token')}`
      } })
        .then(res => {
          // console.log("response",res.data)
          setTosOpen(res.data.message)   
         // GetData(res.data.data)   
         GetUserList()                     
                  
        }).catch((err)=>{
            alert("Something Wrong.. Please Try again...",err)        
        })
    }
    else if(e.target.value=='disable'){
      const reason=prompt('Enter the Reason:')
      // console.log({reason})
      if(reason){
          axios.delete(`https://api.ezioskillacademy.com/api/admin/user/${id}`,{headers:{
        Authorization:`Bearer ${Cookies.get('token')}`
         } ,
          data:{
            'remarks':reason
          }})
        .then(res => {
          // console.log("response",res.data.message)   
          setTosOpen(res.data.message) 
          GetUserList()
         // GetData(res.data.data)  
         $('#flexRadioDefault2'+id).attr('checked', true);                      
                  
        }).catch((err)=>{
            alert("Something Wrong.. Please Try again...",err)      
            $('#flexRadioDefault1'+id).attr('checked', true);  
        })
     }
      else{
        $('#flexRadioDefault1'+id).attr('checked', true);
      }     
    } 
  }

  const selectUser=(e,user)=>{
    // console.log("user..",user,e.target.checked)
    if(e.target.checked){
      setSelectedUserList([...selectedUserList,user])
    }else{
      setSelectedUserList(selectedUserList.filter((li)=>li!==user))
    }
   
  }
  function getBatchList(){
    axios.get(`https://api.ezioskillacademy.com/api/admin/batch`, {headers:{
      Authorization:`Bearer ${Cookies.get('token')}`
    } })
    .then(res => {
      // console.log("batch",res.data)
      setBatchList(res.data.data)    
    }).catch(e=>{
      alert(e.response.data.message)
    })
  }

  const openBatchPopup=()=>{
    setBatchAllocationPopup(true) 
  }

  const allocateBatch=()=>{
    // console.log(selectedUserList.length>0 , selectedBatch)
    if(selectedUserList.length>0 && selectedBatch){
    let filteredDetails=[]
    selectedUserList.map((user)=>{
      filteredDetails.push({
        user_id:user._id,
        name:user.name,
      })
    })
    const jsonData={
      "batch_id": selectedBatch,
      "user": filteredDetails
    }
    axios.post('https://api.ezioskillacademy.com/api/admin/batch/allocate',jsonData,{headers:{
      Authorization:`Bearer ${Cookies.get('token')}`
    } })
  .then(res=>{
      // console.log("res",res.data)
     
          $('#allocate_scs_msg').html(res.data.message)
          setTosOpen(res.data.message)
          GetUserList()
          setSelectedBatch(undefined)
          setSelectedUserList([])
        //  setTimeout(()=>{
              setBatchAllocationPopup(false)      
       //   },3000)
          
      
  }).catch(e=>{
     alert(e.response.data.message)
  })
}
else{
  alert('Please Select Required Data')
}

  }
  // console.log({selectedUserList})
  return (
    <div className="dash_page_bg dash_section_ptb add_payment">
        {/* <button className="add_payment_btn" 
       onClick={() => setAddPopup(true)}
        >
          Add User
        </button> */}
        <div style={{width:'100%',display:'flex'}}>
        <select onChange={(e)=>{setFilteredUser(e.target.value)}} style={{padding:'10px',height:'45px'}} >
          <option value={'all'}>All</option>
          <option value={'true'}>Batch Assigned user</option>
          <option value={'false'}>Batch Not assigned user</option>
        </select>
        <select style={{padding:'10px',height:'45px',margin:'0 20px'}} onChange={(e)=>setBatchForFilter(e.target.value)}>
              <option disabled selected>Select Batch</option>
              {
                batchList.map(batch=><option value={batch._id} >{batch.batch_name}</option>)
              }
               <option value={'none'}>No Filter</option>
            </select>
        {
          selectedUserList.length>0&&(
            <div>
            <button className="add_payment_btn" style={{marginBottom:'10px'}} onClick={openBatchPopup}>Select Batch For Allocation</button>
            <div style={{display:'flex',flexWrap:'wrap',width:'500px'}}>
            {/* {
              selectedUserList.map((user)=>(
                <p style={{margin:'0 10px',fontSize:'10px'}}>{user.name}</p>
              ))
            } */}
            </div>
           
          </div>
          )
        }
       
        </div>
       
        <br />
        <br />
        <div className="clientrate_table">
          <div className="tabcomp-style">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <td style={{width:'60px'}}></td>   
                    <td>Name</td>
                    <td>Email</td>
                    <td>Phone Number</td>    
                    <td>Paid</td>    
                    <td>Batch Allocation</td>    
                    <td style={{width:'400px'}}></td>                   
                  </tr>
                </thead>
                <tbody>
                  {Datas.length > 0 ? (
                    <>
                      {Datas
                        .map((user, id) => {
                          let user1=user 
                          
                          return (                          
                            <tr key={id}>
                              <td>
                                {!user1.batch?.isBatchAllocated&&(
                                  <input type='checkbox' onChange={(e)=>selectUser(e,user1)}/>
                                )}
                                
                              </td>
                              <td>{user1.name} </td>
                              <td>{user1.email}</td>
                              <td>{user1.phoneno}</td>  
                              <td>{user1.isActive?'Paid':'Not Paid'}</td>  
                              <td>{user1.batch?.isBatchAllocated?"Allocated":'Not Allocated'}</td> 
                              <td style={{display:'flex',justifyContent:'center',width:'400px'}}>
                                  <button onClick={()=>setEditPopupOpen(user1._id)} className="blue_btn">Edit</button>
                                  <button onClick={()=>handleViewUser(user1._id)} className="green_btn">View</button>
                                  
                                  <div onChange={(e)=>changeActive(e,user1._id)} style={{display:'flex'}}>
                                    <input class="radio" id={"flexRadioDefault1"+user1._id} type="radio" name={"flexRadioDefault"+user1._id}  value="enable" checked={user1.isEnable}/>
                                    <label for={"flexRadioDefault1"+user1._id}>
                                      Enable
                                    </label>
                                    &nbsp;&nbsp;
                                    <input class="radio" id={"flexRadioDefault2"+user1._id} type="radio" name={"flexRadioDefault"+user1._id}  value="disable" checked={!user1.isEnable}/>
                                    <label for={"flexRadioDefault2"+user1._id}>
                                      Disable
                                    </label>
                                  </div>
                                
                                 
                                  </td>                         
                            </tr>
                          )
                        }
                        
                      )}
                    </>
                  ) : (
                    <tr>
                      <td colSpan="7" style={{ textAlign: "center" }}>
                        No data
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {/* pagination */}
        <div style={{ paddingTop: "32px" }}>
          {/* <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            pageCount={Math.ceil(paymentList.length / cardsPerPage)}
            onPageChange={changePage}
            containerClassName={"paginationBttns"}
            previousLinkClassName={"previousBttn"}
            nextLinkClassName={"nextBttn"}
            disabledClassName={"paginationDisabled"}
            activeClassName={"paginationActive"}
          /> */}
        </div>
        {/* pagination */}
        

      <CustomModal open={editPopup}>
        <div className="scsmdl-container" style={{ height: "auto" }}>
          <div className="scsmdl-header">
            <p>Edit User</p>
            {/* <img src={'/modalclose.jpg'} className="scsmdl-closeimg"  /> */}
            <img
              src={"/modalclose.jpg"}
              className="scsmdl-closeimg"
              onClick={() => setEditPopup(false)}
              alt="close img"
            />
          </div>
          <div className="scsmdl-body" style={{ padding: "20px" }}>
          <select style={{width:'100%',height:'40px',border: '1px solid #e8e8e8',margin:'15px 0'}} onChange={(e)=>setSelectedBatch(e.target.value)}>
              <option disabled selected>Select Batch</option>
              {
                batchList.map(batch=><option value={batch._id} >{batch.batch_name}</option>)
              }
            </select>
            <div className="row">
              <div className="col-lg-5">
                <button
                  onClick={editUser}
                  className="addpayment-submitbtn"
               //   disabled={loader}
                //  style={loader ? { opacity: "0.5" } : {}}
                >
                  Submit
                </button>
              </div>
             
            </div>
             {/* <p style={{ padding: "0", color: "red" }}>{error.failed_status}</p> */}
          </div>
        </div>
      </CustomModal>


      <CustomModal open={userDetail}>
        <div className="scsmdl-container" style={{ height: "auto" }}>
          <div className="scsmdl-header">
            <p>User Details</p>
            {/* <img src={'/modalclose.jpg'} className="scsmdl-closeimg"  /> */}
            <img
              src={"/modalclose.jpg"}
              className="scsmdl-closeimg"
              onClick={() => setUserDetail(undefined)}
              alt="close img"
            />
          </div>
          <div className="scsmdl-body" style={{ padding: "20px" }}>
          <div style={{display:'grid',gridTemplateColumns:'1fr 2fr'}}>
            <div><b>Name:</b></div>
            <div>{userDetail?.name}</div>

            <div><b>Phone No:</b></div>
            <div>{userDetail?.phoneno}</div>

            <div><b>Email:</b></div>
            <div>{userDetail?.email}</div>

            <div><b>Batch Allocated:</b></div>
            <div>{userDetail?.batch.isBatchAllocated?"Allocated":"Not Allocated"}</div>

            <div></div>
            <div></div>
          </div>
           
           
          </div>
        </div>
      </CustomModal>

      <CustomModal open={batchAllocationPopup}>
        <div className="scsmdl-container" style={{ height: "auto" }}>
          <div className="scsmdl-header">
            <p>Batch Allocation</p>
            {/* <img src={'/modalclose.jpg'} className="scsmdl-closeimg"  /> */}
            <img
              src={"/modalclose.jpg"}
              className="scsmdl-closeimg"
              onClick={() => setBatchAllocationPopup(false)}
              alt="close img"
            />
          </div>
          <div className="scsmdl-body" style={{ padding: "20px" }}>
          
          <ul>
            {
              selectedUserList.map(user=>(<li style={{listStyle:'inside'}}>{user.name}</li>))
            }
          </ul>
            <select style={{width:'100%',height:'40px',border: '1px solid #e8e8e8',margin:'15px 0'}} onChange={(e)=>setSelectedBatch(e.target.value)}>
              <option disabled selected>Select Batch</option>
              {
                batchList.map(batch=><option value={batch._id} >{batch.batch_name}</option>)
              }
            </select>
          
               <button
                  onClick={allocateBatch}
                  className="addpayment-submitbtn"
                >
                  Submit
                </button>
                <p id="allocate_scs_msg" style={{color:'green'}}></p>
          </div>
        </div>
      </CustomModal>
      <Toaster tosOpen={tosOpen} setTosOpen={setTosOpen}/>
      </div>
  )
}

export default UserManagement