import React from 'react'

export default function UserIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="34.965" height="35.17" viewBox="0 0 34.965 35.17">
      <g id="user" transform="translate(-0.311 -0.223)">
        <path id="Path_14025" data-name="Path 14025" d="M17.482,0A17.482,17.482,0,1,1,0,17.482,17.482,17.482,0,0,1,17.482,0Z" transform="translate(0.311 0.224)" fill="#f2f2f2"/>
        <path id="Path_14024" data-name="Path 14024" d="M89.731,87.5a6.535,6.535,0,0,0-2.712-1.252l-4.827-.968a1.089,1.089,0,0,1-.872-1.078V83.1a13.225,13.225,0,0,0,.909-1.62,8.893,8.893,0,0,1,.775-1.358,7.2,7.2,0,0,0,2.214-3.506,3.381,3.381,0,0,0-.31-2.6,19.5,19.5,0,0,0-.376-4.448A4.856,4.856,0,0,0,83.386,66a3.4,3.4,0,0,0-2.045-.858,3.572,3.572,0,0,1-.751-.176A7.767,7.767,0,0,0,76.873,64c-2.9.119-6.462,1.963-7.655,5.251a12.492,12.492,0,0,0-.3,3.966l-.029.795a3.525,3.525,0,0,0-.288,2.6,7.183,7.183,0,0,0,2.23,3.524,9.663,9.663,0,0,1,.773,1.351,13.68,13.68,0,0,0,.916,1.613V84.2a1.094,1.094,0,0,1-.876,1.078l-4.831.968a6.55,6.55,0,0,0-2.7,1.25,1.1,1.1,0,0,0-.13,1.6,17.522,17.522,0,0,0,25.883,0,1.1,1.1,0,0,0-.13-1.6Z" transform="translate(-59.126 -59.419)" fill="#455a64"/>
      </g>
    </svg>
  )
}
