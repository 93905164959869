import axios from "axios";
import Cookies from "js-cookie";
import React from 'react'

export default async function RazorpayPay(package_id,setPaymentSuccess) {
    //// console.log("from razorpay",package_id)

      
    const response=await axios.post('https://api.ezioskillacademy.com/api/user/payment/order/'+package_id,{},{headers:{
          Authorization:`Bearer ${Cookies.get('token')}`
        } })
      // .then(res=>{
      //     // console.log("res",res.data.data)
      //     data=res.data.data
             
      // }).catch(e=>{
      //    alert(e.response.data.message)
      // })
    const data=response.data.data
  
    // console.log("raz_data",data);
  
    const options = {
      key: "rzp_test_iOvgI7iSDAdSyX",
      currency: data.currency,
      amount: data.amount,
      name: "Ezio Skill Academy",
      description: "Transaction",
      image: require('../../../images/website/Ezio Skill Academy Logo/Ezio Academy Logo Png (2).png'),
      order_id: data.id,
      handler: async(res) => {

        // console.log(res)
        
        let data = {        
        ...res,        
        package_id:package_id,        
        }        
        axios.post("https://api.ezioskillacademy.com/api/user/payment/order/success", {
        ...data       
        },{headers:{
          Authorization:`Bearer ${Cookies.get('token')}`
        } })
        .then(res=>{
          // console.log("res",res.data)
          setPaymentSuccess(res.data.message)
          //return res.data.message

      }).catch(e=>{
         alert(e.response.data.message)
      })
        
        },
      prefill: {
        name: "SivaKrishnan",
        email: "siva@gmail.com",
        contact: "8248505046x`",
      },
    };
  
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }
  
