import React, { Component,useEffect,useState } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    NavLink ,
    Link
  } from "react-router-dom";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Index from '../index'
// import {routes} from '../index'
import Background from '../../../images/banner.png'
import Video from './../../video';
import Discussions from './../../discussions';
import { course_detail } from './data/course_details_arr';
import { Breadcrumbs, Typography } from '@material-ui/core';
import Notifications from '../../../images/iconsSVG/Notification';
import axios from 'axios';
import Cookies from 'js-cookie'
import { useDispatch } from 'react-redux'
import PdfViewer from '../../pdf_viewer';
import parser from 'html-react-parser';


const headers={
	Authorization:`Bearer ${Cookies.get('token')}`
}

const LessonsDetail = () => {
	const dispatch=useDispatch()

	const [currentVideo,setCurrentVideo]=useState()
	const [ notifyCount, setNotifyCount ] = useState();
	const [currentSection,setCurrentSection] = useState();
	const [courseId,setCourseId]= useState()
	const [sectionId,setSectionId]=useState()
	const [videoId,setVideoId]=useState()
	const [sectionDetails, setSectionDetails] = useState([])
	const [videoList,setVideoList] = useState([])
	const [videoDetails,setVideoDetails] = useState()
	const [ blobUrl, setBlobUrl ] = useState()

	useEffect(()=>{
		let pathArr=window.location.pathname.split('/')
		let _courseid=pathArr[pathArr.length-3]
		let _sectionid=pathArr[pathArr.length-2]
		let _videoid=pathArr[pathArr.length-1]
		setCourseId(_courseid)
		//GetSectionList(_courseid,_sectionid)
		getVideoDetail(_videoid)
		setSectionId(_sectionid)
		getVideolist(_sectionid,_videoid)

		setVideoId(_videoid)

		window.onscroll=()=>{
			// console.log('scrolling',document.documentElement.scrollTop,window.innerWidth)
			if(document.getElementById('videolist_right_bar')){
				let rightsidebar=document.getElementById('videolist_right_bar').style
				if(document.documentElement.scrollTop,window.innerWidth>1200){
					if(document.documentElement.scrollTop>100){
						rightsidebar.position="fixed"
						rightsidebar.width="21%"
					}else{
						rightsidebar.position="relative"
						rightsidebar.width="25%"
					}
				}
			}
		
			
		}

	},[])

	function getVideoDetail(id){
		axios.get(`https://api.ezioskillacademy.com/api/user/video/`+id, { headers:{
			Authorization:`Bearer ${Cookies.get('token')}`
		} })
		.then(res => {  
					  // console.log("video details",res.data.data)					  
					  setVideoDetails(res.data.data)
						// if (res.data.data.sub_section_type=='document') {
						// 	blobImages(res.data.data.sub_section_file) 
						// }
		}).catch(e=>{
		  alert(e.response.data.message)
		})
	}
	const GetSectionList = (course_id,section_id) =>{
    
		axios.get(`https://api.ezioskillacademy.com/api/user/video/section/`+course_id, { headers:{
			Authorization:`Bearer ${Cookies.get('token')}`
		} })
		.then(res => {  
					  // console.log("sectionlist",res.data.data)					  
					  setSectionDetails(res.data.data.sections.find(section=>section._id==section_id))     
		}).catch(e=>{
		  alert(e.response.data.message)
		})
	}

	const getVideolist = (section_id,video_id) => {
		axios.get(`https://api.ezioskillacademy.com/api/user/video/sub-section/${section_id}`, { headers:{
			Authorization:`Bearer ${Cookies.get('token')}`
		  } })
		  .then((res) => {
					// console.log("videol", res.data.data);
					setSectionDetails(res.data.data.sections)
					setVideoList(res.data.data.sub_section)
					

					let d=res.data.data.sub_section.find(video=>{
						// console.log(video._id,video_id)
						return video._id===video_id}
						)

					// setVideoDetails(d)
					// console.log('subsec',res.data.data)
					// blobImages()
					dispatch({
						type:'USER_BREADCRUMPS',
						breadcrump:[
							{name:'course',url:'/course/learn'},
					{name:res.data.data.course.course_name,url:`/course/learn/${res.data.data.course._id}`},
					{name:res.data.data.sections.section_name,url:`/course/learn/${res.data.data.course._id}/${res.data.data.sections._id}`},
					{name:d.sub_title,url:``},
				]})   


					//// console.log({d})
					//setVideoList(res.data.data)
					// if (!res.data.status) {
					// 	window.location.href = "/course"
					// }
		  })
	  };

	// useEffect(() => {
	//  axios.get('https://api.ezioskillacademy.com/api/notification/count',{ headers:{
	// 	Authorization:`Bearer ${Cookies.get('token')}`
	//   } })
	// 	.then((res)=>{
	// 		setNotifyCount(res.data.notify_count)
	//  })
 	// }, [])
	const blobImages = async (pdf_url) => {
		await fetch(pdf_url)
      .then(res => res.blob()) // Gets the response and returns it as a blob
      .then(blob => {
        let objectURL = URL.createObjectURL(blob);
				setBlobUrl(objectURL)
			});
	}

	return (
			<div className='video_page_con'>
				{
					videoDetails&&videoList.length>0&&courseId&&sectionId&&sectionDetails?(
						<section style={{backgroundColor:'#e9f1fe',height:'fit-content',padding:'0'}}>
							
					<div style={{height:'fit-content'}}>
						<div className='col-lg-9 padding_none'>
						
							{/* <img src={require('../../../images/training.png')} alt='' /> */}
							{/**/}
							{
								videoDetails.sub_section_type=='video'?(
									<Video 
									video={videoDetails} 
									courseId={courseId} 
									sectionId={sectionId} 
									videoList={videoList}
									videoId={videoId}
									thumbnail={videoDetails.thumbnail}
									/> 	
								):videoDetails.sub_section_type=='document'?(
									// blobImages()
									<>
										{/* { // console.log("Blob: ", () => blobImages()) } */}
										<PdfViewer videoId={videoId} sub_section_file={videoDetails.sub_section_file}/>
									</>
								):null
							}
							
							
							<div style={{padding:'20px'}}>
							<h1>{videoDetails.sub_title}</h1>
							{/* <h1>Notes:</h1> */}
							{
								 parser(videoDetails.description)
							}
							</div>
							
							{/* <Discussions/> */}

					
						</div>
					 <div id='videolist_right_bar' className='col-lg-3 ta_set padding_none'  style={{height:'100%'}}>
							<h5>Lesson 1:</h5>
							<p>{sectionDetails.section_name}</p>
							
							<section id="tabs" className='video_choose' style={{marginTop:'0'}}>
									<div className="">
										<div className="row margin_none">
											<div className="col-lg-12 padding_none">
												 
												
												<>
												
												 <hr style={{margin:'12px 0 0 0'}}/>
													<>
													 <Accordion allowZeroExpanded>

													{
														videoList.map((video,index)=>(
													<AccordionItem key={index}>
														<AccordionItemHeading>
															<AccordionItemButton>
																<span>{index+1}</span> {video.sub_title}
																<p className='timerSet'><i className="fa fa-clock-o" aria-hidden="true"></i> &nbsp;{parseFloat(video.duration/60).toFixed(2)}</p>
															</AccordionItemButton>
														</AccordionItemHeading>
														<AccordionItemPanel>
															<a href={`/course/learn/${courseId}/${sectionId}/${video._id}`} className='innerVideo'>
																 <span><i className="fa fa-youtube-play" aria-hidden="true"></i></span>
															 <span>{video.sub_title}</span>
															 <span>{parseFloat(video.duration/60).toFixed(2)}</span>
															</a>
														</AccordionItemPanel>
													</AccordionItem>
														))	
													}
													
														
												</Accordion>

													</>
												
												  </>
											 
												 
											
											</div>
										</div>
									</div>
								</section>
						</div> 

				 </div>
				 </section>
					):(
						<h1>Loading</h1>
					)	
				}	  
			  
		   </div>
    )
}

export default LessonsDetail
















/*

import React, { Component,useEffect,useState } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    NavLink ,
    Link
  } from "react-router-dom";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Index from '../index'
// import {routes} from '../index'
import Background from '../../../images/banner.png'
import Video from './../../video';
import Discussions from './../../discussions';
import { course_detail } from './course_details_arr';

const LessonsDetail = () => {
	const [currentVideo,setCurrentVideo]=useState()
	
	const [currentSection,setCurrentSection] = useState();

	useEffect(()=>{
		//course_detail
		//setCurrentVideo()
		let _currentSection=course_detail.filter(course=>course.section_url===window.location.pathname.split('/')[2])[0]
		setCurrentSection(_currentSection)
		// console.log(_currentSection	.videos.filter(video=>video.video_url===window.location.pathname.split('/')[3])[0])
		setCurrentVideo(_currentSection	.videos.filter(video=>video.video_url===window.location.pathname.split('/')[3])[0])
	},[])

   return (
           <div>
				{
					currentVideo&&(
						<>
				<div className='lessons_only'>
						<div className='lat_activity'>					
							<div className='up_section'>
								<div className='row'>
									<div className='col-lg-6 col-md-6'>
										<p>video {currentVideo.vid}</p>
										<h1>{currentVideo.video_name}</h1>
									</div>
										</div>
										</div>					
									 </div>
								</div>
								<div>
									<div className='col-lg-9 padding_none'>
									
										
										<Video/>
										
			
								
									</div>
									<div className='col-lg-3 ta_set padding_none'>
										<h5>Lesson 1:</h5>
										<p>{currentSection	.section_name}</p>
										
										<section id="tabs" className='video_choose'>
												<div className="">
													<div className="row margin_none">
														<div className="col-lg-12 padding_none">
															 
															
															<Tabs>
																<TabList>
																  <Tab>
																  <p className="nav-item nav-link active" >Table Of Contents</p>
																  </Tab>
																  <Tab><p className="nav-item nav-link out_note" >Notes</p></Tab>
																</TabList>
															 
																<TabPanel>
																 <Accordion allowZeroExpanded>
			
																{
																	currentSection	.videos.map((video,index)=>(
																<AccordionItem>
																	<AccordionItemHeading>
																		<AccordionItemButton>
																			<span>{index+1}</span> {video.video_name}
																			<p className='timerSet'><i className="fa fa-clock-o" aria-hidden="true"></i> 2m 25s</p>
																		</AccordionItemButton>
																	</AccordionItemHeading>
																	<AccordionItemPanel>
																		<a href={`/course/${currentSection	.section_url}/${video.video_url}`} className='innerVideo'>
																			 <span><i className="fa fa-youtube-play" aria-hidden="true"></i></span>
																		 <span>{video.video_name}</span>
																		 <span>2m 25s</span>
																		</a>
																	</AccordionItemPanel>
																</AccordionItem>
																	))	
																}
																
																	
															</Accordion>
			
																</TabPanel>
																<TabPanel>
																   <p className='below_set'>Et et consectetur ipsum labore excepteur est proident excepteur ad velit occaecat qui minim occaecat veniam. Fugiat veniam incididunt anim aliqua enim pariatur veniam sunt est aute sit dolor anim. Velit non irure adipisicing aliqua ullamco irure incididunt irure non esse consectetur nostrud minim non minim occaecat. Amet duis do nisi duis veniam non est eiusmod tempor incididunt tempor dolor ipsum in qui sit. Exercitation mollit sit culpa nisi culpa non adipisicing reprehenderit do dolore. Duis reprehenderit occaecat anim ullamco ad duis occaecat ex.</p>
																</TabPanel>
															  </Tabs>
														 
															 
														
														</div>
													</div>
												</div>
											</section>
									</div>
							 </div>
							 </>
								)	
							}	  
						  
					   </div>
				)
			}
			
			export default LessonsDetail

*/