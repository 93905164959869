import React from 'react'
import { ourCourses } from '../../data/our_courses'
import Slider from "react-slick";
import Arrow from '../../../../../images/iconsSVG/Arrow';
function MoreCourse(props) {
    const more_courses_settings = {
        dots: true,
        infinite: true,
        speed: 500,
        autoplay: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 993,
            settings: {
              arrows: false,
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 768,
            settings: {
              arrows: false,
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              arrows: false,
              slidesToShow: 1,
            },
          },
         
        ],
      };
  return (
    <section id='more_courses' className='more_digital_marketing_courses'>
    <div className='container'>
      <h2>More Digital Marketing Courses</h2>
    <div className='row'>
    <Slider {...more_courses_settings}>
      {
        ourCourses.filter(c=>c.url!=="/"+props.url).map(course=>(
          <div>
          <div className='more_digital_marketing_courses_list'>
          <img src={require(`../../../../../images/website/Our-Courses-Syllabus/${course.image}.jpg`)}/>
          <div>
            {/* <div><span>3 Months</span> <span>12 Hours</span></div> */}
            <h4>{course.name}</h4>
            <p dangerouslySetInnerHTML={{ __html: course.description }}></p>
            <div><span><img src={require('../../../../../images/website/Courses_page/star-1.png')}/> {course.rating }</span> <a style={course.url===''?{pointerEvents:'none'}:{}} href={course.url}>Learn More &nbsp;<Arrow/></a></div>
          </div>
          </div>
        </div>
        ))
      }
       
    
    </Slider>
    </div>
</div>

</section> 
  )
}

export default MoreCourse