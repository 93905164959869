import React, { Component, useEffect,useState } from 'react';

import $ from "jquery";
import { withRouter } from 'react-router'

import {
    BrowserRouter as Router,
    Switch,
    Route,
    NavLink ,
    Link,
    useLocation 
    
  } from "react-router-dom";
import { routes } from './routes.js';
import { useHistory } from 'react-router-dom';
import DbCourse from './../../images/iconsSVG/DbCourse';
import DbCertificate from './../../images/iconsSVG/DbCertificate';
import DbFaq from './../../images/iconsSVG/DbFaq';
import DbLogout from './../../images/iconsSVG/DbLogout';
import DbChat from './../../images/iconsSVG/DbChat';
// import DbNotification from 
import {Redirect} from 'react-router-dom'
import Cookies from 'js-cookie';
import { Breadcrumbs, Typography } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux'
import socketClient from "socket.io-client";
import Notifications from '../../images/iconsSVG/Notification.js';
import axios from 'axios';
import Mentor_chat_class_comp from './user/mentor_chat_class_comp.js';
import DbPayment from '../../images/iconsSVG/DbPayment.js';
import moment from 'moment';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';


const Index = (props) => {
  const reduxValue=useSelector(state=>state)
  const dispatch=useDispatch()

  const history=useHistory()
  const location=useLocation()
  const [path,setPath]=useState()

  const [ notificationList, setNotificationList ] = useState();
  const [showNotification,setShowNotification]= useState(false);
  const [notificationCount,setNotificationCount]= useState();
 
  const [score]=useState(localStorage.getItem('user_id')=='6296e6cfd2168d36ddec272e'?56:localStorage.getItem('user_id')=='6296e6f9d2168d36ddec2732'?56:localStorage.getItem('user_id')=='6296e717d2168d36ddec2736'?69:localStorage.getItem('user_id')=='6296e752d2168d36ddec273a'?59:null)

  useEffect(()=>{
    // document.addEventListener('contextmenu', (e) => {
    //   e.preventDefault();
    // });

    // document.onkeydown = function(e) {
    //   if(e.keyCode == 123) {
    //      return false;
    //   }
    //   if(e.ctrlKey && e.shiftKey && e.keyCode == 'I'.charCodeAt(0)) {
    //      return false;
    //   }
    //   if(e.ctrlKey && e.shiftKey && e.keyCode == 'C'.charCodeAt(0)) {
    //      return false;
    //   }
    //   if(e.ctrlKey && e.shiftKey && e.keyCode == 'J'.charCodeAt(0)) {
    //      return false;
    //   }
    //   if(e.ctrlKey && e.keyCode == 'U'.charCodeAt(0)) {
    //      return false;
    //   }
    // }

   
   
    setPath(location.pathname)
    
    // console.log("path",location.pathname)
    $('#bs-sidebar-navbar-collapse-1').removeClass('show')

 

  },[location.pathname])

  useEffect(()=>{
    if(reduxValue){
      // console.log('reduxValue',reduxValue)
    }
  },[reduxValue])
 
useEffect(()=>{
  var socket = socketClient("https://api.ezioskillacademy.com", {
    transports: ['websocket', 'polling', 'flashsocket'],        
    query: { token: `Bearer ${Cookies.get('token')}` }        
  });
  socket.on('connection', () => {
    // console.log('index page Connected')
  });
  socket.on('notification', message => {
    // console.log(socket,"connected")
    // console.log('notification from parent', message)
    setNotificationCount(message?.notifocation_count)
  });
  socket.emit('get-notification-count',null,(n)=>{

    // console.log('noti',n)
    
    })
},[])
const activeNotificationPopup=()=>{
  let show=showNotification
  setShowNotification(!show)
  if(!show){
  axios.get('https://api.ezioskillacademy.com/api/notification/list', { headers:{
      Authorization:`Bearer ${Cookies.get('token')}`
    } })
      .then((res) => {        
        setNotificationList(res.data.data)
      });
    }

}

const mentorChatHandler=()=>{
  $('#mentor_chat').toggleClass('mentor_chat_wrapper_active')
}

const Logout=()=>{
  localStorage.clear()  
  Cookies.remove('token')
}
        return (
		<div >
		 							
		      {/* {console.log("path",location.pathname)} */}
          <div className='row r_dahboard m-0' >
          <div className='lesson_page '>
		   
       <div id="db_user_sidebar_con" className='col-lg-2 col-md-3 col-sm-3 padding_none sidebar_out'>
       <nav  className="navbar navbar-inverse sidebar " role="navigation" >
          <div className="container-fluid" style={{backgroundColor:'#0c2541'}}>
            <div className="navbar-header" style={{backgroundColor:'#0c2541'}}>
              <button type="button" className="navbar-toggle" data-toggle="collapse" data-target="#bs-sidebar-navbar-collapse-1">
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </button>
              {/* <a className="navbar-brand" href="#"><img src={require('../../images/logo.png')} alt='logo' /></a> */}
              <a  className="navbar-brand" href="/course/learn" ><img src={require('../../images/dashboard/ezio_db_logo_white.svg')} style={{maxHeight:'100px',margin:'auto'}} alt='logo' /></a>
          
            </div>
  
            <div className="collapse navbar-collapse" id="bs-sidebar-navbar-collapse-1">
              <ul className="nav navbar-nav user_sidebar" >
               {/* <li className="active"><NavLink to='/course/lessons'><span><img src={require('../../images/home.png')} alt ='' /></span> <span>Home</span></NavLink></li> */}
               <li className=""><Link id="user_sidebar" className={path?.includes('/course/learn')?'user_active_sidebar':''} to='/course/learn'><span><DbCourse/></span> <span>Courses</span></Link></li>
               <li className=""><Link id="user_sidebar" className={path==='/course/chat'?'user_active_sidebar':''} to='/course/chat'><span><DbChat/></span> <span>Chat</span></Link></li>
               <li className=""><Link id="user_sidebar" className={path==='/course/certificate'?'user_active_sidebar':''} to='/course/certificate'><span><DbCertificate /></span> <span>Certificate</span></Link></li>
               <li className="">
                 {/* <Link id="user_sidebar" className={path==='/course/payment'?'user_active_sidebar':''} to='/course/payment'><span><DbPayment /></span> <span>Payment</span></Link> */}
                 </li>
               {/* <li ><NavLink  to='/dashboard/settings'><span><img src={require('../../images/dashboard/ng')} alt ='' /></span> <span> Settings</span></NavLink></li> */}
               <li className='last_bottom_nav'><Link className={path==='/course/faq'?'user_active_sidebar':''} to='/course/faq'> <span><DbFaq /></span> <span>FAQ</span></Link></li>
               <li className='last_bottom_nav' onClick={Logout}><Link to='/login'> <span><DbLogout /></span> <span>Logout</span></Link></li>
              </ul>
            </div>
          </div>
        </nav>
                </div>
      
  <div className='col-lg-10 col-md-9 col-sm-9  sh_content'>	
  <div className='lessons_only course_list_top white_bg'>
	  <div className='lat_activity' >					
					<div className='up_section' style={{width:'100%'}}>
						<div className='row'>
						<div className='col-lg-12' style={{display:'flex',justifyContent:'space-between'}}>
              {
                path&&!path.includes('/course/learn')?(
                <div>        
                  <Breadcrumbs aria-label="breadcrumb">
                          <Link color="inherit" to="/course/learn" >
                            Course
                          </Link>
                          <Typography color="textPrimary" style={{textTransform:'capitalize'}}>{path.split('/')[2]}</Typography>
                  </Breadcrumbs> 
                  </div>	
                ):(
                  <>
                  {/* // console.log('bread',JSON.parse(localStorage.getItem('breadcrumps'))) */}
                  <Breadcrumbs aria-label="breadcrumb">
                  {
                    reduxValue?.breadcrump?.map((bread,i,row)=>(
                      
                          <Link color="inherit" to={bread.url} style={i + 1 === row.length?{color:'rgb(0, 177, 112)',fontWeight:'bold',pointerEvents:'none'}:{}} >
                            {/* { console.log('row',row,i)} */}
                            {bread.name}
                          </Link>
        
                 
                    ))
                    }
                   </Breadcrumbs> 
                  </>
                //   <h1><img src={require('../../images/book.png')} style={{height:'28px',marginRight:'10px'}} alt='' />Digital Marketing Course</h1>
                 )
              }
						
						
							<div style={{display:'flex',justifyContent:'space-between',alignItems:'center',minWidth:'90px'}}>								
              {
                score&&(
                  <p style={{marginRight:'20px'}}>Score:<CircularProgressbar value={score} text={`${score}%`} /></p>		
                )
              }

								<span style={{ marginRight: "20px",position:'relative' }}>
									<div onClick={activeNotificationPopup} style={{cursor:'pointer'}}>

                    <Notifications notifyCount={notificationCount} />
                    </div>
                    {
                      showNotification&&(
                    <div className='db_popup_notification'>
                      <ul className='db_popup_notification_list'>
                         {notificationList?.slice(0).reverse()?.slice(0,3).map(noti=>(
                              <li style={noti.receiver[0].isRead?{}:{backgroundColor: '#02b3e41a'}}>
                              <p>{noti.subject}</p>
                              <span> { moment.utc(noti.createdAt).local().format("L LT") }</span>
                            </li>
                        ))}
                       
                      </ul>
                  
                      <button onClick={()=>{history.push('/course/notifications');activeNotificationPopup();}}>See All Notification</button>
                      
                      </div> 	
                      )
                    }
                    

								</span>
								<p style={{textTransform:'capitalize',fontFamily:'circular-regular'}}>Hi, {localStorage.getItem('user_name')} </p>
								<img src={require('../../images/account.png')} alt="" style={{width:'30px',marginLeft:'15px'}}/>
							</div>
  
							</div>						
						</div>
					</div>					
				</div>
      
				</div>
                            <Switch>
                            {routes.map((route, index) => (
                                
                                    <Route
                                    key={index}
                                    path={route.path}
                                    exact={route.exact}
                                    children={<route.main />}
                                    />
                                ))}
                            </Switch>


                            
  </div> 
  </div>                     
             
				 </div>			
         
         <div id='mentor_chat' className="mentor_chat_wrapper">
                            <Mentor_chat_class_comp mentorChatHandler={mentorChatHandler}/>
                            </div>
                            <div className="chat_fixed_icon" onClick={mentorChatHandler} 
                             >
                                <i class="fa fa-comments" aria-hidden="true"></i>
                                </div>	 	
		</div>
        )
    }


export default withRouter(Index)