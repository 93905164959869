const Filter =(state={},action)=>{
    switch(action.type){
        case "USER_DETAILS":
            //// console.log('redux',action.userdetails)
            return {
                ...state,
                userdetails:action.userdetails
            }
    case "USER_BREADCRUMPS":
            return {
                ...state,
                breadcrump:action.breadcrump
            }
    case "PACKAGE_POPUP":
        return {
            ...state,
            package_popup:action.package_popup
        }
    case "MESSAGE_COUNT":
        return {
            ...state,
            message_count:action.message_count
        }
    }
    }
    export default Filter