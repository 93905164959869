import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import CustomModal from './CustomModal';

const headers={
  Authorization:`Bearer ${Cookies.get('token')}`
}

const Courseunlock = () => {
  const [ courselist, setCoutseLits ] = useState();
  const [ unlockModel, setunlockModel ] = useState(false);
  const [ viewCourse, setViewCourse ] = useState();

  useEffect(() => {
    getCourselist()
  }, []);

  const getCourselist = () => {
    axios.get('https://api.ezioskillacademy.com/api/admin/course-active', { headers:{
      Authorization:`Bearer ${Cookies.get('token')}`
    } })
      .then((res) => {
        // console.log(res.data.data);
        setCoutseLits(res.data.data);
      })
  };

  const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const handleModel = (model_status, course_data) => {
    if (model_status === 'open') {
      setunlockModel(true);
      setViewCourse(course_data)
    } else if (model_status === 'close') {
      setunlockModel(false)
      setViewCourse(null)
    }
  }

  const handleConfirm = () => {
    let courseData = {
      ...viewCourse,
      isActive: !viewCourse.isActive
    }
    // // console.log(courseData)
    axios.put('https://api.ezioskillacademy.com/api/admin/course-active', courseData, { headers:{
      Authorization:`Bearer ${Cookies.get('token')}`
    } })
      .then((res) => {
        // console.log(res);
        getCourselist()
        setunlockModel(false)
        setViewCourse(null);
        
      })
  }

  return (
    <>
      <h3>Course Unlock</h3>
      <div className="clientrate_table">
        <div className="tabcomp-style">
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <td>Course ID</td>
                  <td>Course Name</td>
                  <td>Status</td>    
                  <td style={{width:'200px'}}></td>                   
                </tr>
              </thead>
              <tbody>
                { courselist?.length > 0 ?
                  <>
                    {courselist
                      .map((course, id) => (
                        <tr key={id}>
                          <td>{ course.course_id }</td>
                          <td>{ course.course_title.split('-').map(capitalize).join(' ') }</td>
                          <td>{ course.isActive.toString().toUpperCase()}</td>
                          <td>
                            <button 
                              onClick={ () => handleModel('open', course) }
                              style={{ backgroundColor: course.isActive ? 'red' : 'green', border: "none", padding: '10px', width: "50%", borderRadius: '25px', color: 'white' }}
                            >{ course.isActive ? 'Lock' : 'UnLock' } </button>
                          </td>
                        </tr>
                      ))}
                  </> :
                  <tr>
                    <td colSpan="3" style={{ textAlign: "center" }}>
                      No data
                    </td>
                  </tr>
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <CustomModal open = { unlockModel }>
         <div className="scsmdl-container" style={{ height: "auto" }}>
          <div className="scsmdl-header">
            <p>Course Unlock / Lock</p>
            <img
              src={"/modalclose.jpg"}
              className="scsmdl-closeimg"
              onClick={() => handleModel('close', null)}
              alt="close img"
            />
          </div>
          <div style={{ marginTop: '30px', marginBottom: '30px'  }}>
            <div style={{  width: '60%', margin: 'auto', textAlign: 'center', fontSize: '20px'}}>
              Are you sure, you want to { viewCourse?.isActive ? <span style={{ color: 'red' }}>Lock</span> : <span style={{ color: 'green' }}>UnLock</span> } 
              &nbsp;the course <span style={{ fontSize: '22px', fontWeight: 'bold' }}>{ viewCourse?.course_title.split('-').map(capitalize).join(' ') }</span>  ?.
              <div style={{ margin: 'auto', fontSize: '16px', paddingTop: '20px', justifyContent: 'space-between', display: 'flex' }}>
                <button 
                  style={{ backgroundColor: 'grey', border: "none", padding: '10px', width: "35%", borderRadius: '25px', color: 'white' }}
                  onClick = { () => handleModel('close', null) }
                >
                  Cancel
                </button>
                <button 
                  style={{ backgroundColor: 'green', border: "none", padding: '10px', width: "35%", borderRadius: '25px', color: 'white' }}
                  onClick = { handleConfirm }
                >
                  Confirm
                </button>
              </div>
            </div>
            
          </div>
        </div>
      </CustomModal>
    </>
  )
};

export default Courseunlock