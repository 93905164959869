import React from 'react'

export default function FaqIcon() {
  return (
    <svg id="faq_" data-name="faq " xmlns="http://www.w3.org/2000/svg" width="24.43" height="24.43" viewBox="0 0 24.43 24.43">
      <g id="Group_4157" data-name="Group 4157">
        <g id="Group_4156" data-name="Group 4156">
          <circle id="Ellipse_347" data-name="Ellipse 347" cx="1.193" cy="1.193" r="1.193" transform="translate(11.022 16.867)" fill="#000"/>
          <path id="Path_14022" data-name="Path 14022" d="M12.215,0A12.215,12.215,0,1,0,24.43,12.215,12.208,12.208,0,0,0,12.215,0Zm0,22.521A10.306,10.306,0,1,1,22.521,12.215,10.3,10.3,0,0,1,12.215,22.521Z" fill="#fff"/>
          <path id="Path_14023" data-name="Path 14023" d="M179.817,128.5A3.821,3.821,0,0,0,176,132.317a.954.954,0,0,0,1.909,0,1.909,1.909,0,1,1,1.909,1.909.954.954,0,0,0-.954.954v2.386a.954.954,0,1,0,1.909,0v-1.552a3.817,3.817,0,0,0-.954-7.514Z" transform="translate(-167.602 -122.369)" fill="#fff"/>
        </g>
      </g>
    </svg>

  )
}
