import React from 'react';
import Users from './user-management';
import AdminIndex from './index';
import ProjectManagement from './project-management';
import Chat from '../user/chat';
import Certificate from './certificate';
import Courseunlock from './course-unlock';
import BatchManagement from './batch-management';
import MentorManagement from './mentor-management';
import CourseManagementIndex from './course-management/index';
import SocketChatClass from '../user/socket-chat-class_compoent';
import ProjectSubmission from './project-submission';
import PaymentPackage from './payment-package';
import Notification from '../user/notification';



export const superAdminRoutes=[
    {
      path: "/superadmin/user-management",
      exact: true,
      main: () => <Users/>
    },
    {
      path: "/superadmin/mentor-management",
      exact: true,
      main: () => <MentorManagement/>
    },
    {
      path: "/superadmin/batch-management",
      exact: true,
      main: () => <BatchManagement/>
    },
    {
      path: "/superadmin/course",
      exact: false,
      main: () => <CourseManagementIndex/>
    },
    {
      path: "/superadmin/project-management",
      exact: true,
      main: () => <ProjectManagement/>
    },
    {
      path: "/superadmin/project-submission",
      exact: true,
      main: () => <ProjectSubmission/>
    },
    {
      path: "/superadmin/chat",
      exact: true,
      main: () => <SocketChatClass/>
    },
    {
      path: "/superadmin/certificate",
      exact: true,
      main: () => <Certificate/>
    },
    {
      path: "/superadmin/notifications",
      exact: true,
      main: () => <Notification />
    },
    {
      path: "/superadmin/payment-package",
      exact: true,
      main: () => <PaymentPackage />
    },
    // {
    //   path: "/superadmin/course-activation",
    //   exact: true,
    //   main: () => <Courseunlock />
    // },
    {
      path: "/superadmin/*",
      exact: true,
      main: () => <h1>Page Not Found</h1>
    },
    
]