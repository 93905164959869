import React,{useState, useEffect} from 'react'
import { certification_detail } from './data/certification_details_arr'
import {Modal,Fade,Backdrop,makeStyles ,Button, Breadcrumbs, Typography } from '@material-ui/core'
import axios from 'axios';
import Cookies from 'js-cookie';
// import { LinkedinShareButton, LinkedinIcon } from 'react-share';
import { Link } from 'react-router-dom';
import Notifications from '../../../images/iconsSVG/Notification';
import useWindowSize from 'react-use/lib/useWindowSize'
import Confetti from 'react-confetti'
import { FacebookIcon } from '../../../images/iconsSVG/FacebookIcon';
import { InstagramIcon } from '../../../images/iconsSVG/InstagramIcon';
import { LinkedinIcon } from '../../../images/iconsSVG/LinkedinIcon';
import Toaster_Error from '../../toaster_error';


const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const headers={
  Authorization:`Bearer ${Cookies.get('token')}`
}

function Certificate() {
  // const [certificationDetails,setCertificationDetails]
  const classes = useStyles();
  const [open,setOpen]=useState(false)
  const [ user, setUser ] = useState();
  const [selectedCertificate,setSelectedCertificate]=useState()
  const [ certificate, setCertificate ] = useState();
  const [ notifyCount, setNotifyCount ] = useState();

  const { width, height } = useWindowSize()

  const [colorPaper,setColorPaper]=useState(false)

  const [tosOpen,setTosOpen]=useState()

  useEffect(() => {
    axios.get(`https://api.ezioskillacademy.com/api/user/certificate/`, { headers:{
      Authorization:`Bearer ${Cookies.get('token')}`
    } })
    // axios.get(`http://localhost:3000/api/user/certificate/`, { headers })
      .then((res) => {
        // // console.log(res.data)
        setUser(res.data.data)
        setNotifyCount(res.data.notify_count)
      })
      .catch((err) => {
        // console.log(err)
      })
      // setTimeout(()=>{
      //   setColorPaper(true)
      // },3000)
  }, []);

  function certificateOpen(id){
   
    setColorPaper(true)

    const response = certification_detail.filter(cert=>cert.id===id)[0]
    
    const userCertificate = user[response.field];
    if (userCertificate) {
      setOpen(true)
      setSelectedCertificate(response)
      // console.log("response",response)
      setCertificate(user[response.field]);
    } else {
      alert('No Certificate')
    }
  }

  function checkCompletionStatus(id){
    setTosOpen('You have not complete the course')
    if(false){
    axios.get(`https://api.ezioskillacademy.com/api/user/course/completion`, { headers:{
      Authorization:`Bearer ${Cookies.get('token')}`
    } })
		.then((res) => {
				  if(res.data.data){
				  const {video,section}=res.data.data;
				  if(section==9 && video == 46){
            certificateOpen(id)
          }else{
            alert('You have not completed the course')
          }
        }
				 
				  //// console.log("Data1", section,video,currentSection,course_detail,section==currentSection.sid , video==currentSection.videos.length);
				 
		})
    certificateOpen(id)
  }
  }

  const downloadFile = () => {
    window.location.href = certificate
  }

  function handleClose(){
    // console.log('modal closed')
    setOpen(false)
    setColorPaper(false)
  }
  return (
    <>
    {/* <div className='lessons_only course_list_top white_bg'>
	  <div className='lat_activity' >					
					<div className='up_section' style={{width:'100%'}}>
						<div className='row'>
						<div className='col-lg-12' style={{display:'flex',justifyContent:'space-between'}}>
						
            <div>
							<h1>CERTIFICATE</h1>
              <Breadcrumbs aria-label="breadcrumb">
                        <Link color="inherit" to="/course" >
                          Course
                        </Link>
                        <Typography color="textPrimary">Chat</Typography>
                </Breadcrumbs> 
                </div>
											
							<div style={{display:'flex',justifyContent:'space-between',alignItems:'center',minWidth:'90px'}}>				
                <span style={{ marginRight: "20px" }}>
                  <a href='/course/notifications'><Notifications notifyCount = { notifyCount ? notifyCount : 0 }/></a>
                </span>
                <p style={{textTransform:'capitalize',fontFamily:'circular-regular'}}>Hi, Siva </p>
                <img src={require('../../../images/account.png')} alt="" style={{width:'30px',marginLeft:'15px'}}/>
							</div>
												
						</div>
					</div>					
				</div>	
				</div>
        </div> */}
    <section className='certificate_new'>
     
        <div className='certificate_list_con certificate_list_con_new'>   

        {
          certification_detail.map((certi,index)=>(
            <div className='certificate_list' key={index}>
                     <div className='rounded_img_certificate'  onClick={()=>checkCompletionStatus(certi.id)}>
                       <img src={require(`../../../images/${certi.logo_img}`)} alt=""/>
                     </div>
                     <p>{certi.certification_name}</p>
                 </div>
          ))
        }
           </div>


        {
          colorPaper&&
          <Confetti
          width={width}
          height={height}
          numberOfPieces={1000}
          tweenDuration={10000}
          //tweenDuration={1000}
          gravity={0.1}
          run={colorPaper}
          recycle={false}
       />
        }
          

    </section>
      <Modal
      aria-labelledby="spring-modal-title"
      aria-describedby="spring-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div 
      
        style={{backgroundColor:'#fff',maxWidth:'1000px',margin:'auto',minHeight:'500px',padding:'20px'}}
        >
          <h1 style={{marginLeft:'10px'}}>{selectedCertificate?.certification_name}</h1>
          {/* {// console.log({selectedCertificate})} */}
          <div className='row'>
          <div className='col-lg-7'>
          <img src= { certificate } alt="" height="400"
          style={{width:'550px'}}
           />
          </div>

          <div className='col-lg-5' style={{minHeight:'200px',display:'flex',flexDirection:'column',minHeight:'380px'}}>
            <h3>Description:</h3>
            <p style={{flex:'1'}}>
              lorem Fugiat non commodo Lorem eiusmod ullamco consectetur ut aliquip quis consectetur cupidatat nostrud. Non duis minim dolor proident exercitation labore. Reprehenderit mollit dolore quis excepteur nostrud exercitation. Commodo sunt dolor reprehenderit dolor.
            </p>
          <div style={{display:'flex',justifyContent:'space-evenly',}}>
            {/* { console.log(certificate)} */}
          {/* <ShareLink link={certificate}>
            {link => (
                <a href={link} target='_blank'>Share this on Linkedin</a>
            )}
          </ShareLink> */}
          {/* <LinkedinShareButton resource={ certificate } source={certificate}>
            <LinkedinIcon>

            </LinkedinIcon>
          </LinkedinShareButton> */}
          <a className='certification_share_icon' href={`https://www.facebook.com/sharer/sharer.php?u=${certificate}`} target="_blank" rel="noopener">
              <Button variant="contained" size="large" color="primary" style={{backgroundColor:'#4267B2',color:'white',fontSize:'14px',height:'40px'}}>
                <FacebookIcon style={{height:'20px'}}/>
              </Button>
        </a>
        <a className='certification_share_icon' href={`https://www.linkedin.com/sharing/share-offsite/?url=${certificate}`} target="_blank" rel="noopener">
              <Button variant="contained" size="large" color="primary" style={{backgroundColor:'#0072b1',color:'white',fontSize:'14px',height:'40px'}}>
                <LinkedinIcon/> 
              </Button>
        </a>
        <Button variant="contained" size="large" color="primary" onClick={downloadFile} style={{fontSize:'14px',height:'40px',backgroundColor:'#00b170'}}>
          Download
        </Button>
          </div>
          </div>
            </div>

          
          <br/>
         
          

        </div>
      </Fade>
    </Modal>
    <Toaster_Error tosOpen={tosOpen} setTosOpen={setTosOpen}/>
    </>
  )
}

export default Certificate