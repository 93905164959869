import React, { useEffect } from 'react'
import Footer from './footer'
import {Header} from './header'
import { BrowserRouter as Router , Route, Switch} from 'react-router-dom';
import Home from './pages/home';
import './styles.scss'
import Courses from './pages/courses';
import Notfound from './notfound';
import Marketing_Fundamentals from './pages/courses/marketing-fundamentals';
import Seo from './pages/courses/seo';
import Content_Marketing from './pages/courses/content-marketing';
import EmailMarketing from './pages/courses/email-marketing';
import Smm from './pages/courses/smm';
import TermsCondition from './pages/terms-condition';


function IndexWebsite() {
    useEffect(()=>{

    },[])
  return (
    <div id='website_container'>
    <Header/>
    <div id='website_content'>
        <Switch>
            <Route exact path='/' component={Home} />
            <Route exact path='/courses' component={Courses} />
            <Route exact path='/marketing-fundamentals' component={Marketing_Fundamentals} />
            <Route exact path='/seo' component={Seo} />
            <Route exact path='/content-marketing' component={Content_Marketing} />
            <Route exact path='/email-marketing' component={EmailMarketing} />
            <Route exact path='/smm' component={Smm} />
            <Route exact path='/terms-condition' component={TermsCondition} />
            <Route exact path='/**'><Notfound/></Route>
        </Switch>
    </div>       
    <Footer/>
    </div>
  )
}

export default IndexWebsite