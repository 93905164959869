import axios from 'axios';
import React,{useState,useEffect} from 'react'
import Cookies from 'js-cookie';
import $ from 'jquery'
import CustomModal from '../CustomModal'
import { Link,useHistory } from 'react-router-dom';
import Toaster from '../../../toaster';
import ReactQuill from 'react-quill'; 
import 'react-quill/dist/quill.snow.css'; 
/*
{headers:{
    Authorization:`Bearer ${Cookies.get('token')}`
  } }
*/
function VideoList(props) {
    const history=useHistory()

    const [videoList, setVideoList] = useState([])
    const [addPopup,setAddPopup] = useState(false)
    const [editPopupId,setEditPopupId] = useState()
    const [fields, Getfields] = useState({});
    const [fieldsEdit, GetfieldsEdit] = useState({});
    const [error,setError]=useState({})
    const [courseId,setCourseId]= useState()
    const [sectionId,setSectionId]= useState()
    
    const [tosOpen,setTosOpen]=useState()

    const [description,setDescription] = useState('')
    const [editDescription,setEditDescription] = useState('')

    useEffect(() =>{
    let pathArr=window.location.pathname.split('/')
    setSectionId(pathArr[pathArr.length-1])
    GetVideoList(pathArr[pathArr.length-1])
  },[])
  
  const GetVideoList = (id) =>{
    
      axios.get(`https://api.ezioskillacademy.com/api/admin/section/sub-section/`+id, {headers:{
        Authorization:`Bearer ${Cookies.get('token')}`
      } })
      .then(res => {  
                    // console.log("video",res.data)
                    setVideoList(res.data.data)     
      }).catch(e=>{
        alert(e.response.data.message)
      })
  }


const addnewVideo=()=>{
    let err={}
    let isValid=true
    if(!fields.sub_title){
        err["sub_title"]="*Please Enter Video Name"
        isValid=false
    }
    if(!description){
      err["description"]="*Please Enter Description"
      isValid=false
     }
     if(!fields.sub_section_type){
      err["sub_section_type"]="*Please Select Type"
      isValid=false
     }else{
      if(fields.sub_section_type=='video'&&!fields.video_url){
        err["video_url"]="*Please Enter Video Url"
        isValid=false
      }
      if(fields.sub_section_type=='document'&&!fields.sub_section_file){
        err["sub_section_file"]="*Please Upload File"
        isValid=false
      }
     }
   
    if(fields.sub_section_type=='video'&&!fields.duration){
      err["duration"]="*Please Enter Duration"
      isValid=false
    }
    if(!fields.thumbnail){
      err["thumbnail"]="*Please Give Thumbnail Image"
      isValid=false
    }
    setError(err)
    // console.log('valid',isValid)

    if(isValid){
      //// console.log({fields})
      const formData=new FormData()
      formData.append('sub_title',fields.sub_title)
      formData.append('description',description)
      if(fields.sub_section_type){
        formData.append('sub_section_type',fields.sub_section_type)
      }
      if(fields.video_url){
      formData.append('video_url',fields.video_url)
      }
      if(fields.sub_section_file){
        formData.append('sub_section_file',fields.sub_section_file)
      }
      formData.append('duration',fields.sub_section_type=='document'?353:+fields.duration*60)
      formData.append('thumbnail',fields.thumbnail)
        axios.post('https://api.ezioskillacademy.com/api/admin/section/sub-section/'+sectionId,formData,{headers:{
            Authorization:`Bearer ${Cookies.get('token')}`
          } })
        .then(res=>{
            // console.log("section",res.data)
            GetVideoList(sectionId)
            $('#add_scs_msg').html(res.data.message)
            setTosOpen(res.data.message)
            setDescription(undefined)
            Getfields({})
          // setTimeout(()=>{
               setAddPopup(false)      
        //   },3000)
                
            
        }).catch(e=>{
           alert(e.response.data.message)
        })
    }
}
 
const editBatch=()=>{
  //   let err={}
  //   let isValid=true
  //   if(!fieldsEdit.sub_title){
  //     err["sub_title"]="*Please Enter Video Name"
  //     isValid=false
  // }
  // if(!fieldsEdit.description){
  //   err["description"]="*Please Enter Description"
  //   isValid=false
  //  }
  // if(!fieldsEdit.video_url){
  //   err["video_url"]="*Please Enter Video Url"
  //   isValid=false
  // }
  // if(!fieldsEdit.duration){
  //   err["duration"]="*Please Enter Duration"
  //   isValid=false
  // }
  
    // console.log("fieldsEdit",{fieldsEdit})
    //setError(err)
    if(true){
      const formData=new FormData()
      formData.append('sub_title',fieldsEdit.sub_title)
      formData.append('description',editDescription)
      if(fieldsEdit.sub_section_type){
        formData.append('sub_section_type',fieldsEdit.sub_section_type)
      }
      if(fieldsEdit.video_url){
        formData.append('video_url',fieldsEdit.video_url)
        }
      if(fieldsEdit.sub_section_file){
        formData.append('sub_section_file',fieldsEdit.sub_section_file)
      }
      formData.append('duration',fieldsEdit.sub_section_type=='document'?353:+fieldsEdit.duration*60)
      formData.append('thumbnail',fieldsEdit.thumbnail)
     // console.log('test',fieldsEdit)
    axios.put('https://api.ezioskillacademy.com/api/admin/section/sub-section/'+editPopupId,formData,{headers:{
        Authorization:`Bearer ${Cookies.get('token')}`
      } })
    .then(res=>{
            GetVideoList(sectionId)
            $('#edit_scs_msg').html(res.data.message)
            setTosOpen(res.data.message)
            //GetfieldsEdit({})
           // setTimeout(()=>{
                setEditPopupId(undefined)      
         //   },3000)
            
        
    }).catch(e=>{
       alert(e.response.data.message)
    })
    }
  }
  const HandleDelete=(e,id)=>{
    e.stopPropagation();
    var result = window.confirm("Are you sure you want to delete?");
    if (result) {
      axios.delete(`https://api.ezioskillacademy.com/api/admin/section/sub-section/${id}`,{headers:{
        Authorization:`Bearer ${Cookies.get('token')}`
      } })
      .then(res => {
        GetVideoList(sectionId) 
        setTosOpen(res.data.message)            
                
      }).catch(e=>{
        alert(e.response.data.message)
      })
    }
  }
  const handleValue=(e)=>{
      if(e.target.name==='thumbnail' ||e.target.name==='sub_section_file'){
        Getfields({ ...fields, [e.target.name]:e.target.files[0] })
      }else{
        Getfields({ ...fields, [e.target.name]:e.target.value })
      }
    }
  const handleEditValue=(e)=>{
    if(e.target.name=='thumbnail' ||e.target.name=='sub_section_file'){
      GetfieldsEdit({ ...fieldsEdit, [e.target.name]:e.target.files[0] })
    }else{
      GetfieldsEdit({ ...fieldsEdit, [e.target.name]:e.target.value })
    }
      
    }
  const setEditPopupOpen=(e,video)=>{
      e.stopPropagation();
      setEditPopupId(video._id)
      // console.log({video})
      GetfieldsEdit({...video,['duration']:video.duration/60})
      setEditDescription(video.description)
      //GetBatchFields(id)  
        //GetBatchFields(id)  
      //GetBatchFields(id)  
  }
    
    
   
  return (
    <div className="dash_page_bg dash_section_ptb add_payment">
  
        <button className="add_payment_btn" onClick={()=> setAddPopup(true)}
        >
          Add Sub Section   
        </button>
        
        <br />
        <br />
        <div className="clientrate_table">
          <div className="tabcomp-style">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <td> Name</td> 
                    {/* <td>Video Url</td>  */}
                    <td>Duration(Minutes)</td>
                    <td>Type</td>
                    {/* <td>Thumbnail</td>  */}
                    <td style={{width:'200px'}}></td>                   
                  </tr>
                </thead>
                <tbody>
                  {
                  videoList.length > 0 ? (
                    <>
                      {videoList
                        .map((video, id) => (
                          <tr key={id} className="admin_course_list" 
                          //  onClick={()=>history.push('/superadmin/course/section/'+video._id)}
                          >
                            <td>{video.sub_title}</td>
                            <td>{video.duration/60} {video.sub_section_type=='document'&&('(pre defined)')}</td>
                            <td>{video.sub_section_type||'none'}</td>
                            {/* <td>{video.sub_title}</td>
                            <td>{video.sub_title}</td> */}
                            <td style={{display:'flex',justifyContent:'center',width:'200px'}}>
                     
                                <button className='blue_btn' onClick={(e)=>setEditPopupOpen(e,video)}>Edit</button>
                               
                                <button 
                                  onClick={(e)=>HandleDelete(e,video._id)} className='red_btn'>Delete</button>
                                </td>                         
                          </tr>
                        ))}
                    </>
                  ) : (
                    <tr>
                      <td colSpan="2" style={{ textAlign: "center" }}>
                        No data
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <CustomModal open={addPopup}>
        <div className="scsmdl-container" style={{ height: "auto" }}>
          <div className="scsmdl-header">
            <p>Add Sub Section</p>
            {/* <img src={'/modalclose.jpg'} className="scsmdl-closeimg"  /> */}
            <img
              src={"/modalclose.jpg"}
              className="scsmdl-closeimg"
              onClick={() => setAddPopup(false)}
              alt="close img"
            />
          </div>
          <div className="scsmdl-body" style={{ padding: "20px",height:'560px',overflow:'scroll' }}>
          <div className="form-group">
              <label> Name:</label>
              <input
                className="form-control"
                type="text"
                name="sub_title"
                onChange={(e) => handleValue(e)}
              />
              <p style={{color:'red'}}>{error.sub_title}</p>             
            </div>

            <div className="form-group">
              <label>Description:</label>
              {/* <input
                className="form-control"
                type="text"
                name="description"
                onChange={(e) => handleValue(e)}
              /> */}
               <ReactQuill value={description} onChange={(value)=>setDescription(value)} />
              <p style={{color:'red'}}>{error.description}</p>             
            </div>

            <div className="form-group">
              <label>Type:</label>
              <div onChange={(e) => handleValue(e)} name="sub_section_type" style={{display:'flex'}}>
                                    <input id='video' class="radio" type="radio" name="sub_section_type"   value="video" />
                                    <label for="video">
                                      Video
                                    </label>
                                    &nbsp;&nbsp;
                                    <input id='document'  class="radio"type="radio" name="sub_section_type"  value="document"/>
                                    <label for="document">
                                      Document(.pdf)
                                    </label>
                                  </div>
              <p style={{color:'red'}}>{error.sub_section_type}</p>             
            </div>
           
            {
              fields.sub_section_type=='video'?(
                <div className="form-group">
                <label>Video Url:</label>
                <input
                  className="form-control"
                  type="text"
                  name="video_url"
                  onChange={(e) => handleValue(e)}
                />
                <p style={{color:'red'}}>{error.video_url}</p>             
              </div>
              ):fields.sub_section_type=='document'?(
              <div className="form-group">
                <label>Upload Document:</label>
                <input
                  className="form-control"
                  type="file"
                  name="sub_section_file"
                  onChange={(e) => handleValue(e)}
                />
                <p style={{color:'red'}}>{error.sub_section_file}</p> 
                </div>
              ):null
            }
      
      {
              fields.sub_section_type=='video'?(
            <div className="form-group">
              <label>Duration(Minutes):</label>
              <input
                className="form-control"
                type="text"
                name="duration"
                onChange={(e) => handleValue(e)}
              />
              <p style={{color:'red'}}>{error.duration}</p>             
            </div>
              ):null}
            <div className="form-group">
              <label>Thumbnail:</label>
              <input
                className="form-control"
                type="file"
                name="thumbnail"
                onChange={(e) => handleValue(e)}
              />
              <p style={{color:'red'}}>{error.thumbnail}</p>             
            </div>
            <div className="row">
              <div className="col-lg-5">
                <button
                 onClick={addnewVideo}
                  className="addpayment-submitbtn"
               //   disabled={loader}
                //  style={loader ? { opacity: "0.5" } : {}}
                >
                  Submit
                </button>
              </div>
             
            </div>
            <p id="add_scs_msg" style={{color:'green'}}></p>
          </div>
        </div>
      </CustomModal>

      <CustomModal open={editPopupId}>
        <div className="scsmdl-container" style={{ height: "auto" }}>
          <div className="scsmdl-header">
            <p>Edit Video</p>
            {/* <img src={'/modalclose.jpg'} className="scsmdl-closeimg"  /> */}
            <img
              src={"/modalclose.jpg"}
              className="scsmdl-closeimg"
              onClick={() => setEditPopupId(undefined)}
              alt="close img"
            />
          </div>
          <div className="scsmdl-body" style={{ padding: "20px",height:'560px',overflow:'scroll'  }}>
         
                    <div className="form-group">
              <label>Video Name:</label>
              <input
                className="form-control"
                type="text"
                name="sub_title"
                value={fieldsEdit.sub_title}
                onChange={(e) => handleEditValue(e)}
              />
              <p style={{color:'red'}}>{error.sub_title}</p>             
            </div>

            <div className="form-group">
              <label>Description:</label>
              {/* <input
                className="form-control"
                type="text"
                name="description"
                value={fieldsEdit.description}
                onChange={(e) => handleEditValue(e)}
              /> */}
               <ReactQuill value={editDescription} onChange={(value)=>setEditDescription(value)} />
              <p style={{color:'red'}}>{error.description}</p>             
            </div>
            {
              fieldsEdit.sub_section_type=='video'?(
                <div className="form-group">
                <label>Video Url:</label>
                <input
                  className="form-control"
                  type="text"
                  name="video_url"
                  value={fieldsEdit.video_url}
                  onChange={(e) => handleEditValue(e)}
                />
                <p style={{color:'red'}}>{error.video_url}</p>             
              </div>
              ):fieldsEdit.sub_section_type=='document'?(
              <div className="form-group">
                <label>Upload Document:</label>
                <input
                  className="form-control"
                  type="file"
                  name="sub_section_file"
                 // value={fieldsEdit.sub_section_file}
                  onChange={(e) => handleEditValue(e)}
                />
                <p style={{color:'red'}}>{error.sub_section_file}</p> 
                </div>
              ):null
            }

          {
              fieldsEdit.sub_section_type=='video'?(
                <div className="form-group">
                <label>Duration(Minutes):</label>
                <input
                  className="form-control"
                  type="text"
                  name="duration"
                  value={fieldsEdit.duration}
                  onChange={(e) => handleEditValue(e)}
                />
                <p style={{color:'red'}}>{error.duration}</p>             
              </div>
              ):null}
            
         

            <div className="form-group">
              <label>Thumbnail:</label>
              <input
                className="form-control"
                type="file"
                name="thumbnail"
               // value={fieldsEdit.thumbnail}
                onChange={(e) => handleEditValue(e)}
              />
              <img src={fieldsEdit?.thumbnail} style={{height:'100px'}} alt="thumbnail img"/>
              <p style={{color:'red'}}>{error.thumbnail}</p>             
            </div>
            <br/>
            <div className="row">
              <div className="col-lg-5">
                <button
                  onClick={editBatch}
                  className="addpayment-submitbtn"
                >
                  Submit
                </button>
              </div>
             
            </div>

            <p id="edit_scs_msg" style={{color:'green'}}></p>
          </div>
        </div>
      </CustomModal>
      <Toaster tosOpen={tosOpen} setTosOpen={setTosOpen}/>
      </div>
  )
}

export default VideoList