import { Backdrop, Button, CircularProgress, Fade, makeStyles, Modal } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import PopupClose from '../../images/iconsSVG/PopupClose'
import {useSelector,useDispatch} from 'react-redux'
import axios from 'axios';
import Cookies from 'js-cookie';
import RazorpayPay from '../Dashboard/user/razorpay';
import $ from 'jquery'
import CustomModal from '../Dashboard/superadmin/CustomModal';


const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }));
function PackagePopup(props) {
    const {packagePopup,setPackagePopup}=props
    const classes = useStyles();

    const reduxValue=useSelector(state=>state)
    const dispatch=useDispatch()

    const [loader,setLoader] = useState(false);
    const [fields,setFields] = useState({});
    const [errors,setErrors] = useState({});

    const [packageList,setPackageList] = useState([]);

    const [selectedPackage,setSelectedPackage]= useState();

    const [paymentSuccess,setPaymentSuccess]= useState();


    const handleChange = (e) => {
        // console.log(e.target.name,e.target.value)
        setFields({ ...fields, [e.target.name]:e.target.value })
    };

    useEffect(()=>{
      // console.log("Payment popup",packagePopup  )
        if(packagePopup){
        axios.get(`https://api.ezioskillacademy.com/api/user/payment/package`,{headers:{
        Authorization:`Bearer ${Cookies.get('token')}`
      } })
        .then(res => {
          // console.log("response",res.data.data)
          setPackageList(res.data.data) 
        //  GetData(res.data.data)  
                      
                  
        }).catch((err)=>{
            // console.log(err)
           // alert("Something Wrong.. Please Login again...")
        
        })
    }
    },[packagePopup])



     function createOrder(){
        if(selectedPackage){
           RazorpayPay(selectedPackage,setPaymentSuccess)
           
            setPackagePopup(false)
        }else{
            document.getElementById('edit_scs_msg').innerHTML='Please Select the Package'
            // console.log("error occur")
        }
    }
    

  return (
      <>
    <CustomModal open={packagePopup}>
        <div className="scsmdl-container" style={{ height: "auto" }}>
          <div className="scsmdl-header">
            <p style={{color:'#fff'}}>Choose Package</p>
            {/* <img src={'/modalclose.jpg'} className="scsmdl-closeimg"  /> */}
            <img
              src={"/modalclose.jpg"}
              className="scsmdl-closeimg"
              onClick={() => setPackagePopup(false)}
              alt="close img"
            />
            </div>
            <div className="scsmdl-body" style={{ padding: "20px" }}>
        

<div 
    
    //className='register_popup'
    style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',maxWidth:'400px',margin:'auto'}}
    >
        
    
        <select onChange={(e=>setSelectedPackage(e.target.value))} name="package_plan" style={{maxWidth:'500px',height:'40px',border: '1px solid #e8e8e8'}} >
            <option disabled selected>Choose the plan</option>
          {
              packageList?.map((plan)=>(
                  <option value={plan._id}>{plan.package_name}/{plan.payment_type}/Rs.{plan.payable_amount}</option>
              ))
          }
           
           
        </select>
      
        <br/>
       
    
       
        <button
                 onClick={createOrder} 
                  className="addpayment-submitbtn"
                >
                  Pay Now
                </button>
        </div>
           
            <p id="edit_scs_msg" style={{color:'green'}}></p>
          </div>
        </div>
      </CustomModal>
      <CustomModal open={paymentSuccess}>
        <div className="scsmdl-container" style={{ height: "auto" }}>
          <div className="scsmdl-header">
            <p style={{color:'#fff'}}>Payment Success</p>
            {/* <img src={'/modalclose.jpg'} className="scsmdl-closeimg"  /> */}
            <img
              src={"/modalclose.jpg"}
              className="scsmdl-closeimg"
              onClick={() => setPaymentSuccess(undefined)}
              alt="close img"
            />
            </div>
            <div className="scsmdl-body" style={{ padding: "20px" }}>
        

            <h1 style={{color:'green',textAlign:'center',fontWeight:'bold'}}>{paymentSuccess}</h1>
          </div>
        </div>
      </CustomModal>
      </>
  )
}

export default PackagePopup

