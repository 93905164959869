import axios from 'axios'
import React,{useState,useEffect} from 'react'
import UserIcon from '../../../images/iconsSVG/UserIcon'
import Cookies from 'js-cookie'
import ScrollToBottom from 'react-scroll-to-bottom';
import Notifications from '../../../images/iconsSVG/Notification';

  // import { io } from "socket.io-client";
import Attachment from '../../../images/iconsSVG/Attachment';
import { Breadcrumbs, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';

  // const socket =io.connect('')

  const headers={
    Authorization:`Bearer ${Cookies.get('token')}`
  }
function Chat(props) {
  const [username,setUsername]=useState()

  const [routeUrl,setRouteUrl]=useState()

  const [chatList,setChatList]=useState()

  const [message,setMessage]=useState('')

  const [file,setFile] =useState()


  const [imageFile,setImageFile] =useState()
  const [ notifyCount, setNotifyCount ] = useState();

  const [imagePath,setImagePath] =useState()


  useEffect(()=>{
    setRouteUrl(window.location.pathname)
    // console.log(window.location.pathname)
    getChatList()
    // .then(err=>{
    //   alert(err)
    // })
    setInterval(()=>{
      getChatList() 
    },30000)
  },[])

  function getChatList(){
    axios.get('https://api.ezioskillacademy.com/api/message',{ headers:{
      Authorization:`Bearer ${Cookies.get('token')}`
    } })
    // axios.get('http://localhost:3000/api/message',{headers})
    .then((res)=>{
      // console.log("notify",res)
      setChatList(res.data.data)
      setNotifyCount(res.data.notify_count)
    })
  }

  function sendMessage(){
    const formData=new FormData()
    formData.append('user_id',localStorage.getItem('user_id'))
    if(imageFile){
      formData.append('file_data',imageFile)
    }else if(file){
      formData.append('file_data',file)
    }else{
      formData.append('text',message)
    }
    
   //formData.append('',)
    axios.post('https://api.ezioskillacademy.com/api/message',formData,{ headers:{
      Authorization:`Bearer ${Cookies.get('token')}`
    } })
    // axios.post('http://localhost:3000/api/message',formData,{headers})
    .then((res)=>{
      // console.log(res)
      setChatList(res.data.data)
      getChatList()
      setMessage('')
      setImageFile()
      setImagePath()
      setFile()
      
    })
  }
  function attachFile(e){
    //const previewImg=document.getElementById('preview_img')
      if(e.target.files[0].type.includes('image')){
          setImageFile(e.target.files[0])
          setImagePath(URL.createObjectURL(e.target.files[0]))
          // console.log(URL.createObjectURL(e.target.files[0]),e.target.files[0])
      }else{
          setFile(e.target.files[0])
          // console.log("chat",e.target.files[0])
      }
  }
  return (
    <>
       {
        routeUrl==='/course/chat'&&(
          <header>
          <div className='lessons_only course_list_top white_bg'>
      <div className='lat_activity' >					
            <div className='up_section' style={{width:'100%'}}>
              <div className='row'>
              <div className='col-lg-12' style={{display:'flex',justifyContent:'space-between'}}>	
              <div>
                
              <h1>Chat</h1>
                <Breadcrumbs aria-label="breadcrumb">
                        <Link color="inherit" to="/course" >
                          Course
                        </Link>
                        <Typography color="textPrimary">Chat</Typography>
                </Breadcrumbs> 
                </div>					
               
                <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',minWidth:'90px'}}>								
                  <span style={{ marginRight: "20px" }}>
                    <a href='/course/notifications'><Notifications notifyCount = { notifyCount }/></a>
                  </span>
                  <p style={{textTransform:'capitalize',fontFamily:'circular-regular'}}>Hi, Siva </p>
                  <img src={require('../../../images/account.png')} alt="" style={{width:'30px',marginLeft:'15px'}}/>
                </div>							
                </div>						
              </div>
            </div>					
          </div>	
          </div>
          </header>
        )
      }
    
    <section className='user_chat_section'>
      {/* { console.log("res",imageFile)} */}
   
       
        <div className='user_chat'>
          <div className='user_chat_message_con'>
           <ScrollToBottom className="message-container">
         
            {
              chatList?(
                <>
                {
                  chatList.map((chat,index)=>{
                    let date=new Date(chat.createdAt)
                  //  // console.log(localStorage.getItem('user_id'),chat.users._id)
                    const isImg=chat.file_url? [ "jpeg", "jpg", "gif", "png" ].includes(chat.file_url.split('.')[chat.file_url.split('.').length-1]):null
                  //  // console.log("what",isImg)
                    return (
                      <div key={index} className={localStorage.getItem('user_id')==chat.users._id?'user_individual_message user_individual_message_sender':'user_individual_message'}><UserIcon/>
                      <div className='user_message_name'>
                           <div><h4>{chat.users.first_name} {chat.users.last_name}</h4>&nbsp;&nbsp;<span>{date.getHours()}:{date.getMinutes()}:{date.getSeconds()}</span></div>
                           {
                             chat.message &&(
                              <p>{chat.message}</p>
                             )
                           }
                           {
                             chat.file_url &&
                             <>
                             {
                               isImg?(
                                <a href={chat.file_url} download>
                                <img src={chat.file_url}/>
                                </a>
                               ):(
                                 <a href={chat.file_url} download>
                                 <div className='input_file_box'>
                                   <img src={require('../../../images/dashboard/files.svg')}/>
                                   <h5>File</h5>
                                   
                                 </div>
                                 </a>
                               )
                             }
                             
                            
                             </>
                               
                             
                           }
                          
                      </div> 
                      </div>
                    )
                  }
                   
                  )
                }
                
                </>                
              ):(
                <h1>No Message Found</h1>
              )
            }
                
         
         </ScrollToBottom>
          </div>
         

          <div className='chat_input_con'>
           
                {
                  imageFile?(
                    <div className='chat_input chat_input_img'>
                    <div>
                      <img id="preview_img" src={imagePath} alt="" style={{maxHeight:'100px'}}/>
                    </div>
                    </div>
                  ):file?(
                    <div className='chat_input'>
                      {file.name}
                    </div>
                  ):(
                    <div className='chat_input'>
                    <input type="text" placeholder='Start Your Conversation Here...' autoFocus onChange={(e=>setMessage(e.target.value))} onKeyPress={(e=>{e.key==='Enter'&&sendMessage()})} value={message}/>
                    </div>
                  )
                }
               
            
          <div className='chat_input_bottom'>
           
              <input id='attachFile' type="file" onChange={attachFile} style={{display:'none'}}/>
              <div onClick={()=>{document.getElementById('attachFile').click()}}>
              <Attachment />
              </div>
              <div onClick={sendMessage} style={{display:'flex',alignItems:'center'}}>
            
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21">
                      <g id="Group_2" data-name="Group 2" transform="translate(541 125.96)">
                        <path id="Path_21" data-name="Path 21" d="M5.073,13,2.079,22.245a.609.609,0,0,0,.127.6.5.5,0,0,0,.332.144.447.447,0,0,0,.2,0L22.049,13Z" transform="translate(-543.049 -127.96)"/>
                        <path id="Path_22" data-name="Path 22" d="M5.125,23.752l-3.046-10a.609.609,0,0,1,.127-.6.5.5,0,0,1,.332-.144.447.447,0,0,1,.2,0l19.3,10.9Z" transform="translate(-543.049 -138.96)"/>
                      </g>
                    </svg>

              </div>
             
             
              {/* <img src={require('../../../images/dashboard/attachment.svg')}  alt=""/>
              <img src={require('../../../images/dashboard/send.svg')}  alt=""/> */}
            

              </div>

          </div>
          
        </div>
    </section>
    </>
  )
}

export default Chat