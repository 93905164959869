import React from 'react'

export default function RateBIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="59.061" height="59.061" viewBox="0 0 59.061 59.061">
      <g id="Rate_Our_Business" data-name="Rate Our Business" transform="translate(-397 -708)">
        <path id="Path_14025" data-name="Path 14025" d="M29.531,0A29.531,29.531,0,1,1,0,29.531,29.531,29.531,0,0,1,29.531,0Z" transform="translate(397 708)" fill="#efefef"/>
        <g id="star" transform="translate(409 721)">
          <g id="Group_1198" data-name="Group 1198" transform="translate(0 0)">
            <path id="Path_10460" data-name="Path 10460" d="M34.222,24.21a1,1,0,0,0-.81-.683L22.787,21.983l-4.751-9.627a1,1,0,0,0-1.8,0l-4.752,9.627L.86,23.526A1,1,0,0,0,.3,25.239l7.687,7.494L6.176,43.314a1,1,0,0,0,1.457,1.058l9.5-5,9.5,5a1,1,0,0,0,1.457-1.058L26.279,32.733l7.688-7.494A1,1,0,0,0,34.222,24.21Z" transform="translate(0 -11.796)" fill="#143059"/>
          </g>
        </g>
      </g>
    </svg>
  )
}
