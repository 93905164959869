import React from 'react'

function IndividualPlanIcon() {
  return (
    
<svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M27.0846 9.1637C26.4641 8.54269 25.4776 8.50367 24.8104 9.04725C24.714 9.12587 24.7887 9.05659 23.3198 10.5255L27.1326 14.3383L28.503 12.9851C29.1655 12.3226 29.1655 11.2446 28.503 10.582L27.0846 9.1637Z" fill="#01B170"/>
<path d="M15.5008 18.4166L13.2579 23.2228C13.1069 23.5466 13.1745 23.9302 13.4271 24.1828C13.6797 24.4355 14.0635 24.503 14.3871 24.3519L19.1933 22.109C19.3835 22.0203 18.8078 22.559 25.9235 15.5323L22.1182 11.7269C15.0631 18.782 15.5913 18.2227 15.5008 18.4166Z" fill="#01B170"/>
<path d="M20.6247 23.153C20.4166 23.3585 20.1768 23.5253 19.9119 23.6489L15.1057 25.8918C14.1606 26.3329 13.006 26.165 12.2256 25.3845C11.4706 24.6294 11.2666 23.472 11.7181 22.5042L13.961 17.6979C14.087 17.428 14.2577 17.1844 14.4684 16.9737L21.6938 9.74819V2.54883C21.6938 1.1434 20.5504 0 19.145 0H2.54932C1.14389 0 0.000488281 1.1434 0.000488281 2.54883V26.4512C0.000488281 27.8566 1.14389 29 2.54932 29H19.145C20.5504 29 21.6938 27.8566 21.6938 26.4512V22.0972L20.6247 23.153ZM4.53179 5.49414H17.2193C17.6885 5.49414 18.0689 5.87454 18.0689 6.34375C18.0689 6.81296 17.6885 7.19336 17.2193 7.19336H4.53179C4.06258 7.19336 3.68219 6.81296 3.68219 6.34375C3.68219 5.87454 4.06258 5.49414 4.53179 5.49414ZM4.53179 10.0254H14.5005C14.9698 10.0254 15.3502 10.4058 15.3502 10.875C15.3502 11.3442 14.9698 11.7246 14.5005 11.7246H4.53179C4.06258 11.7246 3.68219 11.3442 3.68219 10.875C3.68219 10.4058 4.06258 10.0254 4.53179 10.0254ZM3.68219 15.4062C3.68219 14.937 4.06258 14.5566 4.53179 14.5566H11.7818C12.251 14.5566 12.6314 14.937 12.6314 15.4062C12.6314 15.8755 12.251 16.2559 11.7818 16.2559H4.53179C4.06258 16.2559 3.68219 15.8755 3.68219 15.4062Z" fill="#F14A51"/>
</svg>

  )
}

export default IndividualPlanIcon