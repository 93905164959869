import React from 'react'

export default function DeleteIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22.869" height={22} viewBox="0 0 22.869 28">
      <g id="delete_2_" data-name="delete (2)" transform="translate(0 0)">
        <path id="Path_14549" data-name="Path 14549" d="M22.8,6.258,22.179,4.4a1.724,1.724,0,0,0-1.637-1.18h-5.2V1.533A1.535,1.535,0,0,0,13.813,0H9.055A1.535,1.535,0,0,0,7.522,1.533V3.225h-5.2A1.724,1.724,0,0,0,.689,4.4L.071,6.258A1.387,1.387,0,0,0,1.386,8.084h.646L3.455,25.672A2.544,2.544,0,0,0,5.977,28h11.2A2.544,2.544,0,0,0,19.7,25.672L21.124,8.084h.358A1.387,1.387,0,0,0,22.8,6.258ZM9.163,1.64h4.543V3.225H9.163Zm8.9,23.9a.9.9,0,0,1-.886.819H5.977a.9.9,0,0,1-.886-.819L3.679,8.084h15.8ZM1.738,6.444l.507-1.52a.085.085,0,0,1,.081-.059H20.542a.085.085,0,0,1,.081.059l.507,1.52Zm0,0" transform="translate(0)" fill="#373737" />
        <path id="Path_14550" data-name="Path 14550" d="M269.074,182h.043a.82.82,0,0,0,.818-.778l.77-14.788a.82.82,0,1,0-1.638-.085l-.77,14.788a.82.82,0,0,0,.777.862Zm0,0" transform="translate(-253.624 -156.522)" fill="#373737" />
        <path id="Path_14551" data-name="Path 14551" d="M106.6,181.232a.82.82,0,0,0,.818.776h.046a.82.82,0,0,0,.774-.864l-.807-14.788a.82.82,0,1,0-1.638.089Zm0,0" transform="translate(-100.008 -156.524)" fill="#373737" />
        <path id="Path_14552" data-name="Path 14552" d="M195.074,182.007a.82.82,0,0,0,.82-.82V166.4a.82.82,0,0,0-1.641,0v14.788A.82.82,0,0,0,195.074,182.007Zm0,0" transform="translate(-183.631 -156.524)" fill="#373737" />
      </g>
    </svg>
  )
}
