import React, { useEffect } from 'react'
import Arrow from '../../../../images/iconsSVG/Arrow'
import RedArrow from '../../../../images/iconsSVG/RedArrow'
import $ from 'jquery'
import Slider from "react-slick";
import { ourCourses } from '../data/our_courses';
import MoreCourse from './common/more_courses';


function Seo() {
  useEffect(()=>{

    const sectionAll = document.querySelectorAll("section");
            const observer = new IntersectionObserver(
              (entries) => {
                entries.forEach((entry) => {
                   
                  if (entry.isIntersecting) {
                    
                    entry.target.classList.add("scroll_ani");
                    
                  }
                });
              },
              {
                rootMargin: "-100px",
              }
            );
            sectionAll.forEach((section) => {
              observer.observe(section);
            });

    courseAccordian('1')
  },[])
  const courseSyllabus=[
    {
      id:'1',
      title:' Introduction to SEO ',
      desc:`It's the organic method to enhance your website quality which helps to increase the traffic and visibility of your website in search engine result pages. `
    },
    {
      id:'2',
      title:'Importance of SEO for Business',
      desc:'Through SEO, businesses can easily build massive exposure to your brand or services. Increases brand authority, and makes your potential audience easily figure out your business. Improve your local SEO, to attract local leads. '
    },
    {
      id:'3',
      title:'What Does Technical SEO Optimization Include? ',
      desc:'Technical SEO is the process of progressing the technical part of a website to improve the ranking of your page in the search engines. Reducing the website loading speed, make search engine easy to crawl and understand your site. '
    },
    {
      id:'4',
      title:'What is On-Page SEO? ',
      desc:'On-page is one of the crucial techniques to consider when it comes to enhancing your website. It is directly related to the content and structure of your website.  '
    },
    {
      id:'5',
      title: `What is Off-Page SEO? `,
      desc:`When you focus on activities outside of your website to increase ranking, which is called off-page SEO.  It involves a variety of techniques; link building is the most winning one. `
    },
    {
      id:'6',
      title:'Link Building Process ',
      desc:`Link building is the technique of building links from other quality sites pointing to your site. It is the signal to the search engine that your site owns high-standard and appropriate content. Thereby increasing your ranking. `
    },
    {
      id:'7',
      title:'What is Local SEO? ',
      desc:`Local SEO is one of the types of SEO strategy, which assists your business to get noticed in local search results. If you target your surroundings, local SEO is the ideal choice to grab more potential leads.  `
    },
  ]
  function courseAccordian(id){
    $('.course_syllabus_inner_left ul li').removeClass('course_syllabus_acc_active')
    $('#course_syllabus_acc'+id).addClass('course_syllabus_acc_active')

  }
  const recognized_certification=[
    {
        id:'1',
        name:'Ezio Skill Academy Certificate',
        desc:'Master the Talents You Required for becoming A Marketing Expert in Any verticals across the globe. Experiential learning through advance syllabus, case studies & live project centric. ',  
        img:'Ezio-Skill-Academy.jpg',
    },
    {
        id:'2',
        name:'Ezio Audit Tool Certificate  ',
        desc:'Expertise in search engine audit tools, our hands-on training let you easily analyze the website and know what is working and what is not. Experience the site audit tool usage and turn the website weaker side into a stronger one.  ',  
        img:'Ezio-Skill-Academy.jpg',
    },
    {
        id:'3',
        name:'LinkedIn Certificate',
        desc:'Become exceptionally skilled and gain the knowledge of DM strategy to transform you into a “Qualified Digital Marketer”.',  
        img:'linkedi-certificate-1.jpg',
    },
    {
        id:'4',
        name:'HubSpot Academy Certificate   ',
        desc:'The candidates will gain top-notch skills in implementing digital marketing strategies with high-standard materials and training exercises. A digital marketing certificate from HubSpot Academy is recognized by the universities.   ',  
        img:'hubspot-academy.jpg',
    },
    {
        id:'5',
        name:'Facebook Blueprint Certificate  ',
        desc:'Earn Facebook blueprint certified "digital marketing associate” becoming an expert level proficiency in Facebook advertising. This is excluded in the Course cost & separate cost will be applicable for this certification ',  
        img:'Facebook-Blueprint.jpg',
    },
    {
        id:'6',
        name:'Google Analytics   ',
        desc: `Qualified in fundamental and advanced Google analytics concepts and Google's measurement tools. Google Analytics Certificates make provision for implementing SEO strategy through learning high-in-demand skills.  `,  
        img:'Google-Analytics.jpg',
    },
    {
        id:'7',
        name:'Google Ads  ',
        desc:'It is an industry-recognized marketing certificate from Google. Professional accreditation is provided by Google. Recognized you as a master in Google Ads.     ',  
        img:'adwords.jpg',
    },
    {
        id:'8',
        name:'Bing Ads   ',
        desc:'Knowing the hack to push your business forward from the effective Microsoft advertising campaigns and enhance your skillset on ads through this program.  With the Bind ads certification, upskill your Microsoft advertising proficiency. ',  
        img:'Bing-Ads-certification.jpg',
    },
]

const certificate_settings = {
  dots: true,
  infinite: true,
  speed: 500,
  autoplay: false,
  slidesToShow: 1,
  slidesToScroll: 1
};


  return (
    <div id='courses'>
    <section className="banner_sec">     
<div className="container">
 <div className="row">
           <div className="col-lg-6 col-md-6 centered_content website_left_inner_banner">
                {/* <p>Academic &gt; &nbsp;<span>Digital Marketing </span></p> */}
                <h1>
                Search Engine Optimization 
                </h1> 
                <p style={{margin:'20px 0'}}> 
                Learn the basics of SEO, explore how to optimize and evaluate your site, and build an effective SEO strategy. Discover techniques to increase your website ranking and visibility in search engines organically with this SEO course. Get hands-on experience with live projects. 
                </p>
                <div style={{display: "flex"}}>
                <a href='#syllabus'>
                    <button className="btn btn-outline-primary my-2 my-sm-0 ml-3 active_btn apply_now_btn" type="submit"> Explore Course </button> 
                </a>
                </div> 
            </div>

            <div className="col-lg-6 col-md-6" >
                <img className='website_banner_standing_girl' src={require('../../../../images/website/Courses_page/women-png.png')} alt='' style={{display:'block',margin:'auto'}} />
                <img className='banner_round_icon' src={require('../../../../images/website/Courses_page/1.png')} alt='' style={{left:'8%',top:'10%',filter: 'drop-shadow(0px 9px 14px rgba(50, 50, 50, 0.15))'}}/>
                <img className='banner_round_icon' src={require('../../../../images/website/Courses_page/archer.png')} alt='' style={{right:'10%',top:'16%'}}/>
                <img className='banner_round_icon' src={require('../../../../images/website/Courses_page/peoples.png')} alt='' style={{right:'5%',bottom:'10%',height:'65px'}}/>
                <img className='banner_round_icon' src={require('../../../../images/website/Courses_page/analytics.png')} alt='' style={{left:'15%',bottom:'5%',height:'65px',filter: 'drop-shadow(0px 9px 14px rgba(50, 50, 50, 0.15))'}}/>
              </div>
 </div>
</div>
</section>


<section id='about' className='about_free_certification'>
  <div className='container'>
      <div className='row' style={{display:'flex',flexWrap:'wrap-reverse',justifyContent:'center'}}>
      {/* <div className='col-lg-6'>
        <div className='about_free_certification_left_inner'>
            <div className='hos_small_box'>
              <h3>130+</h3>
              <p>Lorem Etiam vel ullamcorper augue</p>
            </div>
            <div className='hos_small_box'>
              <h3>130+</h3>
              <p>Lorem Etiam vel ullamcorper augue</p>
            </div>
            <div className='hos_small_box'>
              <h3>130+</h3>
              <p>Lorem Etiam vel ullamcorper augue</p>
            </div>
            <div className='hos_small_box'>
              <h3>130+</h3>
              <p>Lorem Etiam vel ullamcorper augue</p>
            </div>
            </div>
      </div> */}
      <div className='col-lg-12'>
          <h2 >About this SEO Certificate Course    </h2>
          <p>Search engine optimization is the technique of optimizing your website and webpage or app to get higher visibility, that is displaying your website top of any search engine result pages. SEO strategies are framed to blow up the quality of traffic which is directed to your site via a search engine, thereby increasing revenue. You have various strategies to hit your goals, anyways relying on your website's nature SEO strategies will vary. This course covers the most effective SEO strategies which add creating backlinks to enhance link popularity, increasing social media presence, keyword research, SEO content writing, and more. 
            <br/><br/>
            Search engine optimization is the technique of optimizing your website and webpage or app to get higher visibility, that is displaying your website top of any search engine result pages. SEO strategies are framed to blow up the quality of traffic which is directed to your site via a search engine, thereby increasing revenue. You have various strategies to hit your goals, anyways relying on your website's nature SEO strategies will vary. This course covers the most effective SEO strategies which add creating backlinks to enhance link popularity, increasing social media presence, keyword research, SEO content writing, and more. 
          </p>
      </div>
      </div>
  </div>
</section>


<section id='skill_tools' className='skills_and_tools'>
  <div className='container'>
    <h2>Skills and Tools</h2>
    <p>In this online program, you’ll become aware about the technical terms of SEO, and strategies to practice in the real world.  </p>
      <div className='row'>
      <div className='col-lg-6 '>
            <h3>Skills</h3>
            <ul className='skills_and_tools_inner'>
              <li><RedArrow/>  Off-Page SEO   </li>
              <li><RedArrow/> On-Page SEO  </li>
              <li><RedArrow/>Keyword Research   </li>
              <li><RedArrow/> Technical SEO  </li>
              <li><RedArrow/> Link Building   </li>
              <li><RedArrow/> Google Analytics  </li>
              <li><RedArrow/> Google Search Console  </li>
              {/* <li><RedArrow/> Marketing plan implementation</li> */}
              <li><RedArrow/> Google Tag Manager (GTM) </li>
              <li><RedArrow/> Site Audit </li>
              <li><RedArrow/> Local SEO  </li>
            </ul>
    
      </div>
      <div className='col-lg-6'>
          <h3>Tools</h3>
          <div className='skill_tools_con'>
        
            <div className='skill_tools'>
                <img src={require('../../../../images/website/Tools-you-wil-master/google-ads.png')} alt=""/>
            </div>
            <div className='skill_tools'>
                <img src={require('../../../../images/website/Tools-you-wil-master/google-anylitsis.png')} alt=""/>
            </div>  
            <div className='skill_tools'>
                <img src={require('../../../../images/website/Tools-you-wil-master/fb-ads.png')} alt=""/>
            </div>
            <div className='skill_tools'>
                <img src={require('../../../../images/website/Tools-you-wil-master/google-tag-manager.png')} alt=""/>
            </div>
            <div className='skill_tools'>
                <img src={require('../../../../images/website/Tools-you-wil-master/linkedin-ads.png')} alt=""/>
            </div>
            <div className='skill_tools'>
                <img src={require('../../../../images/website/Tools-you-wil-master/google-search-console.png')} alt=""/>
            </div>
            <div className='skill_tools'>
                <img src={require('../../../../images/website/Tools-you-wil-master/twitter-ads.png')} alt=""/>
            </div>
            <div className='skill_tools'>
                <img src={require('../../../../images/website/Tools-you-wil-master/mailchimp.png')} alt=""/>
            </div>
        </div>
        </div>
      </div>
  </div>
</section> 

<section id='syllabus' className='course_syllabus'>
  <div className='container'>
      <div className='row' style={{display:'flex',flexWrap:'wrap-reverse'}}>
      <div className='col-lg-6 course_syllabus_inner_left'>
            <ul>
              {
                courseSyllabus.map((course)=>(
                  <li id={'course_syllabus_acc'+course.id} onClick={()=>courseAccordian(course.id)} key={course.id}>
                  <h4> {course.title} </h4>
                  <p>{course.desc}</p>
                  </li>
                ))
              }
              
            </ul>
    
      </div>
      <div className='col-lg-6' style={{display:'flex',flexDirection:'column',justifyContent:'center'}}>
         <h2>Course Syllabus</h2>
         <p>Introduction to SEO, Overview of Search Engine, On-Page Factors, Off-Page Factors, Technical SEO, Local SEO, Google Analytics, Google Search Console, Google Keyword Planner, Google Tag Manager. </p>
         <a href='/Ezio_Skill_Academy.pdf' download>
         <button>Download Syllabus</button>
         </a>
      </div>
      </div>
  </div>

</section>

<section id='certification' className="website_certificate section_spaces">

    <div className="container">
        <h2>Course Certificate  </h2>
        <p>At last, upon completing the online SEO course, you'll know how to rank your website on Google. Acquire an SEO course completion certificate from Ezio Skill Academy which you can share in the certification division of your LinkedIn profile, resumes, and CVs. </p>
    <Slider {...certificate_settings}>
        {
            recognized_certification.map((certi,index)=>(
        <div key={index}>
        <div className='row'>
            <div className='col-lg-6 website_certificate_left_inner'>
                <h2>{certi.name}</h2>
                <p>{certi.desc}</p>
            </div>
            <div className='col-lg-6'>
                <img src={require(`../../../../images/website/certificate/${certi.img}`)} alt=""/>
            </div>
        </div>     
        </div> 
            ))
        }
        
      </Slider>
        </div>
    </section>

    <MoreCourse url={'seo'}/>


</div>
  )
}

export default Seo



{/* <section className=''>
  <div className='container'>
      <div className='row'>
      <div className='col-lg-6 '>
            
    
      </div>
      <div className='col-lg-6'>
         
      </div>
      </div>
  </div>

</section> */}