import React from 'react'

function DbCertificate() {
  return (
    
<svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_850_58)">
<path d="M25.8669 7.95441L23.1104 6.30519L22.4893 3.73439C22.2136 2.59422 21.1576 1.83411 19.9996 1.92523L16.8456 2.16934L15.0388 0.569591C14.1489 -0.189732 12.8542 -0.189732 11.9613 0.5688L10.1544 2.16929L7.0004 1.92518C5.85559 1.84487 4.78571 2.59491 4.53928 3.64084C4.48829 3.79936 3.56718 6.66257 3.62698 6.47658L1.13359 7.95356C0.134803 8.56692 -0.265345 9.79821 0.181895 10.8813L1.25794 13.4891L0.182686 16.0963C-0.265345 17.1801 0.135541 18.4106 1.13359 19.0232L3.88727 20.6609L4.51065 23.2433C4.78487 24.3826 5.83623 25.1389 7.00034 25.0524L10.1204 24.8114L11.9612 26.4299C12.4069 26.8099 12.9531 27 13.5 27C14.0461 27 14.5931 26.8099 15.0388 26.4299L16.8796 24.8114L19.9996 25.0524C21.1785 25.1458 22.2151 24.3834 22.4893 23.2432L23.1104 20.6724L25.8669 19.0232C26.8641 18.4106 27.2643 17.1801 26.8178 16.0963L25.7417 13.4892L26.8185 10.8805C27.2651 9.79832 26.8649 8.56697 25.8669 7.95441ZM13.5 22.2012C8.70219 22.2012 4.79884 18.2978 4.79884 13.5C4.79884 8.70218 8.70219 4.79883 13.5 4.79883C18.2978 4.79883 22.2012 8.70218 22.2012 13.5C22.2012 18.2978 18.2978 22.2012 13.5 22.2012Z" fill="#F14A51"/>
<path d="M13.5 6.38086C9.57429 6.38086 6.38086 9.5743 6.38086 13.5C6.38086 17.4257 9.57429 20.6191 13.5 20.6191C17.4257 20.6191 20.6191 17.4257 20.6191 13.5C20.6191 9.5743 17.4257 6.38086 13.5 6.38086ZM18.0143 11.6862L13.2682 16.4323C13.1137 16.5868 12.9113 16.6641 12.709 16.6641C12.5066 16.6641 12.3042 16.5868 12.1497 16.4323L8.98567 13.2682C8.6767 12.9593 8.6767 12.4587 8.98567 12.1497C9.29464 11.8407 9.79525 11.8407 10.1042 12.1497L12.709 14.7545L16.8958 10.5677C17.2048 10.2587 17.7054 10.2587 18.0144 10.5677C18.3233 10.8766 18.3233 11.3772 18.0143 11.6862V11.6862Z" fill="#F14A51"/>
</g>
<defs>
<clipPath id="clip0_850_58">
<rect width="27" height="27" fill="white"/>
</clipPath>
</defs>
</svg>

  )
}

export default DbCertificate