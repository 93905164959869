import React from 'react';
import Form from './Form.js';

const Popup = props => {
    return (
      <div className="popup-box">
        <div className="box">
          <span className="close-icon" onClick={props.handleClose}>x</span>
          
          <Form />

        </div>
      </div>
    );
  };

export default Popup;