import React from 'react'

export default function TourIcon() {
  return (
    <svg id="take_a_tour" data-name="take a tour" xmlns="http://www.w3.org/2000/svg" width="20.682" height="20.682" viewBox="0 0 20.682 20.682">
      <g id="Group_4159" data-name="Group 4159">
        <g id="Group_4158" data-name="Group 4158">
          <path id="Path_14026" data-name="Path 14026" d="M10.341,0A10.341,10.341,0,1,0,20.682,10.341,10.341,10.341,0,0,0,10.341,0Zm0,19.205a8.864,8.864,0,1,1,8.864-8.864A8.864,8.864,0,0,1,10.341,19.205Z" fill="#f29423" />
        </g>
      </g>
      <g id="Group_4161" data-name="Group 4161" transform="translate(7.387 6.647)">
        <g id="Group_4160" data-name="Group 4160">
          <path id="Path_14027" data-name="Path 14027" d="M177.976,156.952a.739.739,0,0,0-.332-.332l-5.909-2.955a.739.739,0,0,0-1.069.661v5.909a.739.739,0,0,0,1.069.661l5.909-2.955A.739.739,0,0,0,177.976,156.952Zm-5.832,2.089v-3.519l3.519,1.759Z" transform="translate(-170.666 -153.587)" fill="#f29423" />
        </g>
      </g>
    </svg>
  )
}
