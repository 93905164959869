import React from 'react'

function CustomizePlanIcon() {
  return (
   
<svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="1" y="15" width="10" height="3" rx="1.5" fill="#F14A51"/>
<rect width="10" height="3" rx="1.5" transform="matrix(-1 0 0 1 32 25)" fill="#F14A51"/>
<rect x="1" y="5" width="20" height="3" rx="1.5" fill="#F14A51"/>
<rect x="18" y="15" width="14" height="3" rx="1.5" fill="#F14A51"/>
<rect width="14" height="3" rx="1.5" transform="matrix(-1 0 0 1 15 25)" fill="#F14A51"/>
<circle cx="28.5" cy="6.5" r="4.5" fill="#01B170"/>
<circle cx="11.5" cy="16.5" r="4.5" fill="#01B170"/>
<circle cx="22.5" cy="26.5" r="4.5" fill="#01B170"/>
</svg>

  )
}

export default CustomizePlanIcon