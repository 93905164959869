import React from 'react'

export default function AgreementIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="28.399" height="16.566" viewBox="0 0 28.399 16.566">
      <g id="Agreement" transform="translate(-14655 20826)">
        <g id="hand-shake" transform="translate(14655 -20932.666)">
          <g id="Group_4171" data-name="Group 4171" transform="translate(0 106.666)">
            <g id="Group_4170" data-name="Group 4170" transform="translate(0 0)">
              <path id="Path_14031" data-name="Path 14031" d="M6.863,107.968c-.947-.947-4.97-1.183-6.272-1.3a.435.435,0,0,0-.355.118.9.9,0,0,0-.237.473v10.65a.559.559,0,0,0,.592.592h3.55a.651.651,0,0,0,.592-.355c0-.355,2.13-6.627,2.367-9.7A.473.473,0,0,0,6.863,107.968Z" transform="translate(0 -106.666)" fill="#143059"/>
            </g>
          </g>
          <g id="Group_4173" data-name="Group 4173" transform="translate(10.043 109.151)">
            <g id="Group_4172" data-name="Group 4172">
              <path id="Path_14032" data-name="Path 14032" d="M190.543,153.123c-1.183-.355-2.248-.828-3.195-1.183-2.13-.947-3.077-.473-4.97,1.42a3.872,3.872,0,0,0-1.3,2.485c0,.118,0,.118.237.237.592.237,1.42.355,2.248-.947a.615.615,0,0,1,.473-.237,2.193,2.193,0,0,0,.828-.237,5.769,5.769,0,0,1,.828-.355h.237c.118,0,.355.118.473.118.592.592,1.657,1.42,2.722,2.367a27.1,27.1,0,0,1,4.142,3.787h.118C192.554,158.211,191.134,154.188,190.543,153.123Z" transform="translate(-181.061 -151.467)" fill="#143059"/>
            </g>
          </g>
          <g id="Group_4175" data-name="Group 4175" transform="translate(20.116 107.849)">
            <g id="Group_4174" data-name="Group 4174">
              <path id="Path_14033" data-name="Path 14033" d="M370.358,128a20.751,20.751,0,0,0-7.337,1.183l-.355.355a.711.711,0,0,0,.118.473,66.65,66.65,0,0,1,3.432,9.348.786.786,0,0,0,.592.473h3.55a.559.559,0,0,0,.592-.592v-10.65A.559.559,0,0,0,370.358,128Z" transform="translate(-362.666 -128)" fill="#143059"/>
            </g>
          </g>
          <g id="Group_4177" data-name="Group 4177" transform="translate(6.153 110.216)">
            <g id="Group_4176" data-name="Group 4176" transform="translate(0 0)">
              <path id="Path_14034" data-name="Path 14034" d="M126.2,179.9c-.473-1.065-2.485-2.6-4.26-4.023-.947-.828-1.893-1.538-2.6-2.13-.118.118-.355.118-.355.237a2.229,2.229,0,0,1-.947.237A2.923,2.923,0,0,1,114.6,175.4a1.4,1.4,0,0,1-1.065-1.065c-.237-1.183.828-2.84,1.657-3.668h-2.367a60.33,60.33,0,0,1-1.893,7.1,13.378,13.378,0,0,0,1.3,1.3c2.248,1.893,4.852,3.787,5.325,4.142a3.363,3.363,0,0,0,1.42.473h.355l-1.893-1.893a.586.586,0,0,1,.828-.828l2.367,2.367c.237.237.473.118.71.118.355-.118.473-.355.592-.71l-2.722-2.722a.586.586,0,0,1,.828-.828L123,182.145a1.265,1.265,0,0,0,.947,0c.118-.118.355-.237.473-.473l-3.313-3.313a.586.586,0,0,1,.828-.828l3.432,3.432a.783.783,0,0,0,.71-.118A.8.8,0,0,0,126.2,179.9Z" transform="translate(-110.934 -170.667)" fill="#143059"/>
            </g>
          </g>
        </g>
      </g>
    </svg>

  )
}
