import React from 'react'

export default function QuestionIcon() {
  return (
    <svg id="question_2_" data-name="question (2)" xmlns="http://www.w3.org/2000/svg" width="24" height="40" viewBox="0 0 24.686 41.103">
      <ellipse id="Ellipse_334" data-name="Ellipse 334" cx="5.602" cy="4.74" rx="5.602" ry="4.74" transform="translate(6.656 31.623)" fill="#fba919"/>
      <path id="Path_13856" data-name="Path 13856" d="M112.343,0A12.357,12.357,0,0,0,100,12.343V13.53h9.495V12.343a2.848,2.848,0,1,1,4.689,2.174l-6.588,5.6v8.525h9.495V24.508l3.242-2.756A12.343,12.343,0,0,0,112.343,0Z" transform="translate(-100)" fill="#fba919"/>
    </svg>
  )
}
