import React from 'react'

function UploadIcon() {
    return (
        <div>
            
<svg xmlns="http://www.w3.org/2000/svg" width="49.353" height="49.284" viewBox="0 0 49.353 49.284">
  <g id="upload_1_" data-name="upload (1)" transform="translate(0 -0.358)">
    <g id="Group_4505" data-name="Group 4505" transform="translate(0 30.46)">
      <g id="Group_4504" data-name="Group 4504">
        <path id="Path_14523" data-name="Path 14523" d="M45.5,312.642v13.4a1.93,1.93,0,0,1-1.928,1.928H5.784a1.93,1.93,0,0,1-1.928-1.928v-13.4H0v13.4a5.79,5.79,0,0,0,5.784,5.784H43.57a5.79,5.79,0,0,0,5.784-5.784v-13.4Z" transform="translate(0 -312.642)" fill="#143059"/>
      </g>
    </g>
    <g id="Group_4507" data-name="Group 4507" transform="translate(12.696 0.358)">
      <g id="Group_4506" data-name="Group 4506" transform="translate(0 0)">
        <path id="Path_14524" data-name="Path 14524" d="M143.7.358l-11.98,11.98,2.726,2.726,7.326-7.326V37.786h3.856V7.739l7.326,7.326,2.726-2.726Z" transform="translate(-131.716 -0.358)" fill="#143059"/>
      </g>
    </g>
  </g>
</svg>

        </div>
    )
}

export default UploadIcon
