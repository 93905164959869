import React, { useEffect, useState } from 'react';
import { NavLink, Link, Switch, Route } from 'react-router-dom';
import $ from 'jquery'
import {useDispatch,useSelector} from 'react-redux'
import '../superadmin/styles/styles.scss'
import {adminRoutes} from './routes'
import LogoutIcon from './../../../images/iconsSVG/LogoutIcon'
import UserIcon from './../../../images/iconsSVG/UserIcon';
import NotificationIcon from './../../../images/iconsSVG/Notification'
import axios from 'axios';
import Cookies from 'js-cookie';


export default function AdminIndex(){

    const [ notifyCount, setNotifyCount ] = useState();

    // useEffect(() => {
    //   axios.get('https://api.ezioskillacademy.com/api/notification/count',{headers:{
    //     Authorization:`Bearer ${Cookies.get('token')}`
    //   }})
    //   .then((res)=>{
    //     // // console.log(res.data)
    //     setNotifyCount(res.data.notify_count)
    //   })
    // }, [window.location.href]);


    //closeSlideMenuHandler();


  const Logout=()=>{
    localStorage.clear()
    Cookies.remove('token')
  }

    return(
      <>
      <header>
      
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <div className="ezio_admin_logo" >
          <img src={require('../../../images/logo.png')} alt='logo' />
          
        </div>
        
        <Link
          to="/admin/notifications"
          className="menu-collapse-btn"
          style={{ zIndex: 10, marginRight: '25px' }}
        >
          {/* <NotificationIcon/> */}
          <NotificationIcon notifyCount = { notifyCount ? notifyCount : 0 }/>
        </Link>
      </div>

      <div className="title-section">
        <div className="title-container">
          <h5>
          </h5>
        </div>
        <div>
        </div>
      </div>
    </header>
    <div className='layout'>
          <div className="layout-content">
            <div className="lay-left-menu">
        <div
      className="side-menu"
     // ref={props.refProp}
      //onClick={props.setHeaderTitleFn}
      style={{ paddingTop: "0px" }}
    >
      {/* <div className="package_type">{reduxValue.planName} Package</div> */}
      <div className="set_sidemenu">
        <div className="side-menu-top">
          <ul className="menu_list">
          <li>
      <NavLink
        to='/admin/user-management'
        data-id='user-management-id'
        id='user-management-id'
        className="nav-link nav-link-title"
        activeClassName="nav-link-active"
      >
       {/* <img src={require('../../../images/home.png')} alt ='' /> */}
      <UserIcon/>
        <p className="link-name">
          User Management          
        </p>      
      </NavLink>
    </li>
  
    <li>
      <NavLink
        to='/admin/project-submission'
        data-id='project-submission'
        id='project-submission'
        className="nav-link nav-link-title"
        activeClassName="nav-link-active"
      >
       {/* <img src={require('../../../images/home.png')} alt ='' /> */}
      <UserIcon/>
        <p className="link-name">
          Project Submission          
        </p>      
      </NavLink>
    </li>

 
    
    <li>
      <NavLink
        to='/admin/chat'
          data-id='user-management-id'
        id='user-management-id'
        className="nav-link nav-link-title"
        activeClassName="nav-link-active"
      >
       {/* <img src={require('../../../images/home.png')} alt ='' /> */}
      <UserIcon/>
        <p className="link-name">
          Chat          
        </p>      
      </NavLink>
    </li>
   
          </ul>
        </div>
        <div className="side-menu-bottom">
          <ul>
   
            <li onClick={Logout}>
              <NavLink to="/login" className="nav-link" >
                <LogoutIcon />
                <p className="link-name">Logout</p>
              </NavLink>
            </li>
          </ul>
        </div>

      </div>
    </div>
    </div>
    <div className="lay-right-content">
             <Switch>
                    {
                      adminRoutes.map((route,index)=>(
                                <Route
                                key={index} 
                                path={route.path} 
                                exact={route.exact}
                                children={<route.main />}/>
                                  
                      ))
                    }
                    
                  </Switch>     
    </div>
    </div>
    </div>
    </>
    )
}



