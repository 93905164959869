import React from 'react'

export default function UpgradeIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22.205" height="16.99" viewBox="0 0 22.205 16.99">
      <g id="Upgrade_Package" data-name="Upgrade Package" transform="translate(-18153 20174)">
        <g id="Group_4371" data-name="Group 4371" transform="translate(18107 -20431)">
          <path id="Path_10449" data-name="Path 10449" d="M19.361,32.605l2.256-4.227-4.784.543.7,1.02L0,40.475l.994,1.654L18.626,31.535Z" transform="translate(46.001 228.622)" fill="#143059"/>
          <path id="Path_10450" data-name="Path 10450" d="M13.864,89.287h20.93V76.741Z" transform="translate(33.411 184.702)" fill="#143059"/>
        </g>
      </g>
</svg>  
  )
}
