import React from "react";

export function LikeIcon() {
  return (
    <div>
      <svg
        id="like"
        xmlns="http://www.w3.org/2000/svg"
        width="20.741"
        height="21.336"
        viewBox="0 0 20.741 21.336"
      >
        <path
          id="Path_14566"
          data-name="Path 14566"
          d="M26.793,14.505a2.65,2.65,0,0,0,.62-1.771,2.721,2.721,0,0,0-.58-1.535,2.385,2.385,0,0,0-.567-2.744A4.927,4.927,0,0,0,22.684,7.6a14,14,0,0,0-2.2.281h0c-.223.04-.46.089-.7.143a7.784,7.784,0,0,1,.558-2.592c.625-1.9.589-3.355-.116-4.328A2.836,2.836,0,0,0,17.954,0a1.121,1.121,0,0,0-.861.393,3,3,0,0,0-.375,2.128c-.589,1.579-2.24,5.452-3.636,6.527a.478.478,0,0,0-.071.062,4.793,4.793,0,0,0-.875,1.312,1.841,1.841,0,0,0-.883-.223H8.531a1.858,1.858,0,0,0-1.856,1.856v7.25a1.858,1.858,0,0,0,1.856,1.856h2.722a1.852,1.852,0,0,0,1.071-.339l1.049.125a44.923,44.923,0,0,0,5.948.326c.531.04,1.031.062,1.495.062a10.011,10.011,0,0,0,2.075-.187,3.88,3.88,0,0,0,2.771-1.722,2.731,2.731,0,0,0,.3-1.709A2.846,2.846,0,0,0,26.976,15.4,3.014,3.014,0,0,0,26.793,14.505ZM8.531,19.957a.652.652,0,0,1-.651-.651V12.051a.652.652,0,0,1,.651-.651h2.722a.652.652,0,0,1,.651.651V19.3a.652.652,0,0,1-.651.651H8.531Zm17.12-5.974a.6.6,0,0,0-.08.727,1.856,1.856,0,0,1,.205.745A1.785,1.785,0,0,1,24.937,17a.608.608,0,0,0-.205.687,1.576,1.576,0,0,1-.12,1.151,2.791,2.791,0,0,1-1.972,1.133,11.754,11.754,0,0,1-3.253.1h-.062a44.042,44.042,0,0,1-5.8-.317h0l-.451-.054a1.89,1.89,0,0,0,.04-.393V12.051a1.887,1.887,0,0,0-.085-.553,3.729,3.729,0,0,1,.83-1.53c2-1.588,3.962-6.947,4.047-7.179a.565.565,0,0,0,.027-.3A2.788,2.788,0,0,1,17.986,1.2a1.6,1.6,0,0,1,1.258.6c.455.629.437,1.753-.054,3.244-.75,2.271-.812,3.467-.219,3.993a.972.972,0,0,0,.973.174c.272-.062.531-.116.776-.156l.058-.013c1.37-.3,3.824-.482,4.676.294a1.284,1.284,0,0,1,.152,1.629.6.6,0,0,0,.107.776,1.69,1.69,0,0,1,.5,1.04A1.657,1.657,0,0,1,25.651,13.983Z"
          transform="translate(-6.675 0)"
          fill="#373737"
        />
      </svg>
    </div>
  );
}

export function ShareIcon() {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21.021"
        height="21.027"
        viewBox="0 0 21.021 21.027"
      >
        <path
          id="share"
          d="M21.02,10.508,11.437,0V6.294H10.324A10.324,10.324,0,0,0,0,16.619v4.409l1.839-2.015a13.2,13.2,0,0,1,9.6-4.29v6.294ZM1.232,17.86V16.619a9.092,9.092,0,0,1,9.092-9.092h2.345V3.18l6.683,7.328-6.683,7.328V13.49H11.564a14.433,14.433,0,0,0-10.332,4.37Zm0,0"
          transform="translate(0)"
          fill="#373737"
        />
      </svg>
    </div>
  );
}

export function CommentIcon() {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21.101"
        height="21.1"
        viewBox="0 0 21.101 21.1"
      >
        <path
          id="comment"
          d="M18.827,0H2.274A2.276,2.276,0,0,0,0,2.274V14.4a2.276,2.276,0,0,0,2.274,2.273H7.52l1.737,3.475a1.5,1.5,0,0,0,1.293.953,1.5,1.5,0,0,0,1.293-.953l1.737-3.475h5.246A2.276,2.276,0,0,0,21.1,14.4V2.274A2.276,2.276,0,0,0,18.827,0Zm1.037,14.4a1.038,1.038,0,0,1-1.037,1.037H13.2a.618.618,0,0,0-.553.342l-1.908,3.816a.752.752,0,0,1-.187.259.752.752,0,0,1-.187-.259L8.455,15.778a.618.618,0,0,0-.553-.342H2.274A1.038,1.038,0,0,1,1.236,14.4V2.274A1.038,1.038,0,0,1,2.274,1.236H18.827a1.038,1.038,0,0,1,1.037,1.037Zm0,0"
          fill="#373737"
        />
      </svg>
    </div>
  );
}
export function PolygonIcon() {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="12"
        viewBox="0 0 18 12"
      >
        <path
          id="Polygon_107"
          data-name="Polygon 107"
          d="M9,0l9,12H0Z"
          transform="translate(18 12) rotate(180)"
          fill="#afafaf"
        />
      </svg>
    </div>
  );
}
