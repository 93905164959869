import React from 'react'

function PackagePlanIcon() {
  return (
<svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M30.9355 24.975L24.8855 27.195V11.515C24.8855 9.22501 23.0255 7.36501 20.7355 7.36501H14.5005V6.62501L23.0505 3.49001C24.6805 2.89001 26.4955 3.73001 27.0905 5.36001L28.2105 8.41001L28.9605 10.45L32.8055 20.935C33.4055 22.565 32.5655 24.38 30.9355 24.975Z" fill="#01B170"/>
<path d="M20.7355 8.36499H14.5005V9.63499C14.5005 11.22 13.2105 12.51 11.6305 12.51C10.02 12.51 8.75549 11.1996 8.75549 9.63499V8.36499H4.15049C2.41049 8.36499 1.00049 9.77499 1.00049 11.515V28.1C1.00049 29.84 2.41049 31.25 4.15049 31.25H20.7355C22.4755 31.25 23.8855 29.84 23.8855 28.1V11.515C23.8855 9.77499 22.4755 8.36499 20.7355 8.36499ZM19.5534 27.6252H5.33099C5.05489 27.6252 4.83099 27.4016 4.83099 27.1252C4.83099 26.8489 5.05489 26.6252 5.33099 26.6252H19.5534C19.8295 26.6252 20.0534 26.8489 20.0534 27.1252C20.0534 27.4016 19.8295 27.6252 19.5534 27.6252ZM19.5534 23.5222H5.33099C5.05489 23.5222 4.83099 23.2985 4.83099 23.0222C4.83099 22.7458 5.05489 22.5222 5.33099 22.5222H19.5534C19.8295 22.5222 20.0534 22.7458 20.0534 23.0222C20.0534 23.2985 19.8295 23.5222 19.5534 23.5222ZM19.5534 19.4187H5.33099C5.05489 19.4187 4.83099 19.195 4.83099 18.9187C4.83099 18.6423 5.05489 18.4187 5.33099 18.4187H19.5534C19.8295 18.4187 20.0534 18.6423 20.0534 18.9187C20.0534 19.195 19.8295 19.4187 19.5534 19.4187Z" fill="#F14A51"/>
<path d="M13.4993 6.8635V9.637C13.4993 10.672 12.6633 11.508 11.6283 11.508C10.5993 11.508 9.75684 10.6735 9.75684 9.637V4.6215C9.75684 3.586 10.5973 2.75 11.6283 2.75C12.6633 2.75 13.4993 3.586 13.4993 4.6215V6.8635Z" fill="#F14A51"/>
</svg>

  )
}

export default PackagePlanIcon