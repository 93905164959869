import axios from 'axios';
import React,{useState,useEffect} from 'react'
import Cookies from 'js-cookie';
import CustomModal from './CustomModal';
import $ from 'jquery'
import RazorpayPay from '../user/payment';
import Toaster from '../../toaster';

/*
{headers:{
    Authorization:`Bearer ${Cookies.get('token')}`
  } }
*/
function PaymentPackage(props) {
    const [details, setDetails] = useState([])
    const [packageList, setPackageList] = useState([])
    const [addPopup,setAddPopup] = useState(false)
    const [editPopupId,setEditPopupId] = useState()
    const [fields, Getfields] = useState({});
    const [fieldsEdit, GetfieldsEdit] = useState({});
    const [error,setError]=useState({})
    
    const [courseList, setCourseList] = useState([])
    const [selectedCourseId,setSelectedCourseId]= useState()

    const [batchAllocationPopup,setBatchAllocationPopup]=useState(false)
    const [batchList, setBatchList] = useState([])
    const [selectedBatch,setSelectedBatch]=useState()
    const [selectedMentor,setSelectedMentor]=useState()

    const [packageDetail,setPackageDetail]=useState()

    const [tosOpen,setTosOpen]=useState()

    useEffect(() =>{
        GetPackageList()
      GetCourseList()
  },[])
 

  const GetCourseList = () =>{
    
    axios.get(`https://api.ezioskillacademy.com/api/admin/course`, {headers:{
      Authorization:`Bearer ${Cookies.get('token')}`
    } })
    .then(res => {  
                  // console.log("batch",res.data)
                  setCourseList(res.data.data)     
    }).catch(e=>{
      alert(e.response.data.message)
    })
}
  
  const GetPackageList = () =>{    
      axios.get(`https://api.ezioskillacademy.com/api/admin/payment/package`, {headers:{
        Authorization:`Bearer ${Cookies.get('token')}`
      } })
      .then(res => {  
                    // console.log("package",res.data)
                    setPackageList(res.data.data)     
      }).catch(e=>{
        alert(e.response.data.message)
      })
  }
  const HandleDelete=(id)=>{
    var result = window.confirm("Are you sure you want to delete?");
    if (result) {
      axios.delete(`https://api.ezioskillacademy.com/api/admin/payment/package/${id}`,{headers:{
        Authorization:`Bearer ${Cookies.get('token')}`
      } })
      .then(res => {
        GetPackageList()              
        setTosOpen(res.data.message)
      }).catch(e=>{
        alert(e.response.data.message)
      })
    }
  }
  const addnewPackage=()=>{

    // console.log(fields)
        let err={}
        let isValid=true
        if(!fields.package_name){
            err["package_name"]="Please Enter Package Name"
            isValid=false
        }
        if(!fields.amount){
            err["amount"]="Please Enter Amount"
            isValid=false
        }
        if(!fields.payment_type){
            err["payment_type"]="Please Select Payment Type"
            isValid=false
        }
        if(!fields.isEnable){
            err["isEnable"]="Please Select Status"
            isValid=false
        }
        if(!fields.course_id){
            err["course_id"]="Please Select Course"
            isValid=false
        }
        if(fields.payment_type=='partial_payment'&&!fields.amount_split_limit){
          err["amount_split_limit"]="Please Select Amount Split"
          isValid=false
      }
       
        setError(err)
    // console.log({error})

        if(isValid){
          const jsonData=  {
                "package_name": fields.package_name,
                "amount": +fields.amount,
                "payment_type": fields.payment_type,    
                "isEnable": fields.isEnable,
                "amount_split_limit": fields.amount_split_limit||0
            }
        axios.post('https://api.ezioskillacademy.com/api/admin/payment/package?course_id='+fields.course_id,jsonData,{headers:{
            Authorization:`Bearer ${Cookies.get('token')}`
          } })
        .then(res=>{
            // console.log("res",res.data)
           
                GetPackageList()
                $('#add_scs_msg').html(res.data.message)
                setTosOpen(res.data.message)
                setTimeout(()=>{
                    setAddPopup(false)      
                },3000)
                
            
        }).catch(e=>{
           alert(e.response.data.message)
        })
    }
}

const editBatch=()=>{

    const jsonData={
      "package_name": fieldsEdit.package_name,
      "amount": +fieldsEdit.amount,
      "payment_type": fieldsEdit.payment_type,    
      "isEnable": fieldsEdit.isEnable,
      "amount_split_limit": fieldsEdit.amount_split_limit||0
    }
    axios.put('https://api.ezioskillacademy.com/api/admin/payment/package/'+editPopupId,jsonData,{headers:{
        Authorization:`Bearer ${Cookies.get('token')}`
      } })
    .then(res=>{
            GetPackageList()
            $('#edit_scs_msg').html(res.data.message)
            setTosOpen(res.data.message)
            setTimeout(()=>{
                setEditPopupId(undefined)      
            },3000)
            
        
    }).catch(e=>{
       alert(e.response.data.message)
    })

  }

  const handleValue=(e)=>{
      Getfields({ ...fields, [e.target.name]:e.target.value })
    }
    const handleEditValue=(e)=>{
        GetfieldsEdit({ ...fieldsEdit, [e.target.name]:e.target.value })
      }
    const setEditPopupOpen=(mentor)=>{
        setEditPopupId(mentor._id)
        GetfieldsEdit(mentor)
        //GetBatchFields(id)       

    }

    function getBatchList(){
      axios.get(`https://api.ezioskillacademy.com/api/admin/batch`, {headers:{
        Authorization:`Bearer ${Cookies.get('token')}`
      } })
      .then(res => {
        // console.log("batch",res.data)
        setBatchList(res.data.data)    
      }).catch(e=>{
        alert(e.response.data.message)
      })
    }

    const allocateBatch=()=>{
     // // console.log(selectedBatch,selectedMentor)
      if(selectedBatch){
      const jsonData={
        "mentor_id": selectedMentor._id,
      }
      axios.put('https://api.ezioskillacademy.com/api/admin/batch/mentor/allocate/'+selectedBatch,jsonData,{headers:{
        Authorization:`Bearer ${Cookies.get('token')}`
      } })
      .then(res=>{
        // console.log("res",res.data)
       
            $('#allocate_scs_msg').html(res.data.message)
            setTosOpen(res.data.message)  
            setSelectedBatch(undefined)
            setSelectedMentor(undefined)
            getBatchList()
            setTimeout(()=>{
                setBatchAllocationPopup(false)      
            },3000)
            
        
    }).catch(e=>{
       alert(e.response.data.message)
    })
  }
  else{
    alert('Please Select Required Data')
  }
  
    }


    const changeActive=(e,id)=>{
        // console.log(e.target.value=='enable')
      
        let enableStatus=e.target.value=='enable'
        axios.put(`https://api.ezioskillacademy.com/api/admin/payment/package/${id}`,{
            "isEnable": enableStatus
        }, {headers:{
          Authorization:`Bearer ${Cookies.get('token')}`
        } })
          .then(res => {  
                        // console.log("status",res.data)
                        setTosOpen(res.data.message)
                        GetPackageList()     
          }).catch(e=>{
            alert(e.response.data.message)
          })
      }

  const handleViewPackage=(id)=>{
    axios.get(`https://api.ezioskillacademy.com/api/admin/payment/package/${id}`,{headers:{
      Authorization:`Bearer ${Cookies.get('token')}`
    } })
      .then(res => {
        // console.log("response",res.data)     
        setPackageDetail(res.data.data[0])
       // GetData(res.data.data)  
                    
                
      }).catch((err)=>{
           // console.log(err)
          alert("Something Wrong.. Please Login again...")
      
      })
  }


  return (
    <div className="dash_page_bg dash_section_ptb add_payment">


         <br />
        <br />
  
  <button className="add_payment_btn" 
  onClick={() => setAddPopup(true)}
   >
     Add Package
   </button>
        
      
        
        <br />
        <br />
        <div className="clientrate_table">
          <div className="tabcomp-style">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <td>Package Name</td>
                    <td>Payable Amount</td>
                    <td>Payment Type</td>  
                    <td style={{width:'300px'}}></td>                   
                  </tr>
                </thead>
                <tbody>
                  {packageList.length > 0 ? (
                    <>
                      {packageList
                        .map((pkg, id) => (
                          <tr key={id}>
                            <td>{pkg.package_name}</td>
                            <td>{pkg.payable_amount}</td>
                            <td>{pkg.payment_type}</td>     
                            <td style={{display:'flex',justifyContent:'center',width:'300px'}}>                        
                                <button className='green_btn 'onClick={()=>handleViewPackage(pkg._id)}>View</button>
                                <button 
                                onClick={()=>HandleDelete(pkg._id)} className='red_btn'>Delete</button>
                              
                                <div onChange={(e)=>changeActive(e,pkg._id)} style={{display:'flex'}}>
                                  <input class="radio" id={"flexRadioDefault1"+pkg._id} type="radio" name={"flexRadioDefault"+pkg._id}  value="enable" checked={pkg.isEnable}/>
                                  <label for={"flexRadioDefault1"+pkg._id}>
                                    Enable
                                  </label>
                                  &nbsp;&nbsp;
                                  <input class="radio" id={"flexRadioDefault2"+pkg._id} type="radio" name={"flexRadioDefault"+pkg._id}  value="disable" checked={!pkg.isEnable}/>
                                  <label for={"flexRadioDefault2"+pkg._id}>
                                    Disable
                                  </label>
                                </div>
                               
                                </td>                         
                          </tr>
                        ))}
                    </>
                  ) : (
                    <tr>
                      <td colSpan="4" style={{ textAlign: "center" }}>
                      No data
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <CustomModal open={addPopup}>
        <div className="scsmdl-container" style={{ height: "auto" }}>
          <div className="scsmdl-header">
            <p>Add Package</p>
            {/* <img src={'/modalclose.jpg'} className="scsmdl-closeimg"  /> */}
            <img
              src={"/modalclose.jpg"}
              className="scsmdl-closeimg"
              onClick={() => setAddPopup(false)}
              alt="close img"
            />
          </div>
          <div className="scsmdl-body" style={{ padding: "20px" }}>
          <div className="form-group">
              <label>Package Name:</label>
              <input
                className="form-control"
                type="text"
                name="package_name"
                onChange={(e) => handleValue(e)}
              />
             
            </div>
            <p style={{color:'red'}}>{error.package_name}</p>
            <div className="form-group">
              <label>Amount:</label>
              <input
                className="form-control"
                type="text"
                name="amount"
                onChange={(e) => handleValue(e)}
              />
               <p style={{color:'red'}}>{error.amount}</p>
            </div>
         
            <div className="form-group">
              <label>Payment Type:</label>
              <select style={{width:'100%',height:'40px',border: '1px solid #e8e8e8'}} name='payment_type'  onChange={(e) => handleValue(e)}>
                  <option selected disabled>Please Select</option>
                  <option value='full_payment'>Full Payment</option>
                  <option value='partial_payment'>Partial Payment</option>
                </select>
             <p style={{color:'red'}}>{error.payment_type}</p>
            </div>
            <div className="form-group">
              <label>Status:</label>
              <select style={{width:'100%',height:'40px',border: '1px solid #e8e8e8'}} name='isEnable'  onChange={(e) => handleValue(e)}>
                  <option selected disabled>Please Select</option>
                  <option value='true' >Enable</option>
                  <option value='false'>Disable</option>
                </select>
                <p style={{color:'red'}}>{error.isEnable}</p>
            </div>
            <div className="form-group">
              <label>Amount Split Limit(Applicable for Partial Payment):</label>
              <select style={{width:'100%',height:'40px',border: '1px solid #e8e8e8'}} name='amount_split_limit'  onChange={(e) => handleValue(e)}>
                  <option selected disabled>Please Select</option>
                  <option value='1' >1</option>
                  <option value='2'>2</option>
                  <option value='3'>3</option>
                </select>
                <p style={{color:'red'}}>{error.amount_split_limit}</p>
            </div>
            <div className="form-group">
              <label>Course:</label>
              <select style={{width:'100%',height:'40px',border: '1px solid #e8e8e8'}} name='course_id'  onChange={(e) => handleValue(e)}>
                  <option selected disabled>Please Select</option>
                  {
                      courseList.map(course=>(
                          <option value={course._id}>{course.course_name}</option>
                      ))
                    }
                </select>
                <p style={{color:'red'}}>{error.course_id}</p>
            </div>
           
            <div className="row">
              <div className="col-lg-5">
                <button
                  onClick={addnewPackage}
                  className="addpayment-submitbtn"
               //   disabled={loader}
                //  style={loader ? { opacity: "0.5" } : {}}
                >
                  Submit
                </button>
              </div>
             
            </div>
            <p id="add_scs_msg" style={{color:'green'}}></p>
          </div>
        </div>
      </CustomModal>

      <CustomModal open={editPopupId}>
        <div className="scsmdl-container" style={{ height: "auto" }}>
          <div className="scsmdl-header">
            <p>Edit Package  </p>
            {/* <img src={'/modalclose.jpg'} className="scsmdl-closeimg"  /> */}
            <img
              src={"/modalclose.jpg"}
              className="scsmdl-closeimg"
              onClick={() => setEditPopupId(undefined)}
              alt="close img"
            />
          </div>
          <div className="scsmdl-body" style={{ padding: "20px" }}>
         
          <div className="form-group">
              <label>Package Name:</label>
              <input
                className="form-control"
                type="text"
                name="package_name"
                onChange={(e) => handleEditValue(e)}
                value={fieldsEdit.package_name}
              />
             
            </div>
            <p style={{color:'red'}}>{error.package_name}</p>
            <div className="form-group">
              <label>Amount:</label>
              <input
                className="form-control"
                type="text"
                name="amount"
                onChange={(e) => handleEditValue(e)}
                value={fieldsEdit.amount}
              />
               <p style={{color:'red'}}>{error.amount}</p>
            </div>
         
            <div className="form-group">
              <label>Payment Type:</label>
              <select style={{width:'100%',height:'40px',border: '1px solid #e8e8e8'}} name='payment_type'  onChange={(e) => handleEditValue(e)} value={fieldsEdit.payment_type}>
                  <option selected disabled>Please Select</option>
                  <option value='full_payment'>Full Payment</option>
                  <option value='partial_payment'>Partial Payment</option>
                </select>
             <p style={{color:'red'}}>{error.payment_type}</p>
            </div>
            <div className="form-group">
              <label>Status:</label>
              <select style={{width:'100%',height:'40px',border: '1px solid #e8e8e8'}} name='isEnable'  onChange={(e) => handleEditValue(e)} value={fieldsEdit.isEnable}>
                  <option selected disabled>Please Select</option>
                  <option value='true' >Enable</option>
                  <option value='false'>Disable</option>
                </select>
                <p style={{color:'red'}}>{error.isEnable}</p>
            </div>
            <div className="form-group">
              <label>Amount Split Limit(Applicable for Partial Payment):</label>
              <select style={{width:'100%',height:'40px',border: '1px solid #e8e8e8'}} name='amount_split_limit'  onChange={(e) => handleEditValue(e)}  value={fieldsEdit.amount_split_limit}>
                  <option selected disabled>Please Select</option>
                  <option value='1' >1</option>
                  <option value='2'>2</option>
                  <option value='3'>3</option>
                </select>
                <p style={{color:'red'}}>{error.amount_split_limit}</p>
            </div>
            <br/>       
            <div className="row">
              <div className="col-lg-5">
                <button
                  onClick={editBatch}
                  className="addpayment-submitbtn"
                >
                  Submit
                </button>
              </div>
            </div>
            <p id="edit_scs_msg" style={{color:'green'}}></p>
          </div>
        </div>
      </CustomModal>

      <CustomModal open={batchAllocationPopup}>
        <div className="scsmdl-container" style={{ height: "auto" }}>
          <div className="scsmdl-header">
            <p>Batch Allocation</p>
            {/* <img src={'/modalclose.jpg'} className="scsmdl-closeimg"  /> */}
            <img
              src={"/modalclose.jpg"}
              className="scsmdl-closeimg"
              onClick={() => setBatchAllocationPopup(false)}
              alt="close img"
            />
          </div>
          <div className="scsmdl-body" style={{ padding: "20px" }}>
          
          {/* <ul>
            {
              selectedUserList.map(user=>(<li style={{listStyle:'inside'}}>{user.name}</li>))
            }
          </ul> */}
            <select style={{width:'100%',height:'40px',border: '1px solid #e8e8e8',margin:'15px 0'}} onChange={(e)=>setSelectedBatch(e.target.value)}>
              <option disabled selected>Select Batch</option>
              {
                batchList.map(batch=>(
                  <>
                  {
                    !batch.mentor&&(
                      <option value={batch._id} >{batch.batch_name}</option>
                    )
                  }
                  </>               
                ))
              }
            </select>
          
               <button
                  onClick={allocateBatch}
                  className="addpayment-submitbtn"
                >
                  Submit
                </button>
                <p id="allocate_scs_msg" style={{color:'green'}}></p>
          </div>
        </div>
      </CustomModal>
      <CustomModal open={packageDetail}>
        <div className="scsmdl-container" style={{ height: "auto" }}>
          <div className="scsmdl-header">
            <p>Package Details</p>
            {/* <img src={'/modalclose.jpg'} className="scsmdl-closeimg"  /> */}
            <img
              src={"/modalclose.jpg"}
              className="scsmdl-closeimg"
              onClick={() => setPackageDetail(undefined)}
              alt="close img"
            />
          </div>
          <div className="scsmdl-body" style={{ padding: "20px" }}>
          <div style={{display:'grid',gridTemplateColumns:'1fr 2fr'}}>
            <div><b>Name:</b></div>
            <div>{packageDetail?.package_name}</div>

            <div><b>Amount:</b></div>
            <div>{packageDetail?.payable_amount}</div>

            <div><b>Payment Type:</b></div>
            <div>{packageDetail?.payment_type}</div>

            <div><b>Amount Split Limit(Applicable for Partial Payment):</b></div>
            <div>{packageDetail?.amount_split_limit||0}</div>

            <div></div>
            <div></div>
          </div>
           
           
          </div>
        </div>
      </CustomModal>
      <Toaster tosOpen={tosOpen} setTosOpen={setTosOpen}/>
      </div>
  )
}

export default PaymentPackage