import React from "react";

export function FacebookIcon() {
  return (
  
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14.343"
        height="28.684"
        viewBox="0 0 14.343 28.684"
      >
        <path
          id="facebook_4_"
          data-name="facebook (4)"
          d="M17.911,4.763H20.53V.2A33.813,33.813,0,0,0,16.715,0c-3.775,0-6.362,2.375-6.362,6.739v4.017H6.187v5.1h4.166V28.683h5.108V15.856h4l.635-5.1H15.46V7.245c0-1.474.4-2.482,2.451-2.482Z"
          transform="translate(-6.187)"
        />
      </svg>
 
  );
}

export function FacebookLogo() {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        viewBox="0 0 40 40"
      >
        <g
          id="fb_color"
          data-name="fb color"
          transform="translate(-378.693 -322.693)"
        >
          <g
            id="Group_4307"
            data-name="Group 4307"
            transform="translate(379 323)"
          >
            <circle
              id="Ellipse_373"
              data-name="Ellipse 373"
              cx="20"
              cy="20"
              r="20"
              transform="translate(-0.307 -0.307)"
              fill="#0760c5"
            />
            <path
              id="facebook_4_"
              data-name="facebook (4)"
              d="M15.789,3.9h2.144V.165A27.692,27.692,0,0,0,14.809,0C11.717,0,9.6,1.945,9.6,5.519v3.29H6.187v4.175H9.6V23.491h4.183V12.985h3.274l.52-4.175H13.781V5.933c0-1.207.326-2.033,2.007-2.033Z"
              transform="translate(7.762 7.948)"
              fill="#fff"
            />
          </g>
        </g>
      </svg>
    </div>
  );
}
