import React, { useEffect } from 'react'
import Arrow from '../../../images/iconsSVG/Arrow'
import RedArrow from '../../../images/iconsSVG/RedArrow'
import $ from 'jquery'
import Slider from "react-slick";


function Courses() {
  useEffect(()=>{

    const sectionAll = document.querySelectorAll("section");
            const observer = new IntersectionObserver(
              (entries) => {
                entries.forEach((entry) => {
                   
                  if (entry.isIntersecting) {
                    
                    entry.target.classList.add("scroll_ani");
                    
                  }
                });
              },
              {
                rootMargin: "-100px",
              }
            );
            sectionAll.forEach((section) => {
              observer.observe(section);
            });

    courseAccordian('1')
  },[])
  const courseSyllabus=[
    {
      id:'1',
      title:'How does SEM work?',
      desc:''
    },
    {
      id:'2',
      title:'Different Types of Google Ads',
      desc:''
    },
    {
      id:'3',
      title:'Google Ad Structure',
      desc:''
    },
    {
      id:'4',
      title:'How does the Google Ads platform rank the ads?',
      desc:''
    },
    {
      id:'5',
      title: `How do we measure an Ad's performance?`,
      desc:''
    },
    {
      id:'6',
      title:'Google Ad Structure',
      desc:''
    },
    {
      id:'7',
      title:'Other Marketing Terminologies',
      desc:''
    },
  ]
  function courseAccordian(id){
    $('.course_syllabus_inner_left ul li').removeClass('course_syllabus_acc_active')
    $('#course_syllabus_acc'+id).addClass('course_syllabus_acc_active')

  }
  const recognized_certification=[
    {
        id:'1',
        name:'Ezio Skill Academy Certificate',
        desc:'Master the Talents You Required for becoming A Marketing Expert in Any verticals across the globe. Experiential learning through advance syllabus, case studies & live project centric. ',  
        img:'Ezio-Skill-Academy.jpg',
    },
    {
        id:'2',
        name:'Ezio Audit Tool Certificate  ',
        desc:'Expertise in search engine audit tools, our hands-on training let you easily analyze the website and know what is working and what is not. Experience the site audit tool usage and turn the website weaker side into a stronger one.  ',  
        img:'Ezio-Skill-Academy.jpg',
    },
    {
        id:'3',
        name:'LinkedIn Certificate',
        desc:'Become exceptionally skilled and gain the knowledge of DM strategy to transform you into a “Qualified Digital Marketer”.',  
        img:'linkedi-certificate-1.jpg',
    },
    {
        id:'4',
        name:'HubSpot Academy Certificate   ',
        desc:'The candidates will gain top-notch skills in implementing digital marketing strategies with high-standard materials and training exercises. A digital marketing certificate from HubSpot Academy is recognized by the universities.   ',  
        img:'hubspot-academy.jpg',
    },
    {
        id:'5',
        name:'Facebook Blueprint Certificate  ',
        desc:'Earn Facebook blueprint certified "digital marketing associate” becoming an expert level proficiency in Facebook advertising. This is excluded in the Course cost & separate cost will be applicable for this certification ',  
        img:'Facebook-Blueprint.jpg',
    },
    {
        id:'6',
        name:'Google Analytics   ',
        desc: `Qualified in fundamental and advanced Google analytics concepts and Google's measurement tools. Google Analytics Certificates make provision for implementing SEO strategy through learning high-in-demand skills.  `,  
        img:'Google-Analytics.jpg',
    },
    {
        id:'7',
        name:'Google Ads  ',
        desc:'It is an industry-recognized marketing certificate from Google. Professional accreditation is provided by Google. Recognized you as a master in Google Ads.     ',  
        img:'adwords.jpg',
    },
    {
        id:'8',
        name:'Bing Ads   ',
        desc:'Knowing the hack to push your business forward from the effective Microsoft advertising campaigns and enhance your skillset on ads through this program.  With the Bind ads certification, upskill your Microsoft advertising proficiency. ',  
        img:'Bing-Ads-certification.jpg',
    },
]

const certificate_settings = {
  dots: true,
  infinite: true,
  speed: 500,
  autoplay: false,
  slidesToShow: 1,
  slidesToScroll: 1
};

const more_courses_settings = {
  dots: true,
  infinite: true,
  speed: 500,
  autoplay: true,
  slidesToShow: 4,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 993,
      settings: {
        arrows: false,
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 768,
      settings: {
        arrows: false,
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        arrows: false,
        slidesToShow: 1,
      },
    },
   
  ],
};


  return (
    <div id='courses'>
    <section className="banner_sec">     
<div className="container">
 <div className="row">
           <div className="col-lg-6 col-md-6 centered_content website_left_inner_banner">
                {/* <p>Academic &gt; &nbsp;<span>Digital Marketing </span></p> */}
                <h1>
                Executive Digital Marketing Program 
                </h1> 
                <p style={{margin:'20px 0'}}> 
                Attain real-world experience to run live campaigns as you learn from top professionals in the field. Start your career with a 360-degree understanding of digital marketing.
                </p>
                <div style={{display: "flex"}}>
                    <button className="btn btn-outline-primary my-2 my-sm-0 ml-3 active_btn apply_now_btn" type="submit"> Explore Course </button> 
                </div> 
            </div>

            <div className="col-lg-6 col-md-6" >
                <img className='website_banner_standing_girl' src={require('../../../images/website/Courses_page/women-png.png')} alt='' style={{display:'block',margin:'auto'}} />
                <img className='banner_round_icon' src={require('../../../images/website/Courses_page/1.png')} alt='' style={{left:'8%',top:'10%',filter: 'drop-shadow(0px 9px 14px rgba(50, 50, 50, 0.15))'}}/>
                <img className='banner_round_icon' src={require('../../../images/website/Courses_page/archer.png')} alt='' style={{right:'10%',top:'16%'}}/>
                <img className='banner_round_icon' src={require('../../../images/website/Courses_page/peoples.png')} alt='' style={{right:'5%',bottom:'10%',height:'65px'}}/>
                <img className='banner_round_icon' src={require('../../../images/website/Courses_page/analytics.png')} alt='' style={{left:'15%',bottom:'5%',height:'65px',filter: 'drop-shadow(0px 9px 14px rgba(50, 50, 50, 0.15))'}}/>
              </div>
 </div>
</div>
</section>


<section id='Highlights' className='about_free_certification'>
  <div className='container'>
      <div className='row' style={{display:'flex',flexWrap:'wrap-reverse',justifyContent:'center'}}>
      <div className='col-lg-6'>
        <div className='about_free_certification_left_inner'>
            <div className='hos_small_box'>
              <h3>130+</h3>
              <p>Lorem Etiam vel ullamcorper augue</p>
            </div>
            <div className='hos_small_box'>
              <h3>130+</h3>
              <p>Lorem Etiam vel ullamcorper augue</p>
            </div>
            <div className='hos_small_box'>
              <h3>130+</h3>
              <p>Lorem Etiam vel ullamcorper augue</p>
            </div>
            <div className='hos_small_box'>
              <h3>130+</h3>
              <p>Lorem Etiam vel ullamcorper augue</p>
            </div>
            </div>
      </div>
      <div className='col-lg-6'>
          <h2>About this Free Certificate Course</h2>
          <p>With the rise of the internet, Digital Marketing has become the need of the hour. The majority of organizations and individuals try to leverage opportunities and careers in Digital Marketing. It comprises various concepts like knowledge about google ads and the analytics that revolves around it. In this search engine marketing course, Search Engine Marketing has been taken into focus. Learn the fundamentals of SEM and Google ads that let you master the essential concepts of digital marketing. This SEM course will introduce SEM with its functions, processes, and implementation into the brand. The comparison of SEO vs. SEM will be addressed as well.
            <br/><br/>
            Ezio Skill Academy offers an opportunity to learn Digital Marketing with multiple Post Graduate Programs. You can join the Online Digital Marketing Courses to kick start your career in digital marketing. We have multiple management programs with various university partners such as Jain University, Shiv Nadar University, and Great Lakes University, making excellent education accessible to the learners.
          </p>
      </div>
      </div>
  </div>
</section>


<section className='skills_and_tools'>
  <div className='container'>
    <h2>Skills and Tools</h2>
    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur in dignissim dolor, nec tempor quam. Etiam vel ullamcorper augue, eget porta eros. Sed eu lorem vulputate, finibus libero nec, faucibus mauris. </p>
      <div className='row'>
      <div className='col-lg-6 '>
            <h3>Skills</h3>
            <ul className='skills_and_tools_inner'>
              <li><RedArrow/> Digital Marketing</li>
              <li><RedArrow/> Customer Value Analysis</li>
              <li><RedArrow/> Marketing Strategies</li>
              <li><RedArrow/> Search Engine Optimization</li>
              <li><RedArrow/> Satisfaction and Loyalty</li>
              <li><RedArrow/> Google Ads</li>
              <li><RedArrow/> Digital Marketing</li>
              <li><RedArrow/> Marketing plan implementation</li>
              <li><RedArrow/> Search Engine Optimization</li>
            </ul>
    
      </div>
      <div className='col-lg-6'>
          <h3>Tools</h3>
          <div className='skill_tools_con'>
        
            <div className='skill_tools'>
                <img src={require('../../../images/website/Tools-you-wil-master/google-ads.png')} alt=""/>
            </div>
            <div className='skill_tools'>
                <img src={require('../../../images/website/Tools-you-wil-master/google-anylitsis.png')} alt=""/>
            </div>  
            <div className='skill_tools'>
                <img src={require('../../../images/website/Tools-you-wil-master/fb-ads.png')} alt=""/>
            </div>
            <div className='skill_tools'>
                <img src={require('../../../images/website/Tools-you-wil-master/google-tag-manager.png')} alt=""/>
            </div>
            <div className='skill_tools'>
                <img src={require('../../../images/website/Tools-you-wil-master/linkedin-ads.png')} alt=""/>
            </div>
            <div className='skill_tools'>
                <img src={require('../../../images/website/Tools-you-wil-master/google-search-console.png')} alt=""/>
            </div>
            <div className='skill_tools'>
                <img src={require('../../../images/website/Tools-you-wil-master/twitter-ads.png')} alt=""/>
            </div>
            <div className='skill_tools'>
                <img src={require('../../../images/website/Tools-you-wil-master/mailchimp.png')} alt=""/>
            </div>
        </div>
        </div>
      </div>
  </div>
</section> 

<section className='course_syllabus'>
  <div className='container'>
      <div className='row' style={{display:'flex',flexWrap:'wrap-reverse'}}>
      <div className='col-lg-6 course_syllabus_inner_left'>
            <ul>
              {
                courseSyllabus.map((course)=>(
                  <li id={'course_syllabus_acc'+course.id} onClick={()=>courseAccordian(course.id)} key={course.id}>
                  <h4> {course.title} </h4>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur in dignissim dolor, nec tempor quam. Etiam vel ullamcorper augue, eget porta eros. Sed eu lorem vulputate, finibus libero nec, faucibus mauris.  eget porta eros. Sed eu lorem  eget porta eros.</p>
                  </li>
                ))
              }
              
            </ul>
    
      </div>
      <div className='col-lg-6' style={{display:'flex',flexDirection:'column',justifyContent:'center'}}>
         <h2>Course Syllabus</h2>
         <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur in dignissim dolor, nec tempor quam. Etiam vel ullamcorper augue, eget porta eros. Sed eu lorem vulputate, finibus libero nec, faucibus mauris.</p>
         <button>Download Syllabus</button>
      </div>
      </div>
  </div>

</section>

<section className="website_certificate section_spaces">

    <div className="container">
        <h2>Industrial Recognized Certificates </h2>
    <Slider {...certificate_settings}>
        {
            recognized_certification.map((certi,index)=>(
        <div key={index}>
        <div className='row'>
            <div className='col-lg-6 website_certificate_left_inner'>
                <h2>{certi.name}</h2>
                <p>{certi.desc}</p>
            </div>
            <div className='col-lg-6'>
                <img src={require(`../../../images/website/certificate/${certi.img}`)} alt=""/>
            </div>
        </div>     
        </div> 
            ))
        }
        
      </Slider>
        </div>
    </section>

    <section className='more_digital_marketing_courses'>
      <div className='container'>
        <h2>More Digital Marketing Courses</h2>
      <div className='row'>
      <Slider {...more_courses_settings}>
        {
          [1,1,1,1,1].map(s=>(
            <div>
            <div className='more_digital_marketing_courses_list'>
            <img src={require('../../../images/website/ambitious-business-man-climbing-stairs-success 1.png')}/>
            <div>
              <div><span>3 Months</span> <span>12 Hours</span></div>
              <h4>Email Marketing</h4>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur in dignissim dolor.</p>
              <div><span><img src={require('../../../images/website/Courses_page/star-1.png')}/>  4.7 ( 120 Ratings )</span> <a>Learn More &nbsp;<Arrow/></a></div>
            </div>
            </div>
          </div>
          ))
        }
         
      
      </Slider>
      </div>
  </div>

</section> 


</div>
  )
}

export default Courses



{/* <section className=''>
  <div className='container'>
      <div className='row'>
      <div className='col-lg-6 '>
            
    
      </div>
      <div className='col-lg-6'>
         
      </div>
      </div>
  </div>

</section> */}