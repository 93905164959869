import React  , {useState} from 'react'
import Footer   from '../Footer/footer.js'
import Header  from  '../Header/Header.js'

const Entrollment = () =>{
	const [Login, ShowLogins] = useState(true);
	
	const ShowLogin = (e) =>{
		e.preventDefault()
		ShowLogins(false)
	}
	const ShowSign = (e) =>{
		e.preventDefault()
		ShowLogins(true)
	}
	
	 return(
	       <div>
		       <Header /> 
			<div className="container-fluid sigup_set vi_sign">  
			 <div className='container auto_width'>
			  <div className="row margin_none">
				  <div className="col-lg-7 col-md-7 sgin_form col-sm-12 col-xs-12">
					<form className="signup ">
					
					{Login ? <div className='col-lg-12 padding_none show_signup'>
					  <h2 className="text-center">Sign Up</h2>
						<div className="form-row">
						  <div className="form-group col-lg-6 col-sm-6 col-xs-6 col-md-6 name">
							<label for="inputfirstname">First Name</label>
							<input type="text" className="form-control" id="inputEmail4" required />
						  </div>
						  <div className="form-group col-lg-6 col-sm-6 col-xs-6 col-md-6 name lastname" required>
							<label for="inputlastname">Last Name</label>
							<input type="text" className="form-control" id="inputPassword4" required />
						  </div>
						 </div>
						<div className="form-group">
						  <label for="inputemail">Email</label>
						  <input type="email" className="form-control" id="inputAddress" required />
						</div>
						<div className="form-group">
						  <label for="inputpassword">Password</label>
						  <input type="password" className="form-control" id="inputAddress2" required />
						</div>
						<p className="content-link">By clicking Sign Up, You Agree To Our <a href="">Terms Of Use</a> And Our <a href="">Privacy Policy.</a></p>
						<button type="submit" className="btn btn1 sign_up">Sign Up</button>
						<p className="hr-line"><hr className="leftline" /><span className='or_set'>or</span><hr className="rightline" /></p>
						<div className="form-row">
						  <div className="form-group col-lg-6  col-sm-6 col-md-6 col-xs-6 social-link">
							<a href=''><img src={require('../../images/google-logo.png')} alt="google" /> sign in with google</a>
						  </div>
						  <div className="form-group col-lg-6  col-sm-6 col-md-6 col-xs-6 social-link flt-right">
							<a href=''><img src={require('../../images/fb-logo.png')} alt="facebook" /> sign in with facebook</a>
						  </div> 
						  <div className="form-group text-center accnt-signup">
							<p>Already Have An Account ? <a href='' className='cl_signin' onClick={e => ShowLogin(e)} > Sign in</a></p>
						 </div>      
						</div>
					   </div>:
					   
						<div className='col-lg-12 padding_none show_signin'>
						
					   <h2 className="text-center">Sign In</h2>
						
						<div className="form-group">
						  <label for="inputemail">Email</label>
						  <input type="email" className="form-control" id="inputAddress" required />
						</div>
						<div className="form-group">
						  <label for="inputpassword">Password</label>
						  <input type="password" className="form-control" id="inputAddress2" required />
						</div>
						<div className='col-lg-12 col-sm-12 col-xs-12 padding_none remember_out'>
							<div className='col-lg-6  col-sm-6 col-xs-6 padding_none'>
								 <label>
									  <input type="checkbox" checked="checked" className='remember_me' name="remember" /> <span className="checkmark"></span> Remember me
								  </label>
							</div>
							<div className='col-lg-6 col-sm-6 col-xs-6 foget_pwd padding_none'>
								<a href='#'>forget Password ? </a>
							</div>
						</div>
						<p className="content-link">By clicking Sign Up, You Agree To Our <a href="">Terms Of Use</a> And Our <a href="">Privacy Policy.</a></p>
						<button type="submit" className="btn btn1 sign_up">Sign In</button>
						<p className="hr-line"><hr className="leftline" /><span className='or_set'>or</span><hr className="rightline" /></p>
						<div className="form-row">
						  <div className="form-group col-lg-6  col-sm-6 col-md-6 col-xs-6 social-link">
							<a href=''><img src={require('../../images/google-logo.png')} alt="google" /> sign in with google</a>
						  </div>
						  <div className="form-group col-lg-6  col-sm-6 col-md-6 col-xs-6 social-link flt-right">
							<a href=''><img src={require('../../images/fb-logo.png')} alt="facebook" /> sign in with facebook</a>
						  </div> 
						  <div className="form-group text-center accnt-signup">
							<p>Don't Have An Account ? <a className='signup_here' href='' onClick={e => ShowSign(e)}>Sign Up</a> Here</p>
						 </div>      
						</div>
					   </div>
					   
					}
					</form>
					
				</div>
				   <div className="col-lg-5 col-sm-12 col-md-5 col-xs-12 content ad_content">
        <div className="row margin_none">
          <div className="col-xs-6 text-center bg-box bg-box-content1">
            <h4>LORUM IPSUM</h4>
            <h5><strong>999 </strong>per week</h5>
            <p>courses,paths and skill assessments for individuals.</p>
            <a href="">Subscribtion</a>
          </div>
          <div className="col-xs-6 text-center bg-box">
            <h4>LORUM IPSUM</h4>
            <h5><strong>999 </strong>per week</h5>
            <p>courses,paths and skill assessments for individuals.</p>        
            <a href="">Subscribtion</a>
          </div>
        </div>
      </div>  
			  </div> 
			  </div>
			</div>

		   </div>
	 )
}

export default Entrollment