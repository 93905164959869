import React, { Fragment, useState } from 'react'
import { Button, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({   
    textField: {
      width: '80%',
    },
    submitBtn:{
        backgroundColor:'#00b170 !important',
        color:'#fff',
        padding:'10px 30px'
    },
    cancelBtn:{
        backgroundColor:'rgb(220, 0, 78) !important',
        color:'#fff',
        padding:'10px 30px'
    }
  }));
function Discussions() {
    const classes = useStyles();
    const [askNewQ,setAskNewQ]=useState(false)
    const [addReply,setAddReply]=useState()
    const [seeAllReply,setSeeAllReply]=useState()

    const inputProps = {
        step: 300,
      };
    
    function AddReply(id){
      setAddReply(id)
      setSeeAllReply(undefined)
    }
    function SeeAllReply(id){
      setSeeAllReply(id)
      setAddReply(undefined)
    }
  return (
    <section className='user_discussion'>
    <h2>All questions in this course (2)</h2>
    <hr/>
    <div className='user_questions'>
    <h5 className='user_username'>Siva</h5>
    <p>What is course name?</p>
    <div className='user_reply'>
        <p onClick={()=>AddReply(1)}>Add reply</p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <p onClick={()=>SeeAllReply(1)}>See all reply</p>
    </div>
    {
      addReply===1 &&
        <TextField className={classes.textField}  multiline  id="standard-basic" label="Type Reply..."  variant="outlined" />
    }
    
    {
      seeAllReply===1 && 
      <ul className='user_reply_list'>
        <li>
          <h5>Akshay</h5>
          <p>marketing</p>
        </li>
        <li>
        <h5>Satheesh</h5>
          <p>Digital marketing</p>
        </li>
      </ul>
    }
    </div>


    <div className='user_questions'>
    <h5 className='user_username'>Akshay</h5>
    <p>What is course name?</p>
    <div className='user_reply'>
        <p onClick={()=>AddReply(2)}>Add reply</p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <p onClick={()=>SeeAllReply(2)}>See all reply</p>
    </div>
    {
      addReply===2 &&
        <TextField className={classes.textField}  multiline  id="standard-basic" label="Type Reply..."  variant="outlined" />
    }
    
    {
      seeAllReply===2 && 
      <ul className='user_reply_list'>
        <li>
          <h5>Akshay</h5>
          <p>marketing</p>
        </li>
        <li>
        <h5>Satheesh</h5>
          <p>Digital marketing</p>
        </li>
      </ul>
    }
    </div>
    {
        askNewQ ? (
            <Fragment>
<TextField className={classes.textField}  multiline id="standard-basic" label="Ask Your Question..." />
<br/><br/>
<Button className={classes.submitBtn} variant="contained" color="green" size="large">
  Submit
</Button>&nbsp;&nbsp;
<Button className={classes.cancelBtn} variant="contained" color="green" size="large" onClick={()=>setAskNewQ(undefined)}>
  Back
</Button>
            </Fragment>
            // <input type='text' placeholder='Ask Your Question...'/>
            
        ):(
            <button className='ask_question_btn' onClick={()=>{setAskNewQ(true); setSeeAllReply(undefined);setAddReply(undefined);}}> Ask a new question</button>
        )
    }
    <p>..</p>


</section>
  )
}

export default Discussions