import React from 'react'

export function SupKnowledge() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/1999/xlink" width="65.492" height="53.812" viewBox="0 0 65.492 53.812">
            <defs>
                <linearGradient id="linear-gradient" x1="-0.018" y1="0.219" x2="0.91" y2="0.774" gradientUnits="objectBoundingBox">
                <stop offset="0" stop-color="#c6d1ff"/>
                <stop offset="0.583" stop-color="#e6d5f4"/>
                <stop offset="0.997" stop-color="#ffd9ec"/>
                </linearGradient>
            </defs>
            <g id="Knowledge_Base" data-name="Knowledge Base" transform="translate(-11637.194 646.702)">
                <g id="Group_4131" data-name="Group 4131" transform="translate(11637.194 -646.702)">
                <g id="Group_1666" data-name="Group 1666" transform="translate(0 1.22)">
                    <path id="Path_11718" data-name="Path 11718" d="M60.308,71.932c-8.368-6.433-16.484-1.19-25.537-3.044C25.653,67.021,22.02,60.8,11.635,63.673,2.435,66.216-3.147,76.407,1.911,84.94c1.441,2.432,3.9,4.266,4.9,6.987,1.618,4.389-.845,7.758-.365,12.071,2,17.921,29.366,10.979,39.436,5.4C61.675,100.658,72.36,81.2,60.308,71.932Z" transform="translate(0 -62.928)" fill="url(#linear-gradient)"/>
                </g>
                <path id="Path_11748" data-name="Path 11748" d="M398.541,57.1a1.854,1.854,0,1,1,1.311-.542A1.85,1.85,0,0,1,398.541,57.1Zm0-2.429a.575.575,0,1,0,.407.168A.573.573,0,0,0,398.541,54.668Z" transform="translate(-345.945 -53.39)" fill="#00eca2"/>
                <path id="Path_11749" data-name="Path 11749" d="M423.677,105.989a1.853,1.853,0,1,1,1.311-.542A1.849,1.849,0,0,1,423.677,105.989Zm0-2.429a.575.575,0,1,0,.407.168A.573.573,0,0,0,423.677,103.559Z" transform="translate(-367.865 -96.027)" fill="#ff7eb8"/>
                <path id="Path_11750" data-name="Path 11750" d="M92.74,211.481a1.854,1.854,0,1,1,1.312-.542,1.849,1.849,0,0,1-1.312.542Zm0-2.429a.575.575,0,1,0,.407.168A.574.574,0,0,0,92.74,209.052Z" transform="translate(-79.261 -188.026)" fill="#ff7eb8"/>
                <path id="Path_11751" data-name="Path 11751" d="M78,264.7a.638.638,0,0,1-.452-.187l-.763-.763-.763.763a.64.64,0,0,1-.9-.9l1.215-1.215a.64.64,0,0,1,.9,0l1.215,1.215A.64.64,0,0,1,78,264.7Z" transform="translate(-65.348 -235.491)" fill="#6583fe"/>
                <path id="Path_11752" data-name="Path 11752" d="M405.892,409.5a.638.638,0,0,1-.452-.187l-.763-.763-.763.763a.64.64,0,0,1-.9-.9l1.215-1.215a.64.64,0,0,1,.9,0l1.215,1.215a.639.639,0,0,1-.452,1.092Z" transform="translate(-351.296 -361.769)" fill="#00eca2"/>
                </g>
                <g id="Group_4153" data-name="Group 4153" transform="translate(11649.153 -640.832)">
                <path id="Path_14009" data-name="Path 14009" d="M-10718.968,7805.25l.457,5.751,25.286.456,13.6-7.577s1-4.564-4.017-3.469-12.142,4.838-12.142,4.838,1.826-3.1.456-3.834-4.93-1-4.93-1h-12.324Z" transform="translate(10719.996 -7770.453)" fill="#fff"/>
                <g id="knowledge_1_" data-name="knowledge (1)" transform="translate(0 0)">
                    <path id="Path_14001" data-name="Path 14001" d="M38.522,347.4a3.652,3.652,0,0,0-1.214.209l-11.572,4.068a3.7,3.7,0,0,0-3.619-4.448H11.3A12.448,12.448,0,0,0,2.44,350.9L.118,353.218v7.561H26.891l13.227-6.444a3.653,3.653,0,0,0-1.6-6.937Zm.517,4.722-12.716,6.195H2.582v-4.076l1.6-1.6A10,10,0,0,1,11.3,349.69H22.116a1.232,1.232,0,1,1,0,2.464h-7.83v2.464h10.5l13.334-4.687a1.188,1.188,0,0,1,.914,2.189Z" transform="translate(-0.118 -318.707)" fill="#3d57fe"/>
                    <path id="Path_14002" data-name="Path 14002" d="M141.71,17.312a2.714,2.714,0,0,1,1.046,2.136v3.18l1.526,3.169h6.88l1.526-3.169V19.455a2.73,2.73,0,0,1,1.051-2.147A9.7,9.7,0,0,0,147.721,0c-.125,0-.252,0-.377.007a9.7,9.7,0,0,0-5.635,17.3Zm7.9,6.02H145.83l-.61-1.267V20.679h5v1.386ZM142.508,4.7a7.182,7.182,0,0,1,4.93-2.226c.094,0,.189-.005.283-.005a7.236,7.236,0,0,1,4.487,12.912,5.2,5.2,0,0,0-1.834,2.839h-5.3a5.184,5.184,0,0,0-1.832-2.836,7.22,7.22,0,0,1-.73-10.683Z" transform="translate(-126.695 0)" fill="#3d57fe"/>
                    <path id="Path_14003" data-name="Path 14003" d="M63.426,103.35h3.663v2.464H63.426Z" transform="translate(-58.226 -94.861)" fill="#3d57fe"/>
                    <path id="Path_14004" data-name="Path 14004" d="M0,0H2.464V3.663H0Z" transform="matrix(0.5, -0.866, 0.866, 0.5, 6.704, 2.875)" fill="#3d57fe"/>
                    <path id="Path_14005" data-name="Path 14005" d="M0,0H3.663V2.464H0Z" transform="matrix(0.866, -0.5, 0.5, 0.866, 6.704, 16.566)" fill="#3d57fe"/>
                    <path id="Path_14006" data-name="Path 14006" d="M404.21,103.35h3.663v2.464H404.21Z" transform="translate(-371.02 -94.861)" fill="#3d57fe"/>
                    <path id="Path_14007" data-name="Path 14007" d="M0,0H3.663V2.464H0Z" transform="matrix(0.866, -0.5, 0.5, 0.866, 30.944, 2.572)" fill="#3d57fe"/>
                    <path id="Path_14008" data-name="Path 14008" d="M0,0H2.464V3.663H0Z" transform="matrix(0.5, -0.866, 0.866, 0.5, 30.944, 16.87)" fill="#3d57fe"/>
                </g>
                </g>
            </g>
        </svg>
    )
}

export function SupCall() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/1999/xlink" width="65.492" height="53.812" viewBox="0 0 65.492 53.812">
            <defs>
                <linearGradient id="linear-gradient" x1="-0.018" y1="0.219" x2="0.91" y2="0.774" gradientUnits="objectBoundingBox">
                <stop offset="0" stop-color="#c6d1ff"/>
                <stop offset="0.583" stop-color="#e6d5f4"/>
                <stop offset="0.997" stop-color="#ffd9ec"/>
                </linearGradient>
            </defs>
            <g id="Call" transform="translate(-11942.095 646.702)">
                <path id="Path_11718" data-name="Path 11718" d="M60.308,71.932c-8.368-6.433-16.484-1.19-25.537-3.044C25.653,67.021,22.02,60.8,11.635,63.673,2.435,66.216-3.147,76.407,1.911,84.94c1.441,2.432,3.9,4.266,4.9,6.987,1.618,4.389-.845,7.758-.365,12.071,2,17.921,29.366,10.979,39.436,5.4C61.675,100.658,72.36,81.2,60.308,71.932Z" transform="translate(11942.095 -708.411)" fill="url(#linear-gradient)"/>
                <path id="Path_11748" data-name="Path 11748" d="M398.541,57.1a1.854,1.854,0,1,1,1.311-.542A1.85,1.85,0,0,1,398.541,57.1Zm0-2.429a.575.575,0,1,0,.407.168A.573.573,0,0,0,398.541,54.668Z" transform="translate(11596.149 -700.092)" fill="#00eca2"/>
                <path id="Path_11749" data-name="Path 11749" d="M423.677,105.989a1.853,1.853,0,1,1,1.311-.542A1.849,1.849,0,0,1,423.677,105.989Zm0-2.429a.575.575,0,1,0,.407.168A.573.573,0,0,0,423.677,103.559Z" transform="translate(11574.229 -742.729)" fill="#ff7eb8"/>
                <path id="Path_11750" data-name="Path 11750" d="M92.74,211.481a1.854,1.854,0,1,1,1.312-.542,1.849,1.849,0,0,1-1.312.542Zm0-2.429a.575.575,0,1,0,.407.168A.574.574,0,0,0,92.74,209.052Z" transform="translate(11862.834 -834.728)" fill="#ff7eb8"/>
                <path id="Path_11751" data-name="Path 11751" d="M78,264.7a.638.638,0,0,1-.452-.187l-.763-.763-.763.763a.64.64,0,0,1-.9-.9l1.215-1.215a.64.64,0,0,1,.9,0l1.215,1.215A.64.64,0,0,1,78,264.7Z" transform="translate(11876.746 -882.194)" fill="#6583fe"/>
                <path id="Path_11752" data-name="Path 11752" d="M405.892,409.5a.638.638,0,0,1-.452-.187l-.763-.763-.763.763a.64.64,0,0,1-.9-.9l1.215-1.215a.64.64,0,0,1,.9,0l1.215,1.215a.639.639,0,0,1-.452,1.092Z" transform="translate(11590.799 -1008.472)" fill="#00eca2"/>
                <path id="Path_11754" data-name="Path 11754" d="M52.157,42.472a9.645,9.645,0,0,1-12.913,9.083A37.928,37.928,0,0,1,16.6,28.913,9.632,9.632,0,0,1,25.684,16l0,.011,1.889,8.813-.568.568-3.269,3.269A32.9,32.9,0,0,0,39.495,44.418l3.838-3.838Z" transform="translate(11940.764 -653.659)" fill="#fff"/>
                <g id="Group_1709" data-name="Group 1709" transform="translate(11958.526 -637.659)">
                <path id="Path_11755" data-name="Path 11755" d="M45.151,20.407A2.26,2.26,0,0,0,42.4,18.686a9.517,9.517,0,0,0-3.194,1.444A9.6,9.6,0,0,1,47.132,16l0,.011,1.889,8.813-.568.568-1.841,1.841Z" transform="translate(-39.211 -16)" fill="#e3e3e3"/>
                <path id="Path_11756" data-name="Path 11756" d="M110.784,199.927l-1.257,1.257a2.264,2.264,0,0,1-2.671.39A32.918,32.918,0,0,1,93.38,188.1a2.264,2.264,0,0,1,.39-2.671l1.257-1.257A32.9,32.9,0,0,0,110.784,199.927Z" transform="translate(-89.052 -171.508)" fill="#e3e3e3"/>
                <path id="Path_11757" data-name="Path 11757" d="M358.053,344.371a9.576,9.576,0,0,1-2.9,6.9,9.848,9.848,0,0,1-1.232,1.021,9.526,9.526,0,0,0,1.448-3.212,2.255,2.255,0,0,0-1.725-2.729l-6.827-1.463,2.409-2.409Z" transform="translate(-323.66 -317.899)" fill="#e3e3e3"/>
                </g>
                <g id="Group_1710" data-name="Group 1710" transform="translate(11955.574 -638.863)">
                <path id="Path_11758" data-name="Path 11758" d="M27.706,38.55a10.74,10.74,0,0,1-3.685-.658A39.293,39.293,0,0,1,9.753,28.8,39.29,39.29,0,0,1,.659,14.529,10.663,10.663,0,0,1,.115,9.288a10.934,10.934,0,0,1,5.62-8A10.735,10.735,0,0,1,10.874,0a1.2,1.2,0,0,1,1.178.952l1.891,8.824a1.2,1.2,0,0,1-.326,1.1l-3.231,3.231A31.71,31.71,0,0,0,24.439,28.165l3.231-3.231a1.205,1.205,0,0,1,1.1-.326L37.6,26.5a1.2,1.2,0,0,1,.952,1.178,10.734,10.734,0,0,1-1.284,5.14,10.934,10.934,0,0,1-8,5.62,10.716,10.716,0,0,1-1.556.114ZM9.912,2.462A8.441,8.441,0,0,0,2.923,13.707,36.539,36.539,0,0,0,24.844,35.628a8.441,8.441,0,0,0,11.245-6.988L28.911,27.1l-3.374,3.374a1.2,1.2,0,0,1-1.364.239A34.111,34.111,0,0,1,7.837,14.379a1.2,1.2,0,0,1,.239-1.364L11.45,9.64Z" transform="translate(0 0)" fill="#3d57fe"/>
                </g>
                <g id="Group_1711" data-name="Group 1711" transform="translate(11973.646 -638.863)">
                <path id="Path_11759" data-name="Path 11759" d="M259.294,20.479a1.2,1.2,0,0,1-1.2-1.2A16.884,16.884,0,0,0,241.225,2.409a1.2,1.2,0,1,1,0-2.409A19.274,19.274,0,0,1,260.5,19.274,1.2,1.2,0,0,1,259.294,20.479Z" transform="translate(-240.02)" fill="#3d57fe"/>
                </g>
                <g id="Group_1712" data-name="Group 1712" transform="translate(11973.646 -634.045)">
                <path id="Path_11760" data-name="Path 11760" d="M254.476,79.66a1.2,1.2,0,0,1-1.2-1.2,12.06,12.06,0,0,0-12.047-12.047,1.2,1.2,0,1,1,0-2.409A14.472,14.472,0,0,1,255.68,78.456,1.2,1.2,0,0,1,254.476,79.66Z" transform="translate(-240.02 -64)" fill="#3d57fe"/>
                </g>
                <g id="Group_1713" data-name="Group 1713" transform="translate(11973.646 -629.226)">
                <path id="Path_11761" data-name="Path 11761" d="M249.657,138.842a1.2,1.2,0,0,1-1.2-1.2,7.236,7.236,0,0,0-7.228-7.228,1.2,1.2,0,1,1,0-2.409,9.648,9.648,0,0,1,9.637,9.637A1.2,1.2,0,0,1,249.657,138.842Z" transform="translate(-240.02 -128)" fill="#3d57fe"/>
                </g>
            </g>
        </svg>
    )
}

export function SupChat() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/1999/xlink" width="65.492" height="53.812" viewBox="0 0 65.492 53.812">
            <defs>
                <linearGradient id="linear-gradient" x1="-0.018" y1="0.219" x2="0.91" y2="0.774" gradientUnits="objectBoundingBox">
                <stop offset="0" stop-color="#c6d1ff"/>
                <stop offset="0.583" stop-color="#e6d5f4"/>
                <stop offset="0.997" stop-color="#ffd9ec"/>
                </linearGradient>
            </defs>
            <g id="Live_Chat" data-name="Live Chat" transform="translate(-12247.008 646.469)">
                <path id="Path_11718" data-name="Path 11718" d="M60.308,71.932c-8.368-6.433-16.484-1.19-25.537-3.044C25.653,67.021,22.02,60.8,11.635,63.673,2.435,66.216-3.147,76.407,1.911,84.94c1.441,2.432,3.9,4.266,4.9,6.987,1.618,4.389-.845,7.758-.365,12.071,2,17.921,29.366,10.979,39.436,5.4C61.675,100.658,72.36,81.2,60.308,71.932Z" transform="translate(12247.008 -708.177)" fill="url(#linear-gradient)"/>
                <path id="Path_11748" data-name="Path 11748" d="M398.541,57.1a1.854,1.854,0,1,1,1.311-.542A1.85,1.85,0,0,1,398.541,57.1Zm0-2.429a.575.575,0,1,0,.407.168A.573.573,0,0,0,398.541,54.668Z" transform="translate(11901.063 -699.858)" fill="#00eca2"/>
                <path id="Path_11749" data-name="Path 11749" d="M423.677,105.989a1.853,1.853,0,1,1,1.311-.542A1.849,1.849,0,0,1,423.677,105.989Zm0-2.429a.575.575,0,1,0,.407.168A.573.573,0,0,0,423.677,103.559Z" transform="translate(11879.143 -742.496)" fill="#ff7eb8"/>
                <path id="Path_11750" data-name="Path 11750" d="M92.74,211.481a1.854,1.854,0,1,1,1.312-.542,1.849,1.849,0,0,1-1.312.542Zm0-2.429a.575.575,0,1,0,.407.168A.574.574,0,0,0,92.74,209.052Z" transform="translate(12167.747 -834.495)" fill="#ff7eb8"/>
                <path id="Path_11751" data-name="Path 11751" d="M78,264.7a.638.638,0,0,1-.452-.187l-.763-.763-.763.763a.64.64,0,0,1-.9-.9l1.215-1.215a.64.64,0,0,1,.9,0l1.215,1.215A.64.64,0,0,1,78,264.7Z" transform="translate(12181.659 -881.96)" fill="#6583fe"/>
                <path id="Path_11752" data-name="Path 11752" d="M405.892,409.5a.638.638,0,0,1-.452-.187l-.763-.763-.763.763a.64.64,0,0,1-.9-.9l1.215-1.215a.64.64,0,0,1,.9,0l1.215,1.215a.639.639,0,0,1-.452,1.092Z" transform="translate(11895.712 -1008.238)" fill="#00eca2"/>
                <path id="Path_14000" data-name="Path 14000" d="M-10697.56,7799.581s-6.325,5.246-8.64,5.246a84.667,84.667,0,0,1-8.64-1.08l-7.251,3.085,3.239-7.251s-5.092-7.405-3.239-10.028a36.287,36.287,0,0,1,5.4-5.554s2.93,8.022,9.1,10.645A89.36,89.36,0,0,1-10697.56,7799.581Z" transform="translate(22980.563 -8405.293)" fill="#fff"/>
                <path id="speak" d="M39.64,25.612a1.671,1.671,0,1,0-3.1,1.252l1.968,4.868-5.844-2.576a1.67,1.67,0,0,0-1.33-.008,16.115,16.115,0,0,1-6.36,1.284c-8.945,0-14.954-7-14.954-13.545,0-7.469,6.709-13.545,14.954-13.545S39.931,9.418,39.931,16.887a13.6,13.6,0,0,1-.149,1.914,1.671,1.671,0,1,0,3.305.493,16.876,16.876,0,0,0,.185-2.407A16.149,16.149,0,0,0,37.86,4.895,18.988,18.988,0,0,0,24.976,0a18.987,18.987,0,0,0-12.884,4.9A16.24,16.24,0,0,0,6.747,15.449,13.507,13.507,0,0,0,.5,26.694,12.962,12.962,0,0,0,3.061,34.44L.62,40.477a1.671,1.671,0,0,0,2.223,2.155l7.037-3.1a15.9,15.9,0,0,0,5.573.992c.042,0,.083,0,.124-.006a15.817,15.817,0,0,0,7.861-2.131,14.383,14.383,0,0,0,5.055-4.922,19.248,19.248,0,0,0,3.471-.965l8.964,3.952a1.671,1.671,0,0,0,2.223-2.155ZM15.452,37.176c-.023,0-.045,0-.068,0a12.529,12.529,0,0,1-4.882-1,1.671,1.671,0,0,0-1.33.008L5.26,37.914l1.262-3.122a1.671,1.671,0,0,0-.278-1.71A9.753,9.753,0,0,1,3.84,26.694a9.94,9.94,0,0,1,3.088-7.118,16.867,16.867,0,0,0,4.943,9.032,18.677,18.677,0,0,0,12.156,5.143,12.281,12.281,0,0,1-8.575,3.425ZM27.4,18.463a1.671,1.671,0,1,1,0,3.342H17.374a1.671,1.671,0,1,1,0-3.342Zm5.013-3.342H17.374a1.671,1.671,0,1,1,0-3.342H32.412a1.671,1.671,0,1,1,0,3.342Zm0,0" transform="translate(12256.417 -639.258)" fill="#3d57fe"/>
            </g>
        </svg>
    )
}

export function SupMail() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/1999/xlink" width="65.492" height="53.812" viewBox="0 0 65.492 53.812">
            <defs>
                <linearGradient id="linear-gradient" x1="-0.018" y1="0.219" x2="0.91" y2="0.774" gradientUnits="objectBoundingBox">
                <stop offset="0" stop-color="#c6d1ff"/>
                <stop offset="0.583" stop-color="#e6d5f4"/>
                <stop offset="0.997" stop-color="#ffd9ec"/>
                </linearGradient>
            </defs>
            <g id="Mail" transform="translate(-11638.585 289.154)">
                <g id="Group_1666" data-name="Group 1666" transform="translate(11638.585 -287.934)">
                <path id="Path_11718" data-name="Path 11718" d="M60.308,71.932c-8.368-6.433-16.484-1.19-25.537-3.044C25.653,67.021,22.02,60.8,11.635,63.673,2.435,66.216-3.147,76.407,1.911,84.94c1.441,2.432,3.9,4.266,4.9,6.987,1.618,4.389-.845,7.758-.365,12.071,2,17.921,29.366,10.979,39.436,5.4C61.675,100.658,72.36,81.2,60.308,71.932Z" transform="translate(0 -62.928)" fill="url(#linear-gradient)"/>
                </g>
                <path id="Path_11748" data-name="Path 11748" d="M398.541,57.1a1.854,1.854,0,1,1,1.311-.542A1.85,1.85,0,0,1,398.541,57.1Zm0-2.429a.575.575,0,1,0,.407.168A.573.573,0,0,0,398.541,54.668Z" transform="translate(11292.64 -342.544)" fill="#00eca2"/>
                <path id="Path_11749" data-name="Path 11749" d="M423.677,105.989a1.853,1.853,0,1,1,1.311-.542A1.849,1.849,0,0,1,423.677,105.989Zm0-2.429a.575.575,0,1,0,.407.168A.573.573,0,0,0,423.677,103.559Z" transform="translate(11270.72 -385.181)" fill="#ff7eb8"/>
                <path id="Path_11750" data-name="Path 11750" d="M92.74,211.481a1.854,1.854,0,1,1,1.312-.542,1.849,1.849,0,0,1-1.312.542Zm0-2.429a.575.575,0,1,0,.407.168A.574.574,0,0,0,92.74,209.052Z" transform="translate(11559.324 -477.18)" fill="#ff7eb8"/>
                <path id="Path_11751" data-name="Path 11751" d="M78,264.7a.638.638,0,0,1-.452-.187l-.763-.763-.763.763a.64.64,0,0,1-.9-.9l1.215-1.215a.64.64,0,0,1,.9,0l1.215,1.215A.64.64,0,0,1,78,264.7Z" transform="translate(11573.236 -524.646)" fill="#6583fe"/>
                <path id="Path_11752" data-name="Path 11752" d="M405.892,409.5a.638.638,0,0,1-.452-.187l-.763-.763-.763.763a.64.64,0,0,1-.9-.9l1.215-1.215a.64.64,0,0,1,.9,0l1.215,1.215a.639.639,0,0,1-.452,1.092Z" transform="translate(11287.289 -650.924)" fill="#00eca2"/>
                <g id="Group_4462" data-name="Group 4462" transform="translate(-4.468 -3.141)">
                <path id="Path_14109" data-name="Path 14109" d="M11654.759-270.11l18.24,17.323,5.591-.55,17.049-17.232-3.392-1.283h-35.471Z" transform="translate(0.509 0.24)" fill="#fff"/>
                <g id="mail_2_" data-name="mail (2)" transform="translate(11652.673 -272.837)">
                    <g id="Group_4460" data-name="Group 4460">
                    <path id="Path_14108" data-name="Path 14108" d="M41.583,76H4.007A4.013,4.013,0,0,0,0,80.007v24.042a4.013,4.013,0,0,0,4.007,4.007H41.583a4.012,4.012,0,0,0,4.007-4.007V80.007A4.011,4.011,0,0,0,41.583,76Zm-.561,2.671L25.628,93.984a4.007,4.007,0,0,1-5.669,0L4.568,78.671ZM2.671,103.5V80.552L14.215,92.035Zm1.9,1.879L16.109,93.919l1.964,1.954a6.678,6.678,0,0,0,9.442,0l1.967-1.956L41.02,105.384ZM42.919,103.5,31.375,92.035,42.919,80.552Z" transform="translate(0 -76)" fill="#3d57fe"/>
                    </g>
                </g>
                </g>
            </g>
        </svg>
    )
}

export function SupTour() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/1999/xlink" width="65.492" height="53.812" viewBox="0 0 65.492 53.812">
            <defs>
                <linearGradient id="linear-gradient" x1="-0.018" y1="0.219" x2="0.91" y2="0.774" gradientUnits="objectBoundingBox">
                <stop offset="0" stop-color="#c6d1ff"/>
                <stop offset="0.583" stop-color="#e6d5f4"/>
                <stop offset="0.997" stop-color="#ffd9ec"/>
                </linearGradient>
            </defs>
            <g id="Take_A_Tour" data-name="Take A Tour" transform="translate(-11941.946 288.921)">
                <g id="Group_4254" data-name="Group 4254" transform="translate(11941.946 -288.921)">
                <g id="Group_1666" data-name="Group 1666" transform="translate(0 1.22)">
                    <path id="Path_11718" data-name="Path 11718" d="M60.308,71.932c-8.368-6.433-16.484-1.19-25.537-3.044C25.653,67.021,22.02,60.8,11.635,63.673,2.435,66.216-3.147,76.407,1.911,84.94c1.441,2.432,3.9,4.266,4.9,6.987,1.618,4.389-.845,7.758-.365,12.071,2,17.921,29.366,10.979,39.436,5.4C61.675,100.658,72.36,81.2,60.308,71.932Z" transform="translate(0 -62.928)" fill="url(#linear-gradient)"/>
                </g>
                <path id="Path_11748" data-name="Path 11748" d="M398.541,57.1a1.854,1.854,0,1,1,1.311-.542A1.85,1.85,0,0,1,398.541,57.1Zm0-2.429a.575.575,0,1,0,.407.168A.573.573,0,0,0,398.541,54.668Z" transform="translate(-345.945 -53.39)" fill="#00eca2"/>
                <path id="Path_11749" data-name="Path 11749" d="M423.677,105.989a1.853,1.853,0,1,1,1.311-.542A1.849,1.849,0,0,1,423.677,105.989Zm0-2.429a.575.575,0,1,0,.407.168A.573.573,0,0,0,423.677,103.559Z" transform="translate(-367.865 -96.027)" fill="#ff7eb8"/>
                <path id="Path_11750" data-name="Path 11750" d="M92.74,211.481a1.854,1.854,0,1,1,1.312-.542,1.849,1.849,0,0,1-1.312.542Zm0-2.429a.575.575,0,1,0,.407.168A.574.574,0,0,0,92.74,209.052Z" transform="translate(-79.261 -188.026)" fill="#ff7eb8"/>
                <path id="Path_11751" data-name="Path 11751" d="M78,264.7a.638.638,0,0,1-.452-.187l-.763-.763-.763.763a.64.64,0,0,1-.9-.9l1.215-1.215a.64.64,0,0,1,.9,0l1.215,1.215A.64.64,0,0,1,78,264.7Z" transform="translate(-65.348 -235.491)" fill="#6583fe"/>
                <path id="Path_11752" data-name="Path 11752" d="M405.892,409.5a.638.638,0,0,1-.452-.187l-.763-.763-.763.763a.64.64,0,0,1-.9-.9l1.215-1.215a.64.64,0,0,1,.9,0l1.215,1.215a.639.639,0,0,1-.452,1.092Z" transform="translate(-351.296 -361.769)" fill="#00eca2"/>
                </g>
                <g id="Group_4257" data-name="Group 4257" transform="translate(11959.428 -282.985)">
                <path id="Path_14011" data-name="Path 14011" d="M832.825,478.278v-.221q0-5.529,0-11.057a3.363,3.363,0,0,1,6.664-.724,7.2,7.2,0,0,1,.059,1.174c.008.8,0,1.6,0,2.444a3.385,3.385,0,0,1,5.121,2,3.389,3.389,0,0,1,5.311,2.477,11.129,11.129,0,0,1,1.272-.418,3.338,3.338,0,0,1,3.906,2.822,6.108,6.108,0,0,1,.042.727q0,7.933,0,15.866a3.116,3.116,0,0,1-1.653,2.932,2.991,2.991,0,0,1-1.5.347q-6.707.006-13.415,0a8.177,8.177,0,0,1-1.5-.078A3.019,3.019,0,0,1,835.071,495c-1.327-2.261-2.666-4.515-4-6.772q-2.9-4.916-5.805-9.834a3.206,3.206,0,0,1,0-3.444,3.169,3.169,0,0,1,2.985-1.637,3.206,3.206,0,0,1,2.868,1.8c.564,1.055,1.112,2.118,1.667,3.177Zm20.869,7.109v-5.4c0-.92.009-1.839,0-2.759a1.857,1.857,0,0,0-1.555-1.809,1.821,1.821,0,0,0-2.078,1.362,3.964,3.964,0,0,0-.077.934c-.008,1.116,0,2.232,0,3.348a.745.745,0,0,1-.986.723.786.786,0,0,1-.522-.808c0-2.13,0-4.259-.006-6.389a1.834,1.834,0,0,0-1.692-1.8,1.815,1.815,0,0,0-1.927,1.391,9.252,9.252,0,0,0-.069,1.618c-.009,1.1,0,2.2,0,3.3a.768.768,0,0,1-.593.778.733.733,0,0,1-.828-.392,1.122,1.122,0,0,1-.083-.454c0-2.067,0-4.134,0-6.2a1.858,1.858,0,1,0-3.713-.1q0,3.183,0,6.366a.75.75,0,0,1-.513.762.762.762,0,0,1-1-.791q0-4.527,0-9.054c0-1.052.02-2.1,0-3.154a1.846,1.846,0,0,0-3.692.045c-.009,2.114,0,4.228,0,6.342q0,4.067,0,8.134a.76.76,0,0,1-1.462.361c-.192-.36-.376-.724-.565-1.086q-1.263-2.42-2.527-4.84a1.769,1.769,0,0,0-1.6-1,1.849,1.849,0,0,0-1.635,2.782q2.654,4.5,5.318,9,2.276,3.851,4.552,7.7a1.577,1.577,0,0,0,1.455.832c.833,0,1.665,0,2.5,0h11.717a1.557,1.557,0,0,0,1.6-1.567Q853.7,489.477,853.693,485.386Z" transform="translate(-824.763 -451.271)" fill="#3d57fe"/>
                <path id="Path_14012" data-name="Path 14012" d="M857.529,412.877a11.786,11.786,0,0,1-2.229,6.833,1.391,1.391,0,0,1-.475.435.706.706,0,0,1-.862-.235.779.779,0,0,1-.01-.922A19.878,19.878,0,0,0,855,417.235a10.262,10.262,0,1,0-18.806-8.193,10.081,10.081,0,0,0,1.255,9.9,9.4,9.4,0,0,0,1.8,1.887.716.716,0,0,1,.263.8.727.727,0,0,1-.636.55.745.745,0,0,1-.607-.2,11.757,11.757,0,0,1-4.268-10.36,11.507,11.507,0,0,1,1.872-5.218,11.662,11.662,0,0,1,6.042-4.7,11.174,11.174,0,0,1,5.152-.549,11.61,11.61,0,0,1,8.758,5.657,10.8,10.8,0,0,1,1.561,4.471C857.455,411.812,857.484,412.346,857.529,412.877Z" transform="translate(-832.136 -401.063)" fill="#3d57fe"/>
                <path id="Path_14013" data-name="Path 14013" d="M881.836,430.049l-9.179-5.9v.27q0,2.464,0,4.928a.771.771,0,0,1-.419.758.716.716,0,0,1-.8-.071.734.734,0,0,1-.3-.635c0-1.226,0-2.452,0-3.678,0-.637-.008-1.273,0-1.91a1.3,1.3,0,0,1,1.15-1.332,1.155,1.155,0,0,1,.829.2q2.959,1.887,5.913,3.783c1.21.775,2.421,1.547,3.628,2.326a1.46,1.46,0,0,1,.652,1.654,1.393,1.393,0,0,1-.606.839q-1.782,1.148-3.566,2.294a.762.762,0,0,1-1.1-.168.749.749,0,0,1,.271-1.095c1.034-.67,2.073-1.334,3.111-2Z" transform="translate(-862.029 -418.261)" fill="#3d57fe"/>
                <path id="Path_14021" data-name="Path 14021" d="M859.9,491.553q0,4.091,0,8.181a1.557,1.557,0,0,1-1.6,1.567H846.58c-.833,0-1.665,0-2.5,0a1.577,1.577,0,0,1-1.455-.832q-2.274-3.853-4.552-7.7-2.66-4.5-5.318-9a1.849,1.849,0,0,1,1.635-2.782,1.769,1.769,0,0,1,1.6,1q1.27,2.417,2.527,4.84c.189.362.373.725.565,1.085a.76.76,0,0,0,1.462-.361q0-4.067,0-8.134c0-2.114,0-4.228,0-6.342a1.846,1.846,0,0,1,3.692-.045c.024,1.051,0,2.1,0,3.154q0,4.527,0,9.054a.762.762,0,0,0,1,.791.75.75,0,0,0,.513-.762q0-3.183,0-6.366a1.858,1.858,0,1,1,3.713.1c0,2.067,0,4.134,0,6.2a1.121,1.121,0,0,0,.083.454.733.733,0,0,0,.828.392.768.768,0,0,0,.593-.778c0-1.1-.005-2.2,0-3.3a9.265,9.265,0,0,1,.069-1.618,1.815,1.815,0,0,1,1.927-1.391,1.833,1.833,0,0,1,1.692,1.8c.01,2.13,0,4.259.006,6.389a.786.786,0,0,0,.522.808.745.745,0,0,0,.986-.723c0-1.116,0-2.232,0-3.348a3.973,3.973,0,0,1,.077-.934,1.822,1.822,0,0,1,2.078-1.362A1.857,1.857,0,0,1,859.9,483.4c.013.919,0,1.839,0,2.759Z" transform="translate(-830.969 -457.439)" fill="#fff"/>
                </g>
            </g>
        </svg>
    )
}

export function SupWhatsapp() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/1999/xlink" width="65.492" height="53.812" viewBox="0 0 65.492 53.812">
            <defs>
                <linearGradient id="linear-gradient" x1="-0.018" y1="0.219" x2="0.91" y2="0.774" gradientUnits="objectBoundingBox">
                <stop offset="0" stop-color="#c6d1ff"/>
                <stop offset="0.583" stop-color="#e6d5f4"/>
                <stop offset="0.997" stop-color="#ffd9ec"/>
                </linearGradient>
            </defs>
            <g id="Whatsapp" transform="translate(-12245.156 289.92)">
                <g id="Group_4249" data-name="Group 4249" transform="translate(12245.156 -289.92)">
                <g id="Group_1666" data-name="Group 1666" transform="translate(0 1.22)">
                    <path id="Path_11718" data-name="Path 11718" d="M60.308,71.932c-8.368-6.433-16.484-1.19-25.537-3.044C25.653,67.021,22.02,60.8,11.635,63.673,2.435,66.216-3.147,76.407,1.911,84.94c1.441,2.432,3.9,4.266,4.9,6.987,1.618,4.389-.845,7.758-.365,12.071,2,17.921,29.366,10.979,39.436,5.4C61.675,100.658,72.36,81.2,60.308,71.932Z" transform="translate(0 -62.928)" fill="url(#linear-gradient)"/>
                </g>
                <path id="Path_11748" data-name="Path 11748" d="M398.541,57.1a1.854,1.854,0,1,1,1.311-.542A1.85,1.85,0,0,1,398.541,57.1Zm0-2.429a.575.575,0,1,0,.407.168A.573.573,0,0,0,398.541,54.668Z" transform="translate(-345.945 -53.39)" fill="#00eca2"/>
                <path id="Path_11749" data-name="Path 11749" d="M423.677,105.989a1.853,1.853,0,1,1,1.311-.542A1.849,1.849,0,0,1,423.677,105.989Zm0-2.429a.575.575,0,1,0,.407.168A.573.573,0,0,0,423.677,103.559Z" transform="translate(-367.865 -96.027)" fill="#ff7eb8"/>
                <path id="Path_11750" data-name="Path 11750" d="M92.74,211.481a1.854,1.854,0,1,1,1.312-.542,1.849,1.849,0,0,1-1.312.542Zm0-2.429a.575.575,0,1,0,.407.168A.574.574,0,0,0,92.74,209.052Z" transform="translate(-79.261 -188.026)" fill="#ff7eb8"/>
                <path id="Path_11751" data-name="Path 11751" d="M78,264.7a.638.638,0,0,1-.452-.187l-.763-.763-.763.763a.64.64,0,0,1-.9-.9l1.215-1.215a.64.64,0,0,1,.9,0l1.215,1.215A.64.64,0,0,1,78,264.7Z" transform="translate(-65.348 -235.491)" fill="#6583fe"/>
                <path id="Path_11752" data-name="Path 11752" d="M405.892,409.5a.638.638,0,0,1-.452-.187l-.763-.763-.763.763a.64.64,0,0,1-.9-.9l1.215-1.215a.64.64,0,0,1,.9,0l1.215,1.215a.639.639,0,0,1-.452,1.092Z" transform="translate(-351.296 -361.769)" fill="#00eca2"/>
                </g>
                <path id="Path_14062" data-name="Path 14062" d="M-10157.236,9043.538c.209-.983,2.878-7.443,2.878-7.443s-3.44-6.952-3.44-9.339-.281-8.989,5.969-14.467,14.115-6.461,18.258-5.056,8.988,4.354,10.182,7.022,4.494,7.653,4.073,11.445-6.109,15.73-10.112,17.346a21.438,21.438,0,0,1-12.008,1.4c-3.512-.773-4.705-1.334-6.18-1.4a54.95,54.95,0,0,0-5.758.491Z" transform="translate(22419.422 -9288.877)" fill="#fff"/>
                <path id="whatsapp-2" data-name="whatsapp" d="M36.684,6.228A21.24,21.24,0,0,0,3.262,31.849l-3.013,11L11.507,39.9a21.212,21.212,0,0,0,10.147,2.585h.009A21.244,21.244,0,0,0,36.684,6.228ZM21.663,38.9h-.007a17.628,17.628,0,0,1-8.983-2.46l-.644-.383-6.68,1.752L7.131,31.3l-.42-.668A17.649,17.649,0,1,1,21.663,38.9ZM31.344,25.68c-.53-.266-3.139-1.549-3.626-1.726s-.84-.265-1.193.266-1.371,1.726-1.68,2.08-.619.4-1.15.133A14.492,14.492,0,0,1,19.428,23.8a16,16,0,0,1-2.952-3.675c-.309-.531,0-.791.233-1.083a15.008,15.008,0,0,0,1.326-1.815.976.976,0,0,0-.044-.93c-.132-.265-1.193-2.877-1.635-3.94-.431-1.034-.868-.895-1.194-.911-.309-.015-.663-.019-1.017-.019a1.949,1.949,0,0,0-1.415.664,5.951,5.951,0,0,0-1.857,4.426,10.321,10.321,0,0,0,2.166,5.489c.265.354,3.742,5.713,9.064,8.011a30.37,30.37,0,0,0,3.025,1.118,7.274,7.274,0,0,0,3.342.21c1.02-.152,3.139-1.284,3.581-2.523a4.433,4.433,0,0,0,.309-2.523c-.132-.221-.486-.354-1.017-.62Zm0,0" transform="translate(12258.973 -284.44)" fill="#3d57fe" fillRule="evenodd"/>
            </g>
        </svg>
    )
}
