import React from 'react'

export default function SupportMenuIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18.828" height="21.424" viewBox="0 0 18.828 21.424">
      <g id="Support" transform="translate(-14658 20623.422)">
        <g id="headphones" transform="translate(14627 -20623.422)">
          <g id="Group_1565" data-name="Group 1565" transform="translate(33.51 8.787)">
            <g id="Group_1564" data-name="Group 1564">
              <path id="Path_11637" data-name="Path 11637" d="M92.883,210A1.885,1.885,0,0,0,91,211.883v5.1a1.883,1.883,0,0,0,3.766,0v-5.1A1.885,1.885,0,0,0,92.883,210Z" transform="translate(-91 -210)" fill="#143059"/>
            </g>
          </g>
          <g id="Group_1567" data-name="Group 1567" transform="translate(31 10.141)">
            <g id="Group_1566" data-name="Group 1566">
              <path id="Path_11638" data-name="Path 11638" d="M32.255,242.895a3.114,3.114,0,0,1,.053-.529A1.879,1.879,0,0,0,31,244.15v2.594a1.879,1.879,0,0,0,1.309,1.784,3.114,3.114,0,0,1-.053-.529Z" transform="translate(-31 -242.366)" fill="#143059"/>
            </g>
          </g>
          <g id="Group_1569" data-name="Group 1569" transform="translate(38.531 8.787)">
            <g id="Group_1568" data-name="Group 1568">
              <path id="Path_11639" data-name="Path 11639" d="M217.9,210a1.885,1.885,0,0,0-1.883,1.883v5.1a1.885,1.885,0,0,0,1.883,1.883,1.857,1.857,0,0,0,.539-.088,1.879,1.879,0,0,1-1.795,1.343h-2a1.883,1.883,0,1,0,0,1.255h2a3.142,3.142,0,0,0,3.138-3.138v-6.36A1.885,1.885,0,0,0,217.9,210Z" transform="translate(-211 -210)" fill="#143059"/>
            </g>
          </g>
          <g id="Group_1571" data-name="Group 1571" transform="translate(48.52 10.141)">
            <g id="Group_1570" data-name="Group 1570">
              <path id="Path_11640" data-name="Path 11640" d="M449.722,242.366a3.112,3.112,0,0,1,.053.529V248a3.112,3.112,0,0,1-.053.529,1.879,1.879,0,0,0,1.309-1.784V244.15A1.879,1.879,0,0,0,449.722,242.366Z" transform="translate(-449.722 -242.366)" fill="#143059"/>
            </g>
          </g>
          <g id="Group_1573" data-name="Group 1573" transform="translate(31)">
            <g id="Group_1572" data-name="Group 1572">
              <path id="Path_11641" data-name="Path 11641" d="M40.414,0A9.425,9.425,0,0,0,31,9.414V9.43a3.108,3.108,0,0,1,1.284-.583,8.149,8.149,0,0,1,16.261,0,3.109,3.109,0,0,1,1.284.583V9.414A9.425,9.425,0,0,0,40.414,0Z" transform="translate(-31)" fill="#143059"/>
            </g>
          </g>
        </g>
      </g>
    </svg>

  )
}
