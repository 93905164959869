import React from 'react'

function DeleteIcon1() {
    return (
      
            
<svg xmlns="http://www.w3.org/2000/svg" width="12.016" height="15.445" viewBox="0 0 12.016 15.445">
  <g id="delete_1_" data-name="delete (1)" transform="translate(0)">
    <path id="Path_14082" data-name="Path 14082" d="M126.4.932h2.581v.434h.932v-.5A.872.872,0,0,0,129.04,0h-2.7a.872.872,0,0,0-.871.872v.5h.932Zm0,0" transform="translate(-121.68 0)" fill="#373737"/>
    <path id="Path_14083" data-name="Path 14083" d="M49.653,167.75H40.9a.411.411,0,0,0-.409.444l.732,9.047a.972.972,0,0,0,.968.894h6.17a.972.972,0,0,0,.968-.894l.732-9.047a.41.41,0,0,0-.409-.444Zm-6.632,9.42h-.029a.466.466,0,0,1-.465-.438l-.459-7.427a.466.466,0,0,1,.931-.057l.458,7.427A.466.466,0,0,1,43.02,177.17Zm2.728-.465a.466.466,0,0,1-.932,0v-7.427a.466.466,0,1,1,.932,0Zm2.738-7.4-.438,7.427a.466.466,0,0,1-.465.439h-.028a.466.466,0,0,1-.438-.493l.438-7.427a.466.466,0,0,1,.931.055Zm0,0" transform="translate(-39.269 -162.69)" fill="#373737"/>
    <path id="Path_14084" data-name="Path 14084" d="M12,77.534l-.306-.918a.593.593,0,0,0-.562-.405H.889a.593.593,0,0,0-.562.405l-.306.918a.385.385,0,0,0,.365.506H11.631a.38.38,0,0,0,.2-.059A.384.384,0,0,0,12,77.534Zm0,0" transform="translate(0 -73.912)" fill="#373737"/>
  </g>
</svg>

    )
}

export default DeleteIcon1
