import React from 'react'

export function Addfbicon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
            <g id="Group_4572" data-name="Group 4572" transform="translate(-0.264 0.195)">
                <circle id="Ellipse_373" data-name="Ellipse 373" cx="11.5" cy="11.5" r="11.5" transform="translate(0.264 -0.195)" fill="#0760c5"/>
                <path id="facebook_4_" data-name="facebook (4)" d="M11.626,2.209h1.215V.094A15.686,15.686,0,0,0,11.071,0,2.809,2.809,0,0,0,8.12,3.126V4.99H6.187V7.355H8.12v5.951h2.37V7.356h1.855l.294-2.365h-2.15V3.361c0-.684.185-1.152,1.137-1.152Z" transform="translate(2.097 4.884)" fill="#fff"/>
            </g>
        </svg>
    )
}
export function Addinstaicon() {
    return (
        <svg enableBackground="new 0 0 24 24" height="23" viewBox="0 0 24 24" width="23" xmlns="http://www.w3.org/2000/svg"><linearGradient id="SVGID_1_" gradientTransform="matrix(0 -1.982 -1.844 0 -132.522 -51.077)" gradientUnits="userSpaceOnUse" x1="-37.106" x2="-26.555" y1="-72.705" y2="-84.047"><stop offset="0" stopColor="#fd5"/><stop offset=".5" stopColor="#ff543e"/><stop offset="1" stopColor="#c837ab"/></linearGradient><path d="m1.5 1.633c-1.886 1.959-1.5 4.04-1.5 10.362 0 5.25-.916 10.513 3.878 11.752 1.497.385 14.761.385 16.256-.002 1.996-.515 3.62-2.134 3.842-4.957.031-.394.031-13.185-.001-13.587-.236-3.007-2.087-4.74-4.526-5.091-.559-.081-.671-.105-3.539-.11-10.173.005-12.403-.448-14.41 1.633z" fill="url(#SVGID_1_)"/><path d="m11.998 3.139c-3.631 0-7.079-.323-8.396 3.057-.544 1.396-.465 3.209-.465 5.805 0 2.278-.073 4.419.465 5.804 1.314 3.382 4.79 3.058 8.394 3.058 3.477 0 7.062.362 8.395-3.058.545-1.41.465-3.196.465-5.804 0-3.462.191-5.697-1.488-7.375-1.7-1.7-3.999-1.487-7.374-1.487zm-.794 1.597c7.574-.012 8.538-.854 8.006 10.843-.189 4.137-3.339 3.683-7.211 3.683-7.06 0-7.263-.202-7.263-7.265 0-7.145.56-7.257 6.468-7.263zm5.524 1.471c-.587 0-1.063.476-1.063 1.063s.476 1.063 1.063 1.063 1.063-.476 1.063-1.063-.476-1.063-1.063-1.063zm-4.73 1.243c-2.513 0-4.55 2.038-4.55 4.551s2.037 4.55 4.55 4.55 4.549-2.037 4.549-4.55-2.036-4.551-4.549-4.551zm0 1.597c3.905 0 3.91 5.908 0 5.908-3.904 0-3.91-5.908 0-5.908z" fill="#fff"/></svg>
    )
}
export function Addlinkedicon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="23" viewBox="0 0 24 23">
            <g id="Group_4573" data-name="Group 4573" transform="translate(0.428 -0.27)">
                <g id="Group_4427" data-name="Group 4427" transform="translate(0 0)">
                <ellipse id="Ellipse_373" data-name="Ellipse 373" cx="12" cy="11.5" rx="12" ry="11.5" transform="translate(-0.428 0.269)" fill="#127094"/>
                <g id="linkedin_2_" data-name="linkedin (2)" transform="translate(6.231 6.163)">
                    <path id="Path_14008" data-name="Path 14008" d="M15.528,15.031h0v-4c0-1.955-.421-3.461-2.706-3.461a2.372,2.372,0,0,0-2.137,1.174h-.032V7.757H8.489v7.274h2.256v-3.6c0-.948.18-1.865,1.354-1.865,1.157,0,1.174,1.082,1.174,1.926v3.542Z" transform="translate(-4.635 -4.135)" fill="#fff"/>
                    <path id="Path_14009" data-name="Path 14009" d="M.4,7.977H2.655v7.274H.4Z" transform="translate(-0.216 -4.356)" fill="#fff"/>
                    <path id="Path_14010" data-name="Path 14010" d="M1.308,0A1.315,1.315,0,1,0,2.617,1.308,1.309,1.309,0,0,0,1.308,0Z" fill="#fff"/>
                </g>
                </g>
            </g>
        </svg>
    )
}
export function Addtwiticon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="23" viewBox="0 0 24 23">
            <g id="Group_4574" data-name="Group 4574" transform="translate(0.497 0.196)">
                <g id="Group_4428" data-name="Group 4428" transform="translate(0 0)">
                <ellipse id="Ellipse_373" data-name="Ellipse 373" cx="12" cy="11.5" rx="12" ry="11.5" transform="translate(-0.497 -0.196)" fill="#00b9ff"/>
                <g id="twitter" transform="translate(6.913 8.008)">
                    <g id="Group_4096" data-name="Group 4096" transform="translate(0)">
                    <path id="Path_14007" data-name="Path 14007" d="M9.428,48.907a4.03,4.03,0,0,1-1.114.305,1.922,1.922,0,0,0,.85-1.068,3.863,3.863,0,0,1-1.226.468A1.933,1.933,0,0,0,4.6,49.933a1.99,1.99,0,0,0,.045.441A5.471,5.471,0,0,1,.656,48.352a1.933,1.933,0,0,0,.594,2.583A1.909,1.909,0,0,1,.377,50.7v.021a1.942,1.942,0,0,0,1.549,1.9,1.929,1.929,0,0,1-.507.064,1.709,1.709,0,0,1-.366-.033A1.951,1.951,0,0,0,2.859,54a3.884,3.884,0,0,1-2.4.824A3.619,3.619,0,0,1,0,54.793a5.442,5.442,0,0,0,2.965.867,5.464,5.464,0,0,0,5.5-5.5c0-.085,0-.168-.007-.25A3.856,3.856,0,0,0,9.428,48.907Z" transform="translate(0 -48)" fill="#fff"/>
                    </g>
                </g>
                </g>
            </g>
        </svg>
    )
}
