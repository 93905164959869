import React from 'react';

const Form = () => {

    return(
        <div className="container enroll_form">
            <h2>Enroll Form</h2>
            <form className="form-horizontal" action="">
                <div className="form-group">
                <label className="control-label col-sm-3" for="name"> Name :</label>
                <div className="col-sm-9">
                    <input type="text" className="form-control" id="name" placeholder="Enter Name" name="email" />
                </div>
                </div>
                <div className="form-group">
                <label className="control-label col-sm-3" for="email">Email :</label>
                <div className="col-sm-9">
                    <input type="email" className="form-control" id="email" placeholder="Enter email" name="email" />
                </div>
                </div>
                <div className="form-group">
                <label className="control-label col-sm-3" for="pwd"> Mobile Number : </label>
                <div className="col-sm-9">          
                    <input type="text" className="form-control" id="pwd" placeholder="Enter Mobile Number" name="pwd" />
                </div>
                </div>
                <div className="form-group">        
                <div className="col-sm-12 submit_btn">
                    <button type="submit" className="btn btn1 btn-default"> Enroll Now </button>
                </div>
                </div>
            </form>
        </div>
    )

}

export default Form