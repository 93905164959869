import React from 'react'

function Notfound() {
  return (
    <div style={{height:'90vh',width:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
    <div >
      <img src={require('../../images/website/error404.png')} alt="error-page-not-found"/>
      <h2>Looks like this page doesn’t exist, but don’t let that stop you.</h2>
      <a href="/" className='green_btn' style={{display:'block',margin:'auto',width:'140px',padding:'10px 50px',color:'#fff'}}>Home</a>
    </div>
  </div>
  )
}

export default Notfound