import axios from 'axios';
import React,{useState,useEffect} from 'react'
import Cookies from 'js-cookie';
import $ from 'jquery'
import CustomModal from '../CustomModal'
import { Link,useHistory } from 'react-router-dom';
import Toaster from '../../../toaster';

/*
{headers:{
    Authorization:`Bearer ${Cookies.get('token')}`
  } }
*/
function Sectionlist(props) {
    const history=useHistory()

    const [sectionList, setSectionList] = useState([])
    const [addPopup,setAddPopup] = useState(false)
    const [editPopupId,setEditPopupId] = useState()
    const [fields, Getfields] = useState({});
    const [fieldsEdit, GetfieldsEdit] = useState({});
    const [error,setError]=useState({})
    const [courseId,setCourseId]= useState()

    const [tosOpen,setTosOpen]=useState()

    useEffect(() =>{
    let pathArr=window.location.pathname.split('/')
    setCourseId(pathArr[pathArr.length-1])
    GetSectionList(pathArr[pathArr.length-1])
  },[])
  
  const GetSectionList = (id) =>{
    
      axios.get(`https://api.ezioskillacademy.com/api/admin/course/section/`+id, {headers:{
        Authorization:`Bearer ${Cookies.get('token')}`
      } })
      .then(res => {  
                    // console.log("batch",res.data)
                    setSectionList(res.data.data)     
      }).catch(e=>{
        alert(e.response.data.message)
      })
  }


const addnewBatch=()=>{
    let err={}
    let isValid=true
    if(!fields.section_name){
        err["section_name"]="Please Enter Section Name"
        isValid=false
    }
    if(!fields.section_desc){
      err["section_desc"]="Please Enter Section Name"
      isValid=false
  }
    if(!fields.isEnable){
      err["isEnable"]="Please Enter Status"
      isValid=false
  }
    setError(err)

    if(isValid){
        axios.post('https://api.ezioskillacademy.com/api/admin/course/section/'+courseId,{
            'section_name':fields.section_name,
            'section_desc':fields.section_desc,
            'isEnable':fields.isEnable
        },{headers:{
            Authorization:`Bearer ${Cookies.get('token')}`
          } })
        .then(res=>{
            // console.log("section",res.data)
            GetSectionList(courseId)
            $('#add_scs_msg').html(res.data.message)
            setTosOpen(res.data.message)
                // setTimeout(()=>{
                     setAddPopup(false)      
                // },3000)
                
            
        }).catch(e=>{
           alert(e.response.data.message)
        })
    }
}
 
const editBatch=()=>{
    let err={}
    let isValid=true
  //  // console.log(true=fieldsEdit.isEnable)
    if(!fieldsEdit.section_name){
        err["section_name"]="Please Enter Section Name"
        isValid=false
    }
    if(!fieldsEdit.section_desc){
      err["section_desc"]="Please Enter Section Name"
      isValid=false
  }
    if(!fieldsEdit.isEnable){
        err["isEnable"]="Please Enter Status"
        isValid=false
    }
    setError(err)
    if(isValid){
    const jsonData={
        "section_name": fieldsEdit.section_name,
        'section_desc':fieldsEdit.section_desc,
        "isEnable": fieldsEdit.isEnable=='true'
    }
    axios.put('https://api.ezioskillacademy.com/api/admin/course/section/'+courseId+"/"+editPopupId,jsonData,{headers:{
        Authorization:`Bearer ${Cookies.get('token')}`
      } })
    .then(res=>{
            GetSectionList(courseId)
            $('#edit_scs_msg').html(res.data.message)
            setTosOpen(res.data.message)
           // setTimeout(()=>{
                setEditPopupId(undefined)      
            //},3000)
            
        
    }).catch(e=>{
       alert(e.response.data.message)
    })
    }
  }
  const HandleDelete=(e,id)=>{
    e.stopPropagation();
    var result = window.confirm("Are you sure you want to delete?");
    if (result) {
      axios.delete(`https://api.ezioskillacademy.com/api/admin/course/section/${courseId}/${id}`,{headers:{
        Authorization:`Bearer ${Cookies.get('token')}`
      } })
      .then(res => {
        GetSectionList(courseId)   
        setTosOpen(res.data.message) 
      }).catch(e=>{
        alert(e.response.data.message)
      })
    }
  }
  const handleValue=(e)=>{
      Getfields({ ...fields, [e.target.name]:e.target.value })
    }
    const handleEditValue=(e)=>{
        GetfieldsEdit({ ...fieldsEdit, [e.target.name]:e.target.value })
      }
    const setEditPopupOpen=(e,section)=>{
        e.stopPropagation();
        setEditPopupId(section._id)
        GetfieldsEdit(section)
        //GetBatchFields(id)  
    }
    
    
   
  return (
    <div className="dash_page_bg dash_section_ptb add_payment">
  
        <button className="add_payment_btn" onClick={()=>setAddPopup(true)}
        >
          Add Section
        </button>
        
        <br />
        <br />
        <div className="clientrate_table">
          <div className="tabcomp-style">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <td>Section Name</td> 
                    <td>Status</td>
                    <td>Sub Section Count</td>
                    <td style={{width:'200px'}}></td>                   
                  </tr>
                </thead>
                <tbody>
                  {sectionList.length > 0 ? (
                    <>
                      {sectionList
                        .map((section, id) => (
                          <tr key={id} className="admin_course_list" onClick={()=>history.push('/superadmin/course/section/'+section._id)}>
                            <td>{section.section_name}</td>
                            <td>{section.isEnable?'Enabled':'disable'}</td>
                            <td>{section.sub_section_count}</td>
                            <td style={{display:'flex',justifyContent:'center',width:'200px'}}>
                     
                                <button className='blue_btn' onClick={(e)=>setEditPopupOpen(e,section)}>Edit</button>
                               
                                <button 
                                  onClick={(e)=>HandleDelete(e,section._id)} className='red_btn'>Delete</button>
                                </td>                         
                          </tr>
                        ))}
                    </>
                  ) : (
                    <tr>
                      <td colSpan="3" style={{ textAlign: "center" }}>
                        No data
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <CustomModal open={addPopup}>
        <div className="scsmdl-container" style={{ height: "auto" }}>
          <div className="scsmdl-header">
            <p>Create Section</p>
            {/* <img src={'/modalclose.jpg'} className="scsmdl-closeimg"  /> */}
            <img
              src={"/modalclose.jpg"}
              className="scsmdl-closeimg"
              onClick={() => setAddPopup(false)}
              alt="close img"
            />
          </div>
          <div className="scsmdl-body" style={{ padding: "20px" }}>
          <div className="form-group">
              <label>Section Name:</label>
              <input
                className="form-control"
                type="text"
                name="section_name"
                onChange={(e) => handleValue(e)}
              />
              <p style={{color:'red'}}>{error.section_name}</p>
            </div>

            <div className="form-group">
              <label>Section Description:</label>
              <input
                className="form-control"
                type="text"
                name="section_desc"
                onChange={(e) => handleValue(e)}
              />
              <p style={{color:'red'}}>{error.section_desc}</p>
            </div>
           
            <div className="form-group">
              <label>Status:</label>
              <select style={{width:'100%',height:'40px',border: '1px solid #e8e8e8'}} name='isEnable'  onChange={(e) => handleValue(e)}>
                  <option selected disabled>Status</option>
                  <option value='true' >Enable</option>
                  <option value='false'>Disable</option>
                </select>
                <p style={{color:'red'}}>{error.isEnable}</p>
            </div>
            <br/>
            <div className="row">
              <div className="col-lg-5">
                <button
                 onClick={addnewBatch}
                  className="addpayment-submitbtn"
               //   disabled={loader}
                //  style={loader ? { opacity: "0.5" } : {}}
                >
                  Submit
                </button>
              </div>
             
            </div>
            <p id="add_scs_msg" style={{color:'green'}}></p>
          </div>
        </div>
      </CustomModal>

      <CustomModal open={editPopupId}>
        <div className="scsmdl-container" style={{ height: "auto" }}>
          <div className="scsmdl-header">
            <p>Edit Section</p>
            {/* <img src={'/modalclose.jpg'} className="scsmdl-closeimg"  /> */}
            <img
              src={"/modalclose.jpg"}
              className="scsmdl-closeimg"
              onClick={() => setEditPopupId(undefined)}
              alt="close img"
            />
          </div>
          <div className="scsmdl-body" style={{ padding: "20px" }}>
         
          <div className="form-group">
              <label>Section Name:</label>
              <input
                className="form-control"
                type="text"
                name="section_name"
                onChange={(e) => handleEditValue(e)}
                value={fieldsEdit.section_name}
              />
             <p style={{color:'red'}}>{error.section_name}</p>
            </div>

            <div className="form-group">
              <label>Section Description:</label>
              <input
                className="form-control"
                type="text"
                name="section_desc"
                onChange={(e) => handleEditValue(e)}
                value={fieldsEdit.section_desc}
              />
              <p style={{color:'red'}}>{error.section_desc}</p>
            </div>
            <br/>
            <div className="form-group">
              <label>Status:</label>
              <select style={{width:'100%',height:'40px',border: '1px solid #e8e8e8'}} name='isEnable'  onChange={(e) => handleEditValue(e)}>
                  <option selected disabled>Status</option>
                  <option value='true' >Enable</option>
                  <option value='false'>Disable</option>
                </select>
                <p style={{color:'red'}}>{error.isEnable}</p>
            </div>
            <br/>
            <div className="row">
              <div className="col-lg-5">
                <button
                  onClick={editBatch}
                  className="addpayment-submitbtn"
                >
                  Submit
                </button>
              </div>
             
            </div>

            <p id="edit_scs_msg" style={{color:'green'}}></p>
          </div>
        </div>
      </CustomModal>
      <Toaster tosOpen={tosOpen} setTosOpen={setTosOpen}/>
      </div>
  )
}

export default Sectionlist