import React from 'react'

export default function Chevron() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="56.764" height="30.346" viewBox="0 0 56.764 30.346">
      <g id="chevron" transform="translate(56.764 -126.513) rotate(90)">
        <g id="Group_4444" data-name="Group 4444" transform="translate(126.513 0)">
          <path id="Path_14107" data-name="Path 14107" d="M154.8,23.68,130.516.651a2.439,2.439,0,0,0-3.314,0,2.134,2.134,0,0,0,0,3.136l24.291,23.031a2.137,2.137,0,0,1,0,3.137L127.2,52.98a2.134,2.134,0,0,0,0,3.136,2.439,2.439,0,0,0,3.314,0L154.8,33.085A6.408,6.408,0,0,0,154.8,23.68Z" transform="translate(-126.513 0)" fill="#afafaf"/>
        </g>
      </g>
    </svg>
  )
}
