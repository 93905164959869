import React, { Component,useEffect,useState } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    NavLink ,
    Link
  } from "react-router-dom";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Index from '../index'
// import {routes} from '../index'
import Background from '../../../images/banner.png'
import Video from './../../project_video';
import Discussions from './../../discussions';
import { course_detail } from './data/course_details_arr';
import { Breadcrumbs, Typography, Button } from '@material-ui/core';
import axios from 'axios';
import Cookies from 'js-cookie';
import $ from 'jquery'
import parser from 'html-react-parser';
import { useSelector, useDispatch } from 'react-redux'

const headers={
  Authorization:`Bearer ${Cookies.get('token')}`
}

const ProjectUpload = () => {
	const reduxValue=useSelector(state=>state)
	const dispatch=useDispatch()

	const [currentVideo,setCurrentVideo]=useState()
	const [files, setFiles] = useState();
	const [currentSection,setCurrentSection] = useState();
	const [uploadedProject,setUploadedProject] = useState();
	const [projectId,setProjectId] = useState();
	const [projectDetails,setProjectDetails] = useState();
	const [sectionId,setSectionId]= useState();

	useEffect(()=>{
		let pathArr=window.location.pathname.split('/')
		let _projectid=pathArr[pathArr.length-1]
		let _sectionid=pathArr[pathArr.length-2]
		setProjectId(_projectid)
		setSectionId(_sectionid)
		
		getProjectDetails(_sectionid,_projectid)

		

		window.onscroll=()=>{
			// console.log('scrolling',document.documentElement.scrollTop,window.innerWidth)
			if(document.getElementById('videolist_right_bar')){
				let rightsidebar=document.getElementById('videolist_right_bar').style
				if(document.documentElement.scrollTop,window.innerWidth>1200){
					if(document.documentElement.scrollTop>100){
						rightsidebar.position="fixed"
						rightsidebar.width="21%"
					}else{
						rightsidebar.position="relative"
						rightsidebar.width="25%"
					}
				}
			}

			
		
			
		}

		//setCurrentSection(course_detail.filter(course=>course.section_url===window.location.pathname.split('/')[2])[0])

	},[])

	useEffect(()=>{
		if(reduxValue&&sectionId&&projectDetails){
			//let tempreduxValue=reduxValue
			let c_url=reduxValue.breadcrump[1].url
			let s_url=`${c_url}/${sectionId}`
			if(!reduxValue.breadcrump[2].url){
				reduxValue.breadcrump[2].url=s_url
				// console.log('red',reduxValue,reduxValue.breadcrump)
			
				dispatch({
					type:'USER_BREADCRUMPS',
					breadcrump:[...reduxValue.breadcrump,{name:projectDetails?.project_title,url:''}]}) }
			  
			
		}
	},[reduxValue,sectionId,projectDetails])

	function getProjectDetails(section_id,project_id){
		axios.get(`https://api.ezioskillacademy.com/api/user/project/${section_id}/${project_id}`, { headers:{
			Authorization:`Bearer ${Cookies.get('token')}`
		  } })
		  .then((res) => {
					// console.log("Status", res.data);
					setProjectDetails(res.data.data)
						
		  })
		//   .catch((err)=>{
		// 	//$('project_btn').css('display','none')
		//   })
	}

	// useEffect(() => {
	// 	if(currentSection){
	// 	const jsonData={
	// 		"course_title": currentSection.section_url,
	// 		"student_id": localStorage.getItem('user_id'),
	// 	}
	// 	axios.post('https://api.ezioskillacademy.com/api/user/project-get',jsonData, { headers:{
	// 		Authorization:`Bearer ${Cookies.get('token')}`
	// 	  } })
	// 	.then(res=>{
	// 		// console.log('project',res.data.data)
	// 		setUploadedProject(res.data.data)
	// 		//alert('done')
	// 	})
	// 	}
		
	// }, [currentSection])


	const handleUploadfile = (e) => {
		// // console.log(e.target.files);
		setFiles(e.target.files[0])
	}
	// console.log("msg",currentSection,uploadedProject)
	
	const handleUpload = () => {
	if(files){
	const formData=new FormData()
    formData.append('file_url',files);
    axios.post(`https://api.ezioskillacademy.com/api/user/project/${sectionId}/${projectId}`,formData, { headers:{
		Authorization:`Bearer ${Cookies.get('token')}`
	  } })
			.then(res=>{
				// console.log('project',res.data)
				$('#upload_scs_msg').fadeIn()
			
			})

	}else{
		$('#err_file').fadeIn()
		setTimeout(()=>{
			$('#err_file').fadeOut()
		},2000)
	}
	}

   return (
           <div className='video_page_con'>
				{
					projectDetails?(
						<section style={{backgroundColor:'#e9f1fe',height:'100vh',padding:'0'}}>
							 {/* <div className='lessons_only course_list_top white_bg' style={{zIndex:'1'}}>
	  						<div className='lat_activity' >					
					<div className='up_section' style={{width:'100%'}}>
						<div className='row'>
						<div className='col-lg-12' style={{display:'flex',justifyContent:'space-between'}}>
						<div>
						
										<h1>{currentSection.section_name}</h1>
								    <Breadcrumbs aria-label="breadcrumb">
                        					<Link color="inherit" to="/course" >
                        					  Course
                        					</Link>
											<Link color="inherit" to={"/course/"+currentSection.section_url} >
											{currentSection.section_name}
                        					</Link>
                        					<Typography color="textPrimary">Project</Typography>
                				</Breadcrumbs> 	
						</div>
					
						<div style={{display:'flex',justifyContent:'space-between',alignItems:'center',minWidth:'90px'}}>								
							<p style={{textTransform:'capitalize',fontFamily:'circular-regular'}}>Hi, Siva </p>
							<img src={require('../../../images/account.png')} alt="" style={{width:'30px',marginLeft:'15px'}}/>
							</div>
							
							</div>						
						</div>
					</div>					
				</div>	
				</div> */}
			
					<div style={{height:'100%'}}>
						<div className='col-lg-9 padding_none'>
						
							{/* <img src={require('../../../images/training.png')} alt='' /> */}
							<Video project={projectDetails}/>
							<div style={{padding:'20px'}}>
								
							<h1>Notes:</h1>
							{
								parser(projectDetails.project_desc)
							}
							</div>
							
							{/* <Discussions/> */}

					
						</div>
						<div id='videolist_right_bar' className='col-lg-3 ta_set padding_none'  style={{height:'100%'}}>
							{/* <h5>Section {currentSection.sid}:</h5> */}
							<br/>
							<p>{projectDetails.project_title}</p>
							
							<section id="tabs" className='video_choose' style={{marginTop:'0'}}>
									<div className="">
										<div className="row margin_none">
											<div className="col-lg-12 padding_none">
												 
												
												
												
											
													
												 <hr style={{margin:'12px 0 0 0'}}/>
												
												<div className='project_upload_right_bar'>
                                                    
                                                <h3>Download Template</h3> 
												<a href={projectDetails.project_file} download>                                           
												<Button  variant="contained" size="large" color="primary" style={{fontSize:'14px',width:'100%'}} disabled={projectDetails.status=="completed"}>Download</Button>
												</a>  
											    {/* <input type="file" name=""/> */}

                                                <hr style={{margin:'12px 0'}}/>
												 <h3>Upload Project</h3>                                                 
                                                 {/* <button>Upload</button> */}
												<input type="file" name="" onChange={handleUploadfile}/>
												<p id="err_file" style={{color:'red',display:'none'}}>*please choose file</p>
												<Button onClick={handleUpload} variant="contained" size="large" color="primary" style={{fontSize:'12px',width:'100%', marginTop: '20px'}} disabled={projectDetails.status=="completed"}>
        												  Upload
        												</Button>
												<p id='upload_scs_msg' style={{display:'none',color:'green'}}>We have receive your project... We will give feedback after review it... </p>
                                                 <hr style={{margin:'12px 0'}}/>

                                                 <h3>Feedback</h3>
												 {
													 projectDetails.status=="reviwed" || projectDetails.status=="completed"?<>
													 <p>{projectDetails.remarks}</p>
													 <br/>
													 <a href={projectDetails.file_url} download>
													 <Button  variant="contained" size="large" color="primary" style={{fontSize:'14px',width:'100%'}} >Download Response</Button>
													 </a>													 
													 </>:<p>No Feedback yet</p>
												 }

												
                                                 
                                                    </div>	

													
													
											
											</div>
										</div>
									</div>
								</section>
								{
													projectDetails.status=="completed"?(
														<div style={{
															backgroundColor:'green',color:'#fff',
															height:'80px',display:'flex',
															justifyContent:'center',alignItems:'center',
															marginTop:'20px',position:'absolute',
															width:'100%',bottom:'100px'
															}}>
															<h3>Project Completed</h3>
														</div>
													):null
												}
												 
						</div>
				 </div>
				 </section>
					):(
						<h1>Loading</h1>
					)	
				}	  
			  
		   </div>
    )
}

export default ProjectUpload

