import React from 'react'

export default function CloseIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46">
      <g id="close" transform="translate(-922.426 -75.426)">
        <circle id="Ellipse_348" data-name="Ellipse 348" cx="23" cy="23" r="23" transform="translate(922.427 75.427)" fill="#dbe9ff"/>
        <path id="close_1_" data-name="close (1)" d="M7.8,6.73l5.136-5.136A.854.854,0,0,0,11.73.387L6.594,5.522,1.459.387A.854.854,0,0,0,.251,1.595L5.386,6.73.251,11.866a.854.854,0,1,0,1.208,1.208L6.594,7.939l5.136,5.136a.854.854,0,0,0,1.208-1.208Zm0,0" transform="translate(939.014 91.878)" fill="#3e3e3e"/>
      </g>
    </svg>

  )
}
