import React from 'react'

export default function AboutIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20.002" height="19.855" viewBox="0 0 20.002 19.855">
      <g id="About_Business" data-name="About Business" transform="translate(-18156 20289.018)">
        <g id="Group_4370" data-name="Group 4370" transform="translate(18109 -20456)">
          <path id="Path_10444" data-name="Path 10444" d="M10.86,1.588,1.7.2A1.477,1.477,0,0,0,.517.521,1.49,1.49,0,0,0,0,1.638V19.2a.836.836,0,0,0,.833.833H3.542V15.658A1.455,1.455,0,0,1,5,14.2H7.085a1.455,1.455,0,0,1,1.459,1.459v4.376h3.542v-17A1.459,1.459,0,0,0,10.86,1.588ZM4.584,12.324H3.334a.625.625,0,0,1,0-1.25h1.25a.625.625,0,0,1,0,1.25Zm0-2.5H3.334a.625.625,0,0,1,0-1.25h1.25a.625.625,0,0,1,0,1.25Zm0-2.5H3.334a.625.625,0,0,1,0-1.25h1.25a.625.625,0,0,1,0,1.25Zm0-2.5H3.334a.625.625,0,1,1,0-1.25h1.25a.625.625,0,1,1,0,1.25Zm4.167,7.5H7.5a.625.625,0,0,1,0-1.25h1.25a.625.625,0,0,1,0,1.25Zm0-2.5H7.5a.625.625,0,0,1,0-1.25h1.25a.625.625,0,0,1,0,1.25Zm0-2.5H7.5a.625.625,0,0,1,0-1.25h1.25a.625.625,0,0,1,0,1.25Zm0-2.5H7.5a.625.625,0,1,1,0-1.25h1.25a.625.625,0,1,1,0,1.25Z" transform="translate(46.999 166.806)" fill="#143059"/>
          <path id="Path_10445" data-name="Path 10445" d="M21.434,10.594,15.5,9.351v12.21h5.626A1.46,1.46,0,0,0,22.585,20.1V12.019A1.452,1.452,0,0,0,21.434,10.594ZM19.459,19.06h-1.25a.625.625,0,0,1,0-1.25h1.25a.625.625,0,0,1,0,1.25Zm0-2.5h-1.25a.625.625,0,1,1,0-1.25h1.25a.625.625,0,0,1,0,1.25Zm0-2.5h-1.25a.625.625,0,0,1,0-1.25h1.25a.625.625,0,0,1,0,1.25Z" transform="translate(44.418 165.278)" fill="#143059"/>
        </g>
      </g>
    </svg>

  )
}
