import React from 'react'

export function EyeIcon() {
    return (
        <svg id="eye" xmlns="http://www.w3.org/2000/svg" width="18.783" height="11.972" viewBox="0 0 18.783 11.972">
            <g id="Group_1721" data-name="Group 1721" transform="translate(0 0)">
                <g id="Group_1720" data-name="Group 1720" transform="translate(0 0)">
                <path id="Path_11772" data-name="Path 11772" d="M18.664,98.455c-.168-.23-4.166-5.62-9.272-5.62s-9.1,5.391-9.272,5.62a.62.62,0,0,0,0,.731c.168.23,4.166,5.62,9.272,5.62s9.1-5.391,9.272-5.62A.619.619,0,0,0,18.664,98.455Zm-9.272,5.113c-3.761,0-7.019-3.578-7.983-4.748.963-1.171,4.214-4.747,7.983-4.747s7.019,3.577,7.983,4.748C16.412,99.992,13.161,103.568,9.391,103.568Z" transform="translate(0 -92.835)" fill="#373737"/>
                </g>
            </g>
            <g id="Group_1723" data-name="Group 1723" transform="translate(5.676 2.27)">
                <g id="Group_1722" data-name="Group 1722" transform="translate(0 0)">
                <path id="Path_11773" data-name="Path 11773" d="M158.437,154.725a3.715,3.715,0,1,0,3.715,3.715A3.72,3.72,0,0,0,158.437,154.725Zm0,6.192a2.477,2.477,0,1,1,2.477-2.477A2.48,2.48,0,0,1,158.437,160.917Z" transform="translate(-154.722 -154.725)" fill="#373737"/>
                </g>
            </g>
        </svg>
    )
}

export function DownloadIcon() {
    return (
        <svg id="download" xmlns="http://www.w3.org/2000/svg" width="16.12" height="17.361" viewBox="0 0 16.12 17.361">
            <g id="Group_1620" data-name="Group 1620" transform="translate(0 11.161)">
                <g id="Group_1619" data-name="Group 1619" transform="translate(0)">
                <path id="Path_11679" data-name="Path 11679" d="M32.567,307.2a.62.62,0,0,0-.62.62v3.72a.62.62,0,0,1-.62.62h-12.4a.62.62,0,0,1-.62-.62v-3.72a.62.62,0,1,0-1.24,0v3.72a1.86,1.86,0,0,0,1.86,1.86h12.4a1.86,1.86,0,0,0,1.86-1.86v-3.72A.62.62,0,0,0,32.567,307.2Z" transform="translate(-17.066 -307.2)"/>
                </g>
            </g>
            <g id="Group_1622" data-name="Group 1622" transform="translate(4.348)">
                <g id="Group_1621" data-name="Group 1621" transform="translate(0)">
                <path id="Path_11680" data-name="Path 11680" d="M143.979,10.722a.62.62,0,0,0-.861,0l-2.043,2.042V.62a.62.62,0,0,0-1.24,0V12.764l-2.042-2.042a.62.62,0,0,0-.877.877l3.1,3.1a.62.62,0,0,0,.877,0h0l3.1-3.1A.62.62,0,0,0,143.979,10.722Z" transform="translate(-136.742)"/>
                </g>
            </g>
        </svg>
    )
}