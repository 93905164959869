import axios from "axios";
import Cookies from "js-cookie";
import React,{ useState,useEffect} from "react";
import { Document, Page, pdfjs } from "react-pdf";
//import ControlPanel from "./controlPanel";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function PdfViewer({ videoId,sub_section_file }) {
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(null);
  const [scaleVal, setScaleVal] = useState(1);
  const isFirstPage = 1 === pageNumber;
  const isLastPage = numPages === pageNumber;
//last page
  useEffect(()=>{
    //console.log(pageNumber,numPages)
   if(pageNumber==numPages){
    sendStatus(353)
     console.log('last page')
   }
  },[pageNumber,numPages])

  function firstpage() {
    if (!isFirstPage) setPageNumber(1);
  }
  function previouspage() {
    if (!isFirstPage) setPageNumber(pageNumber - 1);
  }
  function nextpage() {
    if (!isLastPage) setPageNumber(pageNumber + 1);
  }
  function lastpage() {
    if (!isLastPage) setPageNumber(numPages);
    
  }
  function sendStatus(time){
    let jsonData={
      "seconds": time
  }
        axios.put('https://api.ezioskillacademy.com/api/user/video/monitor/'+videoId,jsonData,{headers:{
          Authorization:`Bearer ${Cookies.get('token')}`
        } })
        .then(res=>{
          // console.log("res",res.data)     
      }).catch(e=>{
         alert(e.response.data.message)
      })
  }

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }


  return (
    <div className="pdf_viewer">
     
        <div className="pdf_control_panel">
        
          <i className="fa fa-fast-backward" onClick={firstpage}></i>
          <i className="fa fa-backward" onClick={previouspage}></i>
          {/* <p>
            {select}.pdf [{pageNumber}/{numPages}]
          </p> */}
          <i className="fa fa-forward" onClick={nextpage}></i>{" "}
          <i className="fa fa-fast-forward" onClick={lastpage}></i>
       
       
      </div>
        <Document
       //file={sub_section_file}
        file="https://ezio-video-courses.s3.ap-south-1.amazonaws.com/sub_section_file-1653998991646.pdf"
        //  file='https://lms-offline-frontend.eziosolutions.com/Ezio_Skill_Academy.pdf'
       //   file="https://decentraland.org/whitepaper.pdf"
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page pageNumber={pageNumber}  />
        </Document>
      </div>
    
  );
}
//https://decentraland.org/whitepaper.pdf
export default PdfViewer;
