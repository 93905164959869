import axios from 'axios';
import React,{useState,useEffect} from 'react'
import CustomModal from './CustomModal';
import Cookies from 'js-cookie';

const headers={
  Authorization:`Bearer ${Cookies.get('token')}`
}

function Certificate(props) {  
  const [selectedUser,setSelectedUser] = useState()
  const [userList, setUserList] = useState([])

  useEffect(() =>{
    GetUserList()
  },[])
  
  const GetUserList = (Props) =>{
    axios.get(`https://api.ezioskillacademy.com/api/admin/certification/eligibile/user`,{headers:{
      Authorization:`Bearer ${Cookies.get('token')}`
    }})
    .then(res => {
      setUserList(res.data.data)
      // console.log('data',res.data.data)
    }).catch((err)=>{
      alert("Something Wrong.. Please Login again...")
    })
  }

  const setNextPage=(user)=>{
    // console.log('User Edit',user)     
    setSelectedUser(user)   
  }

  return (
    <div className="dash_page_bg dash_section_ptb add_payment">
      <h1>User List for Certification</h1>
      <br />
      <div className="clientrate_table">
        <div className="tabcomp-style">
          { selectedUser ? (
              <CertificateList user={selectedUser}/>
              // null
            ):(
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <td>Name</td>
                    <td>Email</td>
                    <td style={{width:'200px'}}></td>                   
                  </tr>
                </thead>
                <tbody>
                  {userList.length > 0 ? (
                    <>
                      {userList
                        .map((user, id) => (
                          <tr key={id}>
                            <td>{user.name}</td>
                            <td>
                              {user.email}
                            </td>
                            <td style={{display:'flex',justifyContent:'center',width:'200px'}}>
                                <button onClick={()=>setNextPage(user)} style={{backgroundColor:'blue',color:'#fff',borderRadius:'15px',padding:'5px 10px',border:'transparent',margin:'0 10px'}}>select</button>
                                </td>                          
                          </tr>
                        ))}
                    </>
                  ) : (
                    <tr>
                      <td colSpan="3" style={{ textAlign: "center" }}>
                        No data
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          )
          }
        </div>
      </div>
    </div>
  )
}

export default Certificate;

const CertificateList =(Props)=>{
  const [certifications, setCertification] = useState();
  const [editPopup,setEditPopup] = useState(false)
  const [ cer_img, setCerImg ] = useState();
  const [ course_type, setCourseType ] = useState();

  useEffect(() => {
    // console.log(Props)
    getCertificationlisUser()
  }, []);

  const getCertificationlisUser = () => {
    axios.get(`https://api.ezioskillacademy.com/api/admin/certificate/upload/${Props.user._id}`, { headers:{
      Authorization:`Bearer ${Cookies.get('token')}`
    } })
      .then((res) => {
        setEditPopup(false);
        setCerImg('');
        setCourseType('');
        setCertification(res.data.data)
      })
      .catch((err) => {
        // console.log(err)
      })
  };

  const setEditPopupOpen=(img, type)=>{
    setEditPopup(true);
    setCerImg(img);
    setCourseType(type)
  }

  const handleEditValue = (e) => {
    setCerImg(e.target.files[0])
  }

  const submitCertificate = () => {
    var formdata = new FormData();
    formdata.append('field' , course_type)
    formdata.append('student_name' , `${Props.user.first_name} ${Props.user.last_name}`)
    formdata.append('student_id' , Props.user._id)
    formdata.append('certificate_file' , cer_img)
    formdata.append('course_name' , course_type)

    axios.post(`https://api.ezioskillacademy.com/api/admin/certificate/upload`,
      formdata,
      { headers:{
        Authorization:`Bearer ${Cookies.get('token')}`
      } }
    )
    .then((res) => {
      getCertificationlisUser()
      
    })
    .catch((err) => {
      // console.log(err)
    })
  }

  const handleClosePopup = () => {
    setEditPopup(false)
    setCerImg('');
    setCourseType('');
  }
  function sendMail(certi){
      // console.log(certi,Props)
      axios.get(`https://api.ezioskillacademy.com/api/admin/certification/${Props.user._id}`,{ headers:{
        Authorization:`Bearer ${Cookies.get('token')}`
      } }
    )
    .then((res) => {
      // console.log("mail",res.data)
      
    })
    .catch((err) => {
      // console.log(err)
    })
  }

  return(
    <React.Fragment>
      <button className="button-35" role="button" onClick={ () => window.location.reload() }>Back</button>
      <div style={{display:'flex',justifyContent:'space-between'}}>
      <h1>{Props.user.first_name} {Props.user.last_name}</h1>
      
      <button
       onClick={()=>sendMail(certifications, 'ezio_skill')}
       style={{backgroundColor:'green',color:'#fff',height:'40px',borderRadius:'15px',padding:'5px 10px',border:'transparent',margin:'0 10px'}}
     >
       Send Mail
     </button>
      </div>
    
                  
      <div className="clientrate_table">
        <div className="tabcomp-style">
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <td>Course Name</td>
                  <td>Certificate URL</td>
                  <td style={{width:'200px'}}></td>                   
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td> Ezios Skill Academy </td>
                  <td> { certifications?.ezio_skill ? certifications.ezio_skill : 'No Data' }</td>
                  <td style={{display:'flex',justifyContent:'center',width:'200px'}}>
                    <button
                      onClick={()=>setEditPopupOpen(certifications.ezio_skill, 'ezio_skill')}
                      style={{backgroundColor:'blue',color:'#fff',borderRadius:'15px',padding:'5px 10px',border:'transparent',margin:'0 10px'}}
                    >
                      Edit
                    </button>
                  </td>
                </tr>
                <tr>
                  <td> Ezios Audit Tool </td>
                  <td> { certifications?.ezio_audit ? certifications.ezio_audit : 'No Data' }</td>
                  <td style={{display:'flex',justifyContent:'center',width:'200px'}}>
                    <button
                      onClick={()=>setEditPopupOpen(certifications.ezio_audit, 'ezio_audit')}
                      style={{backgroundColor:'blue',color:'#fff',borderRadius:'15px',padding:'5px 10px',border:'transparent',margin:'0 10px'}}
                    >
                      Edit
                    </button>
                  </td>
                </tr>
                <tr>
                  <td> Google Analytics Certification </td>
                  <td> { certifications?.google_analytics ? certifications.google_analytics : 'No Data' }</td>
                  <td style={{display:'flex',justifyContent:'center',width:'200px'}}>
                    <button
                      onClick={()=>setEditPopupOpen(certifications.google_analytics, 'google_analytics')}
                      style={{backgroundColor:'blue',color:'#fff',borderRadius:'15px',padding:'5px 10px',border:'transparent',margin:'0 10px'}}
                    >
                      Edit
                    </button>
                  </td>
                </tr>
                <tr>
                  <td> Google Ads Certification </td>
                  <td> { certifications?.google_ads ? certifications.google_ads : 'No Data' }</td>
                  <td style={{display:'flex',justifyContent:'center',width:'200px'}}>
                    <button
                      onClick={()=>setEditPopupOpen(certifications.google_ads, 'google_ads')}
                      style={{backgroundColor:'blue',color:'#fff',borderRadius:'15px',padding:'5px 10px',border:'transparent',margin:'0 10px'}}
                    >
                      Edit
                    </button>
                  </td>
                </tr>
                <tr>
                  <td> Hubspot Certification </td>
                  <td> { certifications?.hubspot ? certifications.hubspot : 'No Data' }</td>
                  <td style={{display:'flex',justifyContent:'center',width:'200px'}}>
                    <button
                      onClick={()=>setEditPopupOpen(certifications.hubspot, 'hubspot')}
                      style={{backgroundColor:'blue',color:'#fff',borderRadius:'15px',padding:'5px 10px',border:'transparent',margin:'0 10px'}}
                    >
                      Edit
                    </button>
                  </td>
                </tr>
                <tr>
                  <td> Linkedin Certification </td>
                  <td> { certifications?.linkedin ? certifications.linkedin : 'No Data' }</td>
                  <td style={{display:'flex',justifyContent:'center',width:'200px'}}>
                    <button
                      onClick={()=>setEditPopupOpen(certifications.linkedin, 'linkedin')}
                      style={{backgroundColor:'blue',color:'#fff',borderRadius:'15px',padding:'5px 10px',border:'transparent',margin:'0 10px'}}
                    >
                      Edit
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>




      <CustomModal open={editPopup}>
        <div className="scsmdl-container" style={{ height: "auto" }}>
          <div className="scsmdl-header">
            <p>Upload Certificate</p>
            <img
              src={"/modalclose.jpg"}
              className="scsmdl-closeimg"
              onClick={() => handleClosePopup() }
              alt="close img"
            />
          </div>
          <div className="scsmdl-body" style={{ padding: "20px" }}>
          <div style={{ marginBottom: "20px" }}>
            Certificate: { cer_img ? <img src={ cer_img } height = "100"/> : 'No Certificate Found'}
          </div>
          <div className="form-group">
              <label>File:</label>
              <div style={{display:'flex'}}>
              <input
                className="form-control"
                type="file"
                name="file"
                onChange={(e) => handleEditValue(e)}
                // value={fieldsEdit.first_name}
                placeholder="First Name"
                accept="image/*"
              />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-5">
                <button
                  onClick = { submitCertificate }
                  className="addpayment-submitbtn"
                >
                  Submit
                </button>
              </div>
            
            </div>
          </div>
        </div>
      </CustomModal>
    </React.Fragment>
  )
}