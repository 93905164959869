import React, { Component,Fragment, useEffect, useRef, useState } from 'react';
import './Dashboard.css'
import axios from 'axios';
import {useSelector,useDispatch} from 'react-redux'
import { Redirect } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import view from '../../images/view.png';
import signup_bg from '../../images/signup_bg.png';
import left_arrow from '../../images/left-arrow.png';
import site_logo from '../../images/website/ezio_academy_logo.svg';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie'
import $ from 'jquery'
import PackagePopup from '../website/packagePopup';


export default function Login(){
  const reduxValue=useSelector(state=>state)
  const dispatch=useDispatch()

  const [email,setEmail]=useState()
  const [password,setPassword]=useState()

  const history=useHistory()
	const loginErrRef = useRef();

  const [mblshow, Getmblshow] = useState(false);
  const [checked, setChecked] = useState(false)
  const [errors, Geterrors] = useState({});
  const [emailVal, setEmailVal] = useState('');
	const [passVal, setPassVal] = useState('');
  const [fields] = useState({});

  const [packagePopup,setPackagePopup]= useState(false);

  useEffect(()=>{
    // console.log(reduxValue)
    //setTimeout(()=>{
      
      if(reduxValue){
       // setState(reduxValue.username)
      }else{
        // dispatch({
        //   type:'USER_DETAIL',
        //   payload:"Siva from Reduxxx"
        // })
      }
     
      setTimeout(()=>{
        // console.log(reduxValue) 

      },2000)
     
    //},2000)
  },[reduxValue])

    // function HandleSignin(e){
    //     e.preventDefault()
    //       // console.log('logged',email,password)
    //        history.push('/dashboard/admin/users')
    // }
   
    // function handleChangeInput(e){
    //   let emailTemp=''
    //   let passwordTemp=''
    //    setEmail(document.getElementById('email').value)
    //     emailTemp=document.getElementById('email').value  
    //     setPassword(document.getElementById('password').value)
    //     passwordTemp=document.getElementById('password').value
    //    if(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailTemp)&&passwordTemp.length>0){
       
    //     document.getElementById('submit_btn').classList.add('btn_active')
        
    //   }      
    // }
    const Login = (e) => {
      e.preventDefault();
      if (checked) {
        Cookies.set('email', emailVal, { expires: 1 })
        Cookies.set('password', passVal, { expires: 1 })
      }
     // history.push('/dashboard/admin/jdjd')
      // console.log("cred",emailVal,passVal)
      if (validateLogin()) {        
        axios.post(`https://api.ezioskillacademy.com/api/login`,
        // axios.post(`http://localhost:3000/api/login`,
          {
            email:emailVal,
            password:passVal,
          }
        ).then((response) => {
          // console.log("RES:",response.data)
          dispatch({
            type:'USER_DETAILS',
            userdetails:response.data
          })
          localStorage.setItem('user_id',response.data.user_id)
          Cookies.set('token', response.data.token.access_token, { expires: 1 })
          if(response.data.type_of_user==='super_admin'){
            localStorage.setItem('user_name','super admin')
            localStorage.setItem('isSuperadmin',true)
            history.push('/superadmin/user-management')
          }else if(response.data.type_of_user==='user'){
            localStorage.setItem('user_name',response.data.name)
            localStorage.setItem('isUser',true)
            localStorage.setItem('batch_id',response.data.batch_id)
            localStorage.setItem('mentor_name',response.data.mentor_name)
            if (response.data?.mentor_id) {
              localStorage.setItem('mentor_id',response.data.mentor_id)
            }
             if(response.data.isPaid=='completed' || response.data.isPaid=="partialy_paid"){              
              history.push('/course/learn')
            }else{
              $('#failure_msg').html('You have not paid for this course...')
              $('#failure_msg').fadeIn()
              //setPackagePopup(true)
              // console.log("not paid.......  ")
            }
          }else if(response.data.type_of_user==='admin'){
            localStorage.setItem('user_name','admin')
            localStorage.setItem('isAdmin',true)
            history.push('/admin/user-management')
          }
        }).catch((error) => {
          // console.log("RESERR:",error)
          $('#failure_msg').fadeIn()

        })
      }

    }
    const validateLogin = () => {
      let errors = {};
      let formIsValid = true;
      if (!fields["email"]) {
        formIsValid = false;
        errors["email"] = "*Enter your Email";
      }
      if (!fields["passwords"]) {
        formIsValid = false;
        errors["passwords"] = "*Enter your password";
      }
      Geterrors(errors)
      return formIsValid;
    } 
    const handlePassChange = (e) => {
      fields[e.target.name] = e.target.value;
      setPassVal(e.target.value);
    }
    const changePass = (e) => {
      // console.log(e)
      if (e === 1) {
        Getmblshow(false)
      } else if (e === 2) {
        Getmblshow(true)
      }
    }
    const handleEmailChange = (e) => {
      // console.log(typeof (e.target.name), typeof (e.target.value));
      fields[e.target.name] = e.target.value;
      setEmailVal(e.target.value);
    }
    return(
        <section className="signin_container">
          <div className="signup-banner">
         	<div className="container signup-container">
				<div className="row align-items-end">
					<div className="col-md-6 col-lg-6 offset-lg-1">
						<form className="signup-form" onSubmit={e => Login(e)}>
							<div className="my-4 row">
								<div className="col-xs-12 col-md-5 col-lg-5 nowrap-txt">
									{/* <a href='https://www.eziosolutions.com' target="_blank" rel="noopener noreferrer"><span className="login-top"><img src={left_arrow} alt="" />&nbsp;&nbsp;Return to EZIO.COM</span></a> */}
								</div>
							
							</div>
							<div className="my-3" style={{ 'text-align': 'center' , 'width':'150px', 'margin':'0 auto'}}>
                <a href='/' style={{cursor:'pointer'}}><img src={site_logo} alt="" /></a>
								
							</div>
							<h2 className="login-heading">Ezio's AI Powered Access For <br /> Your Business Growth</h2>
							<div className="err-msg-common-tab" ref={loginErrRef}>Email or Password entered is incorect!</div>
							{/* <div className="err-msg-common-tab" ref={otpErrRef}>Please Contact Customer Support</div> */}
							<input type="email" id="defaultLoginFormEmail" name='email' value={emailVal} className="form-control" placeholder="Email" onChange={handleEmailChange} />
							<div className="errorMsg" style={{color:'red'}}>{errors.email}</div>
              <br/>
							{
								mblshow ?
									<div>
										<input type="password" id="defaultLoginFormPassword" name='passwords' className="form-control" placeholder="Password" onChange={handlePassChange} /><span className="errspan signup-eye" onClick={e => changePass(1)}><img src={view} alt="" /></span>
									</div>
									:
									<div>
										<input type="text" id="defaultLoginFormPassword" name='passwords' value={passVal} className="form-control" placeholder="Password" onChange={handlePassChange} /><span className="errspan signup-eye" onClick={e => changePass(2)}><img src={view} alt="" /></span>
									</div>
							}
							<div className="errorMsg" style={{color:'red'}}>{errors.passwords}</div>
              <br/>
							<div className="set_checkbox">
								<input id="remember" type="checkbox" name='remember' onClick={() => setChecked(!checked)} checked={checked} /><span className="checkspan">Remember me</span>
							</div>
							<button className="btn btn-block my-4 bluebtn" type="submit">Login</button>
              <p id="failure_msg" style={{color:'red',display:'none'}}>Enter the Valid username or password</p>
							{/* <div className="links" style={{ "text-align": "center" }}>Forgot Your Password?  <Link to="/reset-password">Reset</Link></div> */}
						</form>
					</div>
					<div className="col-md-6 col-lg-5 image-col">
						<img src={signup_bg} alt="" />
					</div>
				</div>
			</div>
      </div>
      <PackagePopup packagePopup={packagePopup} setPackagePopup={setPackagePopup}/>
        </section>
      
    )
}