import React from 'react'


const Lessons = () =>{
	return(
	    <div>
		   <p>Lessons</p>
		</div>
	)
}

export default Lessons