import React from 'react'

export default function LogoutIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="59.061" height="59.061" viewBox="0 0 59.061 59.061">
      <g id="Log_Out" data-name="Log Out" transform="translate(-398 -891)">
        <path id="Path_14028" data-name="Path 14028" d="M29.531,0A29.531,29.531,0,1,1,0,29.531,29.531,29.531,0,0,1,29.531,0Z" transform="translate(398 891)" fill="#efefef"/>
        <g id="logout" transform="translate(413.986 909)">
          <g id="Group_4168" data-name="Group 4168" transform="translate(0.014)">
            <g id="Group_4167" data-name="Group 4167" transform="translate(0)">
              <path id="Path_14029" data-name="Path 14029" d="M309.851,153.412a1.139,1.139,0,0,0-.244-.367l-3.376-3.376a1.126,1.126,0,0,0-1.592,1.592l1.456,1.456h-6.289a1.126,1.126,0,0,0,0,2.252h6.289l-1.456,1.456a1.126,1.126,0,1,0,1.592,1.592l3.376-3.376a1.116,1.116,0,0,0,.244-.367A1.127,1.127,0,0,0,309.851,153.412Z" transform="translate(-282.919 -141.458)" fill="#143059"/>
              <path id="Path_14030" data-name="Path 14030" d="M19.152,15.761a1.125,1.125,0,0,0-1.126,1.126v5.629h-4.5V4.5a1.127,1.127,0,0,0-.8-1.078L8.811,2.252h9.216V7.88a1.126,1.126,0,0,0,2.252,0V1.126A1.125,1.125,0,0,0,19.152,0H1.14a1,1,0,0,0-.116.021A1.112,1.112,0,0,0,.547.188C.522.2.491.2.468.223S.455.242.446.249A1.115,1.115,0,0,0,.147.6.919.919,0,0,0,.115.7,1.068,1.068,0,0,0,.026.963a.7.7,0,0,0,0,.1c0,.023-.016.043-.016.065V23.641a1.125,1.125,0,0,0,.905,1.1L12.177,27a1.048,1.048,0,0,0,.221.023,1.127,1.127,0,0,0,1.126-1.126V24.767h5.629a1.125,1.125,0,0,0,1.126-1.126V16.887A1.125,1.125,0,0,0,19.152,15.761Z" transform="translate(-0.014)" fill="#143059"/>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
