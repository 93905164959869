import React, { useEffect } from 'react'
import Arrow from '../../../../images/iconsSVG/Arrow'
import RedArrow from '../../../../images/iconsSVG/RedArrow'
import $ from 'jquery'
import Slider from "react-slick";
import { ourCourses } from '../data/our_courses';
import MoreCourse from './common/more_courses';


function Seo() {
  useEffect(()=>{

    const sectionAll = document.querySelectorAll("section");
            const observer = new IntersectionObserver(
              (entries) => {
                entries.forEach((entry) => {
                   
                  if (entry.isIntersecting) {
                    
                    entry.target.classList.add("scroll_ani");
                    
                  }
                });
              },
              {
                rootMargin: "-100px",
              }
            );
            sectionAll.forEach((section) => {
              observer.observe(section);
            });

    courseAccordian('1')
  },[])
  const courseSyllabus=[
    {
      id:'1',
      title:' What is Social Media Marketing?  ',
      desc:`Social media marketing is utilizing the power of social media channels and social networks to promote your product or service and website through posting content. It is one of the winning and trending ways to interact and engage with your existing, new customers. `
    },
    {
      id:'2',
      title:'Social Media Management  ',
      desc:`Creating and managing your social media handles is the heart of connecting with your brand's target audience. Learn effective practices to manage business social media accounts, which help to acquire customers and build brand popularity. By the way, you’ve several tools to manage your multiple social accounts at a time.  `
    },
    {
      id:'3',
      title:'What are Social Media Marketing Strategies?  ',
      desc:'Social Media Marketing strategies demand a deep understanding of the brand as well as their prospective leads, and craft interesting content regularly.   A strong social media strategy is the pillar of building social media presence.   '
    },
    {
      id:'4',
      title:'Social Media Analytics  ',
      desc:`Through social media analytics or measurement, you can get a better knowledge of your performance and get to know what's working and what is not. There are several metrics to evaluate social media, like awareness metrics, engagement metrics, number of likes, shares, and comments.  Gathered insights help to enhance your marketing decision. `
    },
    {
      id:'5',
      title: `How to Establish a Successful Brand Community   `,
      desc:`By getting deep into your brand's mission, values, and personality it's easy to set up an audience for your business. Publishing engaging content constantly, being actively respond for your audience’s action.  `
    },
    {
      id:'6',
      title:'Creating Personalized Experience  ',
      desc:`When it comes to social channels, it's crucial to craft customized content to give customers a customized experience.  Over and above all, treating your people individually will help you to hit your goal. Giving more importance to customers' preferences and creating content depending on their pain points. `
    },
    {
      id:'7',
      title:'What are the Best Social Media Channels for Social Media Marketing? ',
      desc:`To be frank, each channel has its own specific usage and benefits. So, yes depending on your business type, target audience, and objectives you can pick the appropriate social media. Anyways, creating business profiles on popular social media platforms is mandatory to grow. `
    },
  ]
  function courseAccordian(id){
    $('.course_syllabus_inner_left ul li').removeClass('course_syllabus_acc_active')
    $('#course_syllabus_acc'+id).addClass('course_syllabus_acc_active')

  }
  const recognized_certification=[
    {
        id:'1',
        name:'Ezio Skill Academy Certificate',
        desc:'Master the Talents You Required for becoming A Marketing Expert in Any verticals across the globe. Experiential learning through advance syllabus, case studies & live project centric. ',  
        img:'Ezio-Skill-Academy.jpg',
    },
    {
        id:'2',
        name:'Ezio Audit Tool Certificate  ',
        desc:'Expertise in search engine audit tools, our hands-on training let you easily analyze the website and know what is working and what is not. Experience the site audit tool usage and turn the website weaker side into a stronger one.  ',  
        img:'Ezio-Skill-Academy.jpg',
    },
    {
        id:'3',
        name:'LinkedIn Certificate',
        desc:'Become exceptionally skilled and gain the knowledge of DM strategy to transform you into a “Qualified Digital Marketer”.',  
        img:'linkedi-certificate-1.jpg',
    },
    {
        id:'4',
        name:'HubSpot Academy Certificate   ',
        desc:'The candidates will gain top-notch skills in implementing digital marketing strategies with high-standard materials and training exercises. A digital marketing certificate from HubSpot Academy is recognized by the universities.   ',  
        img:'hubspot-academy.jpg',
    },
    {
        id:'5',
        name:'Facebook Blueprint Certificate  ',
        desc:'Earn Facebook blueprint certified "digital marketing associate” becoming an expert level proficiency in Facebook advertising. This is excluded in the Course cost & separate cost will be applicable for this certification ',  
        img:'Facebook-Blueprint.jpg',
    },
    {
        id:'6',
        name:'Google Analytics   ',
        desc: `Qualified in fundamental and advanced Google analytics concepts and Google's measurement tools. Google Analytics Certificates make provision for implementing SEO strategy through learning high-in-demand skills.  `,  
        img:'Google-Analytics.jpg',
    },
    {
        id:'7',
        name:'Google Ads  ',
        desc:'It is an industry-recognized marketing certificate from Google. Professional accreditation is provided by Google. Recognized you as a master in Google Ads.     ',  
        img:'adwords.jpg',
    },
    {
        id:'8',
        name:'Bing Ads   ',
        desc:'Knowing the hack to push your business forward from the effective Microsoft advertising campaigns and enhance your skillset on ads through this program.  With the Bind ads certification, upskill your Microsoft advertising proficiency. ',  
        img:'Bing-Ads-certification.jpg',
    },
]

const certificate_settings = {
  dots: true,
  infinite: true,
  speed: 500,
  autoplay: false,
  slidesToShow: 1,
  slidesToScroll: 1
};


  return (
    <div id='courses'>
    <section className="banner_sec">     
<div className="container">
 <div className="row">
           <div className="col-lg-6 col-md-6 centered_content website_left_inner_banner">
                {/* <p>Academic &gt; &nbsp;<span>Digital Marketing </span></p> */}
                <h1>
                Social Media Marketing 
                </h1> 
                <p style={{margin:'20px 0'}}> 
                Learn how to use multiple social media channels to promote business and reach your target audience. Building a tailored-made social media strategy for your prospective leads. Create a brand identity and expand your online audience on Facebook, Instagram, YouTube, Twitter, and LinkedIn. 
                </p>
                <div style={{display: "flex"}}>
                <a href='#syllabus'>
                    <button className="btn btn-outline-primary my-2 my-sm-0 ml-3 active_btn apply_now_btn" type="submit"> Explore Course </button> 
                </a>
                </div> 
            </div>

            <div className="col-lg-6 col-md-6" >
                <img className='website_banner_standing_girl' src={require('../../../../images/website/Courses_page/women-png.png')} alt='' style={{display:'block',margin:'auto'}} />
                <img className='banner_round_icon' src={require('../../../../images/website/Courses_page/1.png')} alt='' style={{left:'8%',top:'10%',filter: 'drop-shadow(0px 9px 14px rgba(50, 50, 50, 0.15))'}}/>
                <img className='banner_round_icon' src={require('../../../../images/website/Courses_page/archer.png')} alt='' style={{right:'10%',top:'16%'}}/>
                <img className='banner_round_icon' src={require('../../../../images/website/Courses_page/peoples.png')} alt='' style={{right:'5%',bottom:'10%',height:'65px'}}/>
                <img className='banner_round_icon' src={require('../../../../images/website/Courses_page/analytics.png')} alt='' style={{left:'15%',bottom:'5%',height:'65px',filter: 'drop-shadow(0px 9px 14px rgba(50, 50, 50, 0.15))'}}/>
              </div>
 </div>
</div>
</section>


<section id='about' className='about_free_certification'>
  <div className='container'>
      <div className='row' style={{display:'flex',flexWrap:'wrap-reverse',justifyContent:'center'}}>
      {/* <div className='col-lg-6'>
        <div className='about_free_certification_left_inner'>
            <div className='hos_small_box'>
              <h3>130+</h3>
              <p>Lorem Etiam vel ullamcorper augue</p>
            </div>
            <div className='hos_small_box'>
              <h3>130+</h3>
              <p>Lorem Etiam vel ullamcorper augue</p>
            </div>
            <div className='hos_small_box'>
              <h3>130+</h3>
              <p>Lorem Etiam vel ullamcorper augue</p>
            </div>
            <div className='hos_small_box'>
              <h3>130+</h3>
              <p>Lorem Etiam vel ullamcorper augue</p>
            </div>
            </div>
      </div> */}
      <div className='col-lg-12'>
          <h2 >About This Social Media Marketing Course     </h2>
          <p>The Internet is the root of marketing, in the evolution of technology, digital marketing has become a trend set in the world of business. So, yes, all the companies and brands have started to familiarize themselves with it and create their brand value. Social media marketing is one of the quickly growing spheres currently. You know what, it's actually a mandatory skill that has an abundant opportunity that everyone must be aware of. In this course, you'll get to know about the usage of social media marketing from the start. You'll learn about how to plan, manage, publish, and monitor social media marketing for business accounts. Most importantly, it lets you understand about building a social community for your audience on multiple platforms, personalized experience, 
            <br/><br/>
            This course will help you to set a complete social media strategy and how to build an online audience for your brand on various social channels.  Enroll in Ezio Skill Academy’s social media marketing course and know the tricks to earn and win on social media platforms. 
          </p>
      </div>
      </div>
  </div>
</section>


<section id='skill_tools' className='skills_and_tools'>
  <div className='container'>
    <h2>Skills and Tools</h2>
    <p>This online Social Media Marketing course is crafted to assist you to develop the skills demanded social media marketing via in-depth project experience.   </p>
      <div className='row'>
      <div className='col-lg-6 '>
            <h3>Skills</h3>
            <ul className='skills_and_tools_inner'>
              <li><RedArrow/> Social Media Marketing   </li>
              <li><RedArrow/> Setting Up Social Media Community   </li>
              <li><RedArrow/>Social Strategy     </li>
              <li><RedArrow/> Building an Online Audience  </li>
              <li><RedArrow/> Analytics  </li>
              <li><RedArrow/> Boost Brand Awareness  </li>
              <li><RedArrow/> Social Media Content Creation   </li>
              <li><RedArrow/>Marketing in Social channels  </li>
              <li><RedArrow/> Social Media Monitoring  </li>
              <li><RedArrow/> Online Reputation Management  </li>
            </ul>
    
      </div>
      <div className='col-lg-6'>
          <h3>Tools</h3>
          <div className='skill_tools_con'>
        
            <div className='skill_tools'>
                <img src={require('../../../../images/website/Tools-you-wil-master/google-ads.png')} alt=""/>
            </div>
            <div className='skill_tools'>
                <img src={require('../../../../images/website/Tools-you-wil-master/google-anylitsis.png')} alt=""/>
            </div>  
            <div className='skill_tools'>
                <img src={require('../../../../images/website/Tools-you-wil-master/fb-ads.png')} alt=""/>
            </div>
            <div className='skill_tools'>
                <img src={require('../../../../images/website/Tools-you-wil-master/google-tag-manager.png')} alt=""/>
            </div>
            <div className='skill_tools'>
                <img src={require('../../../../images/website/Tools-you-wil-master/linkedin-ads.png')} alt=""/>
            </div>
            <div className='skill_tools'>
                <img src={require('../../../../images/website/Tools-you-wil-master/google-search-console.png')} alt=""/>
            </div>
            <div className='skill_tools'>
                <img src={require('../../../../images/website/Tools-you-wil-master/twitter-ads.png')} alt=""/>
            </div>
            <div className='skill_tools'>
                <img src={require('../../../../images/website/Tools-you-wil-master/mailchimp.png')} alt=""/>
            </div>
        </div>
        </div>
      </div>
  </div>
</section> 

<section id='syllabus' className='course_syllabus'>
  <div className='container'>
      <div className='row' style={{display:'flex',flexWrap:'wrap-reverse'}}>
      <div className='col-lg-6 course_syllabus_inner_left'>
            <ul>
              {
                courseSyllabus.map((course)=>(
                  <li id={'course_syllabus_acc'+course.id} onClick={()=>courseAccordian(course.id)} key={course.id}>
                  <h4> {course.title} </h4>
                  <p>{course.desc}</p>
                  </li>
                ))
              }
              
            </ul>
    
      </div>
      <div className='col-lg-6' style={{display:'flex',flexDirection:'column',justifyContent:'center'}}>
         <h2>Course Syllabus</h2>
         <p>Introduction to Social Media Marketing, Overview of Facebook, Instagram, LinkedIn, YouTube. Twitter Social Media Strategy & Planning, Social Media Channel Creation & Management, Marketing Tactics for Multiple Media, Social Media Management Tools, Content Strategy. </p>
         <a href='/Ezio_Skill_Academy.pdf' download>
         <button>Download Syllabus</button>
         </a>
      </div>
      </div>
  </div>

</section>

<section id='certification' className="website_certificate section_spaces">

    <div className="container">
        <h2> Course Certificate   </h2>
        <p>After all, completing your SMM course, you’ll acquire an industry-recognized Social Media Marketing certificate from Ezio Skill Academy. It will assist you to shape your social media marketing skills and give you a deep understanding on how to attract your digital audience.    </p>
    <Slider {...certificate_settings}>
        {
            recognized_certification.map((certi,index)=>(
        <div key={index}>
        <div className='row'>
            <div className='col-lg-6 website_certificate_left_inner'>
                <h2>{certi.name}</h2>
                <p>{certi.desc}</p>
            </div>
            <div className='col-lg-6'>
                <img src={require(`../../../../images/website/certificate/${certi.img}`)} alt=""/>
            </div>
        </div>     
        </div> 
            ))
        }
        
      </Slider>
        </div>
    </section>

    <MoreCourse url={'smm'}/>


</div>
  )
}

export default Seo



{/* <section className=''>
  <div className='container'>
      <div className='row'>
      <div className='col-lg-6 '>
            
    
      </div>
      <div className='col-lg-6'>
         
      </div>
      </div>
  </div>

</section> */}