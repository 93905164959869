import React from 'react'

export default function ClientsIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="28.099" height="14.928" viewBox="0 0 28.099 14.928">
      <g id="Clients" transform="translate(-0.495 -9.973)">
        <path id="XMLID_2638_" d="M17.116,105.219v-.878a7.564,7.564,0,0,1,1.124-3.977,4.865,4.865,0,0,0-.95-.508,2.634,2.634,0,1,0-3.569,0,4.836,4.836,0,0,0-3.044,4.486v.878h6.439Z" transform="translate(-10.182 -80.319)" fill="#fff" />
        <path id="XMLID_2639_" d="M362.6,104.34v.878h6.439v-.878a4.836,4.836,0,0,0-3.044-4.486,2.634,2.634,0,1,0-3.569,0,4.872,4.872,0,0,0-.95.508A7.565,7.565,0,0,1,362.6,104.34Z" transform="translate(-340.442 -80.318)" fill="#fff" />
        <path id="XMLID_2731_" d="M162.385,24.023h0a5.856,5.856,0,0,0-3.785-5.477,4.537,4.537,0,1,0-4.138,0,5.856,5.856,0,0,0-3.785,5.477V24.9h11.708Z" transform="translate(-141.987)" fill="#fff" />
      </g>
    </svg>
  )
}
