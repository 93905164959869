import React from "react";

export function InstagramIcon() {
  return (

      <svg
        id="instagram_1_"
        data-name="instagram (1)"
        xmlns="http://www.w3.org/2000/svg"
        width="27.703"
        height="27.709"
        viewBox="0 0 27.703 27.709"
      >
        <path
          id="Path_14004"
          data-name="Path 14004"
          d="M28.081,8.146a10.125,10.125,0,0,0-.644-3.361A7.1,7.1,0,0,0,23.383.731,10.151,10.151,0,0,0,20.022.087C18.538.016,18.068,0,14.306,0S10.073.016,8.595.081A10.128,10.128,0,0,0,5.234.725a6.761,6.761,0,0,0-2.458,1.6,6.822,6.822,0,0,0-1.6,2.452A10.152,10.152,0,0,0,.535,8.141C.465,9.624.449,10.1.449,13.857S.465,18.09.53,19.568a10.124,10.124,0,0,0,.644,3.361,7.1,7.1,0,0,0,4.054,4.054,10.152,10.152,0,0,0,3.361.644c1.478.065,1.949.081,5.711.081s4.233-.016,5.711-.081a10.122,10.122,0,0,0,3.361-.644,7.087,7.087,0,0,0,4.054-4.054,10.159,10.159,0,0,0,.644-3.361c.065-1.478.081-1.949.081-5.711s-.005-4.233-.07-5.711Zm-2.5,11.313a7.6,7.6,0,0,1-.476,2.571,4.6,4.6,0,0,1-2.631,2.631,7.622,7.622,0,0,1-2.571.476c-1.461.065-1.9.081-5.6.081s-4.141-.016-5.6-.081a7.591,7.591,0,0,1-2.571-.476,4.264,4.264,0,0,1-1.591-1.034,4.308,4.308,0,0,1-1.034-1.591,7.623,7.623,0,0,1-.476-2.571c-.065-1.461-.081-1.9-.081-5.6s.016-4.141.081-5.6A7.591,7.591,0,0,1,3.518,5.7,4.212,4.212,0,0,1,4.557,4.108,4.3,4.3,0,0,1,6.148,3.075,7.627,7.627,0,0,1,8.72,2.6c1.461-.065,1.9-.081,5.6-.081s4.141.016,5.6.081a7.6,7.6,0,0,1,2.571.476,4.261,4.261,0,0,1,1.591,1.034A4.307,4.307,0,0,1,25.11,5.7a7.625,7.625,0,0,1,.476,2.571c.065,1.461.081,1.9.081,5.6s-.016,4.13-.081,5.592Zm0,0"
          transform="translate(-0.449 0)"
        />
        <path
          id="Path_14005"
          data-name="Path 14005"
          d="M132.067,124.5a7.118,7.118,0,1,0,7.118,7.118A7.12,7.12,0,0,0,132.067,124.5Zm0,11.735a4.617,4.617,0,1,1,4.617-4.617A4.618,4.618,0,0,1,132.067,136.235Zm0,0"
          transform="translate(-118.21 -117.761)"
        />
        <path
          id="Path_14006"
          data-name="Path 14006"
          d="M365.773,90.264a1.662,1.662,0,1,1-1.662-1.662A1.662,1.662,0,0,1,365.773,90.264Zm0,0"
          transform="translate(-342.855 -83.806)"
        />
      </svg>
  
  );
}
export function InstagramLogo() {
  return (
    <div>
      {/* <img src="/instagramLogo.png" /> */}

      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="102"
        height="102"
        viewBox="0 0 102 102"
      >
        <defs>
          <radialGradient
            id="a"
            cx="6.601"
            cy="99.766"
            r="129.502"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset=".09" stopColor="#fa8f21" />
            <stop offset=".78" stopColor="#d82d7e" />
          </radialGradient>
          <radialGradient
            id="b"
            cx="70.652"
            cy="96.49"
            r="113.963"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset=".64" stopColor="#8c3aaa" stopOpacity="0" />
            <stop offset="1" stopColor="#8c3aaa" />
          </radialGradient>
        </defs>
        <path
          fill="url(#a)"
          d="M25.865,101.639A34.341,34.341,0,0,1,14.312,99.5a19.329,19.329,0,0,1-7.154-4.653A19.181,19.181,0,0,1,2.5,87.694,34.341,34.341,0,0,1,.364,76.142C.061,69.584,0,67.617,0,51s.067-18.577.361-25.14A34.534,34.534,0,0,1,2.5,14.312,19.4,19.4,0,0,1,7.154,7.154,19.206,19.206,0,0,1,14.309,2.5,34.341,34.341,0,0,1,25.862.361C32.422.061,34.392,0,51,0s18.577.067,25.14.361A34.534,34.534,0,0,1,87.691,2.5a19.254,19.254,0,0,1,7.154,4.653A19.267,19.267,0,0,1,99.5,14.309a34.341,34.341,0,0,1,2.14,11.553c.3,6.563.361,8.528.361,25.14s-.061,18.577-.361,25.14A34.5,34.5,0,0,1,99.5,87.694,20.6,20.6,0,0,1,87.691,99.5a34.342,34.342,0,0,1-11.553,2.14c-6.557.3-8.528.361-25.14.361s-18.577-.058-25.134-.361"
          data-name="Path 16"
        />
        <path
          fill="url(#b)"
          d="M25.865,101.639A34.341,34.341,0,0,1,14.312,99.5a19.329,19.329,0,0,1-7.154-4.653A19.181,19.181,0,0,1,2.5,87.694,34.341,34.341,0,0,1,.364,76.142C.061,69.584,0,67.617,0,51s.067-18.577.361-25.14A34.534,34.534,0,0,1,2.5,14.312,19.4,19.4,0,0,1,7.154,7.154,19.206,19.206,0,0,1,14.309,2.5,34.341,34.341,0,0,1,25.862.361C32.422.061,34.392,0,51,0s18.577.067,25.14.361A34.534,34.534,0,0,1,87.691,2.5a19.254,19.254,0,0,1,7.154,4.653A19.267,19.267,0,0,1,99.5,14.309a34.341,34.341,0,0,1,2.14,11.553c.3,6.563.361,8.528.361,25.14s-.061,18.577-.361,25.14A34.5,34.5,0,0,1,99.5,87.694,20.6,20.6,0,0,1,87.691,99.5a34.342,34.342,0,0,1-11.553,2.14c-6.557.3-8.528.361-25.14.361s-18.577-.058-25.134-.361"
          data-name="Path 17"
        />
        <path
          fill="#fff"
          d="M461.114,477.413a12.631,12.631,0,1,1,12.629,12.632,12.631,12.631,0,0,1-12.629-12.632m-6.829,0a19.458,19.458,0,1,0,19.458-19.458,19.457,19.457,0,0,0-19.458,19.458m35.139-20.229a4.547,4.547,0,1,0,4.549-4.545h0a4.549,4.549,0,0,0-4.547,4.545m-30.99,51.074a20.943,20.943,0,0,1-7.037-1.3,12.547,12.547,0,0,1-7.193-7.19,20.923,20.923,0,0,1-1.3-7.037c-.184-3.994-.22-5.194-.22-15.313s.04-11.316.22-15.314a21.082,21.082,0,0,1,1.3-7.037,12.54,12.54,0,0,1,7.193-7.193,20.924,20.924,0,0,1,7.037-1.3c3.994-.184,5.194-.22,15.309-.22s11.316.039,15.314.221a21.082,21.082,0,0,1,7.037,1.3,12.541,12.541,0,0,1,7.193,7.193,20.926,20.926,0,0,1,1.3,7.037c.184,4,.22,5.194.22,15.314s-.037,11.316-.22,15.314a21.023,21.023,0,0,1-1.3,7.037,12.547,12.547,0,0,1-7.193,7.19,20.925,20.925,0,0,1-7.037,1.3c-3.994.184-5.194.22-15.314.22s-11.316-.037-15.309-.22m-.314-68.509a27.786,27.786,0,0,0-9.2,1.76,19.373,19.373,0,0,0-11.083,11.083,27.794,27.794,0,0,0-1.76,9.2c-.187,4.04-.229,5.332-.229,15.623s.043,11.582.229,15.623a27.793,27.793,0,0,0,1.76,9.2,19.374,19.374,0,0,0,11.083,11.083,27.813,27.813,0,0,0,9.2,1.76c4.042.184,5.332.229,15.623.229s11.582-.043,15.623-.229a27.8,27.8,0,0,0,9.2-1.76,19.374,19.374,0,0,0,11.083-11.083,27.716,27.716,0,0,0,1.76-9.2c.184-4.043.226-5.332.226-15.623s-.043-11.582-.226-15.623a27.786,27.786,0,0,0-1.76-9.2,19.379,19.379,0,0,0-11.08-11.083,27.748,27.748,0,0,0-9.2-1.76c-4.041-.185-5.332-.229-15.621-.229s-11.583.043-15.626.229"
          data-name="Path 18"
          transform="translate(-422.637 -426.196)"
        />
      </svg>
    </div>
  );
}
