// import React from 'react'

// export function PolygonIcon1() {
//   return (
//     <svg xmlns="http://www.w3.org/2000/svg" width={18} height={20} viewBox="0 0 18 20">
//       <path id="Polygon_1" data-name="Polygon 1" d="M10,0,20,18H0Z" transform="translate(18) rotate(90)" fill="#bbb25a" />
//     </svg>

//   )
// }

// export function PolygonIcon2() {
//   return (
//     <svg xmlns="http://www.w3.org/2000/svg" width={18} height={20} viewBox="0 0 18 20">
//       <path id="Polygon_2" data-name="Polygon 2" d="M10,0,20,18H0Z" transform="translate(18) rotate(90)" fill="#3f9a98" />
//     </svg> 
//   )
// }

// export function PolygonIcon3() {
//   return (
//     <svg xmlns="http://www.w3.org/2000/svg" width={18} height={20} viewBox="0 0 18 20">
//       <path id="Polygon_3" data-name="Polygon 3" d="M10,0,20,18H0Z" transform="translate(18) rotate(90)" fill="#b95c5c" />
//     </svg>

//   )
// }

// export function PolygonIcon4() {
//   return (
//     <svg xmlns="http://www.w3.org/2000/svg" width={18} height={20} viewBox="0 0 18 20">
//       <path id="Polygon_3" data-name="Polygon 3" d="M10,0,20,18H0Z" transform="translate(18) rotate(90)" fill="#868080" />
//     </svg>

//   )
// }

// export function PolygonRnd1() {
//   return (
//     <img src={require('../../polygon/pass.png')} alt="pass"/>
//   )
// }

// export function PolygonRnd2() {
//   return (
//     <img src={require('../../polygon/error.png')} alt="error"/>
//   )
// }

// export function PolygonRnd3() {
//   return (
//     <img src={require('../../polygon/improve.png')} alt="pass"/>
//   )
// }

// export function PolygonRnd4() {
//   return (
//     <img src={require('../../polygon/information.png')} alt="pass"/>
//   )
// }

// export function PolygonRnd5() {
//   return (
//     <img  className='default_arrow' src={require('../../polygon/defaultarrow.png')} alt="pass"/>
//   )
// }

// export function PolygonRnd6() {
//   return (
//     <img className='selected_arrow'  src={require('../../polygon/selectedarrow.png')} alt="pass"/>
//   )
// }

