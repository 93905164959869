import { React } from 'react';
export const course_detail=[
    {
        sid:1,
        section_name:'Marketing Fundamentals',
        section_url:'marketing-fundamentals',
        videos:[
            {
                vid:1,
                video_name:'4 Ps of Marketing',
                video_url:'video-1',
                video:'https://ezio-video-courses.s3.ap-south-1.amazonaws.com/videos/Marketing-Fundamentals-Video-01.mp4',
                duration:'4.29',
            },
            {
                vid:2,
                video_name:'Example:Little Veggie Shop',
                video_url:'video-2',
                video:'https://ezio-video-courses.s3.ap-south-1.amazonaws.com/videos/Marketing-Fundamental-Video-02.mp4',
                duration:'1.33',
            },
        ]
    },
    {
        sid:2,
        section_name:'SEO On Page',
        section_url:'seo-on-page',
        videos:[
            {
                vid:3,
                video_name:'On-Page SEO & Off-Page SEO',
                video_url:'video-1',
                video:'https://ezio-video-courses.s3.ap-south-1.amazonaws.com/videos/SEO-ON-PAGE-VIDEO-01.mp4',
                duration:'1.21',
            },
            {
                vid:4,
                video_name:'On-Page SEO Techniques',
                video_url:'video-2',
                video:'https://ezio-video-courses.s3.ap-south-1.amazonaws.com/videos/SEO-ON-PAGE-VIDEO-02.mp4',
                duration:'3.04',
            },
            {
                vid:5,
                video_name:'Title Tags With Relevant Keywords',
                video_url:'video-3',
                video:'https://ezio-video-courses.s3.ap-south-1.amazonaws.com/videos/SEO-ON-PAGE-VIDEO-03.mp4',
                duration:'2.14',
            },
            {
                vid:6,
                video_name:'Unique And Fresh Content',
                video_url:'video-4',
                video:'https://ezio-video-courses.s3.ap-south-1.amazonaws.com/videos/SEO-ON-PAGE-VIDEO-04.mp4',
                duration:'1.21',
            },
            {
                vid:7,
                video_name:'Schema Markup',
                video_url:'video-5',
                video:'https://ezio-video-courses.s3.ap-south-1.amazonaws.com/videos/SEO-ON-PAGE-Video-05.mp4',
                duration:'1.24',
            },
            {
                vid:8,
                video_name:'Mobile Friendly Responsive Website',
                video_url:'video-6',
                video:'https://ezio-video-courses.s3.ap-south-1.amazonaws.com/videos/SEO-On-Page-Video-06.mp4',
                duration:'2.17',
            }
        ]
    },
    {
        sid:3,
        section_name:'SEO Off Page',
        section_url:'seo-off-page',
        videos:[
            {
                vid:9,
                video_name:'video 1',
                video_url:'video-1',
                video:'https://ezio-video-courses.s3.ap-south-1.amazonaws.com/videos/SEO-OFF-PAGE-VIDEO-07.mp4',
                duration:'2.28',
            },
            {
                vid:10,
                video_name:'video 2',
                video_url:'video-2',
                video:'https://ezio-video-courses.s3.ap-south-1.amazonaws.com/videos/SEO-OFF-PAGE-VIDEO-08.mp4',
                duration:'1.36',
            },
            {
                vid:11,
                video_name:'video 3',
                video_url:'video-3',
                video:'https://ezio-video-courses.s3.ap-south-1.amazonaws.com/videos/SEO-OFF-PAGE-VIDEO-09.mp4',
                duration:'1.34',
            },
            {
                vid:12,
                video_name:'video 4',
                video_url:'video-4',
                video:'https://ezio-video-courses.s3.ap-south-1.amazonaws.com/videos/SEO-OFF-PAGE-VIDEO-10.mp4',
                duration:'2.08',
            },
            {
                vid:13,
                video_name:'video 5',
                video_url:'video-5',
                video:'https://ezio-video-courses.s3.ap-south-1.amazonaws.com/videos/SEO-OFF-PAGE-VIDEO-11.mp4',
                duration:'1.06',
            },
        ]
    },
    {
        sid:4,
        section_name:'Google Analytics',
        section_url:'google-analytics',
        videos:[
            {
                vid:14,
                video_name:'video 1',
                video_url:'video-1',
                video:'https://ezio-video-courses.s3.ap-south-1.amazonaws.com/videos/Google-Analytics-Video-01.mp4',
                duration:'1.29',
            },
            {
                vid:15,
                video_name:'video 2',
                video_url:'video-2',
                video:'https://ezio-video-courses.s3.ap-south-1.amazonaws.com/videos/Google-Analytics-Video-02.mp4',
                duration:'1.39',
            }
        ]
    },
    {
        sid:5,
        section_name:'Google Tag Manager',
        section_url:'google-tag-manager',
        videos:[
            {
                vid:16,
                video_name:'video 1',
                video_url:'video-1',
                video:'https://ezio-video-courses.s3.ap-south-1.amazonaws.com/videos/google-tag-manager-video-03.mp4',
                duration:'1.46',
            },
            {
                vid:17,
                video_name:'video 2',
                video_url:'video-2',
                video:'https://ezio-video-courses.s3.ap-south-1.amazonaws.com/videos/google-tag-manager-video-04.mp4',
                duration:'2.18',
            }
        ]
    },
    {
        sid:6,
        section_name:'Role of PPC',
        section_url:'role-of-ppc',
        videos:[
            {
                vid:18,
                video_name:'video 1',
                video_url:'video-1',
                video:'https://ezio-video-courses.s3.ap-south-1.amazonaws.com/videos/Role-of-PPC-Video-01.mp4',
                duration:'1.57',
            },
            {
                vid:19,
                video_name:'video 2',
                video_url:'video-2',
                video:'https://ezio-video-courses.s3.ap-south-1.amazonaws.com/videos/Role-of-PPC-Video-02.mp4',
                duration:'3.10',
            },
            {
                vid:20,
                video_name:'video 3',
                video_url:'video-3',
                video:'https://ezio-video-courses.s3.ap-south-1.amazonaws.com/videos/Role-of-PPC-Video-03.mp4',
                duration:'2.21',
            },
            {
                vid:21,
                video_name:'video 4',
                video_url:'video-4',
                video:'https://ezio-video-courses.s3.ap-south-1.amazonaws.com/videos/Role-of-PPC-Video-04.mp4',
                duration:'1.53',
            },
            {
                vid:22,
                video_name:'video 5',
                video_url:'video-5',
                video:'https://ezio-video-courses.s3.ap-south-1.amazonaws.com/videos/Role-of-PPC-Video-05.mp4',
                duration:'2.47',
            },
            {
                vid:23,
                video_name:'video 6',
                video_url:'video-6',
                video:'https://ezio-video-courses.s3.ap-south-1.amazonaws.com/videos/Role-of-PPC-Video-06.mp4',
                duration:'1.58',
            },
            {
                vid:24,
                video_name:'video 7',
                video_url:'video-7',
                video:'https://ezio-video-courses.s3.ap-south-1.amazonaws.com/videos/Role-of-PPC-Video-07.mp4',
                duration:'1.07',
            },
            {
                vid:25,
                video_name:'video 8',
                video_url:'video-8',
                video:'https://ezio-video-courses.s3.ap-south-1.amazonaws.com/videos/Role-of-PPC-Video-08.mp4',
                duration:'1.30',
            },
            {
                vid:26,
                video_name:'video 9',
                video_url:'video-9',
                video:'https://ezio-video-courses.s3.ap-south-1.amazonaws.com/videos/Role-of-PPC-Video-09.mp4',
                duration:'0.41',
            },
            {
                vid:27,
                video_name:'video 10',
                video_url:'video-10',
                video:'https://ezio-video-courses.s3.ap-south-1.amazonaws.com/videos/Role-of-PPC-Video-10.mp4', 
                duration:'2.51',
            },
            {
                vid:28,
                video_name:'video 11',
                video_url:'video-11',
                video:'https://ezio-video-courses.s3.ap-south-1.amazonaws.com/videos/Role-of-PPC-Vídeo-11.mp4', 
                duration:'1.03',
            },
            {
                vid:29,
                video_name:'video 12',
                video_url:'video-12',
                video:'https://ezio-video-courses.s3.ap-south-1.amazonaws.com/videos/Role-of-PPC-Vídeo-12.mp4', 
                duration:'2.13',
            },
            {
                vid:30,
                video_name:'video 13',
                video_url:'video-13',
                video:'https://ezio-video-courses.s3.ap-south-1.amazonaws.com/videos/Role-of-PPC-Vídeo-13.mp4', 
                duration:'3.39',
            },
            {
                vid:31,
                video_name:'video 14',
                video_url:'video-14',
                video:'https://ezio-video-courses.s3.ap-south-1.amazonaws.com/videos/Role-of-PPC-Vídeo-14.mp4', 
                duration:'2.17',
            },
            {
                vid:32,
                video_name:'video 15',
                video_url:'video-15',
                video:'https://ezio-video-courses.s3.ap-south-1.amazonaws.com/videos/Role-of-PPC-Vídeo-15.mp4', 
                duration:'2.27',
            },
        ]
    },
    {
        sid:7,
        section_name:'Email marketing',
        section_url:'email-marketing',
        videos:[
            {
                vid:33,
                video_name:'video 1',
                video_url:'video-1',
                video:'https://ezio-video-courses.s3.ap-south-1.amazonaws.com/videos/email-marketing-video-01.mp4',
                duration:'2.49',
            },
            {
                vid:34,
                video_name:'video 2',
                video_url:'video-2',
                video:'https://ezio-video-courses.s3.ap-south-1.amazonaws.com/videos/email-marketing-video-02.mp4',
                duration:'2.29',
            },
            {
                vid:35,
                video_name:'video 3',
                video_url:'video-3',
                video:'https://ezio-video-courses.s3.ap-south-1.amazonaws.com/videos/email-marketing-video-03.mp4',
                duration:'2.31',
            },
            {
                vid:36,
                video_name:'video 4',
                video_url:'video-4',
                video:'https://ezio-video-courses.s3.ap-south-1.amazonaws.com/videos/email-marketing-video-04.mp4',
                duration:'2.08',
            },
        ]
    },
    {
        sid:8,
        section_name:'Content Marketing',
        section_url:'content-marketing',
        videos:[
            {
                vid:37,
                video_name:'video 1',
                video_url:'video-1',
                video:'https://ezio-video-courses.s3.ap-south-1.amazonaws.com/videos/content-marketing-video01.mp4',
                duration:'1.54',
            },
            {
                vid:38,
                video_name:'video 2',
                video_url:'video-2',
                video:'https://ezio-video-courses.s3.ap-south-1.amazonaws.com/videos/content-marketing-video-02.mp4',
                duration:'1.38',
            },
            {
                vid:39,
                video_name:'video 3',
                video_url:'video-3',
                video:'https://ezio-video-courses.s3.ap-south-1.amazonaws.com/videos/content-marketing-video-03.mp4',
                duration:'1.22',
            },
            {
                vid:40,
                video_name:'video 4',
                video_url:'video-4',
                video:'https://ezio-video-courses.s3.ap-south-1.amazonaws.com/videos/content-marketing-video-04.mp4',
                duration:'1.37',
            },
            {
                vid:41,
                video_name:'video 5',
                video_url:'video-5',
                video:'https://ezio-video-courses.s3.ap-south-1.amazonaws.com/videos/content-marketing-video-05.mp4',
                duration:'0.51',
            },
        ]
    },
    {
        sid:9,
        section_name:'Social Media Marketing',
        section_url:'social-media-marketing',
        videos:[
            {
                vid:42,
                video_name:'video 1',
                video_url:'video-1',
                video:'https://ezio-video-courses.s3.ap-south-1.amazonaws.com/videos/Social-Media-Marketing-Video-01.mp4',
                duration:'1.41',
            },
            {
                vid:43,
                video_name:'video 2',
                video_url:'video-2',
                video:'https://ezio-video-courses.s3.ap-south-1.amazonaws.com/videos/Social-Media-Marketing-Video-02.mp4',
                duration:'3.25',
            },
            {
                vid:44,
                video_name:'video 3',
                video_url:'video-3',
                video:'https://ezio-video-courses.s3.ap-south-1.amazonaws.com/videos/Social-Media-Marketing-Video-03.mp4',
                duration:'3.10',
            },
            {
                vid:45,
                video_name:'video 4',
                video_url:'video-4',
                video:'https://ezio-video-courses.s3.ap-south-1.amazonaws.com/videos/Social-Media-Marketing-Video-04.mp4',
                duration:'1.37',
            },
            {
                vid:46,
                video_name:'video 5',
                video_url:'video-5',
                video:'https://ezio-video-courses.s3.ap-south-1.amazonaws.com/videos/Social-Media-Marketing-Video-05.mp4',
                duration:'1.21',
            },
        ]
    },
]